import React from "react"
import propTypes from "prop-types"
import Styled from "styled-components"
import {Note} from "@uprise/text"

const TextWrapper = Styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: baseline;
	position: relative;

	& textarea {
		padding: 8px;
	}

	& .invalid-feedback {
		display: block;
		position: absolute;
		bottom: -20px;
		left: 0;
	}
`

const Text = ({value, valid, handleChange}) => {
	return (
		<TextWrapper>
			<textarea
				className={`text-input form-control ${valid ? (valid.valid ? "is-valid" : "is-invalid") : ""} input-sm`}
				placeholder={"Your answer"}
				value={value}
				onChange={e => handleChange(e.target.value)}></textarea>
			{valid && valid.message && <Note className='invalid-feedback'>{valid.message}</Note>}
		</TextWrapper>
	)
}

Text.propTypes = {
	value: propTypes.string,
	handleChange: propTypes.func.isRequired
}

Text.defaultProps = {
	value: ""
}

export default Text
