import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {Link} from "react-router-dom"
import {backgrounds} from "@uprise/colors"

const CoachImage = Styled.img`
  width: 185px;
  height: 185px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const Content = Styled.div`
  padding: ${spacing.s3} ${spacing.s5} ${spacing.s5} ${spacing.s5};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 70px;
  overflow: hidden;
`

const ContentWrap = Styled.section`
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
`

const CardWrap = Styled(Link)`
	transition: .3s ease;
	&:hover {
    transform: translateY(-10px) scale(1.02);
	}
`

const Card = Styled.div`
  borderRadius: 5.4px;
  overflow: hidden;
  box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
  background-color: ${backgrounds.white};
  width: ${props => props.width};
  height: ${props => props.height};
`

export {CoachImage, Content, ContentWrap, CardWrap, Card}
