import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import ReactPlayer from "react-player"

// UI
import {Card} from "@uprise/card"
// Api
import {post} from "helpers/api"
// Actions
import {completeTask} from "actions/taskActions"
// Language codes
import languages from "./languageCodes"

const VideoStyle = Styled.section`
      width: 100%;
      height: 100%;
`

const PlayerContent = Styled.section`
	width: 100%;
      display: flex;
      flex-direction: row;
`

const VideoTask = ({className, skillId, video, activity, completeTask, userState, width = '100%', height = '100%'}) => {
	const [sendRequest, setSendRequest] = useState(false)

	useEffect(() => {
		const {taskId} = video
		// Send complete task API request
		if (sendRequest) {
			post("complete", {
				module: taskId,
				completed: true
			})
				.then(resp => {
					completeTask(resp.user_state, skillId)
				})
				.catch(error => {
					console.log("VideoTask -> error", error)
				})
				.finally(() => setSendRequest(false))
		}
	}, [sendRequest])

	const onTimeUpdate = ({played}) => {
		const {taskId} = video
		if (played > 0.8 && !activity[taskId]?.completed && !sendRequest) {
			setSendRequest(true)
		} else setSendRequest(false)
	}

	const locale = window.Localize.getLanguage()

	const getSubtitles = () => {
		return languages
			.map(language => {
				return getTrack(language.code, language.name)
			})
			.filter(track => track !== undefined)
	}

	const subTitleExist = langCode => {
		const {taskId} = video
		try {
			return require(`assets/videos/subtitles/${taskId}-${langCode}.vtt`)
		} catch (err) {
			return null
		}
	}

	const getTrack = (langCode, langName) => {
		const {taskId} = video
		if (subTitleExist(langCode)) {
			return {
				kind: "subtitles",
				src: require(`assets/videos/subtitles/${taskId}-${langCode}.vtt`),
				srcLang: langName,
				default: langCode === locale ? true : false
			}
		}
	}

	return (
		<VideoStyle className={className}>
			<Card className='m-b-10' shadow={true} width='auto'>
				<PlayerContent>
					<ReactPlayer
						data-testid={`video-button`}
						url={video.videoURI}
						config={{
							vimeo: {
								playerVars: {showinfo: 1}
							},
							file: {
								attributes: {
									controlsList: "nodownload",
									onContextMenu: e => e.preventDefault()
								},
								tracks: getSubtitles()
							}
						}}
						volume={0.6}
						muted={false}
						playing={true}
						controls={true}
						onProgress={onTimeUpdate}
						width={width}
						height={height}
					/>
				</PlayerContent>
			</Card>
		</VideoStyle>
	)
}

const mapDispatchToProps = dispatch => ({
	completeTask: (state, skillId) => dispatch(completeTask(state, skillId))
})

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTask)
