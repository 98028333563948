import moment from "moment-timezone"
// Types
import {LOGGED_IN} from "./types"
// Actions
import {storeuserState} from "actions/loginActions"
// Helpers
import {post, get, setJwt} from "helpers/api"

const setTimezone = timezone => {
	moment.tz.setDefault(timezone)

	post("setUserTimezone", {timezone})
		.then(resp => {
			// console.log(resp)
		})
		.catch(err => {
			console.log(err)
		})
}

const handleTrial = (dispatch, response) => {
	const now = moment(new Date())
	const createdAt = moment(response.createdAt)
	const diff = now.diff(createdAt)
	const timeLeft = moment.duration(response.user_state.features.trialLength * (86400 * 1000) - diff)
	dispatch(
		storeuserState({
			...response.user_state,
			...{timeLeft: timeLeft}
		})
	)
}

export function onLoggedIn(response, nextPathname = "/", history) {
	return dispatch => {
		setJwt(response.jwt)
		dispatch(loggedIn(response.jwt))

		if (response?.user_state?.features?.trialLength) {
			handleTrial(dispatch, response)
		} else {
			setIntercomData(response.user_state)

			dispatch(
				storeuserState({
					...response?.data,
					...response.user_state,
					...{createdAt: response.createdAt}
				})
			)
		}

		if (moment.tz.guess()) {
			setTimezone(moment.tz.guess())
		}

		history.push(nextPathname)
	}
}

const setIntercomData = userState => {
	analytics.identify(userState.externalkey, {
		name: userState.name,
		email: userState.email,
		phone: userState.phone,
		countryCode: userState.countryCode,
		weekNumber: userState.weekNumber,
		course: userState.course,
		employerCode: userState.employerCode,
		intercomCoach: userState.intercomCoach
	})
}

export const fetchAdditionalSessions = userState => {
	return dispatch => {
		get("user/additionalSessions")
			.then(resp => {
				if (!resp.error) {
					dispatch(updateUserState({...userState, ...resp.data}))
				}
			})
			.catch(err => {
				console.log(err)
			})
	}
}

export function updateUserState(userState) {
	return dispatch => {
		if (userState) {
			dispatch(storeuserState(userState))
		}
	}
}

export function loggedIn(jwt) {
	return {
		type: LOGGED_IN,
		jwt
	}
}
