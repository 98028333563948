import React, {Component, Fragment, useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import FormValidate from "form-validate.js"

// Api
import {post} from "helpers/api"
// Components
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H2, H3, H4} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
import {TextInputHorizontal} from "@uprise/form"
import {Alert} from "@uprise/alert"

// Validation
import constraints from "./validation.js"
// Colors
import {primary, extended, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
import {getWhiteLabelDomain, isWhiteLabeledApp} from "helpers/utils.js"

const PrivacyStyles = Styled.section`

`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: row;
      width: 100%;

      &:hover {
            cursor: pointer
      }
`

const FormWrap = Styled.section`
      display: flex;
	flex-direction: column;
`

const Form = Styled.form`
      width: 100%;
`

const Table = Styled.table`
      width: 100%;
`

const Tbody = Styled.tbody`
`

const Input = Styled.input`
      margin-bottom: ${spacing.s3};
`

const Tr = Styled.tr`
      margin-bottom: ${spacing.s3};
`

const Ul = Styled.ul`
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
      margin-bottom: ${spacing.s3};
`

const Td = Styled.td`
      border-bottom: 1px solid ${extended.purple.five};
      padding-top: ${spacing.s3};
      padding-bottom: ${spacing.s3};

      &:first-child {
            padding-top: 0;
      }
`

const Privacy = ({className, userState}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState("")
	const [emailSent, setEmailSent] = useState(false)
	const [emailRetrieveSent, setEmailRetrieveSent] = useState(false)
	const [emailDeleteSent, setEmailDeleteSent] = useState(false)
	const [showDeleteForm, setShowDeleteForm] = useState(false)
	const [feedback, setFeedback] = useState({})
	const [email, setEmail] = useState(userState.email)
	const [password, setPassword] = useState("")
	const [showDelete, setShowDelete] = useState(false)

	// Form
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()

	useEffect(() => {
		const defaultValues = {email}
		const validator = new FormValidate(constraints, null, defaultValues)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			setValidation(controls)
		})
	}

	const _downloadData = () => {
		setIsLoading(true)
		setShowDeleteForm(false)
		let data = {}
		if (isWhiteLabeledApp()) {
			data["company"] = getWhiteLabelDomain().toUpperCase()
		}

		post("settings/user/retrieve/email", data)
			.then(resp => {
				if (resp.error) {
					setError("Something went wrong! Please contact technical support")
				} else {
					setEmailRetrieveSent(true)
				}
			})
			.catch(err => {
				setError("An error has occurred")
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const _deleteData = () => {
		setShowDelete(true)
	}

	const _confirmDelete = () => {
		setError()
		setEmailDeleteSent()

		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			setIsLoading(true)
			let data = {
				feedback: feedback,
				email: email,
				password: password
			}
			if (isWhiteLabeledApp()) {
				data["company"] = getWhiteLabelDomain().toUpperCase()
			}
			post("settings/user/delete/email", data)
				.then(resp => {
					if (resp?.error?.error === "BadPassword") {
						setError("Incorrect Password")
						setEmailSent(false)
						setShowDeleteForm(false)
						setIsLoading(false)
					} else {
						setShowDeleteForm(false)
						setEmailDeleteSent(true)
						setEmailRetrieveSent(false)
						setIsLoading(false)
						return resp
					}
				})
				.catch(err => {
					console.log(err)
					setError("An error has occured")
				})
		}
	}

	const _handleReason = reason => {
		const feedback = {
			privacy: false,
			emails: false,
			accident: false,
			undisclosed: false
		}

		feedback[reason] = true

		setFeedback(feedback)
	}

	return (
		<PrivacyStyles className={className}>
			<H3 className='m-b-4'>Privacy</H3>
			<Card shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
				{!showDelete ? (
					<ContentWrap>
						<Button
							className='m-r-4'
							variant='tertiary'
							size='extra-small'
							width='auto'
							title='Download your data'
							onClick={() => _downloadData()}
						/>
						<Button
							variant='tertiary'
							size='extra-small'
							width='auto'
							title='Delete your account'
							onClick={() => _deleteData()}
						/>
					</ContentWrap>
				) : (
					<FormWrap>
						<H4 className='m-b-4'>Delete Account</H4>
						<Form className='m-b-4' onChange={event => _validateForm(event)}>
							<Table>
								<Tbody>
									<Tr>
										<Td width='20%'>
											<Medium weight='bold' color={primary.charcoal}>
												Email
											</Medium>
										</Td>
										<Td>
											<Medium>{userState.email}</Medium>
										</Td>
									</Tr>
									<Tr>
										<Td width='20%'>
											<Medium weight='bold' color={primary.charcoal}>
												Reason for delete
											</Medium>
										</Td>

										<Td>
											<Ul>
												<Li>
													<Input
														className='m-r-4 m-b-0'
														type='radio'
														name='reason'
														onClick={() => _handleReason("privacy")}
													/>
													<Medium>I have concerns about my privacy</Medium>
												</Li>
												<Li>
													<Input
														className='m-r-4 m-b-0'
														type='radio'
														name='reason'
														onClick={() => _handleReason("emails")}
													/>
													<Medium>I created an account by accident</Medium>
												</Li>
												<Li>
													<Input
														className='m-r-4 m-b-0'
														type='radio'
														name='reason'
														onClick={() => _handleReason("accident")}
													/>
													<Medium>I would like to stop receiving emails from Uprise</Medium>
												</Li>
												<Li>
													<Input
														className='m-r-4 m-b-0'
														type='radio'
														name='reason'
														onClick={() => _handleReason("undisclosed")}
													/>
													<Medium>
														It’s something else / I would not like to disclose my reason
													</Medium>
												</Li>
											</Ul>
										</Td>
									</Tr>
									<Tr>
										<Td>
											<Medium weight='bold' color={primary.charcoal}>
												Password
											</Medium>
										</Td>
										<Td>
											<TextInputHorizontal
												value={password}
												onChange={e => setPassword(e.target.value)}
												name='password'
												id='password'
												placeholder='*********'
												type='password'
												isRequired={true}
												validation={validation}
											/>
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</Form>
						<ContentWrap>
							<Button
								className='m-r-4'
								variant='tertiary'
								size='extra-small'
								width='auto'
								title='Cancel'
								onClick={() => setShowDelete(false)}
							/>
							<Button
								variant='primary'
								size='extra-small'
								width='auto'
								title='Confirm delete your account'
								onClick={() => _confirmDelete()}
							/>
						</ContentWrap>

						{emailDeleteSent && (
							<ContentWrap className='m-t-5'>
								<Alert type='success'>Please check your inbox to confirm deletion</Alert>
							</ContentWrap>
						)}
					</FormWrap>
				)}

				{error && (
					<ContentWrap className='m-t-5'>
						<Alert type='error'>{error}</Alert>
					</ContentWrap>
				)}
				{emailRetrieveSent && (
					<ContentWrap className='m-t-5'>
						<Alert type='success'>Success: We have sent a link to your data via your email inbox</Alert>
					</ContentWrap>
				)}
			</Card>
		</PrivacyStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(Privacy)
