import React from "react"
import Styled from "styled-components"
// UI
import {H2} from "@uprise/headings"
import {P} from "@uprise/text"
import {backgrounds} from "@uprise/colors"
// Images
const corporateLandingArt = require("assets/images/art/landing.svg")

const Art = Styled.img`
	width: 480px;
	height: 376px;
	margin-bottom: 50px;

	@media (max-width: 1024px) {
		width: 350px;
	}
`

const WorkableLogoImage = Styled.img`
	width: 50%;
`
const Note = Styled.p`
	font-size:12px;
	padding:10px;
	text-align:justify;
	width:500px;
	border-radius:10px;
	background-color:${backgrounds.fadedPurple}
`

const RightContentAllianz = () => {
	return (
		<div>
			<H2 className='m-b-5' width='358px'>
				Making minds happier.
			</H2>
			<P width='405px' mediumWidth='305px' className='m-b-2'>
				Allianz Mental Wellbeing Service helps you improve happiness, reduce stress & increase performance.
			</P>
			<Art src={corporateLandingArt} alt='' />
			<Note width='405px' mediumWidth='305px'>
				<b>IMPORTANT</b>: Allianz Mental Wellbeing Service does not provide clinical counselling or therapy,
				medical advice, treatment, or diagnosis. The Allianz Mental Wellbeing Service is not a substitute for
				medical advice or treatment. The services provided by Uprise are not designed for crisis management. If
				you are in need of medical treatment or emergency care, please seek immediate assistance from a medical
				or mental health professional.
			</Note>
		</div>
	)
}

export default RightContentAllianz
