// Utils
import * as surveys from "uprise-surveys/lib/surveys/index"

const RTW_SURVEY_LABELS = {
	RTWDEMOG: "Page 1",
	PHQ9: "Page 2",
	GAD7: "Page 3",
	PSS4RTW: "Page 4",
	WHO5RTW: "Page 5",
	WRKBTY: "Page 6",
	SICK: "Page 7",
	HRQOL: "Page 8",
	ECOVAL: "Page 9",
	CSQ8: "Page 10"
}
/**
 *
 * @param {Object} userState | User state from server response
 * @returns {Array} itemsList
 */
export function getRTWItemsList(userState) {
	const firstItem = {title: "Program Overview", header: "Let's get started with Workable", active: true}
	const lastItem = {title: "All Set", header: "All set", active: false}
	let screeners = []
	if (userState) {
		screeners = getScreeners(userState).map(i => {
			return {id: i, title: RTW_SURVEY_LABELS[i], header: `Pre-program Assessment`, active: false}
		})
	}
	return [firstItem, ...screeners, lastItem]
}

// rtw utils
export const defaultCoachRTW = "jay@uprise.co"

export function getScreeners(userState) {
	const task = userState.modules.onboarding.tasks.assesment.tasks["survey-onboarding-rtw"]
	return surveys.retrieveSurvey(task.surveyId).questions.reduce((screeners, question) => {
		if (screeners.indexOf(question.batteryId) === -1) {
			screeners.push(question.batteryId)
		}
		return screeners
	}, [])
}
