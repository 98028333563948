import React, {Fragment} from "react"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"

// Colors
import {backgrounds, extended} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {Medium} from "@uprise/text"
import {H3, H5} from "@uprise/headings"
// spacing
import {spacing} from "@uprise/spacing"

const TaskSummaryStyles = Styled.section`
`

const Content = Styled.div`
	padding: ${spacing.s6}
`

const Ul = Styled.ul`
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
	flex-direction: column;
      margin-bottom: ${spacing.s6};
	padding-bottom: ${spacing.s5};
	border: 1px solid ${extended.purple.four};
	border-right: 0;
	border-left: 0;
	border-top: 0;

	&:last-child {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
`

const TaskSummary = ({className, result, survey}) => {
	const summary = survey.getSummaryResults(result?.answers)

	return (
		<TaskSummaryStyles className={className}>
			<H3>Summary</H3>
			<Medium className='m-b-4'>{moment.unix(result.timestamp).format("DD MMMM YYYY")}</Medium>

			<Card className='m-b-10' backgroundColor={backgrounds.white} shadow={true}>
				<Content>
					<Ul>
						{summary.map((item, index) => {
							return (
								<Li key={index}>
									<H5 className='m-t-0 m-b-3'>{item.question.meta.summaryTitle}</H5>
									<Medium>{item.answer}</Medium>
								</Li>
							)
						})}
					</Ul>
				</Content>
			</Card>

			{survey.exerciseGoal ? (
				<Fragment>
					<H3 className='m-b-4'>Exercise Goal</H3>
					<Card backgroundColor={backgrounds.white} shadow={true}>
						<Content>
							<Medium>{survey.exerciseGoal}</Medium>
						</Content>
					</Card>
				</Fragment>
			) : null}
		</TaskSummaryStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(TaskSummary)
