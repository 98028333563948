import React from "react"
import {string} from "prop-types"

const SelectOption = ({name, value, index}) => {
	return (
		<option name={name} value={value}>
			{value}
		</option>
	)
}

SelectOption.propTypes = {
	className: string,
	name: string
}

SelectOption.defaultProps = {
	className: "",
	name: ""
}

export default SelectOption
