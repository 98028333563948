export const perspectiveTaking = [
	{
		_id: 0,
		text: "Welcome to perspective taking",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "This exercise is easier if you’ve already watched the video about the skill.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 4,
			optionType: "radio",
			replyType: "survey",
			questions: [0, 1, 2, 3],
			recurring: true,
			surveyId: "perspective-taking-v1",
			taskId: "exercise-perspective-taking",
			values: [
				{
					label: "Show me the video that explains Perspective Taking",
					videoURI:
						"https://player.vimeo.com/external/218727492.sd.mp4?s=ce19caafeb7278fe9c3e4aa97b144b7e35c3a88f&profile_id=165",
					vimeoId: "246222303",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 3,
						_next: 4,
						optionType: "radio",
						replyType: "survey",
						questions: [0, 1, 2, 3],
						recurring: true,
						surveyId: "perspective-taking-v1",
						taskId: "exercise-perspective-taking",
						values: [
							{
								label: "Thanks, i've watched the video",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve already watched it. Let’s get started.",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "Thanks for doing the exercise with me. 🏋️",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_next: 6,
		text: "The goal was to build emotional intelligence and have better communication.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text:
			"That’s because we know people that can take the perspective of someone else tend to be rated by others as being better partners, friends or leaders.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_end: true,
		text: "👋 See you next time, I'll take you back.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
