import React, {Fragment} from "react"
import {useHistory, useLocation} from "react-router-dom"
import Styled from "styled-components"

// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// spacing
import {spacing} from "@uprise/spacing"

// Components
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Medium, ExtraSmall} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"

const CardWrap = Styled.div`
      transition: .3s ease;
      &:hover {
            transform: translateY(-10px) scale(1.02);
	}
	
	&:last-child {
		margin-right: 0;
	}
`

const SkillImage = Styled.img`
      height: 114px;
	width: 185px;
      min-width: 185px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`

const Content = Styled.div`
	padding: ${spacing.s3} ${spacing.s5} ${spacing.s5} ${spacing.s5};
      height: 116px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;


`

const CardContent = Styled.div`
      display: flex;
      flex-direction: column;

      &:hover {
            cursor: pointer;
      }
 `

const TextWrap = Styled.div`

`

const SeriesCard = ({
	className,
	seriesId,
	seriesSkills,
	skills,
	series,
	meta,
	navigation,
	index,
	courseType,
	trial,
	set
}) => {
	let history = useHistory()
	let location = useLocation()

	let completedTasks = 0
	let totalTasks = 0

	seriesSkills.forEach(skill => {
		completedTasks = completedTasks += meta[skill].completedTasks
		totalTasks = totalTasks += meta[skill].totalTasks
	})

	let footer = `Series | ${seriesSkills.length} Skills`

	const handleClick = () => {
		history.push(`/series/${seriesId}`, {from: location.pathname})
		// analytics.track("series.click", {seriesId: seriesId})
	}

	return (
		<CardWrap className={className}>
			<Card
				onClick={() => handleClick()}
				backgroundColor={backgrounds.white}
				shadow={true}
				width='185px'
				height='231px'>
				<CardContent>
					<SkillImage src={thumbnails[seriesId]} />
					<ProgressBar percentage={Math.round((100 * completedTasks) / totalTasks)} height='4px' radius={0} />
					<Content>
						<TextWrap>
							<H6 className='m-t-0 m-b-0'>{series[seriesId].title}</H6>
							<ExtraSmall color={extended.charcoal.one}>{series[seriesId].shortDesc}</ExtraSmall>
						</TextWrap>
					</Content>
				</CardContent>
			</Card>
		</CardWrap>
	)
}

export default SeriesCard
