import React from "react"
import {connect} from "react-redux"

// Components
import UpcomingCard from "components/Shared/Bookings/Upcoming/UpcomingCard"
// UI
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
// Colors
import {extended} from "@uprise/colors"
// styles
import {UpcomingStyles} from "components/Shared/Bookings/index.style"

const Upcoming = ({bookings, coaches}) => {
	return (
		<UpcomingStyles className='m-b-4'>
			{!!bookings.length && (
				<>
					<H3 className='m-b-0-5'>Upcoming</H3>
					<Medium color={extended.charcoal.one} className='m-b-6'>
						This is your next Uprise Coach session
					</Medium>
				</>
			)}
			{bookings.map(booking => (
				<UpcomingCard booking={booking} key={booking.id} coaches={coaches} />
			))}
		</UpcomingStyles>
	)
}

export default connect(null, null)(Upcoming)
