import React from "react"
import Styled from "styled-components"
import {connect} from "react-redux"
// Utils
import {taskIdWhitelist} from "helpers/utils"
// Components
import {H3} from "@uprise/headings"
import ExerciseCard from "components/Shared/ExerciseResults/ExerciseCard"

const ExerciseResultsStyles = Styled.section`
`

const ExerciseResultsWrap = Styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
`

const ExerciseResults = ({className, userState, exercises}) => {
	return (
		<ExerciseResultsStyles className={className}>
			<H3 className='m-b-6'>Your completed exercises</H3>
			<ExerciseResultsWrap>
				{exercises.map((result, index) => {
					if (taskIdWhitelist.includes(result.surveyId)) {
						console.log(result)
						console.log(result.surveyId)
						return (
							<ExerciseCard
								className={`m-b-10 m-r-5`}
								key={index}
								result={result}
								homework={userState.homework}
								lessons={userState.lessons}
								bonus={userState.bonus}
								tasks={userState.tasks}
								style={{
									marginRight: index % 2 ? -7.5 : 7.5,
									marginLeft: index % 2 ? 7.5 : -7.5
								}}
							/>
						)
					}
				})}
			</ExerciseResultsWrap>
		</ExerciseResultsStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(ExerciseResults)
