import {STORE_COACH, LOADING_COACH} from "actions/types"

export default function(state = {}, action) {
	switch (action.type) {
		case LOADING_COACH: {
			return {...state, ...action.payload}
		}
		case STORE_COACH: {
			return {...action.payload}
		}
		default:
			return {
				...state
			}
	}
}
