import React from "react"
import {func} from "prop-types"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {SurveyScores} from "components/Shared/Chat/SurveyScores"

// utils
import {retrieveResults} from "uprise-surveys/lib/surveys/getScore"

import {isWhiteLabeledApp} from "helpers/utils"

export const OnboardingScores = ({children, handleNext, surveyResults, dialogueId}) => {
	let surveyId
	let screenerIds = []

	switch (dialogueId) {
		case "onboarding-corporate":
			surveyId = isWhiteLabeledApp() ? "onboarding-allianz-v1" : "onboarding-v1.1"
			screenerIds = ["who5", "stress"]
			break
		case "wellbeingStressWeekly":
			surveyId = "survey-stress-wellbeing-weekly"
			screenerIds = ["who5", "stress"]
			break
		case "wellbeingStressMonthly":
			surveyId = "survey-stress-wellbeing-monthly"
			screenerIds = ["who5", "stress"]
			break
		case "onboarding-students":
			surveyId = "onboarding-students-v1"
			screenerIds = ["who5", "stress"]
			break
		case "wellbeingCheck":
			surveyId = "wellbeing-check-v1"
			screenerIds = ["who5"]
			break
		case "stressCheck":
			surveyId = "stress-check-v1"
			screenerIds = ["stress"]
			break
		case "stressCheckStudents":
			surveyId = "stress-check-v1"
			screenerIds = ["stress"]
			break
	}

	let screener1, screener2

	let survey = surveyResults
		.filter(result => result.surveyId === surveyId)
		.reduce((a, b) => (a.id > b.id ? a : b), [])

	console.log("survey", survey)
	const result = retrieveResults(survey)

	if (screenerIds.length > 1) {
		console.log(screenerIds)
		console.log(result)

		screener1 = result[screenerIds[0]]
		screener2 = result[screenerIds[1]]
	} else {
		screener1 = result[screenerIds[0]]
	}

	return (
		<Container>
			<Row>
				<Col className='col-md-6'>
					<Card
						border={`1px solid ${extended.purple.five}`}
						shadow={false}
						backgroundColor={backgrounds.white}
						padding={spacing.s5}
						width='100%'>
						{screener1 && <SurveyScores screener={screener1} />}
					</Card>
				</Col>
				<Col className='col-md-6'>
					<Card
						border={`1px solid ${extended.purple.five}`}
						shadow={false}
						backgroundColor={backgrounds.white}
						padding={spacing.s5}
						width='100%'>
						{screener2 && <SurveyScores screener={screener2} />}
					</Card>
				</Col>
			</Row>
			<Row className='justify-content-center align-items-center'>
				<Button
					data-testid='next-button'
					className='m-t-10'
					variant='primary'
					title='Next'
					size='large'
					fullWidth={false}
					width='360px'
					disabled={false}
					onClick={() => handleNext()}
				/>
			</Row>
		</Container>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
OnboardingScores.propTypes = {
	handleNext: func.isRequired
}

OnboardingScores.defaultProps = {}
