import React, {useState, useEffect} from "react"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import FormValidate from "form-validate.js"

// Colors
import {backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Validation
import constraints from "validation/phone"
// UI
import {Button} from "@uprise/button"
import {TextInput} from "@uprise/form"
import {Alert} from "@uprise/alert"
import {H3} from "@uprise/headings"
import {Modal} from "@uprise/modal"
// Api
import {post} from "helpers/api"

const Form = Styled.form`
      width: 100%;
`

export const ChangeNumberModal = ({isOpen, handleClose, phone, updateUserState}) => {
	const [phoneNumber, setPhoneNumber] = useState(phone)
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [error, setError] = useState({type: "", value: ""})

	useEffect(() => {
		const defaultValues = {phone: phoneNumber}
		const validator = new FormValidate(constraints, null, defaultValues)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			setValidation(controls)
		})
	}

	const _handleSave = () => {
		setError("")

		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			post("settings/user", {
				phone: phoneNumber
			}).then(resp => {
				if (resp.error) {
					setError("Error please contact tech support")
				} else {
					updateUserState(resp.user_state)
					handleClose()
				}
			})
		}
	}

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='left'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-6'>Change phone number</H3>

			<Form className='m-b-10' onChange={event => _validateForm(event)}>
				<TextInput
					type='text'
					id='phone'
					name='phone'
					label='Phone number'
					value={phoneNumber}
					onChange={e => setPhoneNumber(e.target.value)}
					isLast={true}
					isRequired={true}
					validation={validation}
					placeholder='040 000 000'
				/>

				{error.length > 0 && <Alert type='error'>{error}</Alert>}
			</Form>

			<Button variant='primary' size='medium' title='Save' onClick={() => _handleSave()} />
		</Modal>
	)
}
