const Array1 = [
	`Red`,
	`Blue`,
	`Green`,
	`Pink`,
	`Black`,
	`Brown`,
	`White`,
	`Cyan`,
	`Violet`,
	`Purple`,
	`Gray`,
	`Orange`,
	`Yellow`,
	`Silver`
]
const Array2 = [
	`Koala`,
	`Platypus`,
	`Kangaroo`,
	`Emu`,
	`Wombat`,
	`Quokka`,
	`Echidna`,
	`Kookaburra`,
	`Wallaby`,
	`Bandicoot`,
	`Cockatoo`,
	`Dugong`,
	`Bilby`,
	`Woylie`
]

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

const generateRandomName = () => {
	return `${getRandomFromArray(Array1)} ${getRandomFromArray(Array2)}`
}

export {generateRandomName}
