import React, {useEffect, useState} from "react"
import {Layout} from "components/Shared/Layout"
import {backgrounds} from "@uprise/colors"
import styled from "styled-components"
// import {useAuth0, User} from "@auth0/auth0-react"
import {Button} from "@uprise/button"
import {post} from "helpers/api"
import toast, {Toaster} from "react-hot-toast"
const AllianzAdminChangePassword = props => {
	const storageItems = JSON.parse(sessionStorage.getItem("params"))
	const type = storageItems?.type
	const email = storageItems?.email
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [token, setToken] = useState("")

	const Logo = styled.img`
		width: 200px;
	`
	const ButtonWrap = styled.div`
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 300px;
		align-self: center;
	`
	const onSubmit = () => {
		const pwdRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/
		if (!password || !confirmPassword || !token) {
			toast.error("Please enter valid inputs")
			return
		}
		if (password && !pwdRegex.test(password)) {
			toast.error(`Password strength doesn't match`)
			return
		}
		if (password !== confirmPassword) {
			toast.error(`Password and confirm password doesn't match`)
			return
		}
		post("allianz-admin/change-password", {
			email,
			password,
			token
		})
			.then(response => {
				if (response?.code === "success") {
					sessionStorage.clear()
					if (type === "AU") {
						props.history.replace("/approve-reject/a1011u")
					} else if (type === "NZ") {
						props.history.replace("/approve-reject/n2021z")
					}
				}
			})
			.catch(error => {
				console.log(error)
				toast.error("Error while changing password, please try again")
			})
	}
	const onLogout = () => {
		sessionStorage.clear()
		if (type === "AU") {
			props.history.replace("/approve-reject/a1011u")
		} else if (type === "NZ") {
			props.history.replace("/approve-reject/n2021z")
		}
	}
	const renderEmptyPage = () => {
		return (
			<div
				style={{
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					display: "flex",
					flex: 1
				}}>
				<Logo src={require("../assets/images/allianz/logo.png")} alt='allianz-logo' />
				<b>You are unauthorised to view this page, please use valid credentials to login.</b>
			</div>
		)
	}
	return (
		<div style={{flex: 1, display: "flex", flexDirection: "column"}}>
			{type && email && (
				<>
					<div
						style={{
							display: "flex",
							flex: 0.2,
							justifyContent: "flex-end",
							alignItems: "center",
							width: "50%",
							alignSelf: "center"
						}}>
						<button
							style={{
								border: "none",
								padding: 20,
								backgroundColor: "lightgrey",
								borderRadius: 30,
								width: 30,
								height: 30,
								justifyContent: "center",
								alignItems: "center",
								display: "flex",
								fontSize: 20,
								marginTop: 20,
								marginBottom: 20
							}}
							onClick={() => props.history.goBack()}>
							X
						</button>
					</div>
					<div
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							flexDirection: "column"
						}}>
						<Logo src={require("../assets/images/allianz/logo.png")} alt='allianz-logo' />
						<label style={{fontSize: 12, marginTop: 10, color: "red"}}>
							Password should contain lowercase, uppercase, number &
						</label>
						<label style={{fontSize: 12, color: "red"}}>
							special character and should be of 8 to 15 characters
						</label>

						<input
							type='password'
							placeholder='Password'
							value={password}
							onChange={e => setPassword(e.target.value)}
							style={{
								width: 300,
								marginTop: 20,
								marginBottom: 20,
								padding: 10,
								borderWidth: 0.7,
								borderColor: "#ccc",
								borderRadius: 5,
								height: 45
							}}
						/>

						<input
							style={{
								width: 300,
								marginBottom: 20,
								padding: 10,
								borderWidth: 0.7,
								borderColor: "#ccc",
								borderRadius: 5,
								height: 45
							}}
							type='password'
							placeholder='Confirm Password'
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
						/>
						<input
							style={{
								width: 300,
								marginBottom: 20,
								padding: 10,
								borderWidth: 0.7,
								borderColor: "#ccc",
								borderRadius: 5,
								height: 45
							}}
							type='text'
							placeholder='Token'
							value={token}
							onChange={e => setToken(e.target.value)}
						/>

						<ButtonWrap>
							<Button
								variant='primary'
								className='m-b-2'
								data-testid='sign-in-button'
								title={"Submit"}
								size='large'
								fullWidth={true}
								disabled={false}
								onClick={onSubmit}
							/>
						</ButtonWrap>

						<button
							style={{
								border: "none",
								backgroundColor: "transparent",
								padding: 20,
								height: 40,
								fontSize: 16,
								color: "red",
								textDecoration: "underline"
							}}
							onClick={onLogout}>
							Logout
						</button>
					</div>
				</>
			)}

			{(!type || !email) && renderEmptyPage()}
			<Toaster />
		</div>
	)
}

export default AllianzAdminChangePassword
