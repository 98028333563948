const constraints = {
	consentOne: {
		presence: {
			message: "^Consent is required",
			allowEmpty: false
		}
	},
	consentTwo: {
		presence: {
			message: "^Consent is required",
			allowEmpty: false
		}
	},
	consentThree: {
		presence: {
			message: "^Consent is required",
			allowEmpty: false
		}
	},
	consentFour: {
		presence: {
			message: "^Consent is required",
			allowEmpty: false
		}
	},
	consentFive: {
		presence: {
			message: "^Consent is required",
			allowEmpty: false
		}
	}
}

export default constraints
