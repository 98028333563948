import React from "react"
import {Doughnut} from "react-chartjs-2"
import styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {primary, secondary} from "@uprise/colors"

const Wrapper = styled.div`
	margin: ${spacing.s5} auto;
	max-height: 200px + calc(100% - 100vw);
	/* max-width: 380px; */
	width: min(100%, 380px);
`

const options = {
	legend: {
		// display: false,
		position: "bottom",
		labels: {
			padding: 20,
			fontSize: 18,
			fontColor: "#000"
			// usePointStyle: true
		}
	},
	cutoutPercentage: 70
}

function DoughnutGraph({
	data = {
		labels: ["Low", "Medium", "High"],
		datasets: [
			{
				data: [12, 19, 3],
				backgroundColor: [secondary.electricLightOrange, secondary.electricBlue, primary.purple],
				borderWidth: 0
			}
		]
	}
}) {
	return (
		<Wrapper>
			<Doughnut data={data} options={options} height={200} width={380} />
		</Wrapper>
	)
}

export default DoughnutGraph
