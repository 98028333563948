import React, {Fragment, useState} from "react"
import sanitizeHtml from "sanitize-html"

// styles
import {Options, OtherText, Send, SendIcon} from "../styles"

const TextAreaOption = ({handleSend, currentMessage}) => {
	const [text, setText] = useState("")

	const _handleSubmit = () => {
		const {optionType, surveyId, taskId, recurring, _id, questions} = currentMessage.quickReplies
		const {questionIndex} = currentMessage.quickReplies
		const {replyType, moreInfo, triggerResponse} = currentMessage
		const _next = currentMessage.quickReplies._next ? currentMessage.quickReplies._next : currentMessage._next

		const response = {
			_id: Math.random(),
			_next: _next,
			optionType: optionType,
			moreInfo: moreInfo,
			triggerResponse: triggerResponse,
			surveyId,
			label: sanitizeHtml(text) || "None",
			taskId,
			recurring,
			replyType: replyType,
			questionIndex: questionIndex,
			questions: questions
		}

		handleSend([response])
	}

	return (
		<Options>
			<OtherText placeholder='' value={text} onChange={e => setText(sanitizeHtml(e.target.value))} />
			<Send onClick={() => _handleSubmit()} data-testid='send-button'>
				<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
			</Send>
		</Options>
	)
}

export default TextAreaOption
