import {post, get} from "helpers/api"
import {updateUserState} from "actions/userStateActions"
import moment from "moment-timezone"
import {
	STORE_COACH,
	LOADING_COACH,
	STORE_FILTERS,
	STORE_COACHES,
	LOADING_COACHES,
	STORE_SORT_BY,
	LOADING_CALENDAR,
	STORE_CALENDAR
} from "./types"

export function assignCoach(timezone) {
	return (dispatch, getState) => {
		const {userState} = getState()

		dispatch(loadingCoach(true))
		const timezone = moment.tz.guess()

		const apiURL = userState?.courseType === "rtw" ? "assignRTWCoach" : "assignCoach/timezone"

		post(apiURL, {timezone})
			.then(resp => {
				dispatch(updateUserState(resp.user_state))
				dispatch(loadingCoach(false))
			})
			.catch(err => {
				console.error(err)
			})
	}
}

export function getCoachByEmail(email) {
	return dispatch => {
		dispatch(loadingCoach(true))
		get(`coach/email/${email}`, {email})
			.then(resp => {
				dispatch(storeCoach(resp))
				dispatch(loadingCoach(false))
			})
			.catch(err => {
				dispatch(loadingCoach(false))
				console.error(err)
			})
	}
}

export function getCoaches() {
	return dispatch => {
		dispatch(loadingCoaches(true))
		get("coaches").then(coaches => {
			dispatch(storeCoaches(coaches))
			dispatch(loadingCoaches(false))
		})
	}
}

export function getCalendar() {
	return dispatch => {
		get("coach-calendar")
			.then(calendar => {
				dispatch(loadingCalendar(true))
				dispatch(storeCalendar(calendar.data))
			})
			.catch(err => {
				console.log(err)
				dispatch(loadingCalendar(false))
			})
	}
}

export function getCoachesWithSearch({employerCode, languages, specialization, availability, locations}) {
	return dispatch => {
		dispatch(loadingCoaches(true))
		post("coaches-search", {
			employerCode,
			languages,
			specialization,
			locations,
			availability
		}).then(coaches => {
			dispatch(storeCoaches(coaches))
			dispatch(storeFilters({languages, specialization, availability, reset: false, locations}))
			dispatch(loadingCoaches(false))
		})
	}
}

export function loadingCoach(status) {
	return {
		type: LOADING_COACH,
		payload: {loading: status}
	}
}

export function setFilters({languages, specialization, availability, reset, locations}) {
	return dispatch => {
		dispatch(storeFilters({languages, specialization, availability, reset, locations}))
	}
}

function storeFilters(filters) {
	return {
		type: STORE_FILTERS,
		payload: filters
	}
}

function storeCoach(resp) {
	return {
		type: STORE_COACH,
		payload: {...resp, loading: false}
	}
}

function storeCoaches(resp) {
	return {
		type: STORE_COACHES,
		payload: resp
	}
}

function loadingCoaches(status) {
	return {
		type: LOADING_COACHES,
		payload: status
	}
}

function storeSortBy(sort) {
	return {
		type: STORE_SORT_BY,
		payload: sort
	}
}

function loadingCalendar(status) {
	return {
		type: LOADING_CALENDAR,
		payload: status
	}
}

function storeCalendar(calendar) {
	return {
		type: STORE_CALENDAR,
		payload: calendar
	}
}

export function setSortBy(sort) {
	return dispatch => {
		dispatch(storeSortBy(sort))
	}
}
