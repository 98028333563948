import React, {useState, useEffect, useRef} from "react"
import Styled from "styled-components"
import {connect} from "react-redux"

// surveys
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"
// utils
import {calculateScore, optionType} from "chatBot/utils"
// Components
import QuickReply from "./QuickReply"
import MessageList from "./MessageList"
// actions
import {setActiveScreener} from "actions/surveyActions"
// colors
import {backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"

const ScrollWrapper = Styled.section`
      height: calc(100% - 100px);
	overflow: hidden;
	position: relative;
`

const BotWrapper = Styled.section`
	width: 100%;
      padding: 0;
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const Wrapper = Styled.section`
      padding: 0;
	position: absolute;
      scroll-behavior: smooth;
 	top: 0;
	bottom: 0;
	left: 0;
	right: -17px;
	padding-right: 17px;
	overflow-y: scroll;
`

const TypingWrap = Styled.div`
	display: flex;
	flex-direction: row;
`

const UpriseLogo = Styled.img`
	height: 40px;
	width: 40px;
	visibility: ${props => (props.hidden ? "hidden" : "")};
	display: inline-block;
`

const TypingBubble = Styled.div`
      width: auto;
      max-width: 85px;
      background-color: ${backgrounds.white};
      margin-right: ${spacing.s4};
      margin-bottom: ${spacing.s4};
      border: 0px;
      padding: ${spacing.s5};
      border-radius: 10px;
	color: primary.charcoal;
	
	@media (max-width: 475px) {
		padding: ${spacing.s4};
		text-align: center;
	}
`

const TypingAnimation = Styled.img`
      height: 15px;
	width: 50px;
`

const Bot = ({dialogueFile, skillId, activity, setActiveScreener, name, handleNext, completeDialog}) => {
	const [messages, setMessages] = useState([])
	const [survey, setSurvey] = useState({})
	const [quickReply, setQuickReply] = useState()
	const [isTyping, setIsTyping] = useState(true)
	const [answers, setAnswers] = useState({})

	const messageBox = useRef(null)

	useEffect(() => {
		setMessages([])
		setAnswers({})
		setSurvey({})
		_loadDialogue(null, 0, dialogueFile)
	}, [dialogueFile])

	const _loadDialogue = async (recurring, nextIndex) => {
		let surveyId, taskId
		let exit = false

		for (const message of dialogueFile) {
			message.position = "left"

			if (!exit) {
				if (message.surveyId || message?.quickReplies?.surveyId) {
					surveyId = message.surveyId || message?.quickReplies?.surveyId
				}

				if (message.taskId || message?.quickReplies?.taskId) {
					taskId = message.taskId || message?.quickReplies?.taskId
				}

				// check if start of survey
				if (message.taskId && message._id === nextIndex) {
					_startSurvey(message)
					exit = true
					// if current message matches next index and is the last message in the dialogue
				} else if (message._id === nextIndex && message._end === true) {
					await _delaySend(message)
					// save results, navigate away
					return completeDialog(recurring, answers, surveyId, taskId, handleNext)
					// if current message matches next index then send the message
				} else if (message._id === nextIndex && message.replyType === "calcScore") {
					// Check if message type is to check a score
					return _calculateScore(message.nextRange, message.questionRange)
				} else if (message._id === nextIndex) {
					await _delaySend(message)

					if (message._next) {
						nextIndex = message._next
					} else if (!message.quickReplies) {
						// get next response
						nextIndex++
					} else {
						exit = true
					}
				}
			}
		}
	}

	const _selectedOption = async selectedReply => {
		const message = {
			position: "right",
			contentType: "text",
			date: new Date(),
			text: selectedReply.label || selectedReply.response
		}

		_onSend(message)
	}

	const _startSurvey = async reply => {
		// get survey
		const survey = retrieveSurvey(reply.surveyId)

		setSurvey(survey)

		// get question text
		const questionIndex = reply.questionIndex
		const regex = /(<([^>]+)>)/gi
		const question = survey.questions[questionIndex]

		let extraText = question.extraText ? question.extraText : ""
		extraText = extraText.replace(regex, "")
		let prompt = question.prompt
		prompt = prompt.replace(regex, "")

		// Get question options, Some questions don't have options, e.g. more info question
		const values =
			question?.options?.map((option, index) => {
				return {label: option, value: index, questionIndex: questionIndex}
			}) || []

		const message = {
			position: "left",
			_id: Math.random(),
			_next: reply._next,
			text: `${extraText} ${prompt}`,
			createdAt: new Date(),
			replyType: "survey",
			surveyId: reply.surveyId,
			taskId: reply.taskId,
			moreInfoLabel: question.moreInfoLabel,
			endLabel: question.endLabel,
			answerLabel: question?.meta?.answerLabel,
			skipLabel: question?.meta?.skipLabel,
			moreInfo: question.moreInfo,
			quickReplies: {
				optionType: optionType(question.type),
				_next: reply._next,
				questionIndex: questionIndex,
				responsePrefix: question?.meta?.responsePrefix,
				noResponsePrefix: question?.meta?.noResponsePrefix,
				questions: reply.questions,
				keepIt: false,
				values: values,
				replyType: "survey",
				surveyId: reply.surveyId,
				taskId: reply.taskId,
				recurring: reply.recurring
			}
		}

		message.text = message.text.trim()
		await _delaySend(message)
	}

	// Calculate score for dialogues that require follow up score
	const _calculateScore = async (nextRange, pointScale) => {
		const score = survey.getScoreOutputIndex(calculateScore(survey, answers, pointScale))
		_loadDialogue(true, nextRange[score])
	}

	// Handle on quick reply press triggers
	const _onQuickReply = quickReply => {
		const {optionType, replyType, surveyId, taskId, moreInfo} = quickReply[0]

		switch (replyType) {
			case "questionEnd":
				_selectedOption(quickReply[0])
				_nextReply(quickReply[0])
				break
			case "questionMoreInfo":
				_selectedOption(quickReply[0])
				_questionMoreInfoReply(quickReply[0])
				break
			// trigger for a survey reply
			case "survey":
				_handleAnswer(quickReply, optionType, surveyId, taskId)
				break
			case "dialogue":
				switch (optionType) {
					case "radio":
						// trigger an more dialogue explanation
						if (quickReply[0].value === "more") {
							_selectedOption(quickReply[0])
							_moreReply(quickReply[0])
							// start a survey file
						} else if (quickReply[0].value === "start") {
							_selectedOption(quickReply[0])
							_startSurvey(quickReply[0])
							// trigger a sequence of survey questions from a start question index, and array of question indexes
						} else if (quickReply[0].value === "okay") {
							_selectedOption(quickReply[0])
							_surveyReply(quickReply[0])
							// trigger continue dialogue
						} else if (quickReply[0].value === "next") {
							_selectedOption(quickReply[0])
							_nextReply(quickReply[0])
							// trigger a video dialogue reply
						} else if (quickReply[0].value === "video") {
							_selectedOption(quickReply[0])
							_showVideoReply(quickReply[0], _onSend)
							// trigger going back to the dashboard
						} else if (quickReply[0].value === "back") {
							_endConvoReply(quickReply[0], _onSend)
						}
						break
				}
				break
		}
	}

	const _moreReply = async reply => {
		const message = {
			_id: Math.random(),
			position: "left",
			text: reply.response,
			createdAt: new Date(),
			replyType: reply.replyType,
			quickReplies: reply.quickReplies
		}
		await _delaySend(message)
	}

	const _endConvoReply = async reply => {
		const message = {
			position: "left",
			_id: Math.random(),
			text: reply.response,
			createdAt: new Date()
		}

		await _delaySend(message)
		setTimeout(() => {
			handleNext()
		}, 2000)
	}

	const _showVideoReply = async reply => {
		const message = {
			position: "left",
			_id: Math.random(),
			_next: reply._next,
			text: reply.label,
			taskId: reply.taskId,
			completed: activity[reply.taskId]?.completed,
			videoURI: reply.videoURI,
			vimeoId: reply.vimeoId,
			createdAt: new Date(),
			contentType: "video",
			replyType: reply.replyType,
			quickReplies: reply.quickReplies
		}

		await _delaySend(message)
	}

	const _setScreener = (surveyId, screenerIndex) => {
		// Set active screener in state
		const survey = retrieveSurvey(surveyId)

		if (survey.questions[screenerIndex + 1]) {
			const screener = survey.questions[screenerIndex + 1]?.batteryId
			setActiveScreener(screener)
		}
	}

	// handle answer, sets answer, reply message and stores in db
	const _handleAnswer = (reply, optionType) => {
		let surveyAnswers = answers
		let messageReply
		const survey = retrieveSurvey(reply[0].surveyId)
		const screener = survey.questions[reply[0].questionIndex].batteryId

		// Set active screener in state
		_setScreener(reply[0].surveyId, reply[0].questionIndex)

		// map the questions indexs to each screener in the survey
		let screenerQuestionIndexs = {}

		survey.questions.forEach((q, index) => {
			if (!Object.keys(screenerQuestionIndexs).includes(q.batteryId)) {
				screenerQuestionIndexs[q.batteryId] = [index]
			} else {
				screenerQuestionIndexs[q.batteryId].push(index)
			}
		})

		// get the question index of each screener array
		const questionIndex = screenerQuestionIndexs[screener].indexOf(reply[0].questionIndex)
		let response

		switch (optionType) {
			case "radio":
				messageReply = reply[0]

				messageReply = {
					...reply[0],
					response: reply[0].label
				}

				reply = reply[0]

				try {
					surveyAnswers[screener][questionIndex] = reply.value
				} catch (e) {
					surveyAnswers[screener] = []
					surveyAnswers[screener][questionIndex] = reply.value
				}

				break
			case "checkbox":
			case "multiWithOther":
			case "triggerMultiFollowup":
			case "checkboxSkip":
			case "triggerMulti":
				response = Object.values(reply)
					.map(answer => {
						return answer.label
					})
					.filter(answer => {
						return answer
					})

				messageReply = {
					_id: Math.random(),
					_next: reply[0]._next,
					optionType: reply[0].optionType,
					questionIndex: reply[0].questionIndex,
					triggerResponse: reply.triggerResponse,
					questions: reply[0].questions,
					recurring: reply[0].recurring,
					replyType: reply[0].replyType,
					surveyId: reply[0].surveyId,
					taskId: reply[0].taskId,
					answers: reply,
					response: response.join(", ")
				}

				try {
					surveyAnswers[screener][questionIndex] = response
				} catch (e) {
					surveyAnswers[screener] = []
					surveyAnswers[screener][questionIndex] = response
				}

				break
			case "slider":
			case "percentageSlider": {
				messageReply = reply[0]
				reply = reply[0]

				messageReply = {
					_id: Math.random(),
					_next: reply._next,
					optionType: reply.optionType,
					questions: reply.questions,
					questionIndex: reply.questionIndex,
					recurring: reply.recurring,
					replyType: reply.replyType,
					surveyId: reply.surveyId,
					taskId: reply.taskId,
					response: reply.label
				}

				if (screener) {
					try {
						surveyAnswers[screener][questionIndex] = reply.value
					} catch (e) {
						surveyAnswers[screener] = []
						surveyAnswers[screener][questionIndex] = reply.value
					}
				} else {
					try {
						surveyAnswers[questionIndex] = reply.value
					} catch (e) {
						surveyAnswers = []
						surveyAnswers[questionIndex] = reply.value
					}
				}

				break
			}
			case "textarea":
			case "textAreaWithMore":
			case "textAreaWithMoreEnd":
			case "textAreaWithEnd":
			case "triggerTextAreaFollowUp":
				messageReply = reply[0]
				reply = reply[0]

				messageReply = {
					_id: Math.random(),
					_next: reply._next,
					optionType: reply.optionType,
					questions: reply.questions,
					moreInfo: reply.moreInfo,
					questionIndex: reply.questionIndex,
					triggerResponse: reply.triggerResponse,
					recurring: reply.recurring,
					replyType: reply.replyType,
					surveyId: reply.surveyId,
					taskId: reply.taskId,
					response: reply.label
				}

				if (screener) {
					try {
						surveyAnswers[screener][questionIndex] = reply.label
					} catch (e) {
						surveyAnswers[screener] = []
						surveyAnswers[screener][questionIndex] = reply.label
					}
				} else {
					try {
						surveyAnswers[questionIndex] = reply.label
					} catch (e) {
						surveyAnswers = []
						surveyAnswers[questionIndex] = reply.label
					}
				}

				break
			case "multiRadio":
			case "singleWithOther":
				messageReply = reply[0]
				reply = reply[0]

				messageReply = {
					_id: Math.random(),
					_next: reply._next,
					optionType: reply.optionType,
					other: reply.other,
					questionIndex: reply.questionIndex,
					triggerResponse: reply.triggerResponse,
					questions: reply.questions,
					recurring: reply.recurring,
					replyType: reply.replyType,
					surveyId: reply.surveyId,
					taskId: reply.taskId,
					response: reply.label
				}

				if (screener) {
					try {
						surveyAnswers[screener][questionIndex] = reply.label
					} catch (e) {
						surveyAnswers[screener] = []
						surveyAnswers[screener][questionIndex] = reply.label
					}
				} else {
					try {
						surveyAnswers[questionIndex] = reply.label
					} catch (e) {
						surveyAnswers = []
						surveyAnswers[questionIndex] = reply.label
					}
				}

				break
			case "triggerRadio":
				messageReply = reply[0]
				reply = reply[0]

				messageReply = {
					_id: Math.random(),
					_next: reply._next,
					optionType: reply.optionType,
					questionIndex: reply.questionIndex,
					recurring: reply.recurring,
					replyType: reply.replyType,
					surveyId: reply.surveyId,
					taskId: reply.taskId,
					response: reply.label
				}

				if (screener) {
					try {
						surveyAnswers[screener][questionIndex] = reply.label
					} catch (e) {
						surveyAnswers[screener] = []
						surveyAnswers[screener][questionIndex] = reply.label
					}
				} else {
					try {
						surveyAnswers[questionIndex] = reply.label
					} catch (e) {
						surveyAnswers = []
						surveyAnswers[questionIndex] = reply.label
					}
				}

				break
		}

		setAnswers(surveyAnswers)
		_sendUserReply(messageReply)

		switch (optionType) {
			case "checkboxSkip":
			case "checkbox":
			case "slider":
			case "textarea":
			case "percentageSlider":
			case "multiRadio":
			case "textAreaWithMore":
			case "multiWithOther":
			case "textAreaWithMoreEnd":
			case "textAreaWithEnd":
			case "singleWithOther":
				_surveyReplyMulti(messageReply)
				break
			case "triggerRadio":
				_surveyReplySingleTrigger(messageReply)
				break
			case "triggerMulti":
			case "triggerMultiFollowup":
			case "triggerTextAreaFollowUp":
				_surveyReplyMultiTrigger(messageReply)
				break
			case "radio":
				messageReply.questionIndex++

				if (messageReply.questions && messageReply.questions.includes(messageReply.questionIndex)) {
					_surveyReply(messageReply)
				} else {
					_nextReply(messageReply)
				}
				break
		}
	}

	const _sendUserReply = reply => {
		// If reply text comes from a textarea input display as reply text
		if (
			reply.other ||
			reply.optionType === "radio" ||
			reply.optionType === "multiRadio" ||
			reply.optionType === "textarea" ||
			reply.optionType === "slider" ||
			reply.optionType === "percentageSlider" ||
			reply.optionType === "textAreaWithMore" ||
			reply.optionType === "textAreaWithMoreEnd" ||
			reply.optionType === "textAreaWithEnd" ||
			reply.optionType === "checkboxSkip" ||
			reply.optionType === "checkbox" ||
			reply.optionType === "multiWithOther" ||
			reply.optionType === "triggerRadio" ||
			reply.optionType === "triggerMulti" ||
			reply.optionType === "triggerMultiFollowup" ||
			reply.optionType === "triggerTextAreaFollowUp" ||
			reply.optionType === "singleWithOther"
		) {
			const message = {
				_id: Math.random(),
				_next: reply._next,
				label: reply.response,
				createdAt: new Date()
			}

			_selectedOption(message)
		}
	}

	const _surveyReplySingleTrigger = async reply => {
		const survey = retrieveSurvey(reply.surveyId)
		setSurvey(survey)

		survey.questions.forEach((question, index) => {
			if (question.trigger === reply.response) {
				reply.triggerResponse = true
				reply.questionIndex = index
			}
		})

		_surveyReply(reply)
	}

	const _setNextQuestion = reply => {
		survey.questions.forEach((question, index) => {
			if (!question.trigger && index > reply.questionIndex) {
				reply.questionIndex = index
			}
		})

		reply.triggerResponse = false
		reply.questions = reply.questions.push(reply.questionIndex)

		return reply
	}

	const _surveyReplyMulti = async reply => {
		const survey = retrieveSurvey(reply.surveyId)
		setSurvey(survey)

		// get next question if trigger question
		if (reply.triggerResponse) {
			reply = _setNextQuestion(reply)
		} else {
			// increment question index, check if index is in questions array if so load that question
			reply.questionIndex++
		}

		if (reply?.questions?.length && reply?.questions.includes(reply.questionIndex)) {
			_surveyReply(reply)
		} else {
			_nextReply(reply)
		}
	}

	const _surveyReplyMultiTrigger = async reply => {
		const survey = retrieveSurvey(reply.surveyId)
		setSurvey(survey)

		let triggersAnswersIndex
		let batteryId
		survey.questions.forEach((question, index) => {
			if (question.type === "triggerMulti") {
				triggersAnswersIndex = index
				batteryId = question.batteryId
			}
		})

		let exit = false
		let nextQuestionIndex = reply.questionIndex

		survey.questions
			.filter(question => {
				return question.batteryId === batteryId
			})
			.forEach((question, index) => {
				answers[question.batteryId][triggersAnswersIndex].forEach(answer => {
					if (question.trigger === answer && index > reply.questionIndex && !exit) {
						reply.triggerResponse = true
						nextQuestionIndex = index
						exit = true
					}
				})

				if (!question.trigger && index > reply.questionIndex && !exit) {
					nextQuestionIndex = index
				}
			})

		if (nextQuestionIndex === reply.questionIndex) {
			_nextReply(reply)
		} else {
			reply.questionIndex = nextQuestionIndex
			_surveyReply(reply)
		}
	}

	const _questionMoreInfoReply = async reply => {
		let count = 0
		const questionIndex = reply.questionIndex

		for (const line of reply.moreInfo) {
			if (reply.moreInfo.length - 1 === count) {
				const message = {
					position: "left",
					_id: Math.random(),
					_next: reply._next,
					text: line,
					createdAt: new Date(),
					replyType: "survey",
					surveyId: reply.surveyId,
					taskId: reply.taskId,
					quickReplies: {
						optionType: "textarea",
						questions: reply.questions,
						questionIndex: questionIndex,
						values: [{label: reply.answerLabel, questionIndex}],
						replyType: "survey",
						surveyId: reply.surveyId,
						taskId: reply.taskId,
						recurring: reply.recurring
					}
				}

				await _delaySend(message)
			} else {
				const message = {
					position: "left",
					_id: Math.random(),
					_next: reply._next,
					text: line,
					createdAt: new Date(),
					replyType: "survey"
				}

				await _delaySend(message)
			}
			count++
		}
	}

	const _surveyReply = async reply => {
		const survey = retrieveSurvey(reply.surveyId)
		setSurvey(survey)

		const regex = /(<([^>]+)>)/gi
		const question = survey.questions[reply.questionIndex]

		let extraText = question.extraText ? question.extraText : ""
		extraText = extraText.replace(regex, "")
		let prompt = question.prompt
		prompt = prompt.replace(regex, "")
		const questionIndex = reply.questionIndex

		let values
		if (question.options) {
			values = question?.options?.map((option, index) => {
				return {
					label: option,
					value: index,
					questionIndex: reply.questionIndex
				}
			})
		} else {
			values = []
		}

		const message = {
			position: "left",
			_id: Math.random(),
			_next: reply._next,
			text: `${extraText} ${prompt}`,
			createdAt: new Date(),
			replyType: "survey",
			surveyId: reply.surveyId,
			taskId: reply.taskId,
			moreInfoLabel: question.moreInfoLabel,
			endLabel: question.endLabel,
			answerLabel: question?.meta?.answerLabel,
			skipLabel: question?.meta?.skipLabel,
			moreInfo: question.moreInfo,
			quickReplies: {
				optionType: optionType(question.type),
				_next: reply._next,
				questionIndex: questionIndex,
				questions: reply.questions,
				responsePrefix: question?.meta?.responsePrefix,
				noResponsePrefix: question?.meta?.noResponsePrefix,
				keepIt: false,
				values: values,
				replyType: "survey",
				surveyId: reply.surveyId,
				taskId: reply.taskId,
				recurring: reply.recurring
			}
		}

		message.text = message.text.trim()

		await _delayNoTyping()
		await _delaySend(message)
	}

	const _nextReply = reply => {
		_loadDialogue(reply.recurring, reply._next)
	}

	const _delayNoTyping = () => {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve("done")
			}, 1000)
		})
	}

	const _checkForQuickReply = messages => {
		if (messages[messages.length - 1]?.quickReplies) {
			setQuickReply(messages[messages.length - 1])
		} else {
			setQuickReply(false)
		}
	}

	const _delaySend = message => {
		return new Promise(resolve => {
			setIsTyping(true)
			_scrollMessageBox()
			setTimeout(() => {
				_onSend(message)
				_scrollMessageBox()
				setIsTyping(false)
				resolve("done")
			}, 2000)
		})
	}

	const _onSend = message => {
		setMessages(messages => [...messages, message])
	}

	const _scrollMessageBox = () => {
		var elem = messageBox
		if (elem.current) elem.current.scrollTop = elem.current.scrollHeight
	}

	useEffect(() => {
		_checkForQuickReply(messages)
	}, [messages])

	useEffect(() => {
		_scrollMessageBox()
	}, [quickReply])

	return (
		<BotWrapper>
			<ScrollWrapper>
				<Wrapper ref={messageBox}>
					<MessageList className='message-list' skillId={skillId} data={messages} name={name} />
					{isTyping && (
						<TypingWrap>
							<UpriseLogo
								className='m-r-4'
								src={require(`assets/images/logos/uprise-transparent-64x64.png`)}
							/>
							<TypingBubble>
								<TypingAnimation src={require("assets/images/loaders/typing.svg")} alt='typing' />
							</TypingBubble>
						</TypingWrap>
					)}
				</Wrapper>
			</ScrollWrapper>

			{quickReply && <QuickReply quickReply={quickReply} onQuickReply={_onQuickReply} />}
		</BotWrapper>
	)
}

const mapDispatchToProps = dispatch => ({
	setActiveScreener: screener => dispatch(setActiveScreener(screener))
})

export default connect(null, mapDispatchToProps)(Bot)
