import React from "react"
import {Card} from "@uprise/card"
import {backgrounds, primary} from "@uprise/colors"
import {H3, H5} from "@uprise/headings"
import {CardWrap, ContentRight, ContentWrap, SkillDetails, UpNextStyles} from "../index.style"
import {ImageWrap, MediumStyled, PrivacyImage} from "./index.style"

function UpriseAndPrivacy() {
	return (
		<UpNextStyles>
			<H3 className='m-b-5'>Uprise and Privacy</H3>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<PrivacyImage src={require(`assets/images/hrdashboard/confidentiality.svg`)} />
					</ImageWrap>

					<ContentRight>
						<SkillDetails className='m-b-xs-4 m-b-sm-4 m-t-sm-0'>
							<H5 color={primary.charcoal} className='m-b-3 m-t-0 m-b-sm-4 m-t-sm-4'>
								We value privacy and annonymity at Uprise
							</H5>
							<MediumStyled className='m-b-4'>
								You will not see any personally identifiable information about any of your employees in
								this section.
							</MediumStyled>
							<MediumStyled className='m-b-4'>
								{`Data reporting will not be generated till we have >10 participants and names have
									been changed to protect privacy.`}
							</MediumStyled>
							<MediumStyled>
								Learn more about how we protect privacy{" "}
								<a href='https://uprise.co/privacy/' target='_blank' rel='noreferrer'>
									here
								</a>
							</MediumStyled>
						</SkillDetails>
					</ContentRight>
				</ContentWrap>
			</Card>
		</UpNextStyles>
	)
}

export default UpriseAndPrivacy
