import {get} from "helpers/api"
import {STORE_CAPS} from "./types"

export const fetchCaps = employerCode => {
	return dispatch => {
		get(`caps/${employerCode}`).then(resp => {
			dispatch(storeCaps(resp.data))
		})
	}
}
export const storeCaps = caps => {
	return {
		type: STORE_CAPS,
		caps
	}
}
