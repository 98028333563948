import {STORE_SKILL} from "actions/types"

export default function(state = {}, action) {
	switch (action.type) {
		case STORE_SKILL: {
			return {...state, ...action.skill}
		}

		default:
			return {
				...state
			}
	}
}
