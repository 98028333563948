import React from "react"
import {useSelect} from "downshift"
import {func, object, string, array} from "prop-types"
import Styled from "styled-components"
import {primary, extended, semantic} from "@uprise/colors"

const Container = Styled.div`
	margin-right: 10px;
    margin-bottom:20px;
`

const LabelWrapper = Styled.label`
	font-size: 16px;
	color: rgb(151,150,160);
`

const TriggerButtonContainer = Styled.div`
	width: ${props => (props.isFullWidth ? "100%" : "120px")};
	margin: auto;
	border-bottom: 1px solid #EDF8FE;
`

const TriggerButton = Styled.button`
	width: 100%;
	max-width :${props => (props.isFullWidth ? "" : "400px")};
	padding: ${props => (props.isFullWidth ? "10px 0px" : "6px 22px 6px 12px")};;
	border-radius: 10px;
	text-align: left;
	outline: none;
	border: 0;
	background-color: transparent;
	font-family: "Proxima Nova";
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: #000;
	position: relative;
`

const ArrowIcon = Styled.span`
	border: solid ${primary.purple};
	border-width: 0 1px 1px 0;
	padding: 3px;
	float: right;
	display: inline-block;
	transform: ${props => (props.isOpen ? "rotate(225deg)" : "rotate(45deg)")};
	transition: transform 0.1s linear;
	position: absolute;
	right: 12px;
	top: 35%;
  `

const MenuList = Styled.ul`
	max-height: 200px;
	width: 100%;
	max-width : 400px;
	overflow-y: auto;
	// margin: 0 auto;
	border-top: 0;
	outline: none;
	padding: 0;
	border-radius: 0 0 10px 10px;
	box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);
	background-color: #ffffff;
    position:absolute;
    z-index:10
`

const MenuListItem = Styled.li`
	padding: 4px 10px;
	text-align: left;
	list-style-type: none;
	border-bottom: 1px solid #edeafa;
	font-family: "Proxima Nova";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	background-color: ${props => (props.selectedItem ? "#e9f7f9" : props.highlightedIndex ? "#e9f7f9" : "#fffff")};
  	color: ${props => (props.selectedItem ? primary.purple : "#9796a0")};

	&:last-child {
		border-bottom: 0;
	}
`
const MessageStyles = Styled.label`
      font-size: 12px;
      color:  ${props => {
			if (props.focused) {
				return `${extended.blue.one}`
			} else if (props.validation.length > 0 ) {
				return `${semantic.error}`
			} else {
				return `${extended.purple.five}`
			}
		}};
      display: block;
      margin-top: 8px;
      transition: font-size 0.2s;
`

const FullWidthDropdown = ({
	items,
	title,
	label,
	onChange,
	containerStyle,
	menuStyle,
	initialSelectedItem,
	customListItemRender,
	isFullWidth,
	validation,
    id,
    name
}) => {
	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getItemProps
	} = useSelect({
		items,
		itemToString: item => {
			return item ? item.value : ""
		},
		onSelectedItemChange: e => {
			onChange(e.selectedItem)
		},
		initialSelectedItem: initialSelectedItem
	})
	return (
		<Container style={containerStyle}>
			{title ? <LabelWrapper {...getLabelProps()}>{title}</LabelWrapper> : null}
			<TriggerButtonContainer isFullWidth={isFullWidth}>
				<TriggerButton {...getToggleButtonProps({type: "button"})} isFullWidth={isFullWidth}>
					{(selectedItem && selectedItem.label) || label}
					<ArrowIcon isOpen={isOpen} />
				</TriggerButton>
			</TriggerButtonContainer>
			<MenuList {...getMenuProps()} style={menuStyle}>
				{isOpen &&
					items.map((item, index) => {
						return (
							<MenuListItem
								highlightedIndex={highlightedIndex === index}
								selectedItem={selectedItem?.value === item?.value}
								key={`${index}`}
								{...getItemProps({item, index})}>
								{customListItemRender ? customListItemRender(item) : item.label}
							</MenuListItem>
						)
					})}
			</MenuList>
			{(validation?.[name]) && (
				<MessageStyles htmlFor={id}  validation={validation?.[name]}>
					{validation?.[name]}
				</MessageStyles>
			)}
		</Container>
	)
}

FullWidthDropdown.propTypes = {
	onChange: func.isRequired,
	items: array.isRequired,
	label: string.isRequired,
	containerStyle: object,
	menuStyle: object
}

FullWidthDropdown.defaultProps = {
	items: [],
	label: "",
	onChange: () => {},
	containerStyle: {},
	menuStyle: {}
}
export default FullWidthDropdown
