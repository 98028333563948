import React from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"

// Components
import {Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"

// Colors
import {extended, primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// helpers
import {getCoachingType} from "helpers/utils"
// Icons
import Icons from "constants/Icons"

const GetACoachStyles = Styled.section`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
 	}
`

const ContentWrap = Styled.section`
    width: 100%;

	@media (max-width: 767px) {
		flex-direction: column;
		text-align: center;
	}
`

const ImageWrap = Styled.section`
    padding: ${spacing.s5};
    display: flex;
    justify-content: center;
    align-items: center;
`
const ButtonWrap = Styled.section`
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: -${spacing.s6};

	& button {
		width: 100%;
		@media (min-width: 1160px) {
			width: 170px;
		}
	}
`

const GetACoachImage = Styled.img`
    height: 200px;

	@media (max-width: 767px) {
		margin-top: 30px;
		height: 200px;
		width: 250px;
	}
`

const ContentRight = Styled.div`
      padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} 0;
	width: 100%;

	@media (max-width: 1024px) {
		padding: ${spacing.s7} ${spacing.s7} ${spacing.s7} ${spacing.s7};
	}
`

const GetACoach = ({className, skills, meta, firstLoad, bookings}) => {
	let history = useHistory()

	return (
		<GetACoachStyles className={className}>
			<H3 className='m-b-0-5'>Get a Coach</H3>

			<Medium color={extended.charcoal.one} className='m-b-6'>
				Uprise is 4x more effective with {getCoachingType(bookings.duration).toLowerCase()}
			</Medium>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<Row className='d-flex align-items-center'>
						<Col className='col-xl-4 col-md-6 col-sm-6'>
							<ImageWrap>
								<GetACoachImage src={Icons.noCoach} />
							</ImageWrap>
						</Col>
						<Col className='col-xl-8 col-md-6 col-sm-6'>
							<ContentRight>
								<H3 color={primary.charcoal} className='m-b-2 m-t-0 m-b-sm-4 m-t-sm-4'>
									You haven’t selected a coach yet
								</H3>
								<Medium color={extended.charcoal.one} className='m-b-6'>
									Your coach will show you how to use the skills for your specific situation.
								</Medium>
								<ButtonWrap>
									<Button
										className='m-r-sm-0 m-r-3 m-t-4'
										title='Select a Coach'
										variant='primary'
										size='medium'
										fullWidth={false}
										onClick={() => history.push("select-coach", {from: location.pathname})}
									/>
								</ButtonWrap>
							</ContentRight>
						</Col>
					</Row>
				</ContentWrap>
			</Card>
		</GetACoachStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch,
		bookings: state.bookings
	}
}

export default connect(mapStateToProps)(GetACoach)
