export const introductionWorryTime = [
	{
		_id: 0,
		_next: 1,
		text:
			"One way to deal with worry is to write down your worry thoughts as they happen and then try not to analyse them further.",
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 3,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		text: "Then later in the day, come back and allow yourself to think and worry about them for about 5 minutes",
		replyType: "dialogue",
		quickReplies: {
			_id: 4,
			_next: 5,
			optionType: "radio",
			values: [
				{
					label: "Does that really work?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		text:
			"Research shows that this intervention, which is called ‘Worry Time’ can reduce the number and intensity of your worries over time. Have you already watched the video",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 6,
			_next: 8,
			optionType: "radio",
			surveyId: "introduction-worry-time-v1",
			taskId: "exercise-introduction-worry-time",
			questions: [0],
			values: [
				{
					label: "Please show me the video",
					videoURI:
						"https://player.vimeo.com/external/218729113.sd.mp4?s=8e3f73f332bca4871345a883706fcbccaf691327&profile_id=165",
					vimeoId: "218729113",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 7,
						_next: 8,
						optionType: "radio",
						replyType: "dialogue",
						surveyId: "introduction-worry-time-v1",
						taskId: "exercise-introduction-worry-time",
						questions: [0],
						values: [
							{
								label: "Thanks i've watched the video now",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve already seen it",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text:
			"The idea is that you tell yourself you can worry about something later when you write out the worry. Later on, worry as much as you like for 5 minutes",
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "I’ll give it a try",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text: "Thanks for doing the exercise.",
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
