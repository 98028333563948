import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import moment from "moment-timezone"
// Actions
import {setDuration} from "actions/bookingsActions"

import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Note, Medium, ExtraSmall} from "@uprise/text"
import {List} from "@uprise/list"
import CancelModal from "components/Shared/UpComingSession/CancelModal"
// UI
import {ProgressiveImage} from "@uprise/image"
// Colors
import {extended, primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// helpers
import {getCoachType} from "helpers/utils"
// styles
import {
	ContentWrap,
	CoachPhoto,
	ContentRight,
	DateBar,
	CalIcon,
	Date,
	ButtonWrap,
	ButtonWarning,
	TimezoneStyled
} from "components/Shared/Bookings/index.style"

const UpcomingCard = ({booking, coaches, userState, setDuration}) => {
	const [showCancelModel, setShowCancelModel] = useState(false)
	const [coach, setCoach] = useState()
	const [assignedCoach, setAssignedCoach] = useState()
	const isLess24Hours = moment(booking?.start).diff(moment(), "hours") < 24

	const location = useLocation()
	const history = useHistory()

	useEffect(() => {
		setCoach(coaches.find(item => item.id === booking.coachId))
		setAssignedCoach(coaches.find(item => item.email === userState?.coach))
	}, [booking, coaches])

	const handleReschedule = () => {
		if (location.pathname === "/make-booking") window.scrollTo({top: 0, behavior: "smooth"})

		setDuration(booking.duration)
		history.push("make-booking", {
			duration: booking.duration,
			from: location.pathname,
			reschedule: JSON.parse(JSON.stringify(booking))
		})
	}

	return (
		<Card shadow={true} backgroundColor={backgrounds.white} className='m-b-4'>
			<ContentWrap>
				<CoachPhoto>
					<ProgressiveImage
						className='m-r-6 m-b-4'
						borderRadius='10px'
						height={100}
						width={100}
						src={
							coach?.photos?.avatarURL
								? coach?.photos?.imageURL
								: require("assets/images/coach/default-coach.png")
						}
						overlaySrc={
							coach?.photos?.avatarURI
								? coach?.photos?.avatarURI
								: require("assets/images/coach/default-coach.png")
						}
					/>
				</CoachPhoto>
				<ContentRight>
					<H5 color={primary.charcoal} className='m-b-0 m-t-0'>
						{booking.coachName} will call you on
					</H5>

					<DateBar>
						<CalIcon>
							<img src={require("assets/images/icons/png/calendar/calendar.png")} />
						</CalIcon>

						<H4 lineHeight='1' className='m-t-0 m-b-0'>
							<Date>{moment(booking?.start).format("Do MMMM h:mm a")}</Date>
							<TimezoneStyled>({booking.timezone || moment.tz.guess()})</TimezoneStyled>
						</H4>
					</DateBar>

					<Note>{getCoachType(booking.duration)} calls less than 24 hours away cannot be rescheduled</Note>

					{userState?.coach === booking?.coachEmail ? (
						<ButtonWrap>
							{!isLess24Hours && (
								<Button
									className='m-r-3'
									title='Reschedule'
									size='extra-small'
									paddingLeft={"17px"}
									paddingRight={"17px"}
									variant='tertiary'
									fullWidth={false}
									onClick={handleReschedule}
								/>
							)}
							<ButtonWarning
								title='Cancel'
								size='extra-small'
								paddingLeft={"21px"}
								paddingRight={"21px"}
								variant='tertiary'
								fullWidth={false}
								onClick={() => setShowCancelModel(true)}
							/>

							<CancelModal
								isOpen={showCancelModel}
								booking={booking}
								inTime={!isLess24Hours}
								handleClose={() => setShowCancelModel(false)}
							/>
						</ButtonWrap>
					) : (
						<>
							<ButtonWrap>
								<ButtonWarning
									title='Cancel'
									size='extra-small'
									paddingLeft={"21px"}
									paddingRight={"21px"}
									variant='tertiary'
									fullWidth={false}
									onClick={() => setShowCancelModel(true)}
								/>

								<CancelModal
									isOpen={showCancelModel}
									booking={booking}
									inTime={!isLess24Hours}
									handleClose={() => setShowCancelModel(false)}
								/>
							</ButtonWrap>
							<Note color={primary.charcoal} className='m-b-0 m-t-2'>
								Your assigned wellbeing professional is {assignedCoach?.name}. To change this booking
								you’ll need to cancel it and book again
							</Note>
						</>
					)}
				</ContentRight>
			</ContentWrap>
		</Card>
	)
}

function mapStateToProps(state) {
	return {
		userState: state?.userState
	}
}

const mapDispatchToProps = dispatch => ({
	setDuration: duration => dispatch(setDuration(duration))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingCard)
