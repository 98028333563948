export const filterCoachesByEmployerCode = (coaches, employerCode) => {
	if (coaches) {
		switch (employerCode) {
			case "genesiscare-es":
			case "coach-es":
				return coaches.filter(coach => {
					return coach.timezone === "Europe/Madrid"
				})
			default:
				return coaches.filter(coach => {
					return coach.timezone !== "Europe/Madrid"
				})
		}
	}
}

export const filterCoachesByCourseType = (coaches, courseType = "corporate") => {
	return coaches.filter(coach => {
		if (typeof coach.activeOn === "object") {
			return coach.activeOn[courseType]
		}
		// When coach don't have activeOn field, use default
		let defaultActiveOn = {corporate: true, rtw: false}
		return defaultActiveOn[courseType]
	})
}
