import React, {Fragment} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"

// Components
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Medium, ExtraSmall} from "@uprise/text"
import {H2, H3, H4, H5} from "@uprise/headings"
import SkillCard from "components/Shared/Library/SkillCard"
import SeriesCard from "components/Shared/Library/SeriesCard"

const TrackStyles = Styled.section`
`

const TrackWrap = Styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	:nth-child(2n) {
    margin-right: 0;
}
`

export const Track = ({className, meta, track, skills, series, trial, courseType, set}) => {
	let history = useHistory()

	let cards

	// REMOVE THIS CHECK ON MOBILE APP DEPLOYMENT
	if (set === "startingSkills") {
		cards = track.items
			.filter(item => {
				return item.skill !== "perspectivetaking"
			})
			.map(item => {
				if (item.type === "skill") {
					return {type: "skill", id: item.skill, content: skills[item.skill]}
				} else if (item.type === "series") {
					return {type: "series", id: item.series, skills: series[item.series].skills}
				}
			})
	} else {
		cards = track.items.map(item => {
			if (item.type === "skill") {
				return {type: "skill", id: item.skill, content: skills[item.skill]}
			} else if (item.type === "series") {
				return {type: "series", id: item.series, skills: series[item.series].skills}
			}
		})
	}

	return (
		<TrackStyles className={className}>
			<H3>{track.title}</H3>
			<Medium className='m-b-6'>{track.subText}</Medium>
			<TrackWrap>
				{Object.values(cards).map((item, index) => {
					if (item.type === "series") {
						return (
							<SeriesCard
								className={`m-b-10  m-r-5`}
								key={index}
								index={index}
								seriesId={item.id}
								seriesSkills={item.skills}
								series={series}
								skills={skills}
								meta={meta}
								trial={trial}
								set={set}
							/>
						)
					} else if (item.type === "skill") {
						return (
							<SkillCard
								className={`m-b-10  m-r-5`}
								key={index}
								index={index}
								skillId={item.id}
								content={item.content}
								meta={meta}
								trial={trial}
								set={set}
							/>
						)
					}
				})}
			</TrackWrap>
		</TrackStyles>
	)
}
