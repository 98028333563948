import Styled from "styled-components"

const TrackWrap = Styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	:nth-child(2n) {
    margin-right: 0;
	}
`

export {TrackWrap}
