export const leaderTeamWellbeingAssesment = [
	{
		_id: 0,
		text:
			"Let’s do a quick assessment around the key goals for this course. Please remember that everything you do in Uprise is confidential.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text:
			"We provide de-identified group data back to your employer but those reports never name anyone individually.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text:
			"We provide de-identified group data back to your employer but those reports never name anyone individually.",
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "leader-team-wellbeing-assesment-v1",
		taskId: "exercise-leader-team-wellbeing-assesment",
		questions: [0, 1, 2, 3, 4, 5, 6],
		questionIndex: 0,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_end: true,
		text: "I’ll take you back so you can learn and practice more Uprise skills",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
