import React, {Fragment, useEffect} from "react"
import {object} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"
// Actions
import {setTask} from "actions/taskActions"
import {setSkill} from "actions/skillActions"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// UI
import {ContainerFluid, Row} from "@uprise/grid"
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {SkillProgress} from "components/Shared/Skill/SkillProgress"

// Component
import {Layout} from "components/Shared/Layout"
import VideoTask from "components/Shared/Library/Tasks/Video"
import {TaskList} from "components/Shared/Library/TaskList"

const Wrap2 = Styled.section`	
	background-color: ${extended.purple.six}; 
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`	
	background-color: ${extended.purple.six}; 
      width: 100%;
`

const VideoScreen = ({userState, match, skill, setTask, setSkill}) => {
	let location = useLocation()
	let history = useHistory()

	// Get skill set and skill id from url params

	const {taskId, skillId} = match.params

	useEffect(() => {
		// Store task in application state
		setTask(userState.tasks[taskId], taskId)
	}, [taskId])

	useEffect(() => {
		// Store skill in application state
		setSkill(userState, taskId)
	}, [])

	let task
	userState.homework[taskId].find(tId => {
		if (tId === skillId) {
			task = {...userState.tasks[tId], taskId: tId}
			return
		}
	})

	const {meta} = skill
	const title = task.title || meta?.title

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={1} />
			</LeftPanel>
			<Wrap2>
				<Wrap>
					<Header
						title={title}
						textAlign='left'
						width='100%'
						back={location?.state?.from.length ? true : false}
						handleBack={() => history.push(location?.state?.from)}
					/>

					<Main padding='25px 25px 0 25px'>
						<VideoTask
							skillId={taskId}
							video={task}
							activity={userState.activity}
							width={"unset"}
							height={'800px'}
							className='d-flex justify-content-center'
						/>
					</Main>
				</Wrap>
			</Wrap2>
		</Layout>
	)
}

function mapStateToProps(state) {
	const {userState, skill, task} = state

	return {
		userState,
		skill,
		task
	}
}

const mapDispatchToProps = dispatch => ({
	setTask: (task, taskId) => dispatch(setTask(task, taskId)),
	setSkill: (userState, skillId) => dispatch(setSkill(userState, skillId))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoScreen)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
VideoScreen.propTypes = {
	userState: object.isRequired,
	match: object.isRequired,
	skill: object.isRequired
}

VideoScreen.defaultProps = {}
