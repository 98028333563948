import Styled from "styled-components"
import {backgrounds} from "@uprise/colors"
import {spacing} from "@uprise/spacing"

const CardWrap = Styled.div`
	transition: .3s ease;
	&:hover {
		transform: translateY(-1px) scale(1.01);
	}
`

const UpNextStyles = Styled.section`
	@media (max-width: 1024px) {
		position: relative;
	}

	@media (max-width: 475px) {
		position: relative;
	}

  padding-bottom: ${spacing.s9};
`

const ContentWrap = Styled.section`
	display: flex;
	flex-direction: row;


	&:hover {
		cursor: pointer;
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 475px) {
		flex-direction: column
	}
`

const UpNextImage = Styled.img`
	border-top-right-radius: 0px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	width: 400px;

	@media (max-width: 1024px) {
		width: 100%;
		min-width: 100%;
	}

	@media (max-width: 475px) {
		width: 100%;
		min-width: 100%;
		min-height: 218px;
	}
`
const ContentRight = Styled.div`
	padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} 0;
	width: 100%;

	@media (max-width: 1024px) {
		padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} ${spacing.s7};
	}
`

const SkillDetails = Styled.div`
	background-color: ${backgrounds.white};

	@media (max-width: 1024px) {
		margin-right: ${spacing.s5};
		margin-left: ${spacing.s5};
		padding: ${spacing.s5};
		border-radius: 10px;
	}

	@media (max-width: 475px) {
		margin-right: ${spacing.s5};
		margin-left: ${spacing.s5};
		padding: ${spacing.s5};
		border-radius: 10px;
	}
`

export {CardWrap, UpNextStyles, ContentWrap, UpNextImage, ContentRight, SkillDetails}
