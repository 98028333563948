import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {NavLink, useHistory} from "react-router-dom"
import {connect} from "react-redux"
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {H1, H2, H3} from "@uprise/headings"
import {P, ExtraSmall, Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
import {VerticalProgress} from "@uprise/track"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"

// surveys
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
import TaskSummary from "components/Shared/TaskSummary"
// UI
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"

const Wrap = Styled.section`	background-color: ${extended.purple.six}; 
        width: 100%;
`

const ExerciseSummary = ({userState, location}) => {
	let history = useHistory()

	if (typeof location?.state?.result === "undefined") {
		history.push("notebook")
		return null
	}

	const title = location?.state?.title
	const result = location?.state?.result
	const survey = retrieveSurvey(result?.surveyId)

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={2} />
			</LeftPanel>
			<Wrap>
				<Header
					title={title}
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
					handleBack={() => history.push(location?.state?.from)}
				/>
				<Main>
					<TaskSummary result={result} survey={survey} title={title} />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='250px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(ExerciseSummary)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
ExerciseSummary.propTypes = {}

ExerciseSummary.defaultProps = {}
