import React, {Fragment, useEffect, useState} from "react"
import Styled from "styled-components"
// import {bool, func, string} from "prop-types"
import {useHistory} from "react-router-dom"
import FormValidate from "form-validate.js"
import {connect} from "react-redux"
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds, primary} from "@uprise/colors"
// Validation
import constraints from "validation/ruokday"
// Inputs
import {TextInput} from "@uprise/form"
// Actions
import {storeuserState} from "actions/loginActions"

const ButtonWrap = Styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

const AskYourSelfWrap = Styled(P)`
	@media (max-width: 480px) {
		font-weight: bold;
		color: ${primary.charcoal};
		width: 300px;
	}
`

const RuokdayAccess = ({children, code, errors}) => {
	let history = useHistory()

	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [phone, setPhone] = useState("")
	const [companyCode, setCompanyCode] = useState(code)
	const [error, setError] = useState({type: "", value: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	useEffect(() => {
		const defaultValues = {companyCode}
		const validator = new FormValidate(constraints, null, defaultValues)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleCheckScore()
		}
	}

	const _handleCheckScore = () => {
		history.push("/ruokday/survey", {phone, companyCode})
		setIsLoading(true)
	}

	return (
		<Fragment>
			<H3 className='m-b-3 d-none d-sm-block'>Am I okay?</H3>
			<AskYourSelfWrap className='m-b-6'>Ask yourself by checking your wellbeing in 3 minutes.</AskYourSelfWrap>
			<Card className='m-b-4' backgroundColor={backgrounds.white} shadow={true} padding={spacing.s5} width='100%'>
				{error?.text?.length > 0 && (
					<Alert className='m-b-5' type={error.type}>
						{error.text}
					</Alert>
				)}

				<form onChange={event => _validateForm(event)}>
					<TextInput
						onChange={e => setPhone(e.target.value)}
						validation={validation}
						value={phone}
						validateControl='phone'
						id='phone'
						name='phone'
						label='Phone number'
						isFirst
						isRequired
					/>
					<TextInput
						onChange={e => setCompanyCode(e.target.value)}
						validation={validation}
						value={companyCode}
						id='companyCode'
						type='companyCode'
						name='companyCode'
						label='Company code'
						isLast
						isRequired
					/>
				</form>
			</Card>
			<ButtonWrap>
				<Button
					variant='primary'
					className='m-b-2'
					data-testid='check-score-button'
					title='Check my score'
					size='large'
					fullWidth={true}
					isLoading={isLoading}
					disabled={false}
					onClick={() => _handleSubmit()}
				/>
			</ButtonWrap>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
RuokdayAccess.propTypes = {}

RuokdayAccess.defaultProps = {}

const mapDispatchToProps = dispatch => ({
	storeuserState: state => dispatch(storeuserState(state))
})

export default connect(null, mapDispatchToProps)(RuokdayAccess)
