import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {extended} from "@uprise/colors"

const TitleRow = Styled.div`
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
`

const TitleWrapper = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContentWrap = Styled.div`
  user-select: none;
  cursor: pointer;
`

const Legend = Styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const LegendBox = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${spacing.s5};
`

const LegendImg = Styled.img`
  width: 20px;
  height: 8px;
  margin-right: ${spacing.s2};
`

const GraphDesc = Styled.div`
  border-radius: 10px;
  background-color: ${extended.purple.five};
  padding: 20px 23px 24px 24px;
`

const GraphDescImg = Styled.img`
  width: 100%;
`

const ModalButton = Styled.button`
  border: none;
  background: none;
  font-size: inherit;
  font-face: inherit;
`
const ModalButtonWrapper = Styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border-width: 0;
  height: 50px;
  padding-bottom: 15px;
`

export {
	ContentWrap,
	Legend,
	LegendBox,
	LegendImg,
	GraphDesc,
	GraphDescImg,
	TitleRow,
	ModalButton,
	TitleWrapper,
	ModalButtonWrapper
}
