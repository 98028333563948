import {STORE_TASK} from "actions/types"

export default function(state = {}, action) {
	switch (action.type) {
		case STORE_TASK: {
			return {...state, ...action.task}
		}
		default:
			return {
				...state
			}
	}
}
