import {get} from "helpers/api"
import {STORE_CODE} from "./types"

export const fetchCode = employerCode => {
	return dispatch => {
		get(`employer-code/${employerCode}`).then(resp => {
			dispatch(storeCode(resp.data))
		})
	}
}

export const storeCode = code => {
	return {
		type: STORE_CODE,
		code: code
	}
}
