import React from "react"
import {array, string} from "prop-types"
import Styled from "styled-components"
// UI

import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
// Colors
import {backgrounds} from "@uprise/colors"

const StaticTextStyle = Styled.section`
      width: 100%;
      height: 100%;
`

const Content = Styled.section`
	width: 100%;
	padding: 25px;
`

export const StaticTextTask = ({className, title, content}) => {
	return (
		<StaticTextStyle>
			<H3 className='m-b-4'>{title}</H3>
			<Card backgroundColor={backgrounds.white}>
				<Content dangerouslySetInnerHTML={{__html: content}} />
			</Card>
		</StaticTextStyle>
	)
}

StaticTextTask.propTypes = {
	title: string.isRequired,
	content: array.isRequired
}
