import React, {useEffect, Fragment} from "react"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {backgrounds, extended, primary, secondary} from "@uprise/colors"
import {Col, Row} from "@uprise/grid"
import {H1, H2, H3, H4, H5, H6} from "@uprise/headings"
import {spacing} from "@uprise/spacing"
import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import ToggleModal from "components/Shared/ToggleModal"
import GroupedBarChart from "components/Shared/Graphs/GroupedBar"
import {InfoText, ModalOpenButtonContainer, PurpleBox} from "../index.style"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"
import {Medium} from "@uprise/text"
import {useSelector, useDispatch} from "react-redux"
import {fetchHrDashboardRoiStats} from "actions/hrdashboardActions"
import Preloader from "components/Shared/Preloader/Preloader"

const ReturnOfInvestment = () => {
	const dispatch = useDispatch()
	const {roiStats, loading} = useSelector(state => ({
		roiStats: state.hrdashboard.roiStats.data,
		loading: state.hrdashboard.roiStats.loading
	}))
	const {employerCode} = useSelector(state => state.userState)
	
	useEffect(() => {
		dispatch(fetchHrDashboardRoiStats({employerCode}))
	}, [employerCode])

	return (
		<>
			{/* Changes for uprise users section */}
			<PurpleBox>
				<H4 className='m-b-0'>Presenteeism</H4>
				<TitleWrapper>
					<H5 className='m-t-0 m-b-5'>Changes for engaged uprise users</H5>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-5 bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>
				<Row>
					<Col className='col-md-6'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s1}>
							{loading ? (
								<Preloader />
							) : (
								<Fragment>
									<H6 textAlign='center' className='m-b-0'>
										Pre-Uprise
									</H6>
									<H6 textAlign='center' className='m-t-0'>
										Presenteeism
									</H6>
									<Fragment>
										<H1 color={primary.purple} textAlign='center' className='mb-3'>
											{roiStats?.preUprisePresenteeism}
										</H1>
									</Fragment>
									<H6 textAlign='center' color={extended.charcoal.two}>
										Scores during onboarding
									</H6>
								</Fragment>
							)}
						</Card>
					</Col>
					<Col className='col-md-6'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s1}>
							{loading ? (
								<Preloader />
							) : (
								<Fragment>
									<H6 textAlign='center' className='m-b-0'>
										Uprise engaged users
									</H6>
									<H6 textAlign='center' className='m-t-0'>
										Presenteeism
									</H6>
									<Fragment>
										<H1 color={primary.purple} textAlign='center' className='mb-3'>
											{roiStats?.upriseEngagedPresenteeism}
										</H1>
									</Fragment>
									<H6 textAlign='center' className='m-b-0' color={extended.charcoal.one}>
										Scores after 2 coaching calls
									</H6>
									<H6 textAlign='center' className='m-t-0' color={extended.charcoal.one}>
										or completing 2 skills
									</H6>
								</Fragment>
							)}
						</Card>
					</Col>
				</Row>
				<H6 className='m-b-0' textAlign='center' color={extended.charcoal.one}>
					{roiStats?.eligibleUsers} eligible users
				</H6>
				<Medium textAlign='center' color={extended.charcoal.one}>
					All Uprise users since launch
				</Medium>
			</PurpleBox>

			{/* Uprise compare to benchmark average */}
			<PurpleBox>
				<H4 className='m-b-0'>Presenteeism</H4>
				<TitleWrapper>
					<H5 className='m-t-0 m-b-5'>Uprise compare to benchmark average</H5>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-5 bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>

				{/* Chart - Uprise compare to benchmark average */}

				{loading ? (
					<Preloader />
				) : (
					<PurpleBox>
						<GroupedBarChart
							data={{
								labels: roiStats?.presenteeismUpriseCompare?.labels,
								datasets: [
									{
										label: "Presenteeism at Uprise",
										data: roiStats?.presenteeismUpriseCompare?.presenteeism,
										fill: false,
										backgroundColor: primary.purple,
										borderColor: primary.purple
									},
									{
										label: "Benchmark Average",
										data: roiStats?.presenteeismUpriseCompare?.benchmark,
										fill: false,
										backgroundColor: secondary.electricLightOrange,
										borderColor: secondary.electricLightOrange
									}
								]
							}}
						/>
					</PurpleBox>
				)}
			</PurpleBox>

			{/* 2nd copy */}
			{/* Changes for uprise users section */}
			<PurpleBox>
				<H4 className='m-b-0'>Absenteeism</H4>
				<TitleWrapper>
					<H5 className='m-t-0 m-b-5'>Changes for engaged uprise users</H5>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-5 bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>
				<Row>
					<Col className='col-md-6'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s1}>
							{loading ? (
								<Preloader />
							) : (
								<Fragment>
									<H6 textAlign='center' className='m-b-0'>
										Pre-Uprise
									</H6>
									<H6 textAlign='center' className='m-t-0'>
										Absenteeism
									</H6>
									<Fragment>
										<H1 color={primary.purple} textAlign='center' className='mb-3'>
											{roiStats?.preUpriseAbsenteeism}
										</H1>
									</Fragment>
									<H6 textAlign='center' color={extended.charcoal.two}>
										Scores during onboarding
									</H6>
								</Fragment>
							)}
						</Card>
					</Col>
					<Col className='col-md-6'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s1}>
							{loading ? (
								<Preloader />
							) : (
								<Fragment>
									<H6 textAlign='center' className='m-b-0'>
										Uprise engaged users
									</H6>
									<H6 textAlign='center' className='m-t-0'>
										Absenteeism
									</H6>
									<Fragment>
										<H1 color={primary.purple} textAlign='center' className='mb-3'>
											{roiStats?.upriseEngagedAbsenteeism}
										</H1>
									</Fragment>
									<H6 textAlign='center' className='m-b-0' color={extended.charcoal.one}>
										Scores after 2 coaching calls
									</H6>
									<H6 textAlign='center' className='m-t-0' color={extended.charcoal.one}>
										or completing 2 skills
									</H6>
								</Fragment>
							)}
						</Card>
					</Col>
				</Row>
				<H6 className='m-b-0' textAlign='center' color={extended.charcoal.one}>
					{roiStats?.eligibleUsers} eligible users
				</H6>
				<Medium textAlign='center' color={extended.charcoal.one}>
					All Uprise users since launch
				</Medium>
			</PurpleBox>

			{/* Uprise compare to benchmark average */}
			<PurpleBox>
				<H4 className='m-b-0'>Absenteeism</H4>
				<TitleWrapper>
					<H5 className='m-t-0 m-b-5'>Uprise compare to benchmark average</H5>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-5 bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>

				{/* Chart - Uprise compare to benchmark average */}
				{loading ? (
					<Preloader />
				) : (
					<PurpleBox>
						<GroupedBarChart
							data={{
								labels: roiStats?.absenteeismUpriseCompare?.labels,
								datasets: [
									{
										label: "Absenteeism at Uprise",
										data: roiStats?.absenteeismUpriseCompare?.absenteeism,
										fill: false,
										backgroundColor: primary.purple,
										borderColor: primary.purple
									},
									{
										label: "Benchmark Average",
										data: roiStats?.absenteeismUpriseCompare?.benchmark,
										fill: false,
										backgroundColor: secondary.electricLightOrange,
										borderColor: secondary.electricLightOrange
									}
								]
							}}
						/>
					</PurpleBox>
				)}
			</PurpleBox>
		</>
	)
}

export default ReturnOfInvestment
