import React, {useState, useEffect, Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {Line, Bar} from "react-chartjs-2"
import moment from "moment-timezone"

// UI
import {Container, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
// Components
import {Switch} from "@uprise/switch"
// utils
import {retrieveResults} from "uprise-surveys/lib/surveys/getScore"
// Colors
import {backgrounds, primary, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"

const YourStressStyle = Styled.section`
`

const SwitchWrap = Styled.div`
	min-width: 140px;
 	width: 155px;
	display: flex;
	justify-content: end;
`

const NoData = Styled.div`
      height: 149px;
`

const BarStyle = Styled.section`
      height: 250px;
`

const HeaderStyle = Styled.section`
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
	flex-direction: row;
	justify-content: space-between;
	
	@media (max-width: 475px) {
		flex-direction: column;
	}
`

const Content = Styled.section`
      display: flex;
	flex-direction: row;
`

export const YourStress = ({className, stress, chartOptions}) => {
	const stressConfig = {
		labels: [],
		datasets: [
			{
				fill: true,
				lineTension: 0.3,
				backgroundColor: extended.purple.five,
				borderColor: primary.purple,
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: "miter",
				pointBorderColor: primary.purple,
				pointBackgroundColor: "#fff",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: primary.purple,
				pointHoverBorderColor: primary.purple,
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: []
			}
		]
	}

	const [stressData, setStressData] = useState(stressConfig)
	const [chartFormat, setChartFormat] = useState("YYYY")

	const getStressMonth = stress => {
		setChartFormat("MMM")
		const currentMonth = moment().startOf("month")
		const newStress = Object.assign({}, stressData)
		const scores = stress
			.filter(result => {
				if (moment(result.timestamp).format("X") > currentMonth.format("X")) {
					return result
				}
			})
			.map(result => {
				return result.score
			})

		const dates = stress
			.filter(result => {
				if (moment(result.timestamp).format("X") > currentMonth.format("X")) {
					return result
				}
			})
			.map(result => {
				return moment.unix(result.timestamp).format("DD MMM")
			})

		newStress.labels = dates
		newStress.datasets[0].data = scores

		setStressData(newStress)
	}

	const getStressYear = stress => {
		setChartFormat("YYYY")
		const currentYear = moment().startOf("year")
		const newStress = Object.assign({}, stressData)
		const scores = stress
			.filter(result => {
				if (moment(result.timestamp).format("X") > currentYear.format("X")) {
					return result
				}
			})
			.map(result => {
				return result.score
			})

		const dates = stress
			.filter(result => {
				if (moment(result.timestamp).format("X") > currentYear.format("X")) {
					return result
				}
			})
			.map(result => {
				return moment.unix(result.timestamp).format("DD MMM")
			})

		newStress.labels = dates
		newStress.datasets[0].data = scores

		setStressData(newStress)
	}

	useEffect(() => {
		getStressYear(stress)
	}, [stress])

	return (
		<Card backgroundColor={backgrounds.white} padding={spacing.s5} shadow={true}>
			<HeaderStyle>
				<H5 className='m-t-0 m-b-xs-3'>Stress Score</H5>
				<SwitchWrap className='m-b-xs-6'>
					<Switch
						defaultActive='right'
						leftLabel='1 Month'
						rightLabel='1 Year'
						leftAction={() => getStressMonth(stress)}
						rightAction={() => getStressYear(stress)}
					/>
				</SwitchWrap>
			</HeaderStyle>
			{stressData.labels.length > 1 ? (
				<Line data={stressData} options={chartOptions} />
			) : stressData.labels.length > 0 ? (
				<Bar data={stressData} width={200} height={100} options={chartOptions} />
			) : (
				<NoData>
					<Medium marginTop='90px' textAlign='center'>
						No survey data available for {moment().format(chartFormat)}
					</Medium>
				</NoData>
			)}
		</Card>
	)
}
