export const selfCantChangePersonCan = [
	{
		_id: 0,
		text: "This exercise is about how to change as a person when there is a self that is addicted.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"I’d recommend that you do some of the earlier exercises about self and person in this course before you do this one otherwise this will probably be confusing for you.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "Not right now. Take me back.",
					value: "back",
					replyType: "dialogue",
					response: "Ok sure.. taking you back"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text: "The key idea here is that the ‘self’ is an image. An image in a ‘person’ can change.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "Go on.",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"When you experience your ‘self’ feeling anxious, bad, craving or other urges then go inwards and connect with the ‘self’ image that’s controlling you in the present moment.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "How do I do that?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		text:
			"One way is to get a visual image of the ‘self’ that’s trying to control your behaviour and label it using “as if” phrases",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "What do you mean by “as if” phrases?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		text:
			"For example, you could picture a visual image of the ‘self’ trying to control you of you as a small child and say: “This self thinks as if I’m not as good as everyone else”",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 11,
			_next: 12,
			optionType: "radio",
			values: [
				{
					label: "Can you give some more examples?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		text: "“This self thinks as if I don’t care what anyone else thinks”",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		text: "“This self thinks as if it doesn’t matter if I drink”",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 14,
		text: "“This self thinks as if I want to hurt my partner’s feelings”",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		text: "“This self thinks as if people don’t care about me”",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 16,
			_next: 17,
			optionType: "radio",
			values: [
				{
					label: "Ok. I think I understand",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 17,
		text: "What does your ‘self’ think as if when you are stuck in a strong feeling?",
		replyType: "dialogue",
		createdAt: new Date(),
		quickReplies: {
			_id: 18,
			_next: 19,
			optionType: "radio",
			replyType: "survey",
			surveyId: "self-cant-change-person-can-v1",
			taskId: "exercise-self-cant-change-person-can",
			questions: [0],
			recurring: true,
			values: [
				{
					label: "Let’s do it",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 19,
		text:
			"Thanks for doing that exercise with me. I’d recommend that you do it whenever you notice strong feelings to connect with the self-image inside you.		",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 20,
			_next: 21,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 21,
		text:
			"Try to notice that you are observing the self. If you are observing it then the person you are is separate from the self you are observing.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 22,
			_next: 23,
			optionType: "radio",
			values: [
				{
					label: "That’s confusing",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 23,
		text:
			"I know. Most people find this very confusing to start with. You might want to take a look at the Advanced Mindfulness course that explains some of these concepts in more detail.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 24,
			_next: 25,
			optionType: "radio",
			values: [
				{
					label: "Ok. Thanks.",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 25,
		_end: true,
		text: "Sending you back, I've placed your notes in your notebook 👍",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
