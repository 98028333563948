import React, {useState, Fragment} from "react"

// styles
import {ShowOptions, OptionsWrap, FullOptions, Options, OptionsFlat} from "../styles"
// Components
import RadioButton from "../inputTypes/RadioButton"

const SingleOptions = ({handleSend, currentMessage}) => {
	const [repliesState, setRepliesState] = useState([])
	const [showFull, setShowFull] = useState(false)
	// check if reply exists
	const sameReply = currentReply => {
		return reply => {
			return currentReply.label === reply.label
		}
	}

	const _handlePress = reply => {
		const replies = repliesState
		setRepliesState([...replies, reply])

		const {optionType, surveyId, taskId, recurring, _id, _next, questions} = currentMessage.quickReplies
		const {moreInfo, triggerResponse, replyType} = currentMessage

		const response = {
			...reply,
			...{
				_id: Math.random(),
				_next: _next,
				optionType: optionType,
				moreInfo: moreInfo,
				triggerResponse: triggerResponse,
				surveyId,
				taskId,
				recurring,
				label: reply.label,
				replyType: replyType,
				questionIndex: reply.questionIndex,
				questions: questions
			}
		}

		handleSend([response])
	}

	// get options for render
	const options = Object.values(currentMessage.quickReplies.values).map((reply, index) => {
		const selected = repliesState.find(sameReply(reply))
		if (currentMessage.quickReplies) {
			return (
				<RadioButton
					key={index}
					disabled={false}
					reply={reply}
					handlePress={() => _handlePress(reply)}
					index={index}
					selected={selected}
				/>
			)
		}
	})

	return (
		<Fragment>
			{options.length > 2 && window.innerWidth <= 768 ? (
				<Fragment>
					<ShowOptions onClick={() => setShowFull(!showFull)}>
						{!showFull ? <span>Show options</span> : <span>Hide Options</span>}
					</ShowOptions>
					{showFull && (
						<FullOptions>
							<OptionsWrap>
								<Options>{options}</Options>
							</OptionsWrap>
						</FullOptions>
					)}
				</Fragment>
			) : (
				<OptionsFlat>{options}</OptionsFlat>
			)}
		</Fragment>
	)
}

export default SingleOptions
