import React, {useState} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {Alert} from "@uprise/alert"
import LargeSurvey from "components/Rtw/survey/large/LargeSurvey"
import {H5} from "@uprise/headings"

import * as surveys from "uprise-surveys/lib/surveys/index"

const OnboardingAssessmentWrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

function OnboardingAssessment(props) {
	const [alertType, setAlertType] = useState("primary")
	const task = props.userState?.modules?.onboarding?.tasks?.assesment?.tasks["survey-onboarding-rtw"]
	const screeners = getScreeners(props.userState)

	return (
		<OnboardingAssessmentWrapper className='onboarding-assesment rtw-onboarding-assesment'>
			<div className='row'>
				<div className='col-md-12'>
					<Alert className='mb-5' textAlign='center' type={alertType}>
						All questions are mandatory
					</Alert>

					<H5 className='m-b-5'>Please answer the questions below to proceed</H5>

					<LargeSurvey
						module='onboarding'
						taskId='survey-onboarding-rtw'
						surveyId={task.surveyId}
						task={task}
						surveyResults={props.userState.surveyResults}
						screener={props.activeScreener}
						screeners={screeners}
						done='booking'
						handleChange={props.handleChange}
						setAlertType={setAlertType}
					/>
				</div>
			</div>
		</OnboardingAssessmentWrapper>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(OnboardingAssessment)

function getScreeners(userState) {
	const task = userState.modules.onboarding.tasks.assesment.tasks["survey-onboarding-rtw"]
	return surveys.retrieveSurvey(task.surveyId).questions.reduce((screeners, question) => {
		if (screeners.indexOf(question.batteryId) === -1) {
			screeners.push(question.batteryId)
		}
		return screeners
	}, [])
}
