import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"
// Actions
import {getCoachByEmail} from "actions/coachActions"
import {fetchCode} from "actions/codesActions"
import {setDuration} from "actions/bookingsActions"
// Components
import {MoreInfoModal} from "components/Shared/Coach/MoreInfoModal"
// UI
import {ProgressiveImage} from "@uprise/image"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
import {List} from "@uprise/list"
// Colors
import {extended, primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// helpers
import {isTherapyEnabled, isCoachingEnabled, getDuration} from "helpers/utils"

const GetACoachStyles = Styled.section`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
 	}
`

const ContentWrap = Styled.section`
  display: flex;
	flex-direction: row;
	padding: ${spacing.s7};

	@media (max-width: 767px) {
		flex-direction: column;
		text-align: center;
	}
`

const ImageWrap = Styled.section`
  height: 142px;
  width: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
	margin: 0 auto;
`
const ButtonWrap = Styled.section`
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin-top: -${spacing.s6};
 
	@media (max-width: 767px) {
		text-align: center;
		justify-content: center;
	}

	@media (max-width: 1024px) {
		justify-content: flex-start;
	}

	& button {
		width: 170px !important;

		@media (max-width: 480px) {
			width: 100% !important;
		}
	}
`

const ListWrap = Styled.div`
	@media (max-width: 767px) {
		width: 70%;
		margin: 0 auto;
		text-align: left;
		margin-top: ${spacing.s6};
		margin-bottom: ${spacing.s6};
	}
`

const ContentRight = Styled.div`
  display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-left: ${spacing.s10};
	
	@media (max-width: 1024px) {
		/* padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} ${spacing.s7}; */
	}

	@media (max-width: 480px) {
		margin-left: 0px;
	}
`

const BookACall = ({
	className,
	coachEmail,
	coach,
	getCoachByEmail,
	userState,
	fetchCode,
	code,
	setDuration,
	bookings
}) => {
	let history = useHistory()

	const [showMoreInfo, setShowMoreInfo] = useState(false)
	const [isTherapy, setIsTherapy] = useState(false)
	const [isCoaching, setIsCoaching] = useState(true)

	useEffect(() => {
		fetchCode(userState.employerCode)
	}, [userState?.employerCode])

	// Load coach
	useEffect(() => {
		if (coachEmail && coachEmail !== coach.email) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail, coach.email])

	useEffect(() => {
		if (code) getCoachingType()
	}, [JSON.stringify(code)])

	const getCoachingType = () => {
		const duration = getDuration(userState?.features)
		setDuration(duration)
	}

	const list = [
		{title: "Our users rate their coaching call experience 9.2/10"},
		{title: "Coaching calls help you get 4x better results from Uprise"}
	]

	return (
		<GetACoachStyles className={className}>
			<H3 className='m-b-4'>Book a call with your Coach</H3>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<ProgressiveImage
							width='100%'
							height='100%'
							borderRadius='10px'
							src={
								coach?.photos?.avatarURL
									? coach?.photos?.imageURL
									: require("assets/images/coach/default-coach.png")
							}
							overlaySrc={
								coach?.photos?.avatarURI
									? coach?.photos?.avatarURI
									: require("assets/images/coach/default-coach.png")
							}
						/>
					</ImageWrap>
					<ContentRight>
						<H5 color={primary.charcoal} className='m-b-1 m-t-0 m-b-sm-4 m-t-sm-4'>
							Book a call with {coach.name}
						</H5>
						<ListWrap className='m-b-1'>
							<Medium>You don’t have to do it alone</Medium>
							<List items={list} />
						</ListWrap>
						<ButtonWrap>
							<Button
								className='m-t-4'
								title='Find out more'
								variant='secondary'
								size='medium'
								fullWidth={false}
								onClick={() => setShowMoreInfo(true)}
							/>
							<MoreInfoModal isOpen={showMoreInfo} handleClose={() => setShowMoreInfo(false)} />
							<Button
								className='m-l-3 m-t-4 m-l-xs-0'
								title={`Book a call`}
								variant='primary'
								size='medium'
								fullWidth={false}
								onClick={() =>
									history.push("make-booking", {
										from: location.pathname,
										duration: bookings.duration
									})
								}
							/>
						</ButtonWrap>
					</ContentRight>
				</ContentWrap>
			</Card>
		</GetACoachStyles>
	)
}

function mapStateToProps(state) {
	return {
		coach: state.coach,
		userState: state.userState,
		code: state.code,
		bookings: state.bookings
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	fetchCode: employerCode => dispatch(fetchCode(employerCode)),
	setDuration: duration => dispatch(setDuration(duration))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookACall)
