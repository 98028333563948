import React from "react"
import {Line} from "react-chartjs-2"
import styled from "styled-components"
import {primary, secondary} from "@uprise/colors"

const Wrapper = styled.div`
	max-height: 400px;
	width: min(100%, 700px);
	margin: 15px auto;
`

const options = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
					max: 100,
					stepSize: 20,
					callback: function(val) {
						return `${val}%`
					}
				}
			}
		]
	},
	legend: {
		// display: false,
		position: "bottom",
		labels: {
			padding: 20,
			fontSize: 20
		}
	}
}

const LineChart = ({
	data = {
		labels: [`...`, `...`, `...`, `...`, `...`, `...`],
		datasets: [
			{
				label: "Monthly Average",
				data: [0, 0, 0, 0, 0, 0],
				fill: false,
				backgroundColor: primary.purple,
				borderColor: primary.purple
			},
			{
				label: "Industry Monthly Average",
				data: [0, 0, 0, 0, 0, 0],
				fill: false,
				backgroundColor: secondary.electricLightOrange,
				borderColor: secondary.electricLightOrange
			}
		]
	}
}) => (
	<Wrapper>
		<Line
			data={{
				labels: data.monthLabels,
				datasets: [
					{
						label: "Monthly Average",
						data: data.averageWellbeingByEmpCode,
						fill: false,
						backgroundColor: primary.purple,
						borderColor: primary.purple
					},
					{
						label: "Industry Monthly Average",
						data: data.averageWellbeingAll,
						fill: false,
						backgroundColor: secondary.electricLightOrange,
						borderColor: secondary.electricLightOrange
					}
				]
			}}
			options={options}
			height={140}
		/>
	</Wrapper>
)

export default LineChart
