import React from "react"
import {EditorState, convertToRaw, ContentState} from "draft-js"
import {Editor} from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {WysiwygStyles} from "./index.style"

const toolbarConfig = {
	options: [
		"inline",
		// "blockType",
		// "list",
		"emoji",
		"history"
	],
	inline: {
		options: ["bold", "italic", "underline", "strikethrough"]
	}
}

function Wysiwyg({initialValue, placeholder, onChange, editable = true}) {
	const [savingNow, setSavingNow] = React.useState(false)
	const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

	React.useEffect(() => {
		if (savingNow) return
		if (typeof initialValue === "string" && initialValue) {
			setEditorState(
				EditorState.createWithContent(
					ContentState.createFromBlockArray(htmlToDraft(initialValue)?.contentBlocks)
				)
			)
		} else {
			setEditorState(EditorState.createEmpty())
		}
	}, [initialValue, savingNow])

	React.useEffect(() => {
		if (editorState && savingNow) {
			onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
		}
	}, [editorState, onChange, savingNow])

	return (
		<WysiwygStyles>
			{editable ? (
				<Editor
					editorState={editorState}
					placeholder={placeholder}
					wrapperClassName='wysiwyg-wrapper'
					editorClassName='wysiwyg-editor'
					onEditorStateChange={eState => {
						setEditorState(eState)
						setSavingNow(true)
					}}
					toolbar={toolbarConfig}
				/>
			) : (
				<div dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(editorState.getCurrentContent()))}} />
			)}
		</WysiwygStyles>
	)
}

export default Wysiwyg
