import React, {useState, Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import moment from "moment-timezone"

// Components
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Note, Medium, ExtraSmall} from "@uprise/text"
import {List} from "@uprise/list"
import CancelModal from "components/Shared/UpComingSession/CancelModal"
// Colors
import {extended, primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// helpers
import {getCoachType} from "helpers/utils"

const UpComingSessionStyles = Styled.section`
`

const ContentWrap = Styled.section`
      display: flex;
      flex-direction: row;
`

const ImageWrap = Styled.section`
      min-width: 275px;
      height: 228px;
      display: flex;
      justify-content: center;
      align-items: center;
`
const ButtonWrap = Styled.section`
      display: flex;
      flex-direction: row;
`

const UpComingSessionImage = Styled.img`
      height: 150px;
      width: 195px;
`

const ContentRight = Styled.div`
      padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} 0;
      width: 100%;
`

const Date = Styled.span`

`

const DateBar = Styled.div`
	padding: 15px;
	width: 300px;
	border-radius: 5px;
      border: 1px solid ${extended.lightOrange.one};
	background-color: ${extended.lightOrange.five};
`

const UpComingSession = ({className, booking}) => {
	let history = useHistory()

	const [showCancelModel, setShowCancelModel] = useState(false)
	const isLess24Hours = moment(booking?.start).diff(moment(), "hours") < 24

	return (
		<UpComingSessionStyles className={className}>
			<H3 className='m-b-0-5'>Upcoming session</H3>

			<Medium color={extended.charcoal.one} className='m-b-6'>
				This is your next Uprise {getCoachType(booking.duration)} session
			</Medium>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<UpComingSessionImage src={require(`assets/images/art/coaching-for-a-specific-issue@3x.png`)} />
					</ImageWrap>
					<ContentRight>
						<H5 color={primary.charcoal} className='m-b-2 m-t-0'>
							{booking.coachName} will call you on
						</H5>

						<DateBar className='m-b-4'>
							<H5 lineHeight='1.2' className='m-t-0 m-b-0'>
								<Date>{moment(booking?.start).format("DD MMMM")}, </Date>
								<Date>{moment(booking?.start).format("h:mm A")} </Date>
								<Date>{moment.tz.guess()}</Date>
							</H5>
						</DateBar>

						<Note className='m-b-4'>
							{getCoachType(booking.duration)} calls less than 24 hours away cannot be rescheduled
						</Note>

						<ButtonWrap>
							<Button
								className='m-r-3'
								title='Cancel'
								variant='secondary'
								size='medium'
								fullWidth={false}
								width='160px'
								onClick={() => setShowCancelModel(true)}
							/>
							{!isLess24Hours && (
								<Button
									title='Reschedule'
									variant='primary'
									size='medium'
									fullWidth={false}
									width='170px'
									onClick={() =>
										history.push("make-booking", {
											duration: booking.duration,
											from: location.pathname,
											reschedule: JSON.parse(JSON.stringify(booking))
										})
									}
								/>
							)}

							<CancelModal
								isOpen={showCancelModel}
								booking={booking}
								inTime={!isLess24Hours}
								handleClose={() => setShowCancelModel(false)}
							/>
						</ButtonWrap>
					</ContentRight>
				</ContentWrap>
			</Card>
		</UpComingSessionStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(UpComingSession)
