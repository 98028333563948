import React, {Fragment} from "react"
import styled from "styled-components"
// UI
import {spacing} from "@uprise/spacing"
// Colors
import {extended} from "@uprise/colors"

const RadioContainer = styled.div`
	position: relative;
	width: ${props => (props.size ? props.size : 20)}px;
	height: ${props => (props.size ? props.size : 20)}px;

	&::before {
		content: "";
		border-radius: 100%;
		border: 1px solid ${props => (props.borderColor ? props.borderColor : "#d4cbfb")};
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		box-sizing: border-box;
		pointer-events: none;
		z-index: 0;
		left: 0;
	}
`

const Fill = styled.div`
	background: #7d60ff;
	width: 0;
	height: 0;
	border-radius: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: width 0.2s ease-in, height 0.2s ease-in;
	pointer-events: none;
	z-index: 1;

	&::before {
		content: "";
		opacity: 0;
		width: 6px;
		position: absolute;
		height: 6px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 1px solid ${props => (props.borderActive ? props.borderActive : "#A475E4")};
		border-radius: 100%;
	}
`

const Input = styled.input`
	opacity: 0;
	z-index: 2;
	//position: absolute;
	width: 100%;
	height: 100%;
	margin: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&:checked {
		& ~ ${Fill} {
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			transition: width 0.2s ease-out, height 0.2s ease-out;

			&::before {
				opacity: 1;
				transition: opacity 1s ease;
			}
		}
	}
`

const Label = styled.label`
	width: ${props => (props.width ? props.width : "150px")};
	height: 20px;
	font-family: Proxima Nova;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	margin-left: ${spacing.s4};
	text-align: left;
	color: ${extended.charcoal.one}
`

const Radio = ({className, width, height, checked, iconYPos, name, label, value, ...props}) => (
	<Fragment>
		<RadioContainer className={"radio-item"}>
			<Input
				type='radio'
				id={value}
				name={name}
				value={value}
				checked={checked}
				aria-checked={checked}
				{...props}
			/>
			<Fill />
		</RadioContainer>
		<Label width={width} for={value}>
			{label}
		</Label>
	</Fragment>
)

export default Radio
