import React, {useState, Fragment, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import moment from "moment"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Col, Row} from "@uprise/grid"
import {H3, H4, H5, H6} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {spacing} from "@uprise/spacing"
import {backgrounds, extended, primary, secondary, semantic} from "@uprise/colors"
import ToggleModal from "components/Shared/ToggleModal"
import DropDownBorderless from "components/Shared/DropDown"
import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import GroupedBarChart from "components/Shared/Graphs/GroupedBar"
import GroupedHorizontalBarChart from "components/Shared/Graphs/GroupedHorizontalBar"
import CircularProgress from "components/Shared/Graphs/CircularProgress"
import {InfoText, ModalOpenButtonContainer, PurpleBox, Center, SectionContainer} from "../index.style"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"
import {fetchHrDashboardRiskStats} from "actions/hrdashboardActions"
import Preloader from "components/Shared/Preloader/Preloader"

function Risk() {
	const now = new moment()
	const last6Months = [...Array(6)].map((_, i) => {
		let tempMoment = now
			.clone()
			.startOf("month")
			.subtract(i + 1, "month")

		return {
			value: tempMoment.format("YYYY-MM-DDThh:mm:ss.SSS"),
			label: tempMoment.format("MMMM")
		}
	})

	const industryAverage = {
		causeOfImpairment: {
			"Overloaded with work": {
				count: 620,
				percentage: 31,
				label: "Overloaded with work"
			},
			"Current project not engaging/interesting": {
				count: 647,
				percentage: 32,
				label: "Current project not engaging/interesting"
			},
			"Lacking resources to do job": {
				count: 358,
				percentage: 18,
				label: "Lacking resources to do job"
			},
			"Lacking support to do job": {
				count: 439,
				percentage: 22,
				label: "Lacking support to do job"
			},
			"Uncertainty if my job is secure": {
				count: 327,
				percentage: 16,
				label: "Uncertainty if my job is secure"
			},
			"Not paid enough for the work I do": {
				count: 269,
				percentage: 13,
				label: "Not paid enough for the work I do"
			},
			"Frustrated with a colleague/colleagues": {
				count: 489,
				percentage: 24,
				label: "Frustrated with a colleague/colleagues"
			},
			"Family issue": {
				count: 425,
				percentage: 21,
				label: "Family issue"
			},
			"Unfair/disrespectful treatment by management": {
				count: 136,
				percentage: 7,
				label: "Unfair/disrespectful treatment by management"
			},
			Bullying: {
				count: 42,
				percentage: 2,
				label: "Bullying"
			},
			"Not sure what's expected of me for task/role/project": {
				count: 399,
				percentage: 20,
				label: "Not sure what's expected of me for task/role/project"
			},
			"Not recognised enough for the work I do": {
				count: 330,
				percentage: 17,
				label: "Not recognised enough for the work I do"
			},
			"Lacking friendships/peer support at work": {
				count: 217,
				percentage: 11,
				label: "Lacking friendships/peer support at work"
			},
			"Lacking opportunities to work on what I like": {
				count: 322,
				percentage: 16,
				label: "Lacking opportunities to work on what I like"
			},
			"N/A": {
				count: 0,
				percentage: 0,
				label: "N/A"
			},
			None: {
				count: 0,
				percentage: 0,
				label: "None"
			},
			"None of these options": {
				count: 0,
				percentage: 0,
				label: "None of these options"
			}
		}
	}

	const prepareEndDate = startDate =>
		moment(startDate)
			.add(1, "months")
			.subtract(1, "second")
			.format("YYYY-MM-DDThh:mm:ss.SSS")

	const [month, setMonth] = useState(last6Months[0])

	const dispatch = useDispatch()
	const {riskStats, loading} = useSelector(state => ({
		riskStats: state.hrdashboard.riskStats?.data,
		loading: state.hrdashboard.riskStats?.loading
	}))
	console.log(riskStats, "riskStatsriskStats")
	const {employerCode} = useSelector(state => state.userState)
	useEffect(() => {
		if (employerCode) {
			dispatch(
				fetchHrDashboardRiskStats({employerCode, startDate: month.value, endDate: prepareEndDate(month.value)})
			)
		}
	}, [employerCode, month.value])

	const turnOverRisk =
		(!loading &&
			(riskStats?.preEngagement?.turnOverRisk?.highPercentage +
				riskStats?.postEngagement?.turnOverRisk?.highPercentage) /
				2) ||
		0

	const mentalIssueRisk =
		(!loading &&
			(riskStats?.postWellbeingCounts?.organisationScore?.high +
				riskStats?.preWellbeingCounts?.organisationScore?.high) /
				2) ||
		0

	const stressRisk =
		(!loading &&
			(riskStats?.preStressCounts?.organisationScore?.high +
				riskStats?.postStressCounts?.organisationScore?.high) /
				2) ||
		0

	const psychosocialHazardsLabel =
		(riskStats &&
			Object.keys(riskStats.causeOfImpairment).length !== 0 &&
			Object.keys(riskStats.causeOfImpairment).map((answer, index) => {
				return answer
			})) ||
		[]

	const upriseWellbeingLevels =
		(riskStats &&
			Object.keys(riskStats.causeOfImpairment).length !== 0 &&
			Object.keys(riskStats.causeOfImpairment).map((answer, index) => {
				return riskStats.causeOfImpairment[answer].percentage
			})) ||
		[]

	const industryWellbeingLevels =
		(riskStats &&
			Object.keys(riskStats.causeOfImpairment).length !== 0 &&
			Object.keys(riskStats.causeOfImpairment).map((answer, index) => {
				return industryAverage.causeOfImpairment[answer]
					? industryAverage.causeOfImpairment[answer].percentage
					: 0
			})) ||
		[]

	return (
		<>
			{/* Current Risk Level Row */}
			<PurpleBox padding={spacing.s7}>
				<TitleWrapper>
					<H4 className='m-b-5'>Current Risk Levels</H4>
					<DropDownBorderless
						fullWidth
						onChange={e => setMonth(e)}
						items={last6Months}
						initialSelectedItem={month}
						menuStyle={{position: "absolute", zIndex: 2}}
						containerStyle={{background: "white", width: "400px", marginBottom: 4}}
						customListItemRender={item => (
							<div style={{display: "flex", justifyContent: "space-between"}}>
								<p>{item.label}</p>
							</div>
						)}
					/>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-5 bg-transparent text-right'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>
				{/* Current Risk Level Row - Chart */}
				{loading ? (
					<Preloader />
				) : (
					<Row>
						<Col className='col-md-4 mb-4'>
							<Card
								className='h-100'
								shadow={true}
								backgroundColor={backgrounds.white}
								padding={spacing.s1}>
								<SectionContainer>
									<H6 textAlign='center'>Employees at risk of Leaving</H6>
									<Center>
										<CircularProgress
											strokeWidth='18'
											sqSize='100'
											title='High risk'
											percentage={turnOverRisk}
										/>
									</Center>
								</SectionContainer>
							</Card>
						</Col>
						<Col className='col-md-4 mb-4'>
							<Card
								className='h-100'
								shadow={true}
								backgroundColor={backgrounds.white}
								padding={spacing.s1}>
								<SectionContainer>
									<H6 textAlign='center'>Employees at risk of a mental health issue</H6>
									<Center>
										<CircularProgress
											strokeWidth='18'
											sqSize='100'
											title='High risk'
											percentage={mentalIssueRisk}
										/>
									</Center>
								</SectionContainer>
							</Card>
						</Col>
						<Col className='col-md-4 mb-4'>
							<Card
								className='h-100'
								shadow={true}
								backgroundColor={backgrounds.white}
								padding={spacing.s1}>
								<SectionContainer>
									<H6 textAlign='center'>Employees with high stress</H6>
									<Center>
										<CircularProgress
											strokeWidth='18'
											sqSize='100'
											title='High risk'
											percentage={stressRisk}
										/>
									</Center>
								</SectionContainer>
							</Card>
						</Col>
					</Row>
				)}
			</PurpleBox>

			{/* Psychosocial Hazards - TODO */}
			<PurpleBox padding={spacing.s7}>
				<H4 className='m-b-0'>Psychosocial Hazards</H4>
				<H5 className='m-t-0 m-b-2'>What challenges are people in your organisation facing?</H5>
				{loading ? (
					<Preloader />
				) : (
					<>
						<TitleWrapper>
							<DropDownBorderless
								fullWidth
								onChange={e => setMonth(e)}
								items={last6Months}
								initialSelectedItem={month}
								menuStyle={{position: "absolute", zIndex: 2}}
								containerStyle={{background: "white", width: "400px", marginBottom: 4}}
								customListItemRender={item => (
									<div style={{display: "flex", justifyContent: "space-between"}}>
										<p>{item.label}</p>
									</div>
								)}
							/>
							{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-2 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
						</TitleWrapper>
						{/* Employees at risk of leaving - Uprise vs Benchmark Avg CHART */}
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<GroupedHorizontalBarChart
								data={{
									labels: psychosocialHazardsLabel,
									datasets: [
										{
											label: "Industry Wellbeing Levels",
											data: upriseWellbeingLevels,
											fill: false,
											backgroundColor: secondary.electricLightOrange,
											borderColor: secondary.electricLightOrange
										},
										{
											label: "Uprise Wellbeing Levels",
											data: industryWellbeingLevels,
											fill: false,
											backgroundColor: primary.purple,
											borderColor: primary.purple
										}
									]
								}}
							/>
						</Card>
					</>
				)}
			</PurpleBox>

			{/* Risk level changes for engaged Uprise Users */}
			<PurpleBox padding={spacing.s7}>
				<TitleWrapper>
					<H4 className='m-b-5'>Risk level changes for engaged Uprise Users</H4>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-5 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>

				{/* Risk level changes for engaged Uprise Users - CHARTS */}
				<Row>
					<Col className='col-md-6 mb-4'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<H4 className='m-b-5' textAlign='center'>
								Pre-Uprise
							</H4>
							<Center>
								<CircularProgress strokeWidth='18' sqSize='120' title='High risk' percentage='25' />
							</Center>
							<Medium className='m-t-5' textAlign='center' color={extended.charcoal.one}>
								Scores during onboarding
							</Medium>
						</Card>
					</Col>
					<Col className='col-md-6 mb-4'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<H4 className='m-b-5' textAlign='center'>
								Post-Uprise
							</H4>
							<Center>
								<CircularProgress strokeWidth='18' sqSize='120' title='High risk' percentage='25' />
							</Center>
							<Medium className='m-t-5' textAlign='center' color={extended.charcoal.one}>
								Scores after 2 coaching calls
							</Medium>
							<Medium textAlign='center' color={extended.charcoal.one}>
								or completing 2 modules
							</Medium>
						</Card>
					</Col>
				</Row>
				<Medium textAlign='center' className='m-b-0' color={extended.charcoal.one}>
					Eligible users 5
				</Medium>
			</PurpleBox>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg */}
			<PurpleBox padding={spacing.s7}>
				<H4 className='m-b-0'>Employees at risk of leaving</H4>
				<H5 className='m-t-0 m-b-2'>Uprise vs Benchmark Average</H5>
				<TitleWrapper>
					<DropDownBorderless
						fullWidth
						onChange={e => setMonth(e)}
						items={last6Months}
						initialSelectedItem={month}
						menuStyle={{position: "absolute", zIndex: 2}}
						containerStyle={{background: "white", width: "400px", marginBottom: 4}}
						customListItemRender={item => (
							<div style={{display: "flex", justifyContent: "space-between"}}>
								<p>{item.label}</p>
							</div>
						)}
					/>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-2 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>
				{/* Employees at risk of leaving - Uprise vs Benchmark Avg CHART */}
				<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
					<GroupedBarChart
						data={{
							labels: ["Jan-20", "Feb-20", "Mar-20", "Apr-20", "May-20", "Jun-20"],
							datasets: [
								{
									label: "Employees at risk of leaving",
									data: [55, 60, 70, 65, 70, 70],
									fill: false,
									backgroundColor: primary.purple,
									borderColor: primary.purple
								},
								{
									label: "Benchmark Average",
									data: [50, 60, 75, 70, 60, 60],
									fill: false,
									backgroundColor: secondary.electricLightOrange,
									borderColor: secondary.electricLightOrange
								}
							]
						}}
					/>
				</Card>
			</PurpleBox>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg */}
			<PurpleBox padding={spacing.s7}>
				<H4 className='m-b-0'>Risk of Mental Health Issue</H4>
				<TitleWrapper>
					<H5 className='m-t-0 m-b-2'>Changes for engaged Uprise users</H5>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-2 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>

				{/* Risk level changes for engaged Uprise Users - CHARTS */}
				<Row>
					<Col className='col-md-6 mb-4'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<H4 className='m-b-5' textAlign='center'>
								Pre-Uprise
							</H4>
							<Center>
								<CircularProgress strokeWidth='18' sqSize='120' title='High risk' percentage='25' />
							</Center>
							<Medium className='m-t-5' textAlign='center' color={extended.charcoal.one}>
								Scores during onboarding
							</Medium>
						</Card>
					</Col>
					<Col className='col-md-6 mb-4'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<H4 className='m-b-5' textAlign='center'>
								Post-Uprise
							</H4>
							<Center>
								<CircularProgress strokeWidth='18' sqSize='120' title='High risk' percentage='25' />
							</Center>
							<Medium className='m-t-5' textAlign='center' color={extended.charcoal.one}>
								Scores after 2 coaching calls
							</Medium>
							<Medium textAlign='center' color={extended.charcoal.one}>
								or completing 2 modules
							</Medium>
						</Card>
					</Col>
				</Row>
				<H6 textAlign='center' className='m-t-0 m-b-0' color={extended.charcoal.one}>
					Eligible users 5
				</H6>
				<Medium textAlign='center' className='m-t-0 m-b-0' color={extended.charcoal.one}>
					All Uprise users since launch
				</Medium>
			</PurpleBox>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg */}
			<PurpleBox padding={spacing.s7}>
				<H4 className='m-b-0'>Risk of Mental Health issue</H4>
				<H5 className='m-t-0 m-b-2'>Uprise vs Benchmark Average</H5>
				<TitleWrapper>
					<DropDownBorderless
						fullWidth
						onChange={e => setMonth(e)}
						items={last6Months}
						initialSelectedItem={month}
						menuStyle={{position: "absolute", zIndex: 2}}
						containerStyle={{background: "white", width: "400px", marginBottom: 4}}
						customListItemRender={item => (
							<div style={{display: "flex", justifyContent: "space-between"}}>
								<p>{item.label}</p>
							</div>
						)}
					/>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-2 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>
				{/* Employees at risk of leaving - Uprise vs Benchmark Avg CHART */}
				<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
					<GroupedBarChart
						data={{
							labels: ["Jan-20", "Feb-20", "Mar-20", "Apr-20", "May-20", "Jun-20"],
							datasets: [
								{
									label: "Risk of Mental Health Issue - Uprise",
									data: [55, 60, 70, 65, 70, 70],
									fill: false,
									backgroundColor: primary.purple,
									borderColor: primary.purple
								},
								{
									label: "Benchmark Average",
									data: [50, 60, 75, 70, 60, 60],
									fill: false,
									backgroundColor: secondary.electricLightOrange,
									borderColor: secondary.electricLightOrange
								}
							]
						}}
					/>
				</Card>
			</PurpleBox>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg */}
			<PurpleBox padding={spacing.s7}>
				<H4 className='m-b-0'>Users with high stress</H4>
				<TitleWrapper>
					<H5 className='m-t-0 m-b-2'>Changes for engaged Uprise users</H5>
					<ToggleModal
						toggle={show => (
							<Button
								className='m-b-2 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/>
				</TitleWrapper>

				{/* Risk level changes for engaged Uprise Users - CHARTS */}
				<Row>
					<Col className='col-md-6 mb-4'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<H4 className='m-b-5' textAlign='center'>
								Pre-Uprise
							</H4>
							<Center>
								<CircularProgress strokeWidth='18' sqSize='120' title='High risk' percentage='25' />
							</Center>
							<Medium className='m-t-5' textAlign='center' color={extended.charcoal.one}>
								Scores during onboarding
							</Medium>
						</Card>
					</Col>
					<Col className='col-md-6 mb-4'>
						<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
							<H4 className='m-b-5' textAlign='center'>
								Post-Uprise
							</H4>
							<Center>
								<CircularProgress strokeWidth='18' sqSize='120' title='High risk' percentage='25' />
							</Center>
							<Medium className='m-t-5' textAlign='center' color={extended.charcoal.one}>
								Scores after 2 coaching calls
							</Medium>
							<Medium textAlign='center' color={extended.charcoal.one}>
								or completing 2 modules
							</Medium>
						</Card>
					</Col>
				</Row>
				<H6 textAlign='center' className='m-t-0 m-b-0' color={extended.charcoal.one}>
					Eligible users 5
				</H6>
				<Medium textAlign='center' className='m-t-0 m-b-0' color={extended.charcoal.one}>
					All Uprise users since launch
				</Medium>
			</PurpleBox>

			{/* Employees at risk of leaving - Uprise vs Benchmark Avg */}
			<PurpleBox padding={spacing.s7}>
				<H4 className='m-b-0'>Users with High Stress Levels</H4>
				<H5 className='m-t-0 m-b-2'>Uprise vs Benchmark Average</H5>
				<TitleWrapper>
					<DropDownBorderless
						fullWidth
						onChange={e => setMonth(e)}
						items={last6Months}
						initialSelectedItem={month}
						menuStyle={{position: "absolute", zIndex: 2}}
						containerStyle={{background: "white", width: "400px", marginBottom: 4}}
						customListItemRender={item => (
							<div style={{display: "flex", justifyContent: "space-between"}}>
								<p>{item.label}</p>
							</div>
						)}
					/>
					{/* <ToggleModal
						toggle={show => (
							<Button
								className='m-b-2 text-right bg-transparent'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										<InfoText>Help me interpret this</InfoText>
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/> */}
				</TitleWrapper>
				{/* Employees at risk of leaving - Uprise vs Benchmark Avg CHART */}
				<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
					<GroupedBarChart
						data={{
							labels: ["Jan-20", "Feb-20", "Mar-20", "Apr-20", "May-20", "Jun-20"],
							datasets: [
								{
									label: "Uprise Employees with High Stress Levels",
									data: [55, 60, 70, 65, 70, 70],
									fill: false,
									backgroundColor: primary.purple,
									borderColor: primary.purple
								},
								{
									label: "Benchmark Average",
									data: [50, 60, 75, 70, 60, 60],
									fill: false,
									backgroundColor: secondary.electricLightOrange,
									borderColor: secondary.electricLightOrange
								}
							]
						}}
					/>
				</Card>
			</PurpleBox>
		</>
	)
}

export default Risk
