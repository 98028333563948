import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import moment from "moment-timezone"

// Utils
import {getExercises, getSkills} from "./utils"
// Components
import {Container, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H3, H5} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
// Colors
import {primary, extended, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// icons
import Icons from "assets/images/icons/svg"

const YourJourneyStyles = Styled.section`

`

const CardsWrap = Styled.section`
      display: flex;
	flex-direction: row;
	
	@media (max-width: 475px) {
		flex-direction: column
	}
`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: column;
`

const Header = Styled.header`
      display: flex;
      flex-direction: row;
`

const Count = Styled.h1`
      text-align: center;
      font-family: 'Proxima Nova Semibold';
      color: ${primary.purple};
      font-weight: 'bold';
      font-size: 72px;
      align-self: center;
      width: 100%;
      line-height: 0.75;
`

const Icon = Styled.img`
      margin-top: 2px;
      width: 22px;
      height: 22px;
`

const YourJourney = ({className, userState}) => {
	const skillsCount = getSkills(userState.meta).toString()
	const exerciseCount = getExercises(userState.meta).toString()
	const minCount = moment(userState.totalTasksTimeCompleted, "mm:ss").format("m")

	return (
		<YourJourneyStyles className={className}>
			<H3 className='m-b-4'>Your Journey</H3>
			<CardsWrap>
				<Card
					className='m-r-5 m-b-xs-6'
					height='206px'
					width='100%'
					shadow={true}
					backgroundColor={backgrounds.white}
					padding={spacing.s7}>
					<ContentWrap>
						<Header className='m-b-10'>
							<Icon src={Icons.medal} className='m-r-2' />
							<H5 className='m-t-0 m-b-0' weight='bold' color={primary.charcoal}>
								Skills Acquired
							</H5>
						</Header>
						<Count>{skillsCount}</Count>
					</ContentWrap>
				</Card>
				<Card
					className='m-r-5 m-b-xs-6 m-r-xs-0'
					height='206px'
					width='100%'
					shadow={true}
					backgroundColor={backgrounds.white}
					padding={spacing.s7}>
					<ContentWrap>
						<Header className='m-b-10'>
							<Icon src={Icons.exercise} className='m-r-2' />
							<H5 className='m-t-0 m-b-0' weight='bold' color={primary.charcoal}>
								Exercises completed
							</H5>
						</Header>
						<Count>{exerciseCount}</Count>
					</ContentWrap>
				</Card>
				<Card
					className='m-r-5 m-b-xs-6 m-r-xs-0'
					height='206px'
					width='100%'
					shadow={true}
					backgroundColor={backgrounds.white}
					padding={spacing.s7}>
					<ContentWrap>
						<Header className='m-b-10'>
							<Icon src={Icons.time} className='m-r-2' />
							<H5 className='m-t-0 m-b-0' weight='bold' color={primary.charcoal}>
								Minutes spent
							</H5>
						</Header>
						<Count>{minCount}</Count>
					</ContentWrap>
				</Card>
			</CardsWrap>
		</YourJourneyStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(YourJourney)
