import React from "react"
import Styled from "styled-components"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
import "components/Rtw/survey/styles/main.css"

const ContentWrap = Styled.div`
	display: flex;
	justify-content: center;
`

const Description = Styled.div`
	display: flex;
	justify-content: center;
`

const ListWrapper = Styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0px;

	& li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media (min-width: 1024px) {
		flex-direction: row;
		justify-content: center;
	}
`

function PreOnboardingOverview(props) {
	return (
		<ContentWrap>
			<Card
				border={`1px solid ${extended.purple.five}`}
				shadow={false}
				backgroundColor={backgrounds.white}
				padding={spacing.s10}
				width='900px'>
				<div className='onboarding-introduction rtw-onboarding-introduction'>
					<Row className='row'>
						<Col className='col-md-12 flex flex-column align-items-center'>
							<H3 textAlign='center' className='onboarding__title'>
								Eligibility Check
							</H3>
							<Description>
								<Medium className='m-t-6 text-center' width='500px'>
									Before you begin, we have a few questions for you to determine your eligibility for
									participation in the Uprise Workable Program.
								</Medium>
							</Description>

							<ListWrapper className='onboarding__how-helps mt-5 w-100'>
								<li>
									<img
										className='onboarding__how-helps-img block mb-4'
										src={require("assets/images/rtw/icons/icon-postprogram.png")}
									/>
									<Medium>Pre screening assessment</Medium>
								</li>
							</ListWrapper>

							<div className='w-100 mt-4 mb-5'>
								<hr className='mb-3' />
							</div>

							<Row className='justify-content-center'>
								<Col className='col-sm-6'>
									<Button
										className='m-auto px-4'
										title='Start pre screening assessment'
										fullWidth={false}
										onClick={props.handleNext}
									/>
								</Col>
							</Row>
							<Medium textAlign='center' className='mt-4'>
								All your information is confidential
							</Medium>
						</Col>
					</Row>
				</div>
			</Card>
		</ContentWrap>
	)
}
export default PreOnboardingOverview
