import React from "react"
import Styled from "styled-components"

// UI
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Note} from "@uprise/text"

// Colors
import {backgrounds} from "@uprise/colors"

const Content = Styled.section`
      width: 100%;
      padding: 20px 20px;
`

export const Transcript = ({className, transcript}) => {
	return (
		<Card className='className' shadow={true} width='100%' backgroundColor={backgrounds.white}>
			<Content>
				<H3 className='m-b-5' textAlign='left' width='100%'>
					Transcript
				</H3>
				<Note>
					<div dangerouslySetInnerHTML={{__html: transcript}} />
				</Note>
			</Content>
		</Card>
	)
}
