import {backgrounds, extended, primary} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import styled from "styled-components"

export const PickerStyles = styled.div`
	margin-bottom: 45px;

	.month-picker {
		position: absolute;
	}

	.month-picker > .rmp-container {
		position: relative;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		z-index: 100;
		top: 1px;
		left: -10000px;
		transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
	}

	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container {
			position: fixed;
			top: 0;
			left: -10000px;
			width: 100%;
			height: 100%;
			transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
		}
	}

	.month-picker > .rmp-container.rmp-table {
		display: table;
	}

	.month-picker > .rmp-container.show {
		/* left: 0; */
		left: -270px;
		transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	}

	.month-picker > .rmp-container.show .rmp-overlay {
		left: 0;
	}

	.month-picker > .rmp-container .rmp-overlay {
		position: fixed;
		height: 100%;
		width: 100%;
		z-index: 9;
		top: 0;
		left: -10000px;
		opacity: 1;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		will-change: opacity;
		transform: translateZ(0);
		transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	}

	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container .rmp-overlay {
			background-color: rgba(0, 0, 0, 0.25);
		}
	}

	.month-picker > .rmp-container .rmp-cell {
		display: table-cell;
		vertical-align: middle;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container .rmp-cell {
			vertical-align: bottom;
		}
	}

	.month-picker > .rmp-container .rmp-popup {
		position: absolute;
		transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
		margin: 0 auto;
		z-index: 10;
		font-size: 1.2rem;
		opacity: 0;
		border-radius: 3px;
		padding: 0.4rem;
		box-sizing: content-box;
	}

	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container .rmp-popup {
			box-sizing: border-box;
			bottom: 0;
			width: 100%;
			max-width: 100%;
			height: 14.4rem;
			transform: translate3d(0, 14.4rem, 0);
		}
		.month-picker > .rmp-container .rmp-popup.range {
			height: 28rem;
			transform: translate3d(0, 28rem, 0);
		}
		.month-picker > .rmp-container .rmp-popup.range .rmp-pad {
			margin-top: 0.4rem;
		}
		.month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
			margin-top: 0;
		}
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup {
			transform: translate3d(0, -64px, 0);
			top: 0;
			width: 20rem;
		}
		.month-picker > .rmp-container .rmp-popup.range {
			width: 40.6rem;
			padding: 0.6rem;
		}
	}
	.month-picker > .rmp-container .rmp-popup.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	.month-picker > .rmp-container .rmp-popup:after {
		content: " ";
		clear: both;
		display: table;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad {
		position: relative;
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup .rmp-pad {
			box-sizing: border-box;
			float: left;
			width: 20rem;
		}
		.month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
			float: right;
		}
	}

	.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
		display: block;
		font-size: 1rem;
		text-align: center;
		line-height: 3.4rem;
		font-family: "Proxima Nova";
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
	}

	.month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
		font-weight: normal;
		margin-right: 0.5em;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
		font-style: normal;
		text-align: center;
		width: 3.4rem;
		height: 3.4rem;
		line-height: 3.4rem;
		position: absolute;
		top: 0;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
		left: 0;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
		right: 0;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad ul,
	.month-picker > .rmp-container .rmp-popup .rmp-pad li {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad ul {
		display: block;
		width: 100%;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
		content: " ";
		clear: both;
		display: table;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad li {
		display: block;
		float: left;
		text-align: center;
		font-size: 1.15rem;
		border-radius: 3px;
		/* line-height: 3.3rem; */
		box-sizing: border-box;
		padding: 0.05rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		*white-space: nowrap;
		overflow: hidden;
		-webkit-font-smoothing: antialiased;
		-webkit-text-stroke-width: 0.2px;
		-moz-osx-font-smoothing: grayscale;
		transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

		font-family: "Proxima Nova";
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.5rem;
	}
	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container .rmp-popup .rmp-pad li {
			width: 25%;
		}
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup .rmp-pad li {
			width: 33.3333333333%;
		}
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
		background-clip: content-box;
	}
	.month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
		border-radius: 1px;
	}
	.month-picker > .rmp-container .rmp-popup.light {
		color: #666;
		/* background-color: rgba(255, 255, 255, 0.96); */
		background-color: ${backgrounds.white};
	}
	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container .rmp-popup.light {
			border-top: 1px solid #ccc;
			box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
		}
		.month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
			border-top: 1px solid rgba(204, 204, 204, 0.5);
		}
		.month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
			border-top: 0;
		}
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup.light {
			border: 1px solid ${extended.charcoal.four};
			box-shadow: 0 1px 5px ${extended.charcoal.five};
		}
		.month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
			/* background-color: rgba(238, 238, 238, 0.9); */
		}
	}
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
		cursor: pointer;
		moz-user-select: -moz-none;
		-moz-user-select: none;
		-o-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
			background-color: ${extended.purple.six};
		}
	}
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
		/* background-color: #d3d3d3; */
		background-color: ${extended.purple.five};
	}
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active,
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
		/* background-color: rgba(31, 42, 58, 0.73); */
		background-color: ${primary.purple};
		color: white;
	}
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable,
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover,
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable,
	.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
		background-color: transparent;
		color: ${extended.charcoal.three};
		cursor: default;
	}
	.month-picker > .rmp-container .rmp-popup.dark {
		color: ${backgrounds.white};
		background-color: rgba(50, 50, 50, 0.96);
	}
	@media screen and (max-width: 767px) {
		.month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
			border-top: 1px solid rgba(113, 113, 113, 0.41);
		}
		.month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
			border-top: 0;
		}
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
			background-color: rgba(70, 70, 70, 0.9);
		}
	}
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
		cursor: pointer;
		moz-user-select: -moz-none;
		-moz-user-select: none;
		-o-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	@media screen and (min-width: 768px) {
		.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
			background-color: rgba(255, 210, 96, 0.33);
		}
	}
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
		background-color: #262828;
	}
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active,
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
		background-color: rgba(189, 211, 242, 0.7);
		color: #303030;
	}
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable,
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover,
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable,
	.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
		background-color: transparent;
		color: #717171;
		cursor: default;
	}
`

export const MonthBox = styled.div`
	background: ${backgrounds.white};
	width: 400px;
	padding: 5px 10px;
	margin-bottom: ${spacing.s5};

	font-family: "Proxima Nova";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
`
