import React from "react"
import {connect} from "react-redux"

// Actions
import {setDuration} from "actions/bookingsActions"
// UI
import {Row, Col} from "@uprise/grid"
// Components
import Radio from "components/Shared/Radio"
// helpers
import {isCoachingEnabled, isTherapyEnabled} from "helpers/utils"

const BookingsToggle = ({bookings, setDuration, userState}) => {
	return (
		<>
			{isTherapyEnabled(userState?.features) && isCoachingEnabled(userState?.features) && (
				<Row>
					<Col className='col-12 col-lg-12 col-md-12 col-sm-12 d-flex m-b-6'>
						<Radio
							checked={bookings.duration === 30}
							label={"Book a Coaching call"}
							value={30}
							onChange={() => setDuration(30)}
							name={"duration"}
							width={"250px"}
						/>
					</Col>
					<Col className='col-12 col-lg-12 col-md-12 col-sm-12 d-flex m-b-6'>
						<Radio
							checked={bookings.duration === 60}
							label={"Book a Counselling call"}
							value={60}
							onChange={() => setDuration(60)}
							name={"duration"}
							width={"250px"}
						/>
					</Col>
				</Row>
			)}
		</>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		bookings: state?.bookings
	}
}

const mapDispatchToProps = dispatch => ({
	setDuration: duration => dispatch(setDuration(duration))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingsToggle)
