import React from "react"
import {bool, func, string} from "prop-types"
import Styled from "styled-components"

// Colors
import {backgrounds, extended, primary} from "@uprise/colors"

const PanelWrap = Styled.div`
	position: relative;
	height: 100vh;
	background-color: ${backgrounds.fadedPurple};
	color: ${props => props.color};

	width: ${props => props.width};
	min-width: ${props => props.width};
	max-width: ${props => props.width};

	@media (max-width: 1200px) {
		position: relative;
		width: ${props => props.widthLg};
		min-width: ${props => props.widthLg};
		max-width: ${props => props.widthLg};
	}

	@media (max-width: 992px) {
		position: relative;
		width: ${props => props.widthMd};
		min-width: ${props => props.widthMd};
		max-width: ${props => props.widthMd};
	}

	@media (max-width: 768px) {
		position: relative;
		width: 100%;
		max-width: 100%;
	}
`
const PanelStyle = Styled.div`
	top: 0;
	position: fixed;
	box-shadow: 0 10px 10px 0 ${extended.purple.five};
	background-color: ${backgrounds.fadedPurple};

	width: ${props => props.width};
	min-width: ${props => props.width};
	max-width: ${props => props.width};

	@media (max-width: 1200px) {
		position: relative;
		width: ${props => props.widthLg};
		min-width: ${props => props.widthLg};
		max-width: ${props => props.widthLg};
	}

	@media (max-width: 992px) {
		position: relative;
		width: ${props => props.widthMd};
		min-width: ${props => props.widthMd};
		max-width: ${props => props.widthMd};
	}

	@media (max-width: 768px) {
		position: relative;
		width: 100%;
		max-width: 100%;
	}

	@media (max-width: 475px) {
		top: auto;
	}
`

const ScrollWrap = Styled.aside`
	margin-right: -17px;

	@media (max-width: 1200px) {
		margin-right: 0;
		width: ${props => props.widthLg};
		min-width: ${props => props.widthLg};
		max-width: ${props => props.widthLg};
	}

	@media (max-width: 992px) {
		margin-right: 0;
		width: ${props => props.widthMd};
		min-width: ${props => props.widthMd};
		max-width: ${props => props.widthMd};
	}
	
	@media (max-width: 768px) {
		margin-right: 0;
		max-width: 100%;
	}
`

const PanelScroll = Styled.div`
	overflow-y: scroll;
	// padding-right: 17px;

	@media (max-width: 1200px) {
		overflow: hidden;
	}

	@media (max-width: 992px) {
		overflow: hidden;
	}

	@media (max-width: 768px) {
		overflow: hidden;
	}
`

const Content = Styled.aside`
	height: 100vh
	min-height: 100vh

	@media (max-width: 1200px) {
		height: auto;
	}

	@media (max-width: 992px) {
		height: auto;
	}

	@media (max-width: 768px) {
		height: auto;
	}
`

export const RightPanel = ({
	children,
	className,
	width,
	widthXs,
	widthSm,
	widthMd,
	widthLg,
	textAlign,
	weight,
	color
}) => {
	return (
		<PanelWrap
			className={className}
			width={width}
			widthXs={widthXs}
			widthSm={widthSm}
			widthMd={widthMd}
			widthLg={widthLg}>
			<PanelStyle width={width} widthXs={widthXs} widthSm={widthSm} widthMd={widthMd} widthLg={widthLg}>
				<ScrollWrap width={width} widthXs={widthXs} widthSm={widthSm} widthMd={widthMd} widthLg={widthLg}>
					<PanelScroll>
						<Content className={className} weight={weight} textAlign={textAlign} color={color}>
							{children}
						</Content>
					</PanelScroll>
				</ScrollWrap>
			</PanelStyle>
		</PanelWrap>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
RightPanel.propTypes = {
	weight: string,
	color: string,
	width: string,
	textAlign: string
}

RightPanel.defaultProps = {
	weight: "normal",
	color: extended.charcoal.two,
	width: "310px",
	widthMd: "100%",
	textAlign: ""
}
