import Styled, {keyframes} from "styled-components"
import {fadeIn} from "react-animations"
const fadeInAnimation = keyframes`${fadeIn}`
// colors
import {extended, primary, secondary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Typography
import {text} from "@uprise/typography"

export const FullOptions = Styled.section`
      padding: 0;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;

	@media (max-width: 475px) {
            margin-top: ${spacing.s32};
		border-radius: 10px 10px 0 0;
		background-color: ${backgrounds.white};
		position: relative;
		width: 100%;
		height: auto;
		max-height: 90%;
		margin-bottom: -10px;
		padding-bottom: 20px;
            padding: ${spacing.s5};
            overflow: hidden;
 	}
`

export const OptionsWrap = Styled.div`
      display: flex;
      flex-direction: column;
      justify-content: flex-end; 

      @media (max-width: 475px) {
            background-color: ${backgrounds.white};
            overflow-y: scroll;
            margin-right: -15px;
            padding-right: 15px;
      }
`

export const Options = Styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
      width: auto;
      border-radius: 10px;
	padding: ${spacing.s5} 0 0 0;
	animation: 1s ${fadeInAnimation};

	@media (max-width: 475px) {
		margin-top: 0;
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		width: auto;
		padding: 0;
		border-radius: 10px;
		justify-content: flex-end;
		animation: 1s ${fadeInAnimation};
	}
`

export const OptionsFlat = Styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
      width: auto;
      border-radius: 10px;
	padding: ${spacing.s5} 0 0 0;
	animation: 1s ${fadeInAnimation};

	@media (max-width: 475px) {
		margin-top: 0;
		display: flex;
		flex-wrap: wrap;
		height: auto;
		width: auto;
		padding: 0;
		border-radius: 10px;
		justify-content: flex-end;
		animation: 1s ${fadeInAnimation};
	}
`

export const Send = Styled.button`
	min-height: 50px;
	min-width: 50px;
	margin: 0 20px;
	background-color: ${primary.purple};
	color: ${backgrounds.white};
	border: 0px;
	border-radius: 50%;
	align-self: flex-end;
	text-align: center;
	margin-bottom: 15px;
	
	@media (max-width: 475px) {
            min-height: 40px;
            min-width: 40px;
            margin-top: ${spacing.s5};
            position: relative;
            margin-bottom: 0;
		right: 0
	}
`

export const SendIcon = Styled.img`
	height: 22px;
	width: 22px;
	margin-left: 7px;
	margin-top: 3px;
	height: 20px;
      width: 22px;
      
      @media (max-width: 475px) {
            height: 17px;
            width: 17px;
	}
`

export const ShowOptions = Styled.button`
	width: auto;
	min-width: 100px;
	font-family: "Proxima Nova";
	background-color: transparent
	margin-right: 0;
	margin-bottom: ${spacing.s32};
	font-size: ${text.t7};
	border: 0px;
	padding: ${spacing.s5};
	border-radius: 10px;
	color:  ${primary.purple};
      border: 1px solid ${primary.purple};

	@media (max-width: 475px) {
		font-size: ${text.t5};
		background-color: ${props => (props.selected ? secondary.electricBlue : "transparent")};
		color: ${primary.purple};
		border-color: ${props => (props.selected ? secondary.electricBlue : primary.purple)};
		border-width: 1px;
		border-style: solid;
		padding: ${spacing.s4};
		margin-right: 0;
	}
`

export const OtherText = Styled.textarea`
      width: 400px;
      height: 100px;
      font-family: "Proxima Nova";
      border: 1px solid ${primary.purple};
      padding: ${spacing.s5};
	border-radius: 10px;
      align-self: flex-end;
      margin-bottom: ${spacing.s4};
      
	@media (max-width: 475px) {
		width: 100%;
	}
`

export const SliderInputWrap = Styled.section`
	width: 100%;
	
      @media (max-width: 475px) {
            border: 1px solid ${primary.purple};
            border-radius: 10px;
      }
`

export const SliderWrap = Styled.section`
      display: flex;
      flex-direction: row;
      width: auto;
      padding: ${spacing.s5};
      animation: 1s ${fadeInAnimation};

      @media (max-width: 475px) {
            flex-direction: column;
      }
`

export const MoreInfoWrap = Styled.div`
	display: flex;
      flex-direction: column;
      align-self: flex-end;

      @media (max-width: 475px) {
            align-self: center;
      }
      
`

export const MoreInfo = Styled.button`
	width: 400px;
	min-width: 100px;
	background-color: ${primary.purple};
	margin-right: 0;
	margin-bottom: ${spacing.s4};
	border: 0px;
	padding: ${spacing.s5};
	border-radius: 10px;
      color: ${backgrounds.white};
      font-size: ${text.t7};
      
      @media (max-width: 475px) {
            width: auto;
            min-width: 100px;
            margin-right: 0;
      }
`

export const EndSequence = Styled.button`
      width: 400px;
      min-width: 100px;
	background-color: ${primary.purple};
	margin-right: 0;
	margin-bottom: ${spacing.s4};
	border: 0px;
	padding: ${spacing.s5};
	border-radius: 10px;
      color: ${backgrounds.white};
      font-size: ${text.t7};
      
      @media (max-width: 475px) {
            width: auto;
            min-width: 100px;
            margin-right: 0;
      }
`
