import React, {useEffect, useState} from "react"
import {Layout} from "components/Shared/Layout"
import {backgrounds} from "@uprise/colors"
import styled from "styled-components"
import {Button} from "@uprise/button"
import {post} from "helpers/api"
import toast, {Toaster} from "react-hot-toast"
import qrcode from "qrcode"

const AllianzApproveRejectNZ = props => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [userData, setUserData] = useState("")
	const [secretCode, setSecretCode] = useState("")
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const Logo = styled.img`
		width: 200px;
	`
	const ButtonWrap = styled.div`
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 300px;
		align-self: center;
		margin-top: 20px;
	`

	const onAuthenticate = () => {
		try {
			const emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			if (!email || !password || !emailRegx.test(email)) {
				toast.error("Please enter valid email and password")
				return
			}
			setIsLoading(true)
			const data = {
				email,
				password
			}
			post("allianz-admin/verify", data)
				.then(response => {
					setIsLoading(false)
					console.log(response)
					if (response?.code === "success") {
						setIsAuthenticated(true)
						qrcode.toDataURL(response?.message?.otpauth_url, (err, data) => {
							setUserData({
								...response?.message,
								qrimage: data
							})
						})
					} else {
						toast.error("Invalid username or password")
						setIsAuthenticated(false)
						return
					}
				})
				.catch(error => {
					console.log(error)
					toast.error("Error while authenticating.")
					setIsLoading(false)
					setIsAuthenticated(false)
				})
		} catch (error) {
			console.log(error)
			toast.error("Error while authenticating.")
			setIsLoading(false)
			setIsAuthenticated(false)
		} finally {
			setIsLoading(false)
		}
	}
	const verfiyToken = () => {
		if (!secretCode || secretCode.length < 6) {
			toast.error("Please enter valid secret code")
			return
		}
		const data = {
			email,
			token: secretCode
		}
		post("allianz-admin/verifyToken", data)
			.then(response => {
				console.log(response)
				if (response?.code === "success") {
					sessionStorage.setItem("params", JSON.stringify({type: "NZ", email}))
					props.history.push("/approve-reject-list")
				} else {
					toast.error("Invalid secret code")
					return
				}
			})
			.catch(error => {
				console.log(error)
				toast.error("Error while verifying token.")
			})
	}

	useEffect(() => {
		const storageItems = sessionStorage.getItem("params")
		if (storageItems) {
			props.history.push("/approve-reject-list")
		}
	}, [])

	return (
		<Layout
			backgroundColor={backgrounds.white}
			rowClassName='flex-sm-column justify-content-center align-items-center h-100'>
			<Logo src={require("../assets/images/allianz/logo.png")} alt='allianz-logo' />
			{!userData && (
				<>
					<input
						type='email'
						placeholder='Email Address'
						value={email}
						onChange={e => setEmail(e.target.value)}
						style={{
							width: 300,
							marginTop: 20,
							marginBottom: 20,
							padding: 10,
							borderWidth: 0.7,
							borderColor: "#ccc",
							borderRadius: 5,
							height: 45
						}}
					/>
					<input
						style={{
							width: 300,
							marginBottom: 20,
							padding: 10,
							borderWidth: 0.7,
							borderColor: "#ccc",
							borderRadius: 5,
							height: 45
						}}
						type='password'
						placeholder='Password'
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
				</>
			)}
			{userData && userData.qrimage && (
				<>
					{!userData?.isVerified && <img src={userData?.qrimage} />}
					<input
						style={{
							width: 300,
							marginTop: 20,
							marginBottom: 20,
							padding: 10,
							borderWidth: 0.7,
							borderColor: "#ccc",
							borderRadius: 5,
							height: 45
						}}
						type='text'
						placeholder='Secret Code'
						value={secretCode}
						onChange={e => setSecretCode(e.target.value)}
					/>
				</>
			)}

			<ButtonWrap>
				<Button
					variant='primary'
					className='m-b-2'
					data-testid='sign-in-button'
					title={!isAuthenticated ? "Sign in" : "Submit"}
					size='large'
					fullWidth={true}
					isLoading={isLoading}
					disabled={false}
					onClick={!isAuthenticated ? onAuthenticate : verfiyToken}
				/>
			</ButtonWrap>

			<Toaster />
		</Layout>
	)
}

export default AllianzApproveRejectNZ
