import React, {useState, useEffect, useRef} from "react"
import {connect} from "react-redux"
import {get} from "helpers/api"
import Styled from "styled-components"

// Components
import {Layout} from "components/Shared/Layout"
import {ContainerFluid, Row} from "@uprise/grid"
import {Main} from "components/Shared/Panels"

// Colors
import {extended, backgrounds} from "@uprise/colors"

const Wrap = Styled.section`	background-color: ${extended.purple.six}; 
        width: 100%;
`

const ConfirmEmail = ({match}) => {
	const [message, setMessage] = useState("")

	const linkRef = useRef()

	useEffect(() => {
		// <domain>/user/delete/<token>/<email>
		const path = match.path.split("/")[2]
		let contentType

		if (path === "delete") {
			contentType = null
			setMessage("Deleting account and logging out... ")
		} else {
			contentType = "text/html"
		}

		get(`settings/user/${path}/data/${match.params.token}/${match.params.email}`, contentType)
			.then(resp => {
				if (resp.error) {
					setMessage(resp.error)
				}
				if (resp.redirect) {
					setTimeout(() => {
						window.location.replace("/sign-in")
					}, 2000)
				} else {
					const href = window.URL.createObjectURL(resp)
					const node = linkRef.current
					const link = document.createElement("a")
					const t = document.createTextNode("Download my data") // Create a text node
					link.appendChild(t)
					link.target = "_blank"
					link.download = "user-data.html"
					link.href = href

					node.appendChild(link)
					link.click()
				}
			})
			.catch(err => {
				console.log(err)
			})
	}, [])

	return (
		<Layout>
			<Main>
				<span ref={linkRef} />
				{message}
			</Main>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		UserState: state.UserState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(ConfirmEmail)
