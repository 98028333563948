import React from "react"
import {string} from "prop-types"
import Styled from "styled-components"

// Colors
import {extended} from "@uprise/colors"

const Content = Styled.section`
	width: 100%;
	padding: 35px 25px;
 	background-color: ${extended.purple.six};
	height: 100%;

	@media (max-width: 475px) {
		padding: ${props => props.padding};
	}
`

const MainStyles = Styled.section`
	width: 100%;
	background-color: ${extended.purple.six};
	height: ${props => (props.containerHeight ? props.containerHeight : `calc(100% - 100px)`)};
`
// UI
import {Row, Col} from "@uprise/grid"

export const Main = ({children, padding, containerHeight}) => {
	return (
		<MainStyles containerHeight={containerHeight}>
			<Content padding={padding}>
				<Row className='h-100'>
					<Col className='col-md-12 h-100'>{children}</Col>
				</Row>
			</Content>
		</MainStyles>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Main.propTypes = {
	weight: string,
	color: string,
	width: string,
	textAlign: string,
	padding: string,
	containerHeight: string
}

Main.defaultProps = {
	weight: "normal",
	color: extended.charcoal.two,
	width: "310px",
	textAlign: "",
	padding: "50px 25px 70px 25px"
}
