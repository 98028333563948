import React from "react"
import {object, string, array} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"

// UI
import {H1, H2, H3, H4, H6} from "@uprise/headings"
import {Card} from "@uprise/card"
import {Medium} from "@uprise/text"
import {TaskButton} from "@uprise/button"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// icons
import Icons from "assets/images/icons/svg"
// Spacing
import {spacing} from "@uprise/spacing"

const LessonsStyle = Styled.section`
	padding: 0 ${spacing.s10} 0 ${spacing.s10};
	width: 100%;
	
	@media (max-width: 1024px) {
		padding: ${spacing.s8} ${spacing.s8} 0 ${spacing.s8};
	}
`

const Lessons = ({className, lessons, activity, skillId, task}) => {
	const history = useHistory()
	let location = useLocation()

	// Icons
	const completedIcon = Icons.taskCompleted
	const videoIcon = Icons.overview
	const audioIcon = Icons.audio
	const exerciseIcon = Icons.exercise

	return (
		<LessonsStyle className={className}>
			<H6 className='m-t-0 m-b-3'>Lessons</H6>
			<Card backgroundColor={backgrounds.white} width=' '>
				{Object.values(lessons).map((lessonTask, index) => {
					const active = lessonTask.taskId === task?.taskId ? true : false

					if (lessonTask.type === "overview-video" || lessonTask.type === "video") {
						return (
							<TaskButton
								last={index === lessons.length - 1 ? true : false}
								active={active}
								key={index}
								type='lessons'
								index={index}
								activity={activity}
								task={lessonTask}
								skillId={skillId}
								iconLeft={videoIcon}
								completedIcon={completedIcon}
								handleClick={() =>
									history.push(`/skill/${skillId}/video/${lessonTask.taskId}`, {
										from: location.pathname
									})
								}
							/>
						)
					}

					if (lessonTask.type === "survey") {
						return (
							<TaskButton
								last={index === lessons.length - 1 ? true : false}
								active={active}
								key={index}
								type='lessons'
								index={index}
								activity={activity}
								task={lessonTask}
								skillId={skillId}
								iconLeft={exerciseIcon}
								completedIcon={completedIcon}
								handleClick={() =>
									history.push(`/skill/${skillId}/bot/${lessonTask.taskId}`, {
										from: location.pathname
									})
								}
							/>
						)
					}

					if (lessonTask.type === "audio-file") {
						return (
							<TaskButton
								last={index === lessons.length - 1 ? true : false}
								active={active}
								key={index}
								type='lessons'
								index={index}
								activity={activity}
								task={lessonTask}
								skillId={skillId}
								iconLeft={audioIcon}
								completedIcon={completedIcon}
								handleClick={() =>
									history.push(`/skill/${skillId}/audio/${lessonTask.taskId}`, {
										from: location.pathname
									})
								}
							/>
						)
					}
				})}
			</Card>
		</LessonsStyle>
	)
}

function mapStateToProps(state) {
	const {task} = state
	return {task}
}

export default connect(mapStateToProps, null)(Lessons)

Lessons.propTypes = {
	lessons: array.isRequired,
	activity: object.isRequired
}
