import React from "react"
import {array, object, string} from "prop-types"
import {TaskButton} from "@uprise/button"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {backgrounds} from "@uprise/colors"
// Spacing
import {spacing} from "@uprise/spacing"
// UI
import {Card} from "@uprise/card"
import {H6} from "@uprise/headings"
// icons
import Icons from "assets/images/icons/svg"

const HomeworkStyle = Styled.section`
	padding: 0 ${spacing.s10} 0 ${spacing.s10};
	width: 100%;
	
	@media (max-width: 1024px) {
		padding: ${spacing.s8} ${spacing.s8} 0 ${spacing.s8};
	}
`

const Homework = ({className, homework, skillId, activity, task}) => {
	const history = useHistory()
	let location = useLocation()

	// Icons
	const completedIcon = Icons.taskCompleted
	const audioIcon = Icons.audio
	const exerciseIcon = Icons.exercise
	const videoIcon = Icons.overview

	return (
		<HomeworkStyle className={className}>
			{homework.length > 0 && <H6 className='m-t-0 m-b-3'>Homework</H6>}
			<Card backgroundColor={backgrounds.white} width=' '>
				{homework.map((homeworkTask, index) => {
					const active = homeworkTask.taskId === task?.taskId ? true : false

					let iconLeft, handleClick

					switch (homeworkTask.type) {
						case "audio-file":
							iconLeft = audioIcon
							handleClick = () =>
								history.push(`/skill/${skillId}/audio/${homeworkTask.taskId}`, {
									from: location.pathname
								})
							break

						case "video":
						case "overview-video":
							iconLeft = videoIcon
							handleClick = () =>
								history.push(`/skill/${skillId}/video/${homeworkTask.taskId}`, {
									from: location.pathname
								})
							break
						case "survey":
							iconLeft = exerciseIcon
							handleClick = () =>
								history.push(`/skill/${skillId}/bot/${homeworkTask.taskId}`, {from: location.pathname})
							break
						case "doc":
							iconLeft = exerciseIcon
							handleClick = () =>
								history.push(`/skill/${skillId}/doc/${homeworkTask.taskId}`, {
									from: location.pathname
								})
							break
						case "tips":
							iconLeft = exerciseIcon
							handleClick = () =>
								history.push(`/skill/${skillId}/tips/${homeworkTask.taskId}`, {from: location.pathname})
							break
					}

					return (
						<TaskButton
							last={index === homework.length - 1 ? true : false}
							type='homework'
							active={active}
							key={index}
							index={index}
							activity={activity}
							task={homeworkTask}
							skillId={skillId}
							iconLeft={iconLeft}
							completedIcon={completedIcon}
							handleClick={() => handleClick()}
						/>
					)
				})}
			</Card>
		</HomeworkStyle>
	)
}

function mapStateToProps(state) {
	const {task} = state
	return {task}
}

export default connect(mapStateToProps, null)(Homework)

Homework.propTypes = {
	homework: array.isRequired,
	skillId: string.isRequired,
	activity: object.isRequired
}
