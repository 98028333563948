import React from "react"
import styled from "styled-components"
import {backgrounds, extended, primary} from "@uprise/colors"
import {H4} from "@uprise/headings"
import {spacing} from "@uprise/spacing"

const Nav = styled.nav`
	/* border: 1px solid yellow; */
	display: flex;
	justify-content: space-between;
	background: "transparent";
	border-radius: 10px;
`
const NavItem = styled(H4)`
	display: block;
	color: ${props => (props.active ? primary.charcoal : extended.charcoal.one)};
	padding: ${spacing.s6} ${spacing.s8} ${spacing.s8};
	background: ${props => (props.active ? extended.purple.five : backgrounds.fadedPurple)};

	&:first-child {
		border-top-left-radius: 10px;
	}
	&:last-child {
		border-top-right-radius: 10px;
	}

	user-select: none;
	cursor: pointer;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&:hover {
		background: ${extended.purple.five} !important;
	}
`
const TabContainer = styled.div`
	/* border: 1px solid red; */
	box-shadow: 0 4px 10px 0 ${extended.charcoal.five};
	border-radius: 10px;
`
const TabContent = styled.div`
	/* border: 1px solid aqua; */
	background: white;
	min-height: 300px;
	border-radius: 10px;
	padding: 30px;
	margin-top: -15px;
	transform: translateZ(0);
`
const TabPane = styled.div`
	/* border: 1px solid greenyellow; */
`

function map(children, func) {
	let index = 0

	return React.Children.map(children, child => (React.isValidElement(child) ? func(child, index++) : child))
}

function Tabs({id, initialActiveKey, children}) {
	const [activeKey, setActiveKey] = React.useState(initialActiveKey)

	const onSelect = eventKey => {
		if (typeof eventKey === "string") setActiveKey(eventKey)
	}

	const _renderTab = child => {
		const {title, eventKey, disabled, tabClassName, id} = child.props
		if (title === null) {
			return null
		}

		return (
			<NavItem
				active={Boolean(eventKey === activeKey)}
				onClick={() => onSelect(eventKey)}
				disabled={disabled}
				id={id}
				className={tabClassName}>
				{title}
			</NavItem>
		)
	}

	return (
		<TabContainer id={id} activeKey={activeKey} onSelect={onSelect}>
			<Nav role='tablist' as='nav'>
				{map(children, _renderTab)}
			</Nav>

			<TabContent>
				{map(children, child => {
					const childProps = {...child.props}

					if (childProps.eventKey !== activeKey) return

					delete childProps.title
					delete childProps.disabled

					return <TabPane {...childProps} />
				})}
			</TabContent>
		</TabContainer>
	)
}

export const Tab = () => {
	throw new Error(`Never render this`)
}

export default Tabs
