import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"

// Components
import Bot from "components/Shared/Chat/Bot"
// Utils
import {retrieveResults} from "uprise-surveys/lib/surveys/getScore"
import {getSurveyIndex} from "helpers/utils"
// Actions
import {saveSurvey} from "actions/surveyActions"
import {updateUserState} from "actions/userStateActions"

import {
	onboardingCorporate,
	onboardingStudents,
	wellbeingCheck,
	wellbeingStressWeekly,
	wellbeingStressMonthly,
	wellbeingCheckRuokday,
	stressCheck,
	retrainingThinking,
	retrainingThinkingBeliefs,
	retrainingThinkingMetacognition,
	retrainingThinkingStress,
	retrainingThinkingRevision,
	retrainingThinkingSleepPredictions,
	retrainingThinkingSleepThoughts,
	retrainingThinkingFocus,
	focusQuestion,
	values,
	progress,
	perspectiveTaking,
	guilt,
	sleepChecklist,
	// health anxiety
	coronaAnxietyOdds,
	coronaAnxietyPositives,
	coronaToleratingUncertainty,
	coronaBeliefsAboutHealthAnxietyChecking,
	// alcohol
	motivationalInterviewingAddictions,
	testForHazardousDrinking,
	testForGambling,
	testForDrugUse,
	selfCantChangePersonCan,
	willingnessTrustLettingGoAddictedSelf,
	personalFormulaForHappiness,
	// RTW
	problemDefinition,
	problemSolution,
	experimentBefore,
	experimentAfter,
	// Manager Training
	leaderTeamWellbeingAssesment,
	buildingEmpathy,
	// Stress
	stressTriggers,
	introductionWorryTime,
	worryTimeExercise,
	//allianz
	onboardingAllianz,
	wellbeingStressWeeklyAllianz,
	wellbeingStressMonthlyAllianz
} from "chatBot/dialogue"

import {isWhiteLabeledApp} from "helpers/utils"

const BotLoaderStyles = Styled.section`

`

const BotLoader = ({userState, skillId, handleNext, completeDialog, dialogueId, updateUserState}) => {
	let history = useHistory()
	const [dialogueFile, setDialogueFile] = useState([])

	if (!handleNext) {
		handleNext = () => {
			if (history?.location?.state?.from) {
				history.push(history?.location?.state?.from)
			} else {
				history.push("/dashboard")
			}
		}
	}

	if (!completeDialog) {
		completeDialog = async (recurring, answers, surveyId, taskId, next) => {
			let surveyIndex = getSurveyIndex(taskId, userState.surveyResults)

			try {
				const resp = await saveSurvey(recurring, answers, surveyId, surveyIndex, taskId)

				if (resp.status === "success") {
					updateUserState(resp.user_state)

					const result = resp.user_state.surveyResults.filter(result => {
						// If survey index is null then it becomes 0 in saveSurvey
						const newSurveyIndex = surveyIndex === null ? 0 : parseInt(surveyIndex) + 1
						return parseInt(result.id) === parseInt(newSurveyIndex) && result.taskId === taskId
					})[0]

					setTimeout(() => {
						next(result)
					}, 3000)
				}
			} catch (err) {
				console.log(err)
			}
		}
	}

	useEffect(() => {
		let currentDialogueFile

		switch (dialogueId) {
			case "onboarding-corporate":
				currentDialogueFile = isWhiteLabeledApp() ? onboardingAllianz : onboardingCorporate
				break
			case "onboarding-students":
				currentDialogueFile = onboardingStudents
				break
			case "ruokday":
				currentDialogueFile = wellbeingCheckRuokday
				handleNext = result => {
					const results = retrieveResults(result)

					history.push({
						pathname: "/bot/wellbeingCheck/score",
						state: results["who5"]
					})
				}
				break
			case "wellbeingStressWeekly":
				currentDialogueFile = isWhiteLabeledApp() ? wellbeingStressWeeklyAllianz : wellbeingStressWeekly
				handleNext = result => {
					const results = retrieveResults(result)

					history.push({
						pathname: "/bot/wellbeingStressWeekly/score",
						state: results["who5"]
					})
				}
				break
			case "wellbeingStressMonthly":
				currentDialogueFile = isWhiteLabeledApp() ? wellbeingStressMonthlyAllianz : wellbeingStressMonthly
				handleNext = result => {
					const results = retrieveResults(result)

					history.push({
						pathname: "/bot/wellbeingStressMonthly/score",
						state: results["who5"]
					})
				}
				break
			case "wellbeingCheck":
				currentDialogueFile = wellbeingCheck
				handleNext = result => {
					const results = retrieveResults(result)

					history.push({
						pathname: "/bot/wellbeingCheck/score",
						state: results["who5"]
					})
				}
				break
			case "stressCheck":
				currentDialogueFile = stressCheck
				handleNext = result => {
					const results = retrieveResults(result)

					history.push({
						pathname: "/bot/stressCheck/score",
						state: results["stress"]
					})
				}

				break
			case "exercise-retraining-thinking":
				currentDialogueFile = retrainingThinking
				break
			case "exercise-retraining-thinking-revision":
				currentDialogueFile = retrainingThinkingRevision
				break
			case "exercise-retraining-thinking-stress":
				currentDialogueFile = retrainingThinkingStress
				break
			case "exercise-retraining-thinking-metacognition":
				currentDialogueFile = retrainingThinkingMetacognition

				break
			case "exercise-retraining-thinking-beliefs":
				currentDialogueFile = retrainingThinkingBeliefs
				break
			case "exercise-retraining-thinking-sleep-predictions":
				currentDialogueFile = retrainingThinkingSleepPredictions
				break
			case "exercise-retraining-thinking-sleep-thoughts":
				currentDialogueFile = retrainingThinkingSleepThoughts
				break
			case "exercise-retraining-thinking-focus":
				currentDialogueFile = retrainingThinkingFocus
				break
			case "exercise-sleep-checklist":
				currentDialogueFile = sleepChecklist
				break
			case "exercise-values":
				currentDialogueFile = values
				break
			case "survey-progress":
				currentDialogueFile = progress
				break
			case "exercise-perspective-taking":
				currentDialogueFile = perspectiveTaking
				break
			case "exercise-guilt":
				currentDialogueFile = guilt
				break
			case "exercise-corona-anxiety-odds":
				currentDialogueFile = coronaAnxietyOdds
				break
			case "exercise-corona-anxiety-positives":
				currentDialogueFile = coronaAnxietyPositives
				break
			case "exercise-corona-tolerating-uncertainty":
				currentDialogueFile = coronaToleratingUncertainty
				break
			case "exercise-corona-beliefs-about-health-anxiety-checking":
				currentDialogueFile = coronaBeliefsAboutHealthAnxietyChecking
				break
			// RTW
			case "exercise-problem-definition":
				currentDialogueFile = problemDefinition
				break
			case "exercise-problem-solution":
				currentDialogueFile = problemSolution
				break
			case "exercise-before-experiment":
				currentDialogueFile = experimentBefore
				break
			case "exercise-after-experiment":
				currentDialogueFile = experimentAfter
				break
			case "exercise-focus-question":
				currentDialogueFile = focusQuestion
				break
			// Alcohol
			case "exercise-motivational-interviewing-addictions":
				currentDialogueFile = motivationalInterviewingAddictions
				break
			case "exercise-self-test-hazardous-drinking":
				currentDialogueFile = testForHazardousDrinking
				break
			case "exercise-self-test-gambling":
				currentDialogueFile = testForGambling
				break
			case "exercise-self-test-drug-use":
				currentDialogueFile = testForDrugUse
				break
			case "exercise-self-cant-change-person-can":
				currentDialogueFile = selfCantChangePersonCan
				break
			case "exercise-willingness-trust-letting-go-addicted-self":
				currentDialogueFile = willingnessTrustLettingGoAddictedSelf
				break
			case "exercise-personal-formula-happiness":
				currentDialogueFile = personalFormulaForHappiness
				break
			// Manager Training
			case "exercise-leader-team-wellbeing-assesment":
				currentDialogueFile = leaderTeamWellbeingAssesment
				break
			case "exercise-building-empathy":
				currentDialogueFile = buildingEmpathy
				break
			// Stress
			case "exercise-stress-triggers":
				currentDialogueFile = stressTriggers
				break
			case "exercise-introduction-worry-time":
				currentDialogueFile = introductionWorryTime
				break
			case "exercise-worry-time-exercise":
				currentDialogueFile = worryTimeExercise
				break
		}

		setDialogueFile(currentDialogueFile)
	}, [dialogueId])

	if (dialogueFile.length > 0) {
		return (
			<BotLoaderStyles className='mt-0 h-100'>
				<Bot
					dialogueFile={dialogueFile}
					dialogueId={dialogueId}
					skillId={skillId}
					name={userState?.name?.split(" ")[0]}
					handleNext={score => handleNext(score)}
					completeDialog={(recurring, answers, surveyId, taskId, next) =>
						completeDialog(recurring, answers, surveyId, taskId, next)
					}
					activity={userState.activity}
				/>
			</BotLoaderStyles>
		)
	} else {
		return null
	}
}

const mapDispatchToProps = dispatch => ({
	updateUserState: state => dispatch(updateUserState(state))
})

function mapStateToProps(state) {
	return {
		userState: state.userState,
		activity: state.userState.activity
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BotLoader)
