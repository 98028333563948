import React, {Fragment, useState, useEffect} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
// Actions
import {assignCoach, getCoachByEmail} from "actions/coachActions"
// Content
import PostProgramOverview from "components/Rtw/PostProgram/PostProgramOverview"
import PostProgramAssessment from "components/Rtw/PostProgram/PostProgramAssesment"
import PostProgramBooking from "components/Rtw/PostProgram/PostProgramBooking"

function checkProgress(surveyResults, isThreeMonthSurvey) {
	let screener = "PHQ9"
	if (surveyResults) {
		for (const result of surveyResults) {
			if (
				isThreeMonthSurvey
					? result.taskId === "survey-postprogram-3-month-rtw"
					: result.taskId === "survey-postprogram-rtw"
			) {
				if (!result.completed) {
					const keys = Object.keys(result.answers)
					screener = keys[keys.length - 1]
				}
			}
		}
	}
	return screener
}

function PostProgramRTWSteps({
	progressCheckpoints,
	handleNext,
	handleComplete,
	userState,
	activeIndex,
	getCoachByEmail,
	coach,
	assignCoach,
	isThreeMonthSurvey
}) {
	const [activeScreener, setActiveScreener] = useState(checkProgress(userState.surveyResults, isThreeMonthSurvey))

	// Fetch RTW coach
	useEffect(() => {
		if (userState.coach === undefined) {
			assignCoach(userState.timezone)
		}

		if (userState.coach && userState.coach !== coach.email) {
			getCoachByEmail(userState.coach)
		}
	}, [userState.coach])

	useEffect(() => {
		progressCheckpoints.forEach((checkpoint, index) => {
			if (index === activeIndex && index > 0 && index < progressCheckpoints.length - 1) {
				setActiveScreener(checkpoint.id)
			}
		})
	}, [activeIndex, progressCheckpoints])

	const introCheckpoint = progressCheckpoints[0]
	const bookingCheckpoint = progressCheckpoints[progressCheckpoints.length - 1]

	const isSurveyActive = activeIndex > 0 && activeIndex < progressCheckpoints.length - 1
	const nextCheckpoint = progressCheckpoints.findIndex(c => c.id === activeScreener)

	return (
		<Fragment>
			{introCheckpoint && introCheckpoint["active"] && (
				<PostProgramOverview handleNext={() => handleNext(nextCheckpoint)} />
			)}

			{isSurveyActive ? (
				<PostProgramAssessment
					userState={userState}
					handleChange={delta => handleNext(activeIndex + delta)}
					activeScreener={activeScreener}
					isThreeMonthSurvey={isThreeMonthSurvey}
				/>
			) : null}

			{bookingCheckpoint && bookingCheckpoint["active"] && (
				<PostProgramBooking
					selectedCoach={coach}
					dialogueId='onboarding'
					surveyResults={userState.surveyResults}
					handleNext={handleComplete}
				/>
			)}
		</Fragment>
	)
}

PostProgramRTWSteps.propTypes = {
	activeIndex: PropTypes.number.isRequired,
	handleComplete: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	progressCheckpoints: PropTypes.array.isRequired,
	userState: PropTypes.object.isRequired,
	isThreeMonthSurvey: PropTypes.bool.isRequired
}

export default connect(state => ({coach: state.coach}), {assignCoach, getCoachByEmail})(PostProgramRTWSteps)
