import React, {useRef, useCallback, useState, useEffect} from "react"
import {connect} from "react-redux"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
import lottie from "lottie-web"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
// Icons
import animationData from "assets/images/lottie/star-lottie.json"
// Utils
import {getFirstName} from "helpers/utils"

const Media = Styled.img`
	width: 233px;
	height: 171px;
`

const MediaContainer = Styled.div`
	width: 198px;
	height: 198px;
	margin-left: auto;
	margin-right: auto;
`

const COACHING_MODAL_LAST_SEEN = "coachingModalLastSeen"

const CoachingScenarioModal = ({isOpen, handleClose, userState, coach, bookings, skillName, ...props}) => {
	const [isLastSeenToday, setIsLastSeenToday] = useState(false)
	const history = useHistory()

	// Load animation
	const mediaContainerRef = useCallback(
		node => {
			if (node !== null && isOpen) {
				const animation = lottie.loadAnimation({
					container: node, // the dom element that will contain the animation
					renderer: "svg",
					loop: false,
					autoplay: true,
					animationData: animationData // animation json
				})
				animation.setSpeed(1.25)
			}
		},
		[isOpen]
	)

	// Handle last seen
	useEffect(() => {
		let previousLastSeen = localStorage.getItem(COACHING_MODAL_LAST_SEEN)

		if (previousLastSeen && moment().diff(moment(previousLastSeen), "hours") < 24) {
			setIsLastSeenToday(true)
		} else {
			setIsLastSeenToday(false)
		}
	}, [isOpen])

	const bookingState = {
		hasCoach: userState.coach,
		hasNeverDoneBooking: !bookings.loading && bookings?.data?.length === 0
	}

	const userJustLoggedIn = !skillName
	const needToShowPopup = !bookingState.hasCoach || bookingState.hasNeverDoneBooking

	let data = {
		heading: userJustLoggedIn ? `Get a coach, it really helps!` : `Nice going ${getFirstName(userState.name)}!`,
		imageURL: require("assets/images/art/mental-health-awareness-training.svg"),
		subHeading: userJustLoggedIn ? `Hey ${getFirstName(userState.name)}!` : `You have completed ${skillName}!`,
		body: userJustLoggedIn
			? `We noticed you haven’t picked a coach yet. Our studies show users with a coach get 4x better results from Uprise.
		Our coaches help you apply Uprise skills to your life problems.`
			: `You can learn more about how to apply this skill to your life with an Uprise coach. Did we mention that
		coaches help you get 4x better results from Uprise?`,
		primaryButtonLabel: `Get a coach`,
		primaryButtonLink: `/select-coach`,
		secondaryButtonLabel: `Learn more`,
		secondaryButtonLink: `/booking`
	}

	if (bookingState.hasCoach && bookingState.hasNeverDoneBooking) {
		data.heading = userJustLoggedIn ? `Book your first coaching call` : `Nice going ${getFirstName(userState.name)}`
		data.imageURL = require("assets/images/art/coaching-for-a-specific-issue.svg")
		data.body = userJustLoggedIn
			? `We noticed you haven’t booked your first coaching call yet. Coaching calls help you handle stress, improve performance and build self-awareness. It takes only 2 minutes to book one and our users rate 9.2/10. Go ahead and book now.`
			: `This is the right time to book a coaching call with ${getFirstName(
					coach.name
			  )} to discuss how you can apply this skill to solve your life problems.`
		data.primaryButtonLabel = `Book coaching call`
		data.primaryButtonLink = `/make-booking`
	}

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={needToShowPopup && isOpen && !isLastSeenToday}
			handleClose={() => {
				// Save last seen
				localStorage.setItem(COACHING_MODAL_LAST_SEEN, new Date().toISOString())
				handleClose()
			}}>
			<H3 className='m-b-6'>{data.heading}</H3>
			{userJustLoggedIn ? (
				<Media className='m-b-6' src={data.imageURL} alt='' />
			) : (
				<MediaContainer ref={mediaContainerRef} className='m-b-6' />
			)}
			<H4 className='m-b-3'>{data.subHeading}</H4>
			<Medium className='m-b-6'>{data.body}</Medium>
			<Button
				className='m-b-2'
				variant='primary'
				size='medium'
				title={data.primaryButtonLabel}
				onClick={() => {
					// Save last seen
					localStorage.setItem(COACHING_MODAL_LAST_SEEN, new Date().toISOString())
					history.push(data.primaryButtonLink, {from: location.pathname})
				}}
			/>
			<Button
				variant='secondary'
				size='medium'
				title={data.secondaryButtonLabel}
				onClick={() => {
					// Save last seen
					localStorage.setItem(COACHING_MODAL_LAST_SEEN, new Date().toISOString())
					history.push(data.secondaryButtonLink, {from: location.pathname})
				}}
			/>
		</Modal>
	)
}

const ConnectedCoachingScenarioModal = connect(
	state => ({userState: state.userState, bookings: state.bookings, coach: state.coach}),
	null
)(CoachingScenarioModal)

export default ConnectedCoachingScenarioModal
