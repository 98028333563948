import React, {Fragment} from "react"
import {Modal} from "@uprise/modal"

import {spacing} from "@uprise/spacing"
import {backgrounds} from "@uprise/colors"

const ToggleModal = ({toggle, content, width = "70%", textAlign = "left"}) => {
	const [isShown, setIsShown] = React.useState(false)
	const hide = () => {
		setIsShown(false)
	}
	const show = () => {
		setIsShown(true)
	}

	return (
		<Fragment>
			{toggle(show)}
			{isShown && (
				<Modal
					padding={spacing.s6}
					backgroundColor={backgrounds.white}
					width={width}
					textAlign={textAlign}
					isOpen={isShown}
					handleClose={hide}>
					{content(hide)}
				</Modal>
			)}
		</Fragment>
	)
}

export default ToggleModal
