import React from "react"
import Styled from "styled-components"
// UI
import {P} from "@uprise/text"
// Images
const workableLandingArt = require("assets/images/art/workable-hero-art.svg")
const workableLogo = require("assets/images/logos/workable.svg")

const Art = Styled.img`
	width: 480px;
	height: 376px;
	margin-bottom: 155px;

	@media (max-width: 1024px) {
		width: 350px;
	}
`

const WorkableLogoImage = Styled.img`
	width: 50%;
`

const RightContentRTW = () => {
	return (
		<div>
			<WorkableLogoImage className='m-b-5' src={workableLogo} alt='workable logo' />
			<P width='405px' mediumWidth='305px' className='m-b-10'>
				Workable is an evidence based, digital mental resilience program to assist injured workers return to
				work earlier.
			</P>
			<Art src={workableLandingArt} alt='' />
		</div>
	)
}

export default RightContentRTW
