// Types
import {STORE_SKILL} from "./types"
// Utils
import {getSkill} from "helpers/utils"

export const setSkill = (userState, skillId) => {
	return dispatch => {
		const skill = getSkill(userState, skillId)
		dispatch(storeSkill(skill))
	}
}

export const storeSkill = skill => {
	return {
		type: STORE_SKILL,
		skill: skill
	}
}
