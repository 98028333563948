import React from "react"
import {func, number, object, string} from "prop-types"
import moment from "moment-timezone"

const baseClass = "uprise-card bookingcalls__btn"
export const isCancellable = start => moment().isBefore(start.clone().subtract(24, "hours"), "minute")

const wellbeingPacks = ["coaching-perspectivetaking", "coaching-retraining2", "coaching-sleep", "coaching-addition"]

const BookingButton = ({booking, taskId, skillName, index, onCreate, onCancel, isDisabled, course}) => {
	const canItBeCancelled = booking && isCancellable(booking.start)
	const action = booking === undefined ? () => onCreate(taskId) : canItBeCancelled ? () => onCancel(taskId) : () => {}
	const className = baseClass + (booking ? " has-booking" : "") + (canItBeCancelled ? " is-editable" : "")

	const text =
		booking === undefined
			? `Click to book`
			: `${booking.coachName} - ${moment(booking.start).format("ddd Do MMM. h:mma")}`

	const disabled = (!canItBeCancelled && booking !== undefined) || isDisabled

	return (
		<button className={className} onClick={action} disabled={disabled}>
			<span>
				<span>
					Week {index + 1}
					<br />
					{skillName}
				</span>

				<br />
				<br />
				{text}
			</span>
		</button>
	)
}

BookingButton.propTypes = {
	booking: object,
	taskId: string.isRequired,
	skillName: string.isRequired,
	index: number.isRequired,
	onCancel: func.isRequired,
	onCreate: func.isRequired
}

export default BookingButton
