import {
	LOADING_WELLBEING_GRAPH_DATA,
	STORE_WELLBEING_GRAPH_DATA,
	LOADING_POPULAR_SKILLS,
	STORE_POPULAR_SKILLS,
	LOADING_POPULAR_COACHES,
	STORE_POPULAR_COACHES,
	LOADING_RECENT_BLOG_POSTS,
	STORE_RECENT_BLOG_POSTS,
	LOADING_WELLBEING_LEVEL,
	STORE_WELLBEING_LEVEL,
	LOADING_WELLBEING_LEVEL_FOR_MONTH,
	STORE_WELLBEING_LEVEL_FOR_MONTH,
	LOADING_WELLBEING_LEVEL_FOR_ENGAGED,
	STORE_WELLBEING_LEVEL_FOR_ENGAGED,
	LOADING_STRESS_GRAPH_DATA,
	STORE_STRESS_GRAPH_DATA,
	LOADING_STRESS_LEVEL,
	STORE_STRESS_LEVEL,
	LOADING_STRESS_LEVEL_FOR_MONTH,
	STORE_STRESS_LEVEL_FOR_MONTH,
	LOADING_STRESS_LEVEL_FOR_ENGAGED,
	STORE_STRESS_LEVEL_FOR_ENGAGED,
	LOADING_DATA_UTILIZATION_STATS,
	STORE_DATA_UTILIZATION_STATS,
	LOADING_DATA_UTILIZATION,
	STORE_DATA_UTILIZATION,
	LOADING_HR_FEEDBACK_STATS,
	STORE_HR_FEEDBACK_STATS,
	LOADING_HR_RISK_STATS,
	STORE_HR_RISK_STATS,
	LOADING_HR_ROI_STATS,
	STORE_HR_ROI_STATS
} from "./types"
import {get, post} from "helpers/api"

const fetcherAndLoader = ({url, actions: [loading, store]}) => {
	return async dispatch => {
		try {
			dispatch({type: loading, payload: {loading: true}})
			const resp = await get(url)
			dispatch({type: store, payload: {data: resp.data}})
			dispatch({type: loading, payload: {loading: false}})
		} catch (error) {
			console.log({error})
			dispatch({type: loading, payload: {loading: false}})
		}
	}
}

export const fetchSixMonthWellbeingGraphData = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/six-month-wellbeing-chart/${employerCode}`,
		actions: [LOADING_WELLBEING_GRAPH_DATA, STORE_WELLBEING_GRAPH_DATA]
	})
}

export const fetchPopularSkills = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/popular-skills/${employerCode}`,
		actions: [LOADING_POPULAR_SKILLS, STORE_POPULAR_SKILLS]
	})
}

export const fetchPopularCoaches = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/popular-coaches/${employerCode}`,
		actions: [LOADING_POPULAR_COACHES, STORE_POPULAR_COACHES]
	})
}

export const fetchRecentBlogPosts = () => {
	return dispatch => {
		dispatch(loadingRecentBlogPosts(true))

		fetch(
			`https://api.storyblok.com/v1/cdn/stories?token=nJC3QFFdrZvekKrndmQVZQtt&starts_with=blog/&sort_by=published_at:desc&per_page=7&page=1`
		)
			.then(response => response.json())
			.then(resp => {
				console.log({resp})
				dispatch(storeRecentBlogPosts(resp.stories))
				dispatch(loadingRecentBlogPosts(false))
			})
			.catch(err => {
				dispatch(loadingRecentBlogPosts(false))
				console.log(err)
			})
	}
}

export function loadingRecentBlogPosts(status) {
	return {
		type: LOADING_RECENT_BLOG_POSTS,
		payload: {loading: status}
	}
}

export function storeRecentBlogPosts(payload) {
	return {
		type: STORE_RECENT_BLOG_POSTS,
		payload: {data: payload}
	}
}

export const fetchWellbeingLevel = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/wellbeing-level/${employerCode}`,
		actions: [LOADING_WELLBEING_LEVEL, STORE_WELLBEING_LEVEL]
	})
}

export const fetchWellbeingLevelForMonth = ({employerCode, monthNumber}) => {
	return fetcherAndLoader({
		url: `hr/wellbeing-level-for-month/${employerCode}/${monthNumber}`,
		actions: [LOADING_WELLBEING_LEVEL_FOR_MONTH, STORE_WELLBEING_LEVEL_FOR_MONTH]
	})
}
export const fetchWellbeingLevelForEngaged = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/wellbeing-level-for-engaged/${employerCode}`,
		actions: [LOADING_WELLBEING_LEVEL_FOR_ENGAGED, STORE_WELLBEING_LEVEL_FOR_ENGAGED]
	})
}

export const fetchSixMonthStressGraphData = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/six-month-stress-chart/${employerCode}`,
		actions: [LOADING_STRESS_GRAPH_DATA, STORE_STRESS_GRAPH_DATA]
	})
}
export const fetchStressLevel = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/stress-level/${employerCode}`,
		actions: [LOADING_STRESS_LEVEL, STORE_STRESS_LEVEL]
	})
}
export const fetchStressLevelForEngaged = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/stress-level-for-engaged/${employerCode}`,
		actions: [LOADING_STRESS_LEVEL_FOR_ENGAGED, STORE_STRESS_LEVEL_FOR_ENGAGED]
	})
}
export const fetchStressLevelForMonth = ({employerCode, monthNumber}) => {
	return fetcherAndLoader({
		url: `hr/stress-level-for-month/${employerCode}/${monthNumber}`,
		actions: [LOADING_STRESS_LEVEL_FOR_MONTH, STORE_STRESS_LEVEL_FOR_MONTH]
	})
}

export const fetchDataUtilizationStats = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/data-utilization-stats/${employerCode}`,
		actions: [LOADING_DATA_UTILIZATION_STATS, STORE_DATA_UTILIZATION_STATS]
	})
}

export const fetchEmpCodeUtilization = ({employerCode, period}) => {
	return fetcherAndLoader({
		url: `hr/data-utilization/${employerCode}/${period}`,
		actions: [LOADING_DATA_UTILIZATION, STORE_DATA_UTILIZATION]
	})
}

export const fetchHrDashboardFeedbackStats = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/data-feedback-stats/${employerCode}`,
		actions: [LOADING_HR_FEEDBACK_STATS, STORE_HR_FEEDBACK_STATS]
	})
}

export const fetchHrDashboardRoiStats = ({employerCode}) => {
	return fetcherAndLoader({
		url: `hr/data-roi-stats/${employerCode}`,
		actions: [LOADING_HR_ROI_STATS, STORE_HR_ROI_STATS]
	})
}

const postAndLoader = ({url, data, actions: [loading, store]}) => {
	return async dispatch => {
		try {
			dispatch({type: loading, payload: {loading: true}})
			const resp = await post(url, data)
			dispatch({type: store, payload: {data: resp.data}})
			dispatch({type: loading, payload: {loading: false}})
		} catch (error) {
			console.log({error})
			dispatch({type: loading, payload: {loading: false}})
		}
	}
}

export const fetchHrDashboardRiskStats = ({employerCode, startDate,endDate}) => {
	return postAndLoader({
		url: `hr/data-risk-stats/${employerCode}`,
		data: {
			startDate,
			endDate
		},
		actions: [LOADING_HR_RISK_STATS, STORE_HR_RISK_STATS]
	})
}