import React from "react"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
// Utils
import {getThumbnailId} from "helpers/utils"
// utils
import {getTaskId} from "helpers/utils"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// Components
import {Card} from "@uprise/card"
import {ExtraSmall} from "@uprise/text"
import {H6} from "@uprise/headings"
// surveys
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"

const CardWrap = Styled.div`
      transition: .3s ease;
      &:hover {
            transform: translateY(-10px) scale(1.02);
      }
`

const SkillImage = Styled.img`
      height: 114px;
	width: 185px;
      min-width: 185px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
      margin-bottom: 2px;
`
const CardContent = Styled.div`
      display: flex;
      flex-direction: column;

      &:hover {
            cursor: pointer;
      }
 `

const Content = Styled.div`
	padding: ${spacing.s3} ${spacing.s5} ${spacing.s5} ${spacing.s5};
      height: 116px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
`

const TextWrap = Styled.div`
`

const Footer = Styled.div`
`

const ExerciseCard = ({className, key, result, homework, lessons, bonus, tasks, userState}) => {
	let history = useHistory()

	const title = tasks[result.taskId].title

	const handleClick = () => {
		history.push(`/exercise-summary`, {result, title, from: "notebook"})
		// analytics.track("skill.click", {skillId: skillId})
	}

	const skillId = getTaskId(result, homework) || getTaskId(result, lessons) || getTaskId(result, bonus)
	const thumbnailId = getThumbnailId(skillId)
	const survey = retrieveSurvey(result?.surveyId)
	const summary = survey.getSummaryResults(result?.answers)

	return (
		<CardWrap>
			<Card
				className={className}
				onClick={() => handleClick()}
				backgroundColor={backgrounds.white}
				shadow={true}
				width='185px'
				height='231px'>
				<CardContent>
					<SkillImage src={thumbnails[`${thumbnailId}@3x`]} />
					<Content>
						<TextWrap>
							<H6 className='m-t-0 m-b-0'>{title}</H6>
							<ExtraSmall className='m-t-0 m-b-0' color={extended.charcoal.one}>
								{summary[0]?.answer?.toString().substring(0, 50)}
							</ExtraSmall>
						</TextWrap>
						<Footer>
							<ExtraSmall>{moment.unix(result.timestamp).format("DD MMMM YYYY")}</ExtraSmall>
						</Footer>
					</Content>
				</CardContent>
			</Card>
		</CardWrap>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(ExerciseCard)
