import {useState, useEffect, useRef} from "react"

// Hook
function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef()

	// Store current value in ref
	useEffect(() => {
		ref.current = value
	}, [value]) // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current
}

function useDimension(id) {
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)

	let element = null

	const handleResize = () => {
		element = document.getElementById(id)

		if (element) {
			const width = element.getClientRects()[0]?.width
			const height = element.getClientRects()[0]?.height
			setWidth(width)
			setHeight(height)
		}
	}

	useEffect(() => {
		// For setting initial values
		handleResize()
		// Mount/Update
		window.addEventListener("resize", handleResize)
		// UnMount
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	return [width, height]
}

export {usePrevious, useDimension}
