import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
import {Link} from "react-router-dom"
import Styled from "styled-components"

const LeftMenuStyles = Styled.div`
	@media (max-width: 1024px) {
		width: 100%
	}

	@media (max-width: 475px) {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
`

const ItemTitle = Styled.span`
  font-family: ${props => (props.active ? "Proxima Nova Semibold" : "Proxima Nova")};
  top: 2px;
  font-size: ${spacing.s5};
  position: relative;
  color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
	width: 100%;
	text-align: left;

	@media (max-width: 1024px) {
		text-align: center
		display: none;
	}
`

const MenuItem = Styled(Link)`
	display: flex;
  position: relative;
	align-items: center;
  border: 0;
  width: 100%;
  text-align: left;
  height: 51px;
  font-size: ${spacing.s6};
  border-right: 1px solid ${extended.purple.five};
  background-color: ${props => (props.active ? backgrounds.fadedPurple : backgrounds.white)};
  color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
  margin-top: 1rem;
  padding-right: 0;
  padding-left: 0;

  &:after {
    content: " ";
    display: ${props => (props.active ? "inline-block" : "none")};
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    right: 0;
    width: 6px;
    height: 21px;
    background-color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
  }

  &:hover {
    cursor: pointer
  }

	@media (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding-bottom: 1rem;
		height: auto;
		width: 100%;

		&:after {
			content: " ";
			display: none;
		}
	}

	@media (max-width: 1024px) {
 		padding-top: ${spacing.s5};
 	}

	@media (max-width: 475px) {
		width: 50px;
		margin-top: 0;
		padding-top: ${spacing.s5};
		border-right: 0;
		background-color: ${backgrounds.white};
	}
`

const Icon = Styled.img`
      width: 19px;
      height: 21px;
      margin-left: 40px;
	margin-right: 16px;

	@media (max-width: 1024px) {
		margin: 0 0 5px 0
	}
`

const Collapse = Styled.div`
	width : 30px;
	height : 30px;
	z-index: 12;
	position: absolute;
	right: 0;
	top: 10px;

	@media (max-width: 1024px) {
		display: none;
	}
`
const Arrow = Styled.i`
	border: solid #6d6c79;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 3px;
`
const ArrowIcon = Styled(Arrow)`
  	transform: ${props => (!props.toggle ? "rotate(45deg) translateY(-5px)" : "rotate(-135deg)  translate(-4px, 4px)")};
		transition: transform 0.1s linear;
`

const SubMenuWrap = Styled.div`
	max-height: ${props => (props.toggle ? "100%" : "0")};
	overflow: hidden;
	transition: padding 0.3s, opacity 0.3s linear;
	background-color : #f8f8ff;
	opacity: ${props => (props.toggle ? 1 : 0)};

	@media (max-width: 475px) {
		display: ${props => (props.toggle ? "block" : "none")}
	}
`

const SubMenuItem = Styled(Link)`
	display: flex;
	position : relative;
	align-items: center;
	outline: none;
	border: 0;
	width: 100%;
	text-align: center;
	font-size: ${spacing.s6};
	border-right: 1px solid ${extended.purple.five};
	background-color: ${props => (props.active ? "#edeafa" : "#f8f8ff")};
	color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
	padding: 12px 0 12px 30px;

	&:after {
		content: " ";
		position: absolute;
		display: ${props => (props.active ? "inline-block" : "none")};
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
		right: 0;
		width: 6px;
		height: 21px;
		background-color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
	}

	&:hover {
		cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding-bottom: 1rem;
		height: auto;
		width: 100%;
		padding: unset;
		padding-bottom: 1rem;

		&:after {
			content: " ";
			display: none;
		}
	}

	@media (max-width: 1024px) {
 		padding-top: ${spacing.s5};
 	}

	@media (max-width: 475px) {
		width: 50px;
		margin-top: 0;
		padding-top: ${spacing.s5};
		border-right: 0;
		/* background-color: ${backgrounds.white}; */
	}
`

export {LeftMenuStyles, ItemTitle, MenuItem, Icon, Collapse, Arrow, ArrowIcon, SubMenuWrap, SubMenuItem}
