import React, {useState, useEffect} from "react"
import moment from "moment-timezone"
import {connect} from "react-redux"

// Actions
import {fetchBookings} from "actions/bookingsActions"
// UI
import {ProgressiveImage} from "@uprise/image"
import {Row, Col} from "@uprise/grid"
import {Card} from "@uprise/card"
import {H5, H6} from "@uprise/headings"
import {Note} from "@uprise/text"
import {Button} from "@uprise/button"
// Colors
import {primary, backgrounds} from "@uprise/colors"
// styles
import {HR, ContentWrap, CoachPhoto, ContentRight, H6Styled, StarsStyled} from "components/Shared/Bookings/index.style"
// API
import {post} from "helpers/api"

const CompletedCard = ({booking, coaches, fetchBookings}) => {
	const [coach, setCoach] = useState()
	const [rating, setRating] = useState(booking.rating || 0)
	const [isLoading, setIsLoading] = useState(false)

	const isRatingSaved = booking.rating

	useEffect(() => {
		setCoach(coaches.find(item => item.id === booking.coachId))
	}, [booking, coaches])

	const handleRating = value => {
		setRating(value)
	}

	const handleSubmit = async () => {
		try {
			if (!rating) return
			setIsLoading(true)
			const response = await post(`session-rating`, {
				id: booking.id,
				rating
			})
			setIsLoading(false)
			if (!response.error) {
				fetchBookings()
			}
		} catch (err) {
			console.log(err)
			setIsLoading(false)
		}
	}
	return (
		<Card shadow={true} backgroundColor={backgrounds.white} className='m-b-4'>
			<ContentWrap>
				<CoachPhoto>
					<ProgressiveImage
						className='m-r-6 m-b-4'
						borderRadius='10px'
						height={100}
						width={100}
						src={
							coach?.photos?.avatarURL
								? coach?.photos?.imageURL
								: require("assets/images/coach/default-coach.png")
						}
						overlaySrc={
							coach?.photos?.avatarURI
								? coach?.photos?.avatarURI
								: require("assets/images/coach/default-coach.png")
						}
					/>
				</CoachPhoto>
				<ContentRight>
					<H5 color={primary.charcoal} className='m-b-0 m-t-0'>
						{booking.coachName}
					</H5>

					<Row>
						<Col className='d-flex align-items-center m-t-1 m-b-1'>
							<img src={require("assets/images/icons/svg/completed.svg")} className='m-r-3' />
							<Note>
								{`Completed: ${moment(booking?.start).format("Do MMMM, h:mm a")} (${booking.timezone ||
									moment.tz.guess()})`}
							</Note>
						</Col>
					</Row>
					<HR />
					<Row>
						<Col className='d-flex align-items-center'>
							<H6Styled>Session Rate</H6Styled>
							<StarsStyled
								count={5}
								onChange={isRatingSaved ? null : handleRating}
								half={false}
								value={rating}
								char={"★"}
								color1={"#EAEAEC"}
								size={25}
								color2={"#ffaf5b"}
								edit={!isRatingSaved}
							/>
							{!!rating && <Note>{`${rating}/5`}</Note>}
							{!isRatingSaved && (
								<Button
									className='m-l-4'
									onClick={handleSubmit}
									title='Save'
									size='extra-small'
									paddingLeft={"17px"}
									paddingRight={"17px"}
									variant='primary'
									fullWidth={false}
									isLoading={isLoading}
								/>
							)}
						</Col>
					</Row>
				</ContentRight>
			</ContentWrap>
		</Card>
	)
}

const mapDispatchToProps = dispatch => ({
	fetchBookings: () => dispatch(fetchBookings())
})

export default connect(null, mapDispatchToProps)(CompletedCard)
