import React, {useState, useEffect} from "react"
import Styled from "styled-components"
import {SelectHorizontal} from "@uprise/form"
import {extended} from "@uprise/colors"

const Language = Styled.div`
	width: 65px;
`

const Link = Styled.a`
	padding: 7px;
	padding-left: 0;
	font-size: 15px;
	color: ${extended.charcoal.two} !important;

	 
`

const FooterStyles = Styled.footer`
	border-top: 1px solid ${extended.purple.five};
	color: ${extended.charcoal.four};
	align-self: flex-start;
	width: 100%;
	display: flex;
	flex-direction: row;
`

const Footer = ({className}) => {
	const [languages, setLanguages] = useState([])
	const [language, setLanguage] = useState("")

	useEffect(() => {
		setLanguage(window.Localize.getLanguage())

		window.Localize.getAvailableLanguages(function(err, languages) {
			if (err) return console.log(err)

			const lang = languages.map(l => {
				return {label: l.name, value: l.code}
			})
			setLanguages(lang)
		})
	}, [])

	const _handleLang = e => {
		window.Localize.setLanguage(e.target.value)
		setLanguage(e.target.value)
	}

	return (
		<FooterStyles className={className}>
			<Language>
				<SelectHorizontal
					onChange={e => _handleLang(e)}
					value={language}
					id='language'
					options={languages}
					name='language'
					label='Language'
					color={extended.charcoal.two}
					isRequired
				/>
			</Language>
			<Link target='_blank' href='https://uprise-co.statuspage.io/'>
				Status
			</Link>
		</FooterStyles>
	)
}

export default Footer
