import React, {useEffect, useState, Fragment} from "react"
import {object, func} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"
// Actions
import {setSkill} from "actions/skillActions"
import {setTask} from "actions/taskActions"
// Helpers
import {usePrevious} from "helpers/customHooks"
import {getNextTask, getTaskPathFromTaskType, isCoachingEnabled} from "helpers/utils"
// Colors
import {extended} from "@uprise/colors"
// UI
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {SkillProgress} from "components/Shared/Skill/SkillProgress"
// Component
import {Layout} from "components/Shared/Layout"
import SkillLanding from "components/Shared/Skill/SkillLanding"
import VideoTask from "components/Shared/Library/Tasks/Video"
import AudioTask from "components/Shared/Library/Tasks/Audio"
import {TaskList} from "components/Shared/Library/TaskList"
import CoachingScenarioModal from "components/Shared/Coach/CoachingScenarioModal"

const Wrap2 = Styled.section`
	background-color: ${extended.purple.six};
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
      width: 100%;
`

const Skill = ({userState, match, skill, setSkill, setTask}) => {
	let history = useHistory()
	let location = useLocation()
	// We need `usePrevious` to keep track of previous value
	// It will be use to figure out, Is the skill just completed or not?
	const previousMeta = usePrevious(skill.meta)

	const [showLanding, setShowLanding] = useState(true)
	const [video, setVideo] = useState(null)
	const [audio, setAudio] = useState(null)
	const [survey, setSurvey] = useState(null)
	const [showCoachingModal, setShowCoachingModal] = useState(false)
	// Get skill set and skill id from url params
	const {skillId} = match.params

	useEffect(() => {
		// Store skill in application state
		setSkill(userState, skillId)
	}, [skillId])

	useEffect(() => {
		if (Object.values(skill).length) {
			// Get skill overview video
			skill.lessons.forEach(lesson => {
				if (lesson.type === "overview-video" || lesson.type === "video") {
					setVideo(lesson)
					setAudio(null)
					setSurvey(null)
				}

				if (lesson.type === "audio-file") {
					setAudio(lesson)
					setVideo(null)
					setSurvey(null)
				}

				if (lesson.type === "survey") {
					setAudio(null)
					setVideo(null)
					setSurvey(lesson)
				}
			})

			let justCompleted = false
			if (previousMeta && !previousMeta.completed && skill.meta.completed) {
				justCompleted = true
			}
			// Show coaching modal If skill is completed
			if (!showCoachingModal && skillId === skill.skillId && justCompleted) {
				setShowCoachingModal(skill.meta.completedTasks === skill.meta.totalTasks)
			}
		}
	}, [skill, skillId])

	useEffect(() => {
		if (video) {
			// Store task in application state
			setTask(userState.tasks[video.taskId], video.taskId)
		}
		if (audio) {
			// Store task in application state
			setTask(userState.tasks[audio.taskId], audio.taskId)
		}

		if (survey) {
			// Store task in application state
			setTask(userState.tasks[survey.taskId], survey.taskId)
		}
	}, [video, audio, survey])

	const [nextTask, nextPath] = getNextTask(userState, skillId)

	const _handleNext = () => {
		setShowLanding(false)
		const completed = skill.meta.completed

		if (completed) {
			const {taskId} = skill.lessons[0]
			const task = userState.tasks[taskId]

			history.push(`/skill/${skillId}/${getTaskPathFromTaskType(task?.type)}/${taskId}`, {
				from: location.pathname
			})
		} else {
			history.push(`/skill/${skillId}/${getTaskPathFromTaskType(nextTask?.type)}/${nextPath}`, {
				from: location.pathname
			})
		}
	}

	if (video || audio || survey) {
		const {meta} = skill
		let task = video || audio || survey
		const isCompleted = meta.completed
		const ctaLabel = isCompleted ? "View" : `Next: ${nextTask?.altTitle || nextTask?.title}`

		return (
			<Layout>
				<LeftPanel>
					<LeftMenu active={1} />
				</LeftPanel>
				<Wrap2>
					<Wrap>
						<Header
							title={meta.title}
							textAlign='left'
							width='100%'
							back={location?.state?.from.length ? true : false}
							handleBack={() => history.push(location?.state?.from)}
						/>
						<Main padding='25px 25px 0 25px'>
							{showLanding ? (
								<SkillLanding
									nextTask={nextTask}
									skillId={skillId}
									skillTitle={meta?.title}
									skillDescription={meta?.longDesc}
									ctaLabel={ctaLabel}
									handleNext={_handleNext}
								/>
							) : (
								<Fragment>
									{video && (
										<VideoTask skillId={skillId} video={video} activity={userState.activity} />
									)}

									{audio && (
										<AudioTask skillId={skillId} audio={audio} activity={userState.activity} />
									)}
								</Fragment>
							)}
							{isCoachingEnabled(userState?.features) && userState?.courseType !== "rtw" && (
								<CoachingScenarioModal
									isOpen={showCoachingModal}
									handleClose={() => setShowCoachingModal(false)}
									skillName={meta?.title}
								/>
							)}
						</Main>
					</Wrap>

					<RightPanel className='' width='407px' widthLg='100%' widthMd='100%' widthSm='100%' widthXs='100%'>
						<SkillProgress
							className='d-none d-sm-none d-md-none d-lg-none d-xl-flex'
							completedTasks={meta.completedTasks}
							totalTasks={meta.totalTasks}
						/>
						<TaskList className='m-t-10' skill={skill} skillId={skillId} userState={userState} />
					</RightPanel>
				</Wrap2>
			</Layout>
		)
	} else {
		return null
	}
}

function mapStateToProps(state) {
	const {userState, skill} = state

	return {
		userState,
		skill
	}
}

const mapDispatchToProps = dispatch => ({
	setSkill: (userState, skillId) => dispatch(setSkill(userState, skillId)),
	setTask: (task, taskId) => dispatch(setTask(task, taskId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Skill)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Skill.propTypes = {
	userState: object.isRequired,
	match: object.isRequired,
	skill: object.isRequired,
	setSkill: func.isRequired
}

Skill.defaultProps = {}
