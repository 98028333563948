export const retrainingThinkingMetacognition = [
	{
		_id: 0,
		text: "Welcome to retraining thinking for metacognition",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "This exercise is easier if you’ve already watched the video about the skill.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 4,
			optionType: "radio",
			replyType: "survey",
			questions: [0, 1, 2, 3],
			recurring: true,
			surveyId: "retraining-thinking-metacognition-v1",
			taskId: "exercise-retraining-thinking-metacognition",
			values: [
				{
					label: "Show me the video that explains Metacognition",
					videoURI:
						"https://player.vimeo.com/external/246221802.sd.mp4?s=f33dcd5fcc91a7a1ca6b496a43db4f1c94f2e1be&profile_id=165",
					vimeoId: "246221802",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 3,
						_next: 4,
						optionType: "radio",
						replyType: "survey",
						questions: [0, 1, 2, 3],
						recurring: true,
						surveyId: "retraining-thinking-metacognition-v1",
						taskId: "exercise-retraining-thinking-metacognition",
						values: [
							{
								label: "Thanks, i've watched the video",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve already watched it. Let’s get started.",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text:
			"If the metacognition or your worries keep coming up then practice shifting your attention from your internal thoughts to external things like other people.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_next: 7,
		text:
			"Or you can try shifting attention to the place you are in by focusing on what you can smell, feel or hear.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 6,
			_next: 7,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		text: "Thanks for doing the exercise.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text: "The goal was to identify the metacognition that keeps your worrying or ruminating going.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		text:
			" Understanding the costs and payoffs helps to decide if the disadvantages outweigh the benefits of using a metacognition.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text:
			"They can be hard to let go of so we recommend repeatedly shifting your attention from your internal world to your external world or others whenever you notice repeated worries come up.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_next: 12,
		text: "Thanks for doing the exercise.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
