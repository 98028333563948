import React, {Fragment, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {extended, primary} from "@uprise/colors"
import {Col, Row} from "@uprise/grid"
import {H1, H3, H4} from "@uprise/headings"
import {spacing} from "@uprise/spacing"
import {Button} from "@uprise/button"
import GroupedBarChart from "components/Shared/Graphs/GroupedBar"
import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"
import ToggleModal from "components/Shared/ToggleModal"
import DropDownBorderless from "components/Shared/DropDown"
import Preloader from "components/Shared/Preloader/Preloader"
import MonthRangePicker from "components/Shared/MonthRangePicker"
import {fetchDataUtilizationStats, fetchEmpCodeUtilization} from "actions/hrdashboardActions"
import {SectionContainer, PurpleBox, ModalOpenButtonContainer} from "../index.style"

function DataUtilization() {
	const dispatch = useDispatch()
	const {employerCode} = useSelector(state => state.userState)

	const [duration, setDuration] = React.useState({value: "Last Month", label: "Last Month"})
	const [rangeValue, setRangeValue] = React.useState({
		from: {year: new Date().getFullYear(), month: new Date().getMonth() - 1},
		to: {year: new Date().getFullYear(), month: new Date().getMonth()}
	})

	useEffect(() => {
		dispatch(fetchDataUtilizationStats({employerCode}))
	}, [employerCode])

	const {data: organizationStatsData, loading: organizationStatsLoading} = useSelector(
		state => state.hrdashboard.organizationStats
	)

	useEffect(() => {
		let period = {}

		let lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1))

		if (duration.value === "Last Month") {
			period = {
				from: {year: lastMonthDate.getFullYear(), month: lastMonthDate.getMonth()},
				to: {year: lastMonthDate.getFullYear(), month: lastMonthDate.getMonth()}
			}
		} else if (duration.value === "Last 6 Month") {
			let sixMonthAgoDate = new Date().setMonth(lastMonthDate.getMonth() - 6 + 1)
			let sixMonthAgo = new Date(sixMonthAgoDate)
			period = {
				from: {year: sixMonthAgo.getFullYear(), month: sixMonthAgo.getMonth()},
				to: {year: lastMonthDate.getFullYear(), month: lastMonthDate.getMonth()}
			}
		} else if (duration.value === "Custom Selection") {
			period = {
				from: {year: rangeValue.from.year, month: rangeValue.from.month - 1},
				to: {year: rangeValue.to.year, month: rangeValue.to.month - 1}
			}
		}

		period = JSON.stringify(period)

		dispatch(fetchEmpCodeUtilization({employerCode, period}))
	}, [employerCode, duration, rangeValue])

	const {data: organizationUtilizationData, loading: organizationUtilizationLoading} = useSelector(
		state => state.hrdashboard.organizationUtilization
	)

	const showCustomSelection = duration.value === "Custom Selection"

	return (
		<>
			<SectionContainer>
				<TitleWrapper>
					<H3 className='m-b-5'>Your organisation and Uprise - stats till date</H3>
					<ToggleModal
						toggle={show => (
							<Button
								className='m-b-5'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										Help me interpret this
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/>
				</TitleWrapper>
				<Row>
					{!organizationStatsLoading &&
					organizationStatsData &&
					Object.values(organizationStatsData).length ? (
						Object.values(organizationStatsData).map(({label, value}, i) => (
							<Col className='col-md-4 mb-4' key={`${label}-${i + 1}`}>
								<PurpleBox>
									<Fragment>
										<H1 color={primary.purple} textAlign='center' className='mb-3'>
											{value}
										</H1>
										<H4 textAlign='center' color={extended.charcoal.one}>
											{label}
										</H4>
									</Fragment>
								</PurpleBox>
							</Col>
						))
					) : organizationStatsLoading ? (
						<Col className='col-md-12 mb-4'>
							<PurpleBox>
								<Preloader />
							</PurpleBox>
						</Col>
					) : (
						<Col className='col-md-12 mb-4'>
							<PurpleBox>
								<H1 color={primary.purple} textAlign='center' className='mb-3'>
									Loaded with errors
								</H1>
							</PurpleBox>
						</Col>
					)}
				</Row>
			</SectionContainer>
			<SectionContainer>
				<TitleWrapper>
					<div>
						<H3 className='m-b-4'>Select time period for utilization reporting:</H3>
					</div>
					<div>
						<DropDownBorderless
							fullWidth
							onChange={e => {
								console.log(e)
								setDuration(e)
							}}
							items={[
								{value: "Last Month", label: "Last Month"},
								{value: "Last 6 Month", label: "Last 6 Month"},
								{value: "Custom Selection", label: "Custom Selection"}
							]}
							initialSelectedItem={duration}
							menuStyle={{position: "absolute", zIndex: 2}}
							containerStyle={{background: "white", width: "400px", marginBottom: spacing.s5}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
						/>
						{showCustomSelection && (
							<MonthRangePicker
								key={rangeValue}
								value={rangeValue}
								onChange={val => setRangeValue(val)}
							/>
						)}
					</div>
				</TitleWrapper>
			</SectionContainer>
			{!organizationUtilizationLoading &&
			organizationUtilizationData &&
			Object.values(organizationUtilizationData).length ? (
				Object.values(organizationUtilizationData).map(({label, data}, i) => (
					<PurpleBox key={i} padding={`${spacing.s5}`}>
						<SectionContainer>
							<TitleWrapper>
								<div>
									<H3 className='m-b-4'>{label}</H3>
								</div>
								<ToggleModal
									toggle={show => (
										<Button
											className='m-b-5'
											title={
												<ModalOpenButtonContainer>
													<img
														src={require(`assets/images/hrdashboard/information-symbol.svg`)}
														className='mr-2'
													/>
													Help me interpret this
												</ModalOpenButtonContainer>
											}
											fullWidth={false}
											size='medium'
											onClick={show}
											variant='tertiary'
											disabled={false}
											icon={true}
										/>
									)}
									content={hide => <HelpMeInterpretModal hide={hide} />}
								/>
							</TitleWrapper>
							<div>
								<GroupedBarChart data={data} />
							</div>
						</SectionContainer>
					</PurpleBox>
				))
			) : organizationUtilizationLoading ? (
				<SectionContainer>
					<PurpleBox>
						<Preloader />
					</PurpleBox>
				</SectionContainer>
			) : (
				<SectionContainer>
					<PurpleBox>
						<H1 color={primary.purple} textAlign='center' className='mb-3'>
							Loaded with errors
						</H1>
					</PurpleBox>
				</SectionContainer>
			)}
		</>
	)
}

export default DataUtilization
