import {STORE_CODE} from "actions/types"

export default function(state = {}, action) {
	switch (action.type) {
		case STORE_CODE: {
			return {...state, ...action.code}
		}
		default:
			return {
				...state
			}
	}
}
