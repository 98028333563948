import React, {useState, useEffect, Fragment} from "react"
import sanitizeHtml from "sanitize-html"

// styles
import {ShowOptions, FullOptions, Options, OptionsWrap, OtherText, Send, SendIcon} from "../styles"
// colors
import {extended, primary, secondary, backgrounds} from "@uprise/colors"
// Components
import RadioButton from "../inputTypes/RadioButton"
// spacing
import {spacing} from "@uprise/spacing"
// Typography
import {text} from "@uprise/typography"

const SingleWithOtherOptions = ({handleSend, currentMessage}) => {
	const [message, setMessage] = useState("")
	const [showOther, setShowOther] = useState("")
	const [repliesState, setRepliesState] = useState([])
	const [otherText, setOtherText] = useState("")
	const [showFull, setShowFull] = useState(false)

	// check if reply exists
	const sameReply = currentReply => {
		return reply => {
			return currentReply.label === reply.label
		}
	}

	const diffReply = currentReply => {
		return reply => {
			return currentReply.label !== reply.label
		}
	}

	const _handlePress = reply => {
		const replies = repliesState

		if (reply.label === "Other" || reply.oldLabel === "Other") {
			if (replies.find(sameReply(reply))) {
				setRepliesState(repliesState.filter(diffReply(reply)))
			} else {
				setRepliesState([...replies, reply])
			}
		} else {
			setRepliesState([...replies, reply])
			_handleSubmit(reply)
		}
	}

	const _handleOtherSubmit = () => {
		repliesState.forEach(reply => {
			if (reply.label === "Other" || reply.oldLabel === "Other") {
				reply.label = otherText || "None"
				_handleSubmit(reply)
			}
		})
	}

	const _handleSubmit = reply => {
		const {optionType, surveyId, taskId, recurring, _id, _next, questions} = currentMessage.quickReplies
		const {replyType} = currentMessage

		let questionIndex = reply.questionIndex

		const response = {
			...reply,
			...{
				_id: Math.random(),
				_next: _next,
				optionType: optionType,
				moreInfo: currentMessage.moreInfo,
				triggerResponse: currentMessage.triggerResponse,
				surveyId,
				taskId,
				recurring,
				replyType: replyType,
				questionIndex: reply.questionIndex,
				questions: questions
			}
		}

		handleSend([response])
	}

	// get options for render
	const options = Object.values(currentMessage.quickReplies.values).map((reply, index) => {
		const selected = repliesState.find(sameReply(reply))
		if (currentMessage.quickReplies) {
			return (
				<RadioButton
					key={index}
					disabled={repliesState && Object.values(repliesState).length > 0 && !selected ? true : false}
					reply={reply}
					handlePress={() => _handlePress(reply)}
					index={index}
					selected={selected}
				/>
			)
		}
	})

	useEffect(() => {
		const options = Object.values(currentMessage.quickReplies.values).map((reply, index) => {
			const selected = repliesState.find(sameReply(reply))

			if (reply.label === "Other" && selected) {
				setShowOther(true)
			} else {
				setShowOther(false)
			}
		})
	}, [options])

	return (
		<Fragment>
			{options.length > 3 && window.innerWidth <= 768 ? (
				<Fragment>
					<ShowOptions onClick={() => setShowFull(!showFull)}>
						{!showFull ? <span>Show options</span> : <span>Hide Options</span>}
					</ShowOptions>
					{showFull && (
						<FullOptions>
							<OptionsWrap>
								<Options>{options}</Options>
								{showOther && (
									<OtherText
										placeholder='Other...'
										value={otherText}
										onChange={e => setOtherText(sanitizeHtml(e.target.value))}
									/>
								)}
							</OptionsWrap>

							{showOther && (
								<Send onClick={() => _handleOtherSubmit()} data-testid='send-button'>
									<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
								</Send>
							)}
						</FullOptions>
					)}
				</Fragment>
			) : (
				<OptionsWrap>
					<OptionsWrap>
						<Options>{options}</Options>
						{showOther && (
							<OtherText
								placeholder='Other...'
								value={otherText}
								onChange={e => setOtherText(sanitizeHtml(e.target.value))}
							/>
						)}
					</OptionsWrap>
					{showOther && (
						<Send onClick={() => _handleOtherSubmit()} data-testid='send-button'>
							<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
						</Send>
					)}
				</OptionsWrap>
			)}
		</Fragment>
	)
}

export default SingleWithOtherOptions
