import React, {Fragment, useEffect} from "react"
import {array, func, number} from "prop-types"
import InputSlider from "react-rangeslider"

const Slider = ({handleChange, valid, value, options}) => {
	// Set default value
	useEffect(() => {
		if (value === undefined) {
			handleChange(parseInt(options.length / 2))
		}
	}, [])

	const labels = Object.assign({}, options)
	return (
		<Fragment>
			<div
				className={`mt-4 h-100 slider-input slider form-control ${
					valid ? (valid.valid ? "is-valid" : "is-invalid") : ""
				} `}>
				<InputSlider
					min={0}
					max={options.length - 1}
					step={1}
					value={value === undefined ? parseInt(options.length / 2) : value}
					orientation={"horizontal"}
					format={value => options[Math.round(value)]}
					reverse={false}
					tooltip={false}
					labels={labels}
					onChange={handleChange}
				/>
				{options.length === 8 && <span className='slider-input__type small'>Days</span>}
			</div>
			{valid && valid.message && <span className='invalid-feedback'>{valid.message}</span>}
		</Fragment>
	)
}

Slider.propTypes = {
	handleChange: func.isRequired,
	score: number,
	options: array.isRequired
}

export default Slider
