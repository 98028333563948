import React from "react"

// UI
import {H3} from "@uprise/headings"
// Components
import CompletedCard from "components/Shared/Bookings/Completed/CompletedCard"
// styles
import {CompletedStyles} from "components/Shared/Bookings/index.style"

const Completed = ({bookings, coaches}) => {
	return (
		<CompletedStyles className='m-b-4'>
			{!!bookings.length && <H3 className='m-b-5 m-t-5'>Completed</H3>}
			{bookings.map(booking => (
				<CompletedCard booking={booking} key={booking.id} coaches={coaches} />
			))}
		</CompletedStyles>
	)
}

export default Completed
