import React from "react"
import Styled from "styled-components"
// Components
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
import {Medium} from "@uprise/text"
import {H5} from "@uprise/headings"
import {Button} from "@uprise/button"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds} from "@uprise/colors"

const Wrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

const ButtonWrapper = Styled.div`
	justify-content: center
	display: flex;
`

export default function LandingContent({handleNext, name}) {
	return (
		<Wrapper>
			<Alert type='success' className='alert alert-primary mb-5'>
				Thank you for taking the time to complete the Pre-screener questionaire. Your answers will always remain
				completely confidential.
			</Alert>
			<Card shadow={false} backgroundColor={backgrounds.white} padding={spacing.s6} width='100%'>
				<H5 className='m-b-2'>Well done {name.split(" ")[0]}</H5>

				<H5 className='m-t-0'>What do I do now?</H5>

				<ul className='m-t-1'>
					<li>
						<Medium>Read the participant information statement. If you wish to participate, you can then provide your consent to take part.</Medium>
					</li>
					<li>
						<Medium>Complete Pre-program questionaire</Medium>
					</li>
					<li>
						<Medium>Start learning your first skill on Workable</Medium>
					</li>
				</ul>

				<ButtonWrapper>
					<Button
						className='m-t-8 align-center'
						title='Continue'
						size='medium'
						paddingLeft={spacing.s6}
						paddingRight={spacing.s6}
						fullWidth={false}
						onClick={handleNext}
					/>
				</ButtonWrapper>
			</Card>
		</Wrapper>
	)
}
