import React, {Fragment} from "react"
import {string} from "prop-types"
import Styled from "styled-components"
import {useLocation, useHistory} from "react-router-dom"

// Colors
import {backgrounds} from "@uprise/colors"
// UI
import {Layout} from "components/Shared/Layout"
import {Row, Col} from "@uprise/grid"
// Components
import {RuokdayResultsLeft, RuokdayResultsRight} from "components/Ruokday"

const ContentLeft = Styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 1200px) {
		margin: 0px 0px 60px 0px;
	}
`

const Main = Styled.div`
`

const ContentRight = Styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 1200px) {
		margin: 30px 0px;
	}
`

const RowExtended = Styled(Row)`
	@media (max-width: 992px) {
		background: ${backgrounds.fadedPurple}
	}
`

const Image = Styled.img`
	width: 106px;
	height: 30px;
	margin-bottom: 100px;
	cursor: pointer;
`

const RuokdayResults = ({courseType}) => {
	let history = useHistory()
	const location = useLocation()
	const {results} = location.state

	return (
		<Layout backgroundColor={backgrounds.white} rowClassName='justify-content-center h-100'>
			<Col className='col-lg-6 col-md-12'>
				<RowExtended className='justify-content-center h-100' backgroundColor={backgrounds.white}>
					<Col className='col-lg-8 col-md-12'>
						<ContentLeft>
							<Main>
								<Image
									className='m-t-5'
									src={require("assets/images/logos/logo-blue.svg")}
									onClick={() => history.push("/")}
								/>
								<RuokdayResultsLeft />
							</Main>
						</ContentLeft>
					</Col>
				</RowExtended>
			</Col>
			<Col className='col-12 col-lg-6 d-lg-block'>
				<Row className='justify-content-center h-100' backgroundColor={backgrounds.fadedPurple}>
					<Col className='col-lg-8 col-md-12'>
						<ContentRight>
							<RuokdayResultsRight results={results} />
						</ContentRight>
					</Col>
				</Row>
			</Col>
		</Layout>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
RuokdayResults.propTypes = {
	courseType: string.isRequired
}

RuokdayResults.defaultProps = {courseType: "corporate"}

export default RuokdayResults
