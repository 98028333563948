import React, {Fragment, useEffect, useState} from "react"
import {object, func} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"
// Actions
import {setTask} from "actions/taskActions"

// Colors
import {extended} from "@uprise/colors"
// UI
import {Layout} from "components/Shared/Layout"
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {SkillProgress} from "components/Shared/Skill/SkillProgress"

// Component
import {StaticTextTask} from "components/Shared/Library/Tasks/StaticText"
import {TaskList} from "components/Shared/Library/TaskList"

const Wrap2 = Styled.section`
	background-color: ${extended.purple.six};
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`	background-color: ${extended.purple.six};
        width: 100%;
`

const Science = ({userState, match, skill, setTask}) => {
	let history = useHistory()
	let location = useLocation()
	// Get skill set and skill id from url params
	const {skillId} = match.params

	useEffect(() => {
		// Store task in application state
		setTask(userState.faqs[skillId], "science")
	}, [skillId])

	const {meta} = skill
	const science = userState.science[skillId]

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={1} />
			</LeftPanel>

			<Wrap2>
				<Wrap>
					<Header
						title={meta.title}
						textAlign='left'
						width='100%'
						back={location?.state?.from.length ? true : false}
						handleBack={() => history.push(location?.state?.from)}
					/>

					<Main>
						<StaticTextTask title='Science' content={science} />
					</Main>
				</Wrap>
				<RightPanel className='' width='407px' widthLg='100%' widthMd='100%' widthSm='100%' widthXs='100%'>
					{Object.values(skill).length && (
						<Fragment>
							<SkillProgress
								className='d-none d-sm-none d-md-none d-lg-none d-xl-flex'
								completedTasks={meta.completedTasks}
								totalTasks={meta.totalTasks}
							/>
							<TaskList className='m-t-10' skill={skill} skillId={skillId} userState={userState} />
						</Fragment>
					)}
				</RightPanel>
			</Wrap2>
		</Layout>
	)
}

function mapStateToProps(state) {
	const {userState, skill, task} = state

	return {
		userState,
		skill,
		task
	}
}

const mapDispatchToProps = dispatch => ({
	setTask: (task, taskId) => dispatch(setTask(task, taskId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Science)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Science.propTypes = {
	userState: object.isRequired,
	match: object.isRequired,
	skill: object.isRequired
}

Science.defaultProps = {}
