import * as surveys from "uprise-surveys/lib/surveys/index"

const RTW_SURVEY_LABELS = {
	RTW_PRE_SCREENER: "Eligibility Check"
}

// export const postProgramTask = {
// 	surveyId: "progress-rtw-v1.1",
// 	taskId: "survey-postprogram",
// 	recurring: true
// }

export function getScreeners(userState) {
	const task = userState.modules.preOnboarding.tasks.assessment.tasks["survey-pre-onboarding-rtw"]
	return surveys.retrieveSurvey(task.surveyId).questions.reduce((screeners, question) => {
		if (screeners.indexOf(question.batteryId) === -1) {
			screeners.push(question.batteryId)
		}
		console.log("getScreeners -> screeners", screeners)
		return screeners
	}, [])
}

/**
 *
 * @param {Object} userState | User state from server response
 * @returns {Array} itemsList
 */
export function getRTWItemsList(userState) {
	let screeners = []
	if (userState) {
		screeners = getScreeners(userState).map(i => {
			return {id: i, title: RTW_SURVEY_LABELS[i], header: `Pre-program Assessment`, active: false}
		})
	}
	return [
		{title: "Before you begin", header: "Welcome to Workable", active: true},
		...screeners,
		{
			title: "Next steps",
			header: "You are eligble to participate in Workable",
			active: false
		}
	]
	// {title: "Pre-screener Questions", header: "Welcome to Pre-screener survey", active: false},
}
