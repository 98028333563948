import React, {Fragment} from "react"
import Styled from "styled-components"
// Components
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
import {P, Medium} from "@uprise/text"
import {H2, H5} from "@uprise/headings"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Content
import BookingCoachIntro from "components/Rtw/Booking/BookingCoachIntro"

const Wrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

export default function OnboardingBooking({selectedCoach, handleNext}) {
	const isMobile = window.innerWidth < 480

	return (
		<Wrapper>
			<Alert type='success' className='alert alert-primary mb-5'>
				Thank you for taking the time to complete the Pre-program questionnaire. Your answers will always remain
				completely confidential.
			</Alert>
			<Card
				border={`1px solid ${extended.purple.five}`}
				shadow={false}
				backgroundColor={backgrounds.white}
				padding={spacing.s10}
				width='100%'>
				<div className='row'>
					<div className='col-12'>
						<div className={`${isMobile ? "pt-4 pb-4" : "p-5"}`}>
							<H5 className='mb-5 note-grey'>
								You’re doing really well. Now let’s get you a Coach to help you get the best out of this
								program.
							</H5>
							<BookingCoachIntro
								courseType='rtw'
								coach={selectedCoach}
								location='onboarding'
								handleNext={handleNext}
							/>
							<div className='mt-5 note-yellow'>
								<H2>Why do I need a coach?</H2>

								<ul className='mt-3'>
									<li>
										<Medium>
											Your Uprise coach will support &amp; guide you through the program.
										</Medium>
									</li>
									<li>
										<Medium>
											Our coaches are all licenced psychologists or counsellors with experience in
											helping injured workers.
										</Medium>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</Card>
		</Wrapper>
	)
}
