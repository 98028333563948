export const personalFormulaForHappiness = [
	{
		_id: 0,
		text:
			"Some people say that there is no such thing as a relapse. Just someone who is not practicing their personal formula for happiness steps on a daily basis.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Sounds smart",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "So let’s take a look at the activities that will form part of your practice.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			replyType: "survey",
			surveyId: "personal-formula-happiness-v1",
			taskId: "exercise-personal-formula-happiness",
			questions: [0, 1, 2, 3],
			recurring: true,
			values: [
				{
					label: "Ok",
					value: "start",
					replyType: "dialogue",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text:
			"Thanks for that. Now, can you take a moment to put a repeating reminder into your diary to remember to practice them?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "I’ve done it",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "I’ll do it later",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"This is your personal formula for happiness. You’ll probably be pulled into a thousand priorities that compete against these",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "Yep",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		text: "So be kind to yourself and come back to doing them whenever you remember.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "Will do",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_end: true,
		text: "Sending you back, I've placed your notes in your notebook 👍",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
