const constraints = {
	gpEmail: {
		presence: false,
		email: {
			message: "^Please enter a valid email address"
		}
	},
	gpName: {
		presence: false,
		length: {
			minimum: 2,
			message: "^Your name must be at least 1 characters"
		}
	},
	gpPhone: {
		presence: false,
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 0,
			message: "Must be at least 8 digits in length"
		}
	},
	rehabEmail: {
		presence: false,
		email: {
			message: "^Please enter a valid email address"
		}
	},
	rehabName: {
		presence: false,
		length: {
			minimum: 2,
			message: "^Your name must be at least 1 characters"
		}
	},
	rehabPhone: {
		presence: false,
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 8,
			message: "Must be at least 8 digits in length"
		}
	},
	alliedEmail: {
		presence: false,
		email: {
			message: "^Please enter a valid email address"
		}
	},
	alliedName: {
		presence: false,
		length: {
			minimum: 2,
			message: "^Your name must be at least 1 characters"
		}
	},
	alliedPhone: {
		presence: false,
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 8,
			message: "Must be at least 8 digits in length"
		}
	}
}

export default constraints