import React from "react"
import {bool, func, oneOf, string} from "prop-types"
import {formatStringToSentenceCase} from 'helpers/utils'

const DotOption = ({checked, fill, className, disabled, group, label, name, onClick, type}) => {
	const combinedClassName =
		"dot-option" + (group ? ` group-${group}` : "") + (className ? className : "") + (fill ? " is-filled" : "")

	return (
		<label className={combinedClassName}>
			<input type={type} name={name} checked={checked} onClick={onClick} disabled={disabled} readOnly />
			<span className='dot-option__label'>{formatStringToSentenceCase(label)}</span>
		</label>
	)
}

DotOption.propTypes = {
	checked: bool.isRequired,
	className: string,
	disabled: bool,
	group: string,
	label: string.isRequired,
	name: string,
	onClick: func.isRequired,
	type: oneOf(["radio", "checkbox"]).isRequired
}

DotOption.defaultProps = {
	className: "",
	group: "",
	name: ""
}

export default DotOption
