import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {H3} from "@uprise/headings"
import {fetchPopularSkills} from "actions/hrdashboardActions"
import SkillCard from "components/Shared/Library/SkillCard/index"
import Preloader from "components/Shared/Preloader/Preloader"
import {UpNextStyles} from "../index.style"
import {TrackWrap} from "./index.style"

function PopularSkillsForEmployees() {
	const dispatch = useDispatch()
	const {meta, employerCode} = useSelector(state => state.userState)
	React.useEffect(() => {
		dispatch(fetchPopularSkills({employerCode}))
	}, [])

	const {data, loading} = useSelector(state => state.hrdashboard.popularSkills)

	return (
		<UpNextStyles>
			<H3 className='m-b-5'>Popular Skills for your employees this month</H3>

			<TrackWrap>
				{loading ? (
					<Preloader />
				) : data?.length ? (
					Object.values(data).map((item, index) => {
						return (
							<SkillCard
								className={`m-b-2  m-r-5`}
								key={index}
								index={index}
								skillId={item}
								meta={meta}
								showProgressBar={false}
							/>
						)
					})
				) : (
					"No data to display."
				)}
			</TrackWrap>
		</UpNextStyles>
	)
}

export default PopularSkillsForEmployees
