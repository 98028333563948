import Styled from "styled-components"
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
import {Tooltip} from "chart.js"

export const PolicyWrapper = Styled.div`
    display:flex;
    flex-direction:row;
    margin-bottom:${spacing.s4}
`
export const PolicyInputContainer = Styled.div`
    width:90%;
`
export const PolicyToolTipContainer = Styled.div`
    width:10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(237,234,250);
`
export const ToolTip = Styled.div`
display:flex;
position:absolute;
top:25%;
left:80%;
background: white;
padding: 10px;
border-radius: 5px;
border: 1px solid #ccc;
flex-direction:column;
width:400px;
z-index:1;
`

export const InfoIcon = Styled.a`
    cursor:pointer;
}
`
export const InfoImage = Styled.img`
    width:20px;
    height:20px;
`
export const InfoText = Styled.p`
    margin-top:10px;
    margin-bottom:10px; 
`
