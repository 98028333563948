import {applyMiddleware, compose, createStore} from "redux"
import thunk from "redux-thunk"
import {routerMiddleware} from "connected-react-router"
import reducers from "../reducers"
import history from "./history"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
	reducers(history), // root reducer with router state
	composeEnhancers(
		applyMiddleware(
			thunk,
			routerMiddleware(history) // for dispatching history actions),
		)
	)
)
