import React from "react"
import {Redirect} from "react-router"
import Styled from "styled-components"
import {useSelector} from "react-redux"
import {
	HowToBestUseThisDashboard,
	OverallWellbeingLevelsAtUprise,
	PopularCoachesInOrganization,
	PopularSkillsForEmployees,
	UpriseAndPrivacy,
	UpriseBlog
	// RecentActivity,
	// PendingApprovalRequests
} from "components/Shared/HrDashboard"
import {Layout, Wrap} from "components/Shared/Layout"
import LeftMenu from "components/Shared/Menus/Left"
import {Header, LeftPanel, Main, RightPanel} from "components/Shared/Panels"
import YourHealth from "components/Shared/YourHealth"

const HRDashboard = () => {
	const {surveyResults, isDashboardViewEnabled} = useSelector(state => state.userState)

	if (!isDashboardViewEnabled) {
		return <Redirect to='/' />
	}

	return (
		<Layout>
			<LeftPanel LogoMobile={true}>
				<LeftMenu active={4} />
			</LeftPanel>
			<Wrap>
				<Header title='Org Dashboard' textAlign='left' width='100%' handleBack={null} hideMobile={true} />
				<Main>
					{/* <PendingApprovalRequests /> */}
					<OverallWellbeingLevelsAtUprise />
					<UpriseAndPrivacy />
					{/* <RecentActivity /> */}
					<HowToBestUseThisDashboard />
					<PopularSkillsForEmployees />
					<PopularCoachesInOrganization />
					<UpriseBlog />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthMd='250px' widthLg='250px'>
				<YourHealth className='m-b-7' surveyResults={surveyResults} />
			</RightPanel>
		</Layout>
	)
}

export default HRDashboard
