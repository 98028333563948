import React, {Fragment} from "react"
import {string} from "prop-types"
import Styled from "styled-components"

// UI
import {Layout} from "components/Shared/Layout"
import {Row, Col} from "@uprise/grid"
import {backgrounds, primary, secondary, extended} from "@uprise/colors"
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
import {Bold, Medium} from "@uprise/text"
// Components
import {RuokdayAccess, RightContentRuokday} from "components/Ruokday/"
import Footer from "components/Shared/Home/Footer"

const ContentLeft = Styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const ContentLeftBackground = Styled.div` 
	width: 100%;
	display: flex;
	justify-content: center;
	
	@media (max-width: 960px) {
	 	background-image: url(${require("assets/images/art/ruokday/amiok.png")});
		background-size: 600px 450px;
		background-repeat: no-repeat;
		background-position: bottom right;
	}

	@media (max-width: 480px) {
		background-position: top;
		background-image: url(${require("assets/images/art/ruokday/header@3x.png")});
		background-size: 100% 450px;
		background-repeat: no-repeat;
	}

`

const Main = Styled.div`
`

const ContentRight = Styled.section`
	background-image: url(${require("assets/images/art/ruokday/amiok.png")});
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	background-position: bottom right;
	display: flex;
	justify-content: center;


      @media (max-width: 1200px) {
            background-size: 450px 350px;
	}

	@media screen and ( max-height: 800px ){
		background-size: 420px 300px;
	}
`

const Image = Styled.img`
	width: 106px;
	height: 30px;
	margin-bottom: 116px;
	cursor: pointer;
`

const RuokdayAccessWrap = Styled.div`
	@media (max-width: 480px) {
		margin-top: 100px;
	}
`

const TopAlert = Styled.div`
	display: flex;
	flex-direction: row;
`

const BottomAlertText = Styled.div`
	display: flex;
	flex-direction: row;
`

const LockIcon = Styled.img`
	width: 40px;
	height: 40px;
`

const CardExtend = Styled(Card)`
	display: none;
	
	@media (max-width: 480px) {
		display: block;
	}
`

const Ruokday = ({match}) => {
	const companyCode = match?.params?.companyCode
	return (
		<Fragment>
			<Layout backgroundColor={backgrounds.white} rowClassName='justify-content-center h-100'>
				<Col className='col-lg-6 col-md-12'>
					<Row className='justify-content-center h-100' backgroundColor={backgrounds.fadedPurple}>
						<ContentLeftBackground>
							<Col className='col-lg-8 col-md-12'>
								<ContentLeft>
									<Main>
										<Image
											className='m-t-5'
											src={require("assets/images/logos/logo-blue.svg")}
											onClick={() => history.push("/")}
										/>
										<RuokdayAccessWrap className='m-b-5'>
											<RuokdayAccess code={companyCode} />
										</RuokdayAccessWrap>

										<CardExtend
											className='m-b-1'
											height='auto'
											border={`1px solid ${secondary.electricLightOrange}`}
											padding={spacing.s5}
											backgroundColor={extended.lightOrange.five}>
											<TopAlert className='m-b-1'>
												<LockIcon
													className='m-r-4'
													src={require("assets/images/icons/png/confidential/confidential@3x.png")}
												/>
												<Medium>
													<Bold color={primary.charcoal}>
														Your responses are completely anonymous.
													</Bold>
												</Medium>
											</TopAlert>
											<BottomAlertText>
												<Medium>
													Uprise provides your business with a de-identified report so that
													your responses stay confidential. Your confidential response will
													help your company know what the stressors are at work.
												</Medium>
											</BottomAlertText>

											<TopAlert className='m-b-1'></TopAlert>
										</CardExtend>
									</Main>
									<Footer className='m-b-2' />
								</ContentLeft>
							</Col>
						</ContentLeftBackground>
					</Row>
				</Col>
				<Col className='col-12 col-lg-6 d-none d-lg-block'>
					<Row className='justify-content-center h-100' backgroundColor={backgrounds.white}>
						<ContentRight>
							<RightContentRuokday />
						</ContentRight>
					</Row>
				</Col>
			</Layout>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Ruokday.propTypes = {
	courseType: string.isRequired
}

Ruokday.defaultProps = {courseType: "corporate"}

export default Ruokday
