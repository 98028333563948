import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {backgrounds, extended} from "@uprise/colors"
import {H3, H6} from "@uprise/headings"
import {ExtraSmall} from "@uprise/text"
import sanitizeHtml from "sanitize-html"
import Preloader from "components/Shared/Preloader/Preloader"
import Wysiwyg from "components/Shared/Wysiwyg"
import {fetchPopularCoaches} from "actions/hrdashboardActions"
import {UpNextStyles} from "../index.style"
import {TrackWrap} from "../PopularSkillsForEmployees/index.style"
import {CoachImage, Content, ContentWrap, CardWrap, Card} from "./index.style"

function PopularCoachesInOrganization() {
	const dispatch = useDispatch()
	const {employerCode} = useSelector(state => state.userState)
	React.useEffect(() => {
		dispatch(fetchPopularCoaches({employerCode}))
	}, [])

	const {data, loading} = useSelector(state => state.hrdashboard.popularCoaches)

	return (
		<UpNextStyles>
			<H3 className='m-b-5'>Popular Coaches in your organisation</H3>

			<TrackWrap>
				{loading ? (
					<Preloader />
				) : data?.length ? (
					data.map(({name, shortDesc, photos, email}, idx) => (
						<CardWrap className='m-b-2  m-r-5' key={idx} to={`/coach-details/${email}`}>
							<Card width='185px' height='269px'>
								<ContentWrap>
									<CoachImage
										src={
											photos?.imageURL
												? photos.imageURL
												: require("assets/images/coach/default-coach.png")
										}
									/>
									<Content>
										<div>
											<H6 className='m-t-0 m-b-0'>{name}</H6>
											<ExtraSmall color={extended.charcoal.one}>
												{Object.keys(shortDesc).length ? (
													<Wysiwyg
														initialValue={
															shortDesc.english
																? shortDesc.english
																: shortDesc[Object.keys(shortDesc)[0]]
														}
														editable={false}
													/>
												) : shortDesc ? (
													sanitizeHtml(shortDesc)
												) : null}
											</ExtraSmall>
										</div>
									</Content>
								</ContentWrap>
							</Card>
						</CardWrap>
					))
				) : (
					"No data to display."
				)}
			</TrackWrap>
		</UpNextStyles>
	)
}

export default PopularCoachesInOrganization
