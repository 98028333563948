const constraints = {
	email: {
		presence: {
			message: "^Please enter an email address"
		},
		email: {
			message: "^Please enter a valid email address",
			allowEmpty: false
		}
	}
}

export default constraints
