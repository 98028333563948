// Fetch pollyfill
import "whatwg-fetch"
// polyfill all `core-js` features:
import "core-js"
// React
import React from "react"
import ReactDOM from "react-dom"
require("./libs/segmentio.js")
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3"

// base
import "./styles/base.css"
// Grid
import "./styles/bootstrap-grid-utils.css"
// Font
import "./assets/fonts/proxima-nova-web-fonts/fonts.css"

import App from "./App"
import {ModalRoot} from "@uprise/modal"
import {Provider} from "react-redux"
import {store} from "./store"

const rootElement = document.getElementById("root")
ReactDOM.render(
	<Provider store={store}>
		<GoogleReCaptchaProvider useEnterprise={false} reCaptchaKey='6LcZ-GYbAAAAAPfRWBtmneit8TsWz5_Ki99Dkms2'>
			<App />
		</GoogleReCaptchaProvider>
		<ModalRoot />
	</Provider>,
	rootElement
)

import * as serviceWorker from "./serviceWorker"

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
