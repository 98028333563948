import React, {Fragment, useState, useEffect} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
// Content
import PreOnboardingOverview from "components/Rtw/PreOnboarding/PreOnboardingOverview"
import PreOnboardingAssessment from "components/Rtw/PreOnboarding/PreOnboardingAssessment"
import PreOnboardingLanding from "components/Rtw/PreOnboarding/PreOnboardingLanding"

// Actions
import {updateUserState} from "actions/userStateActions"

function checkProgress(surveyResults) {
	let screener = "RTW_PRE_SCREENER"
	if (surveyResults) {
		for (const result of surveyResults) {
			if (result.taskId === "pre-onboarding") {
				if (!result.completed) {
					const keys = Object.keys(result.answers)
					screener = keys[keys.length - 1]
				}
			}
		}
	}
	return screener
}

function PreOnboardingRTWSteps({progressCheckpoints, handleNext, updateHeader, userState, activeIndex}) {
	const [activeScreener, setActiveScreener] = useState(checkProgress(userState.surveyResults))

	useEffect(() => {
		progressCheckpoints.forEach((checkpoint, index) => {
			if (index === activeIndex && index > 0 && index < progressCheckpoints.length - 1) {
				setActiveScreener(checkpoint.id)
			}
		})
	}, [activeIndex, progressCheckpoints])

	const introCheckpoint = progressCheckpoints[0]
	const preOnboardingAssesmentCheckpoint = progressCheckpoints[1]
	const preOnboardingLandingCheckpoint = progressCheckpoints[2]

	const nextCheckpoint = progressCheckpoints.findIndex(c => c.id === activeScreener)

	return (
		<Fragment>
			{introCheckpoint && introCheckpoint["active"] && (
				<PreOnboardingOverview handleNext={() => handleNext(nextCheckpoint)} />
			)}

			{preOnboardingAssesmentCheckpoint && preOnboardingAssesmentCheckpoint["active"] && (
				<PreOnboardingAssessment
					userState={userState}
					handleChange={value => handleNext(activeIndex + value)}
					activeScreener={activeScreener}
				/>
			)}

			{preOnboardingLandingCheckpoint && preOnboardingLandingCheckpoint["active"] && (
				<PreOnboardingLanding
					dialogueId='onboarding'
					name={userState.name}
					surveyResults={userState.surveyResults}
					updateHeader={title => updateHeader(title)}
					handleNext={index => handleNext(index)}
				/>
			)}
		</Fragment>
	)
}

PreOnboardingRTWSteps.propTypes = {
	activeIndex: PropTypes.number.isRequired,
	handleNext: PropTypes.func.isRequired,
	progressCheckpoints: PropTypes.array.isRequired,
	userState: PropTypes.object.isRequired
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		surveyState: state.surveyState
	}
}

export default connect(mapStateToProps, {updateUserState})(PreOnboardingRTWSteps)
