export const problemSolution = [
	{
		_id: 0,
		_next: 1,
		text: "Welcome to the problem solution exercise",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "Here we'll help you find the problem solution",
		quickReplies: {
			_id: 2,
			_next: 3,
			optionType: "radio",
			surveyId: "survey-problem-solution-v1",
			taskId: "exercise-problem-solution",
			questions: [0, 1, 2, 3],
			recurring: true,
			values: [
				{
					label: "Let's do it",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		text: "Thanks for completing this exercise. ",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_end: true,
		text: "Taking you back now",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
