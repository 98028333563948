import React, {Fragment} from "react"
import Styled from "styled-components"
// colors
import {extended, primary, secondary, backgrounds} from "@uprise/colors"

const Base = Styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 10px;
    border: none;
    height: 50px;
    padding-left: ${props => props.paddingLeft};
    padding-right: ${props => props.paddingRight};
    font-size: ${props => props.fontSize};
    color: ${primary.purple};
    font-family: ${props => (props.weight === "bold" ? "Proxima Nova Semibold" : "Proxima Nova")};
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`

export const Icon = Styled.img`
	margin-right: 12px;
`

export const Primary = Styled(Base)`
    background-color: ${props => props.backgroundColor};

    text-transform: ${props => {
		if (props.uppercase) {
			return "uppercase"
		} else {
			return "none"
		}
	}};

    border-radius: ${props => (props.borderRadius ? props.borderRadius : "10px")};
    color: ${props => {
		if (props.white) {
			return extended.purple.dark
		} else {
			return backgrounds.white
		}
	}};
    width: ${props => props.width};
    height: ${props => props.height};

    
    &:hover {
        background-color: ${props => props.backgroundHover};
    }
`

export const SocialButton = ({
	title,
	size,
	weight,
	fullWidth,
	variant,
	borderRadius,
	isLoading,
	transparent,
	paddingLeft,
	paddingRight,
	uppercase,
	hover,
	...props
}) => {
	let width, height, fontSize, icon, backgroundColor, backgroundHover

	switch (size) {
		case "tiny":
			width = fullWidth ? "100%" : "70px"
			height = "24px"
			fontSize = "11px"
			weight = "normal"
			break
		case "extra-small":
			width = fullWidth ? "100%" : "auto"
			height = "24px"
			fontSize = "13px"
			weight = "normal"
			break
		case "small":
			width = fullWidth ? "100%" : "auto"
			height = "32px"
			fontSize = "16px"
			weight = variant === "secondary" ? "normal" : "bold"
			break
		case "medium":
			width = fullWidth ? "100%" : "auto"
			height = "42px"
			fontSize = "16px"
			weight = "bold"
			break
		case "large":
			width = fullWidth ? "100%" : "auto"
			height = "50px"
			fontSize = "16px"
			weight = "bold"
			break
	}

	switch (variant) {
		case "azure":
			icon = require("assets/images/icons/png/azure/azure.png")
			backgroundColor = secondary.electricLightBlue
			backgroundHover = extended.blue.two
			break
	}

	return (
		<Primary
			role='button'
			weight={weight}
			fontSize={fontSize}
			uppercase={uppercase}
			paddingLeft={paddingLeft}
			paddingRight={paddingRight}
			width={width}
			backgroundColor={backgroundColor}
			backgroundHover={backgroundHover}
			icon={icon}
			transparent={transparent}
			height={height}
			hover={hover}
			borderRadius={borderRadius}
			{...props}>
			{isLoading ? (
				<img className='' src={require(`@uprise/core/assets/images/animated/ellipsis-white.svg`)} />
			) : (
				<Fragment>
					<Icon className='' src={icon} />
					<span>{title}</span>
				</Fragment>
			)}
		</Primary>
	)
}

SocialButton.defaultProps = {
	borderRadius: "0.625rem",
	fontSize: "1rem",
	selected: false,
	key: 1,
	isLoading: false
}
