import React, {useState, Fragment} from "react"
// styles
import {OptionsWrap, ShowOptions, FullOptions, Options, Send, SendIcon} from "../styles"
// Components
import RadioButton from "../inputTypes/RadioButton"

const MultiOptions = ({handleSend, currentMessage}) => {
	const [repliesState, setRepliesState] = useState([])
	const [showFull, setShowFull] = useState(false)

	// check if reply exists
	const sameReply = currentReply => {
		return reply => {
			return currentReply.label === reply.label
		}
	}

	const diffReply = currentReply => {
		return reply => {
			return currentReply.label !== reply.label
		}
	}

	const _handlePress = reply => {
		const replies = repliesState
		if (replies.find(sameReply(reply))) {
			setRepliesState(repliesState.filter(diffReply(reply)))
		} else {
			setRepliesState([...replies, reply])
		}
	}

	const _handleSubmit = () => {
		const {
			optionType,
			surveyId,
			recurring,
			_id,
			_next,
			responsePrefix,
			noResponsePrefix,
			questions
		} = currentMessage.quickReplies

		const {taskId} = currentMessage.quickReplies.values[0]
		const {replyType} = currentMessage
		let replies = repliesState

		const labels = replies.map(reply => {
			return reply.label
		})

		replies = replies.map((reply, index) => {
			let questionIndex = reply.questionIndex

			const response = {
				...reply,
				...{
					_id: Math.random(),
					_next: _next,
					optionType: optionType,
					moreInfo: currentMessage.moreInfo,
					triggerResponse: currentMessage.triggerResponse,
					surveyId,
					taskId,
					recurring,
					replyType: replyType,
					questionIndex: reply.questionIndex,
					questions: questions,
					answer: reply.label,
					response: `${responsePrefix} ${labels.join(", ")}`
				}
			}

			return response
		})

		if (replies.length === 0) {
			replies = [
				{
					_id: Math.random(),
					_next: _next,
					label: noResponsePrefix || "None",
					optionType: optionType,
					moreInfo: currentMessage.moreInfo,
					triggerResponse: currentMessage.triggerResponse,
					surveyId,
					taskId,
					recurring,
					replyType: replyType,
					questionIndex: currentMessage.quickReplies.values[0].questionIndex,
					questions: questions
				}
			]
		}

		handleSend(replies)
	}

	// get options for render
	const options = Object.values(currentMessage.quickReplies.values).map((reply, index) => {
		const selected = repliesState.find(sameReply(reply))
		if (currentMessage.quickReplies) {
			return (
				<RadioButton
					key={index}
					disabled={false}
					reply={reply}
					handlePress={() => _handlePress(reply)}
					index={index}
					selected={selected}
				/>
			)
		}
	})

	return (
		<Fragment>
			{options.length > 1 && window.innerWidth <= 768 ? (
				<Fragment>
					<ShowOptions onClick={() => setShowFull(!showFull)}>
						{!showFull ? <span>Show options</span> : <span>Hide Options</span>}
					</ShowOptions>
					{showFull && (
						<FullOptions>
							<OptionsWrap>
								<Options>{options}</Options>
							</OptionsWrap>

							<Send onClick={() => _handleSubmit()} data-testid='send-button'>
								<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
							</Send>
						</FullOptions>
					)}
				</Fragment>
			) : (
				<OptionsWrap>
					<Options>{options}</Options>
					<Send onClick={() => _handleSubmit()} data-testid='send-button'>
						<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
					</Send>
				</OptionsWrap>
			)}
		</Fragment>
	)
}

export default MultiOptions
