const countryList = [
	{label: "Afghanistan", value: "AF", name: "+93"},
	{label: "Albania", value: "AL", name: "+355"},
	{label: "Algeria", value: "DZ", name: "+213"},
	{label: "AmericanSamoa", value: "AS", name: "+1 684"},
	{label: "Andorra", value: "AD", name: "+376"},
	{label: "Angola", value: "AO", name: "+244"},
	{label: "Anguilla", value: "AI", name: "+1 264"},
	{label: "Antigua and Barbuda", value: "AG", name: "+1268"},
	{label: "Argentina", value: "AR", name: "+54"},
	{label: "Armenia", value: "AM", name: "+374"},
	{label: "Aruba", value: "AW", name: "+297"},
	{label: "Australia", value: "AU", name: "+61"},
	{label: "Austria", value: "AT", name: "+43"},
	{label: "Azerbaijan", value: "AZ", name: "+994"},
	{label: "Bahamas", value: "BS", name: "+1 242"},
	{label: "Bahrain", value: "BH", name: "+973"},
	{label: "Bangladesh", value: "BD", name: "+880"},
	{label: "Barbados", value: "BB", name: "+1 246"},
	{label: "Belarus", value: "BY", name: "+375"},
	{label: "Belgium", value: "BE", name: "+32"},
	{label: "Belize", value: "BZ", name: "+501"},
	{label: "Benin", value: "BJ", name: "+229"},
	{label: "Bermuda", value: "BM", name: "+1 441"},
	{label: "Bhutan", value: "BT", name: "+975"},
	{label: "Bolivia, Plurinational State of", value: "BO", name: "+591"},
	{label: "Bosnia and Herzegovina", value: "BA", name: "+387"},
	{label: "Botswana", value: "BW", name: "+267"},
	{label: "Brazil", value: "BR", name: "+55"},
	{label: "British Indian Ocean Territory", value: "IO", name: "+246"},
	{label: "Brunei Darussalam", value: "BN", name: "+673"},
	{label: "Bulgaria", value: "BG", name: "+359"},
	{label: "Burkina Faso", value: "BF", name: "+226"},
	{label: "Burundi", value: "BI", name: "+257"},
	{label: "Cambodia", value: "KH", name: "+855"},
	{label: "Cameroon", value: "CM", name: "+237"},
	{label: "Canada", value: "CA", name: "+1"},
	{label: "Cape Verde", value: "CV", name: "+238"},
	{label: "Cayman Islands", value: "KY", name: "+ 345"},
	{label: "Central African Republic", value: "CF", name: "+236"},
	{label: "Chad", value: "TD", name: "+235"},
	{label: "Chile", value: "CL", name: "+56"},
	{label: "China", value: "CN", name: "+86"},
	{label: "Christmas Island", value: "CX", name: "+61"},
	{label: "Cocos (Keeling) Islands", value: "CC", name: "+61"},
	{label: "Colombia", value: "CO", name: "+57"},
	{label: "Comoros", value: "KM", name: "+269"},
	{label: "Congo", value: "CG", name: "+242"},
	{label: "Congo, The Democratic Republic of the", value: "CD", name: "+243"},
	{label: "Cook Islands", value: "CK", name: "+682"},
	{label: "Costa Rica", value: "CR", name: "+506"},
	{label: "Cote d'Ivoire", value: "CI", name: "+225"},
	{label: "Croatia", value: "HR", name: "+385"},
	{label: "Cuba", value: "CU", name: "+53"},
	{label: "Cyprus", value: "CY", name: "+537"},
	{label: "Czech Republic", value: "CZ", name: "+420"},
	{label: "Denmark", value: "DK", name: "+45"},
	{label: "Djibouti", value: "DJ", name: "+253"},
	{label: "Dominica", value: "DM", name: "+1 767"},
	{label: "Dominican Republic", value: "DO", name: "+1 849"},
	{label: "Ecuador", value: "EC", name: "+593"},
	{label: "Egypt", value: "EG", name: "+20"},
	{label: "El Salvador", value: "SV", name: "+503"},
	{label: "Equatorial Guinea", value: "GQ", name: "+240"},
	{label: "Eritrea", value: "ER", name: "+291"},
	{label: "Estonia", value: "EE", name: "+372"},
	{label: "Ethiopia", value: "ET", name: "+251"},
	{label: "Falkland Islands (Malvinas)", value: "FK", name: "+500"},
	{label: "Faroe Islands", value: "FO", name: "+298"},
	{label: "Fiji", value: "FJ", name: "+679"},
	{label: "Finland", value: "FI", name: "+358"},
	{label: "France", value: "FR", name: "+33"},
	{label: "French Guiana", value: "GF", name: "+594"},
	{label: "French Polynesia", value: "PF", name: "+689"},
	{label: "Gabon", value: "GA", name: "+241"},
	{label: "Gambia", value: "GM", name: "+220"},
	{label: "Georgia", value: "GE", name: "+995"},
	{label: "Germany", value: "DE", name: "+49"},
	{label: "Ghana", value: "GH", name: "+233"},
	{label: "Gibraltar", value: "GI", name: "+350"},
	{label: "Greece", value: "GR", name: "+30"},
	{label: "Greenland", value: "GL", name: "+299"},
	{label: "Grenada", value: "GD", name: "+1 473"},
	{label: "Guadeloupe", value: "GP", name: "+590"},
	{label: "Guam", value: "GU", name: "+1 671"},
	{label: "Guatemala", value: "GT", name: "+502"},
	{label: "Guernsey", value: "GG", name: "+44"},
	{label: "Guinea", value: "GN", name: "+224"},
	{label: "Guinea-Bissau", value: "GW", name: "+245"},
	{label: "Guyana", value: "GY", name: "+595"},
	{label: "Haiti", value: "HT", name: "+509"},
	{label: "Holy See (Vatican City State)", value: "VA", name: "+379"},
	{label: "Honduras", value: "HN", name: "+504"},
	{label: "Hong Kong", value: "HK", name: "+852"},
	{label: "Hungary", value: "HU", name: "+36"},
	{label: "Iceland", value: "IS", name: "+354"},
	{label: "India", value: "IN", name: "+91"},
	{label: "Indonesia", value: "ID", name: "+62"},
	{label: "Iran, Islamic Republic of", value: "IR", name: "+98"},
	{label: "Iraq", value: "IQ", name: "+964"},
	{label: "Ireland", value: "IE", name: "+353"},
	{label: "Isle of Man", value: "IM", name: "+44"},
	{label: "Israel", value: "IL", name: "+972"},
	{label: "Israel", value: "IL", name: "+972"},
	{label: "Italy", value: "IT", name: "+39"},
	{label: "Jamaica", value: "JM", name: "+1 876"},
	{label: "Japan", value: "JP", name: "+81"},
	{label: "Jersey", value: "JE", name: "+44"},
	{label: "Jordan", value: "JO", name: "+962"},
	{label: "Kazakhstan", value: "KZ", name: "+7 7"},
	{label: "Kenya", value: "KE", name: "+254"},
	{label: "Kiribati", value: "KI", name: "+686"},
	{label: "Korea, Democratic People's Republic of", value: "KP", name: "+850"},
	{label: "Korea, Republic of", value: "KR", name: "+82"},
	{label: "Kuwait", value: "KW", name: "+965"},
	{label: "Kyrgyzstan", value: "KG", name: "+996"},
	{label: "Lao People's Democratic Republic", value: "LA", name: "+856"},
	{label: "Latvia", value: "LV", name: "+371"},
	{label: "Lebanon", value: "LB", name: "+961"},
	{label: "Lesotho", value: "LS", name: "+266"},
	{label: "Liberia", value: "LR", name: "+231"},
	{label: "Libyan Arab Jamahiriya", value: "LY", name: "+218"},
	{label: "Liechtenstein", value: "LI", name: "+423"},
	{label: "Lithuania", value: "LT", name: "+370"},
	{label: "Luxembourg", value: "LU", name: "+352"},
	{label: "Macao", value: "MO", name: "+853"},
	{label: "Macedonia, The Former Yugoslav Republic of", value: "MK", name: "+389"},
	{label: "Madagascar", value: "MG", name: "+261"},
	{label: "Malawi", value: "MW", name: "+265"},
	{label: "Malaysia", value: "MY", name: "+60"},
	{label: "Maldives", value: "MV", name: "+960"},
	{label: "Mali", value: "ML", name: "+223"},
	{label: "Malta", value: "MT", name: "+356"},
	{label: "Marshall Islands", value: "MH", name: "+692"},
	{label: "Martinique", value: "MQ", name: "+596"},
	{label: "Mauritania", value: "MR", name: "+222"},
	{label: "Mauritius", value: "MU", name: "+230"},
	{label: "Mayotte", value: "YT", name: "+262"},
	{label: "Mexico", value: "MX", name: "+52"},
	{label: "Micronesia, Federated States of", value: "FM", name: "+691"},
	{label: "Moldova, Republic of", value: "MD", name: "+373"},
	{label: "Monaco", value: "MC", name: "+377"},
	{label: "Mongolia", value: "MN", name: "+976"},
	{label: "Montenegro", value: "ME", name: "+382"},
	{label: "Montserrat", value: "MS", name: "+1664"},
	{label: "Morocco", value: "MA", name: "+212"},
	{label: "Mozambique", value: "MZ", name: "+258"},
	{label: "Myanmar", value: "MM", name: "+95"},
	{label: "Namibia", value: "NA", name: "+264"},
	{label: "Nauru", value: "NR", name: "+674"},
	{label: "Nepal", value: "NP", name: "+977"},
	{label: "Netherlands", value: "NL", name: "+31"},
	{label: "Netherlands Antilles", value: "AN", name: "+599"},
	{label: "New Caledonia", value: "NC", name: "+687"},
	{label: "New Zealand", value: "NZ", name: "+64"},
	{label: "Nicaragua", value: "NI", name: "+505"},
	{label: "Niger", value: "NE", name: "+227"},
	{label: "Nigeria", value: "NG", name: "+234"},
	{label: "Niue", value: "NU", name: "+683"},
	{label: "Norfolk Island", value: "NF", name: "+672"},
	{label: "Northern Mariana Islands", value: "MP", name: "+1 670"},
	{label: "Norway", value: "NO", name: "+47"},
	{label: "Oman", value: "OM", name: "+968"},
	{label: "Pakistan", value: "PK", name: "+92"},
	{label: "Palau", value: "PW", name: "+680"},
	{label: "Palestinian Territory, Occupied", value: "PS", name: "+970"},
	{label: "Panama", value: "PA", name: "+507"},
	{label: "Papua New Guinea", value: "PG", name: "+675"},
	{label: "Paraguay", value: "PY", name: "+595"},
	{label: "Peru", value: "PE", name: "+51"},
	{label: "Philippines", value: "PH", name: "+63"},
	{label: "Pitcairn", value: "PN", name: "+872"},
	{label: "Poland", value: "PL", name: "+48"},
	{label: "Portugal", value: "PT", name: "+351"},
	{label: "Puerto Rico", value: "PR", name: "+1 939"},
	{label: "Qatar", value: "QA", name: "+974"},
	{label: "Romania", value: "RO", name: "+40"},
	{label: "Russia", value: "RU", name: "+7"},
	{label: "Rwanda", value: "RW", name: "+250"},
	{label: "Réunion", value: "RE", name: "+262"},
	{label: "Saint Barthélemy", value: "BL", name: "+590"},
	{label: "Saint Helena, Ascension and Tristan Da Cunha", value: "SH", name: "+290"},
	{label: "Saint Kitts and Nevis", value: "KN", name: "+1 869"},
	{label: "Saint Lucia", value: "LC", name: "+1 758"},
	{label: "Saint Martin", value: "MF", name: "+590"},
	{label: "Saint Pierre and Miquelon", value: "PM", name: "+508"},
	{label: "Saint Vincent and the Grenadines", value: "VC", name: "+1 784"},
	{label: "Samoa", value: "WS", name: "+685"},
	{label: "San Marino", value: "SM", name: "+378"},
	{label: "Sao Tome and Principe", value: "ST", name: "+239"},
	{label: "Saudi Arabia", value: "SA", name: "+966"},
	{label: "Senegal", value: "SN", name: "+221"},
	{label: "Serbia", value: "RS", name: "+381"},
	{label: "Seychelles", value: "SC", name: "+248"},
	{label: "Sierra Leone", value: "SL", name: "+232"},
	{label: "Singapore", value: "SG", name: "+65"},
	{label: "Slovakia", value: "SK", name: "+421"},
	{label: "Slovenia", value: "SI", name: "+386"},
	{label: "Solomon Islands", value: "SB", name: "+677"},
	{label: "Somalia", value: "SO", name: "+252"},
	{label: "South Africa", value: "ZA", name: "+27"},
	{label: "South Georgia and the South Sandwich Islands", value: "GS", name: "+500"},
	{label: "Spain", value: "ES", name: "+34"},
	{label: "Sri Lanka", value: "LK", name: "+94"},
	{label: "Sudan", value: "SD", name: "+249"},
	{label: "Surivalue", value: "SR", name: "+597"},
	{label: "Svalbard and Jan Mayen", value: "SJ", name: "+47"},
	{label: "Swaziland", value: "SZ", name: "+268"},
	{label: "Sweden", value: "SE", name: "+46"},
	{label: "Switzerland", value: "CH", name: "+41"},
	{label: "Syrian Arab Republic", value: "SY", name: "+963"},
	{label: "Taiwan, Province of China", value: "TW", name: "+886"},
	{label: "Tajikistan", value: "TJ", name: "+992"},
	{label: "Tanzania, United Republic of", value: "TZ", name: "+255"},
	{label: "Thailand", value: "TH", name: "+66"},
	{label: "Timor-Leste", value: "TL", name: "+670"},
	{label: "Togo", value: "TG", name: "+228"},
	{label: "Tokelau", value: "TK", name: "+690"},
	{label: "Tonga", value: "TO", name: "+676"},
	{label: "Trinidad and Tobago", value: "TT", name: "+1 868"},
	{label: "Tunisia", value: "TN", name: "+216"},
	{label: "Turkey", value: "TR", name: "+90"},
	{label: "Turkmenistan", value: "TM", name: "+993"},
	{label: "Turks and Caicos Islands", value: "TC", name: "+1 649"},
	{label: "Tuvalu", value: "TV", name: "+688"},
	{label: "Uganda", value: "UG", name: "+256"},
	{label: "Ukraine", value: "UA", name: "+380"},
	{label: "United Arab Emirates", value: "AE", name: "+971"},
	{label: "United Kingdom", value: "GB", name: "+44"},
	{label: "United States", value: "US", name: "+1"},
	{label: "Uruguay", value: "UY", name: "+598"},
	{label: "Uzbekistan", value: "UZ", name: "+998"},
	{label: "Vanuatu", value: "VU", name: "+678"},
	{label: "Venezuela, Bolivarian Republic of", value: "VE", name: "+58"},
	{label: "Viet Nam", value: "VN", name: "+84"},
	{label: "Virgin Islands, British", value: "VG", name: "+1 284"},
	{label: "Virgin Islands, U.S.", value: "VI", name: "+1 340"},
	{label: "Wallis and Futuna", value: "WF", name: "+681"},
	{label: "Yemen", value: "YE", name: "+967"},
	{label: "Zambia", value: "ZM", name: "+260"}
]
export default countryList
