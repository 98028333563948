import React from "react"
import {Card} from "@uprise/card"
import {backgrounds, extended, primary} from "@uprise/colors"
import {H1, H3, H4, H6} from "@uprise/headings"
import {useDispatch, useSelector} from "react-redux"
import LineChart from "components/Shared/Graphs/LineChart"
import ToggleModal from "components/Shared/ToggleModal"
import HelpMeInterpretModal from "../HelpMeInterpretModal"
import {fetchSixMonthWellbeingGraphData} from "actions/hrdashboardActions"
import {CardWrap, UpNextStyles} from "../index.style"
import {
	ContentWrap,
	GraphDesc,
	GraphDescImg,
	Legend,
	LegendBox,
	LegendImg,
	TitleWrapper,
	ModalButtonWrapper
} from "./index.style"
import Preloader from "components/Shared/Preloader/Preloader"

const OverallWellbeingLevelsAtUprise = () => {
	const dispatch = useDispatch()
	const {employerCode} = useSelector(state => state.userState)
	React.useEffect(() => {
		dispatch(fetchSixMonthWellbeingGraphData({employerCode}))
	}, [])

	const {data, loading} = useSelector(state => state.hrdashboard.wellbeingGraph)

	return (
		<UpNextStyles>
			<TitleWrapper>
				<H3 className='m-b-5'>Overall Wellbeing Levels at Uprise</H3>

				<ToggleModal
					toggle={show => (
						<ModalButtonWrapper onClick={show}>
							<img src={require(`assets/images/hrdashboard/information-symbol.svg`)} />
							<H6 color={extended.purple.one} className='ml-2'>
								Help me interpret this
							</H6>
						</ModalButtonWrapper>
					)}
					content={hide => <HelpMeInterpretModal hide={hide} />}
				/>
			</TitleWrapper>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap className='m-4'>
					{data && Object.keys(data).length === 3 ? (
						<LineChart data={data} />
					) : loading ? (
						<Preloader />
					) : (
						<H1 color={primary.purple} textAlign='center' className='mb-3'>
							Loaded with errors
						</H1>
					)}
					{/* <Legend>
							<LegendBox>
								<LegendImg src={require(`assets/images/hrdashboard/chart-1.svg`)} />
								<H6>Monthly Average</H6>
							</LegendBox>
							<LegendBox>
								<LegendImg src={require(`assets/images/hrdashboard/chart-2.svg`)} />
								<H6>Industry Monthly Average</H6>
							</LegendBox>
						</Legend> */}
					<GraphDesc>
						<H4>Understanding Wellbeing</H4>
						<GraphDescImg className='m-t-10' src={require(`assets/images/hrdashboard/range.svg`)} />
					</GraphDesc>
				</ContentWrap>
			</Card>
		</UpNextStyles>
	)
}

export default OverallWellbeingLevelsAtUprise
