import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {NavLink} from "react-router-dom"
import FormValidate from "form-validate.js"
import {connect} from "react-redux"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
// Validation
import constraints from "validation/signIn"
// Inputs
import {TextInput} from "@uprise/form"
// Actions
import {loginUser, storeuserState} from "actions/loginActions"
// utils
import {checkActivityComplete} from "helpers/utils"

const WellbeingLogin = ({children, errors}) => {
	let history = useHistory()

	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [error, setError] = useState({type: "", value: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleLogin()
		}
	}

	const _handleLogin = () => {
		setIsLoading(true)

		loginUser(
			// Format email before sending to server
			email.toLowerCase().trim(),
			password
		)
			.then(response => {
				if (response.error) {
					_handleError(response)
				} else {
					storeuserState(response.user_state)

					const {activity} = response.user_state

					// Activity validation
					if (checkActivityComplete("onboarding", activity)) {
						history.push("/bot/wellbeingCheck")
					} else {
						history.push("onboarding")
					}
				}
			})
			.catch(err => {
				console.log(err)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const _handleError = resp => {
		if (resp.error) {
			let errors = {}

			switch (resp.error) {
				case "BadEmail":
					errors.email = {
						type: "error",
						text: "No account exists for that email address, please try another address"
					}

					break
				case "BadPassword":
					errors.password = {
						type: "error",
						text: "Incorrect username and/or password, please try again"
					}

					break
				case "Disabled":
					errors.disabled = {
						type: "error",
						text:
							"Your account has been disabled. For further support, please contact the Operations Team via team@uprise.co or call 1300 209 371"
					}
					break
				case "ValidationFail":
					switch (resp.errors[0].property) {
						case "instance.name":
							errors.name = {type: "error", text: "Please enter valid name"}
							break
						case "instance.password":
							errors.password = {type: "error", text: "Please enter a 10 character length password"}
							break
					}
					break
				default:
					errors.name = {type: "error", text: resp.error}
			}

			setError(Object.values(errors)[0])
			return errors
		}
	}

	return (
		<Fragment>
			<H3 className='m-b-10'>Check your Wellbeing in 1 minute</H3>
			<Card className='m-b-4' backgroundColor={backgrounds.white} shadow={true} padding={spacing.s5} width='100%'>
				{error?.text?.length > 0 && (
					<Alert className='m-b-5' type={error.type}>
						{error.text}
					</Alert>
				)}

				<form onChange={event => _validateForm(event)}>
					<TextInput
						onChange={e => setEmail(e.target.value)}
						validation={validation}
						value={email}
						validateControl='email'
						id='email'
						name='email'
						label='Email'
						isFirst
						isRequired
					/>
					<TextInput
						onChange={e => setPassword(e.target.value)}
						validation={validation}
						value={password}
						id='password'
						type='password'
						name='password'
						label='Password'
						isLast
						isRequired
					/>
				</form>
			</Card>
			<Button
				variant='primary'
				className='m-b-4'
				data-testid='sign-in-button'
				title='Check my score'
				size='large'
				fullWidth={true}
				isLoading={isLoading}
				disabled={false}
				onClick={() => _handleSubmit()}
			/>
			<Note>
				Don’t have an account? <NavLink to={`/wellbeing-sign-up`}>Sign up</NavLink>. Forgot your password?{" "}
				<NavLink to={`/reset`}>Reset</NavLink>.
			</Note>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
WellbeingLogin.propTypes = {}

WellbeingLogin.defaultProps = {}

const mapDispatchToProps = dispatch => ({
	storeuserState: state => dispatch(storeuserState(state))
})

export default connect(null, mapDispatchToProps)(WellbeingLogin)
