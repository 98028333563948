import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import {useHistory, useParams} from "react-router-dom"
import FormValidate from "form-validate.js"
import Styled from "styled-components"

// UI
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
// Validation
import constraints from "validation/EapSupport"
// Inputs
import {TextInput, Select} from "@uprise/form"
// Api
import {post} from "helpers/api"

const EapSupportStyles = Styled.section`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
 	}
`

const ImageWrap = Styled.section`
      display: flex;
      justify-content: center;
	align-items: center;
 `

const AccessSupportImage = Styled.img`
      height: 119px;
	width: 143px;
	
	@media (max-width: 1024px) {
 		height: 200px;
		width: 250px;
	}
`

export const EapSupportForm = () => {
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [firstName, setFirstName] = useState("")
	const [company, setCompany] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [therapyType, setTherapyType] = useState("")

	const [message, setMessage] = useState({type: "", value: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [success, setSuccess] = useState(false)

	const PLATFORM = "Web app"

	const THERAPY = [
		{label: "Phone", value: "Phone"},
		{label: "Video Call", value: "Video Call"},
		{label: "Face to Face", value: "Face to Face"}
	]

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleEapSupportForm()
		}
	}

	const _handleEapSupportForm = async () => {
		setIsLoading(true)

		try {
			setIsLoading(true)
			const GOOGLE_SHEET_SCRIPT_URL =
				"https://script.google.com/a/macros/uprise.co/s/AKfycbzSEtn-sqk4qTR4VvKVRkP-zmFQ2opwsOSsRL91-hZzx_4HLR_U/exec"
			const formData = new FormData()

			formData.append("First Name", firstName)
			formData.append("Last name", lastName)
			formData.append("Company name", company)
			formData.append("Email address", email)
			formData.append("I am looking for", therapyType)
			formData.append("Phone number", phone)
			formData.append("Submitted At", new Date().toUTCString())
			formData.append("From", PLATFORM)
			let response = await fetch(GOOGLE_SHEET_SCRIPT_URL, {
				method: "POST",
				body: formData
			})

			response = await response.json()

			if (response?.result === "success") {
				setSuccess(true)
				setMessage({
					type: "success",
					value: `Success: we will contact you within 24 hours`
				})
			}

			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setMessage({
				type: "error",
				value: "Sorry, something went wrong please contact tech support"
			})
			setIsLoading(false)
		}
	}

	return (
		<EapSupportStyles className='m-b-0'>
			<ImageWrap>
				<AccessSupportImage src={require(`assets/images/art/eap-support/eap-support@3x.png`)} />
			</ImageWrap>

			{message?.value?.length > 0 && (
				<Alert className='m-t-5 text-center' type={message.type}>
					{message.value}
				</Alert>
			)}
			{!success && (
				<Fragment className='m-t-5'>
					<form onChange={event => _validateForm(event)}>
						<TextInput
							onChange={e => setFirstName(e.target.value)}
							validation={validation}
							value={firstName}
							validateControl='firstName'
							id='firstName'
							name='firstName'
							label='First Name'
							isFirst
							isRequired
						/>
						<TextInput
							onChange={e => setLastName(e.target.value)}
							validation={validation}
							value={lastName}
							validateControl='lastName'
							id='lastName'
							name='lastName'
							label='Last Name'
							isRequired
						/>
						<TextInput
							onChange={e => setEmail(e.target.value)}
							validation={validation}
							value={email}
							id='email'
							name='email'
							label='Email Address'
							isRequired
						/>
						<TextInput
							onChange={e => setCompany(e.target.value)}
							validation={validation}
							value={company}
							id='company'
							name='company'
							label='Company Name'
							isRequired
						/>
						<TextInput
							onChange={e => setPhone(e.target.value)}
							validation={validation}
							value={phone}
							id='phone'
							type='text'
							name='phone'
							label='Phone number'
							isRequired
						/>
						<Select
							onChange={e => setTherapyType(e.target.value)}
							validation={validation}
							value={therapyType}
							id='therapyType'
							options={THERAPY}
							name='therapyType'
							label='Counselling Type'
							isRequired
						/>
					</form>
					<Button
						data-testid='sign-up-button'
						variant='primary'
						className=''
						title='Submit'
						size='large'
						fullWidth={true}
						isLoading={isLoading}
						disabled={false}
						onClick={() => _handleSubmit()}
					/>
				</Fragment>
			)}
		</EapSupportStyles>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
EapSupportForm.propTypes = {}

EapSupportForm.defaultProps = {}
