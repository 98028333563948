import React, {Fragment} from "react"
import Styled from "styled-components"

// import {bool, func, string} from "prop-types"
import {connect} from "react-redux"
import {H2, H3} from "@uprise/headings"
import {P, Bold, Medium} from "@uprise/text"
import {backgrounds, primary, secondary, extended} from "@uprise/colors"
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
import {text} from "@uprise/typography"
import {EmojiDonut} from "@uprise/charts"

const TopAlert = Styled.div`
	display: flex;
	flex-direction: row;
`

const BottomAlertText = Styled.div`
	display: flex;
	flex-direction: row;
`

const Score = Styled.div`
	display: flex;
	flex-direction: row;
 `

const ScoreBar = Styled.div`
	border-radius: 10px;
	background-color: ${extended.purple.five};
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	text-align: center;
`

const RangeWrap = Styled.div`
	border-radius: 10px;
	background-color: ${extended.purple.five};
	width: 100%;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	text-align: center;
`

const LowText = Styled.span`
	&:before {
 		left: 15px;
		top: -20px;
		font-size: ${text.t6};
		color: ${extended.purple.one};
		position: relative;
		${props => {
			return props.arrow ? `content: '⬇';` : `content: ' ';`
		}}
	}
`

const MiddleText = Styled.span`
	&:before {
		left: 15px;
		top: -20px;
		font-size: ${text.t6};
		color: ${extended.purple.one};
		position: relative;
		${props => {
			return props.arrow ? `content: '⬇';` : `content: ' ';`
		}}
	}
`

const HighText = Styled.span`
 

	&:before {
		left: 15px;
		top: -20px;
		font-size: ${text.t6};
		color: ${extended.purple.one};
		position: relative;
		${props => {
			return props.arrow ? `content: '⬇';` : `content: ' ';`
		}}
	}
`

const Low = Styled.div`
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color: ${extended.purple.four};
	color: ${backgrounds.white};
	flex-basis: 15%;
	text-align: center;
	font-size: ${text.t4};
`

const Middle = Styled.div`
	background-color: ${extended.purple.three};
	font-size: ${text.t4};
	color: ${backgrounds.white};
	flex-basis: 70%;
	text-align: center;
`

const High = Styled.div`
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: ${extended.purple.one};
	font-size: ${text.t4};
	color: ${backgrounds.white};
	flex-basis: 15%;
 	text-align: center;
`

const RangeScoresWrap = Styled.div`
	border-radius: 10px;
 	width: 100%;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	text-align: center;
`

const RangeLow = Styled.div`
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	color: ${extended.purple.one};
	flex-basis: 15%;
	text-align: center;
	font-size: ${text.t3};
`

const RangeMiddle = Styled.div`
 	font-size: ${text.t3};
	color: ${extended.purple.one};
	flex-basis: 70%;
	text-align: center;
`

const RangeHigh = Styled.div`
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
 	font-size: ${text.t3};
	color: ${extended.purple.one};
	flex-basis: 15%;
 	text-align: center;
`

const PhoneIcon = Styled.img`
	width: 28px;
	height: 28px;
`

const WellbeingScoreText = Styled(H3)`
	@media (max-width: 1200px) {
		font-size: ${text.t6}
	}
`

const RuokdayResultsRight = ({results}) => {
	let image
	const score = results?.who5?.score || 0

	if (score < 28) {
		image = require("assets/images/emojis/emoji-low-score-2x.png")
	} else if (score <= 50) {
		image = require("assets/images/emojis/emoji-mid-score-2x.png")
	} else {
		image = require("assets/images/emojis/emoji-high-score-2x.png")
	}

	const data = {
		datasets: [
			{
				data: [score, 100 - score],
				backgroundColor: [primary.purple, backgrounds.fadedPurple],
				borderColor: [primary.purple, backgrounds.fadedPurple],
				hoverBackgroundColor: [primary.purple, backgrounds.fadedPurple],
				borderWidth: 0,
				weight: 1
			}
		]
	}

	const options = {
		cutoutPercentage: 70,
		maintainAspectRatio: false,
		tooltips: {enabled: false}
	}

	return (
		<Fragment>
			<H2 className='m-b-3'>Your Wellbeing Score</H2>
			<P fontSize={text.t6} className='m-b-6'>
				We hope that taking this time to reflect was useful. Remember that wellbeing is subjective, and your
				Wellbeing Score may not accurately reflect how you perceive your own wellbeing.
			</P>
			<Card className='m-b-8' shadow={true} padding={spacing.s5} backgroundColor={backgrounds.white}>
				<Score className='m-b-5'>
					<EmojiDonut
						image={image}
						width={52}
						height={52}
						emojix={20}
						emojiy={14.5}
						emojiWidth={23}
						emojiHeight={23}
						data={data}
						options={options}
					/>
					<ScoreBar className='m-t-2 m-l-4'>
						<WellbeingScoreText>Wellbeing Score:</WellbeingScoreText>{" "}
						<WellbeingScoreText className='m-t-1 m-l-2' color={primary.purple}>
							{score}%
						</WellbeingScoreText>
					</ScoreBar>
				</Score>

				{results?.who5?.summary === "Low" && (
					<P className='m-b-6' color={primary.purple} textAlign='center' fontSize={text.t5}></P>
				)}

				{results?.who5?.summary === "Middle" && (
					<P className='m-b-6' color={primary.purple} textAlign='center' fontSize={text.t5}></P>
				)}

				{results?.who5?.summary === "High" && (
					<P className='m-b-6' color={primary.purple} textAlign='center' fontSize={text.t5}>
						You are doing better than 90% of people who took this test
					</P>
				)}

				<RangeWrap className='m-b-1'>
					<Low>
						<LowText arrow={results?.who5?.summary === "Low"}>10%</LowText>
					</Low>
					<Middle>
						<MiddleText arrow={results?.who5?.summary === "Middle"}>80%</MiddleText>
					</Middle>
					<High>
						<HighText arrow={results?.who5?.summary === "High"}>10%</HighText>
					</High>
				</RangeWrap>

				<RangeScoresWrap className='m-b-6'>
					<RangeLow>0-35 score</RangeLow>
					<RangeMiddle>35-60 score</RangeMiddle>
					<RangeHigh>60-100 score</RangeHigh>
				</RangeScoresWrap>

				<P fontSize={text.t5}>{results.who5.text}</P>
			</Card>

			<Card
				className='m-b-1'
				height='auto'
				border={`1px solid ${secondary.electricLightOrange}`}
				padding={spacing.s5}
				backgroundColor={extended.lightOrange.five}>
				<TopAlert className='m-b-1'>
					<PhoneIcon className='m-r-4' src={require("assets/images/icons/png/telephone/telephone.png")} />
					<Medium>
						If you are having a difficult time or feeling low, you are not alone. Contact Lifeline at{" "}
						<Bold color={primary.charcoal}>13 11 14</Bold>
					</Medium>
				</TopAlert>
				<BottomAlertText></BottomAlertText>
			</Card>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
RuokdayResultsRight.propTypes = {}

RuokdayResultsRight.defaultProps = {}

export default connect(null, null)(RuokdayResultsRight)
