import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
// Colors
import {backgrounds, primary} from "@uprise/colors"
import {H3, H5} from "@uprise/headings"
// spacing
import {spacing} from "@uprise/spacing"
import {Medium} from "@uprise/text"
import {EapNumber} from "components/Shared/AccessSupport/EapNumber"
import {EmergencyNumbers} from "components/Shared/AccessSupport/EmergencyNumbers"
import {RequestCallBack} from "components/Shared/AccessSupport/RequestCallBack"
import {isCoachingEnabled, isTherapyEnabled} from "helpers/utils"
import React, {useState} from "react"
import Styled from "styled-components"

const AccessSupportStyles = Styled.section`
	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
 	}
`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: row;

	@media (max-width: 1024px) {
		flex-direction: column;
		text-align: center;
	}
`

const ImageWrap = Styled.section`
      display: flex;
      justify-content: center;
	align-items: center;
	padding: 30px 40px 30px 30px;

	@media (max-width: 475px) {
		padding: 0 40px 0 30px;
	}
`

const ButtonWrap = Styled.section`
      display: flex;
	flex-direction: row;
	flex-wrap: wrap;
      margin-top: -${spacing.s6};
      justify-content: space-between;

	@media (max-width: 1024px) {
		text-align: center;
		justify-content: center;
	}
`

const ButtonLeftWrap = Styled.section`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

	& button {
		width: 185px;
	}

	@media (max-width: 1024px) {
		text-align: center;
		justify-content: center;
	}

	@media (max-width: 475px) {
		width: 100%;
		& button {
			width: 100%;
		}
	}
`

const ButtonRightWrap = Styled.section`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
	flex-wrap: wrap;
	
			& button {
				width: 170px;
			}
			& button:nth-child(2) {
				width: 190px;
			}

	@media (max-width: 1024px) {
 		text-align: center;
		justify-content: center;
	}

	@media (max-width: 475px) {
		width: 100%;
		flex-direction: column;
		& button {
			width: 100%;
		}
		& button:nth-child(2) {
			width: 100%;
		}
	}
`

const AccessSupportImage = Styled.img`
      height: 119px;
	width: 143px;

	@media (max-width: 1024px) {
		margin-top: 20px;
		height: 200px;
		width: 243px;
	}

	@media (max-width: 475px) {
		margin-top: 30px;
		height: 119px;
		width: 143px;
	}
`

const ContentRight = Styled.div`
      padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} 0;
	width: 100%;

	@media (max-width: 1024px) {
		padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} ${spacing.s7};
	}
`

export const AccessSupport = ({className, handleBookTherapy, features}) => {
	const [showEapNumber, setShowEapNumber] = useState(false)
	const [showRequestCall, setShowRequestCall] = useState(false)
	const [showEmergencyNumbers, setShowEmergencyNumbers] = useState(false)

	return (
		<AccessSupportStyles className={className}>
			<H3 className='m-b-4'>Access Support Now</H3>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<AccessSupportImage src={require(`assets/images/art/eap-support/eap-support@3x.png`)} />
					</ImageWrap>
					<ContentRight>
						<H5 color={primary.charcoal} className='m-b-3 m-t-0 m-b-sm-4 m-t-sm-4'>
							We’re always here to help
						</H5>

						<Medium className='m-b-6'>
						Call us on 0800 461 271 (or +61 2 8294 9546 from outside of New Zealand) to speak with the Uprise team or request a call back. If this is an emergency, use the emergency numbers below.
						</Medium>

						<ButtonWrap>
							<ButtonLeftWrap>
								<Button
									className='m-r-3 m-t-4'
									padding='0'
									title='Emergency numbers'
									variant='text'
									icon={true}
									size='medium'
									fullWidth={false}
									width='auto'
									onClick={() => setShowEmergencyNumbers(true)}
								/>
							</ButtonLeftWrap>
							<EmergencyNumbers
								isOpen={showEmergencyNumbers}
								handleClose={() => setShowEmergencyNumbers(false)}
							/>
							<ButtonRightWrap>
								<Button
									className='m-r-3 m-t-4'
									title='Request a call back'
									variant='primary'
									size='medium'
									fullWidth={false}
									onClick={() => setShowRequestCall(true)}
								/>
								{(isTherapyEnabled(features) || isCoachingEnabled(features)) && (
									<Button
										className='m-r-3 m-t-4'
										title='Book a call'
										variant='primary'
										size='medium'
										fullWidth={false}
										onClick={handleBookTherapy}
									/>
								)}
								<EapNumber isOpen={showEapNumber} handleClose={() => setShowEapNumber(false)} />
								<RequestCallBack
									isOpen={showRequestCall}
									handleClose={() => setShowRequestCall(false)}
								/>
								<Button
									className='m-t-4'
									title='Call Us'
									variant='secondary'
									size='medium'
									fullWidth={false}
									onClick={() => setShowEapNumber(true)}
								/>
							</ButtonRightWrap>
						</ButtonWrap>
					</ContentRight>
				</ContentWrap>
			</Card>
		</AccessSupportStyles>
	)
}
