import React, {Fragment} from "react"
import Styled from "styled-components"
import {OptionButton} from "components/Rtw/Buttons"
// spacing
import {spacing} from "@uprise/spacing"

const Row = Styled.div`
	padding: ${spacing.s5} ${spacing.s5} 0rem;
`

const MultiChoice = ({options, valid, handleChange, value, id, name, questionIndex}) => {
	const onClick = value => {
		handleChange(value, id, questionIndex)
	}

	const optionsArray = options.map((option, index) => {
		return (
			<div className='col-md-6 mb-3' key={index}>
				<OptionButton
					type='radio'
					name={name}
					className=' mt-0'
					index={index}
					onClick={() => onClick(index)}
					label={option.toString()}
					checked={index === value}
				/>
			</div>
		)
	})

	return (
		<Fragment>
			<div
				className={`btn-list btn-inputs h-auto mt-4 form-control ${
					valid ? (valid.valid ? "is-valid" : "is-invalid") : ""
				} `}>
				<Row className='row'>{optionsArray}</Row>
			</div>
			{valid && valid.message && <span className='invalid-feedback'>{valid.message}</span>}
		</Fragment>
	)
}

export default MultiChoice
