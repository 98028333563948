import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {extended} from "@uprise/colors"

const Table = Styled.table`
  width: 100%;
  padding: ${spacing.s3} ${spacing.s7};
`

const Tr = Styled.tr`
  &:last-child td {
    border-bottom: 0px solid ${extended.purple.five} !important;
  }
`
const Td = Styled.td`
  border-bottom: 1px solid ${extended.purple.five};
  height: 50px
  text-align: ${props => (props.alignRight ? "right" : "left")}
`

export {Table, Tr, Td}
