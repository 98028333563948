import React, {Fragment} from "react"
import {getFirstName} from "helpers/utils"
// Components
import {Button} from "@uprise/button"
import {P, Medium} from "@uprise/text"
import {H2} from "@uprise/headings"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"

import BookingYourCoach from "./BookingYourCoach"

const BookingCoachIntro = ({handleNext, coach, location, courseType}) => {
	if (!coach || !coach.name) {
		return <P>Sorry! There is some problem fetching your coach. Please contact team@uprise.co</P>
	}
	return (
		<Fragment>
			<div className='your-coach mt-3'>
				<div className='row'>
					<div className='col-md-12'>
						<H2 className='onboarding__title mb-1'>Meet your coach {coach.name}</H2>
						<P className='mb-5'>
							{getFirstName(coach.name)} will call you soon to setup your first coaching session.
						</P>

						<BookingYourCoach coach={coach} />
					</div>
				</div>
				<div className='row'>
					<div className='col-sm-6'>
						<Button
							className='mt-3 px-4'
							type='primary'
							onClick={handleNext}
							title='Begin course'
							fullWidth={false}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default BookingCoachIntro
