import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
// Icons
import Icons from "constants/Icons"

const ErrorImage = Styled.img`
      height: 164xwpx;
      width: 234px;
`

const Date = Styled.span`
`

const DateBar = Styled.div`
	padding: 9px 0;
	width: 420px;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

export const ErrorModal = ({isOpen, handleClose, data, ...props}) => {
	let history = useHistory()

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H4 className='m-b-5'>Our chat widget is being blocked by your browser ad-blocker</H4>

			<ErrorImage className='m-b-7' src={require('assets/images/art/error-modal/error-modal.svg')} />

			<Medium className='m-b-5'>
				There are no ads at Uprise, but our chat widget runs on JavaScript which is restricted by your ad-blocker. To start a chat, please disable your ad blocker and try again.
			</Medium>

			<Button variant='primary' size='medium' title='Close' onClick={() => handleClose()} />
		</Modal>
	)
}
