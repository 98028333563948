import * as surveys from "uprise-surveys/lib/surveys/index"

const RTW_SURVEY_LABELS = {
	PHQ9: "Page 1",
	GAD7: "Page 2",
	PSS4RTW: "Page 3",
	WHO5RTW: "Page 4",
	WRKBTY: "Page 5",
	SICK: "Page 6",
	ECOVAL: "Page 7",
	CSQ8: "Page 8",
	RTW_FEEDBACK: "Page 9"
}

const RTW_3_MONTH_SURVEY_LABELS = {
	PHQ9: "Page 1",
	GAD7: "Page 2",
	PSS4RTW: "Page 3",
	WHO5RTW: "Page 4",
	WRKBTY: "Page 5",
	SICK: "Page 6",
	ECOVAL: "Page 7"
}

export const postProgramTask = {
	surveyId: "progress-rtw-v1.2",
	taskId: "survey-postprogram-rtw",
	recurring: false
}

export const post3MonthTask = {
	surveyId: "progress-rtw-3-month-v1",
	taskId: "survey-postprogram-3-month-rtw",
	recurring: false
}

export function getScreeners(userState, ctx) {
	// const task = userState.modules.onboarding.tasks.assesment.tasks["survey-preprogram"]
	const task = ctx.isThreeMonthSurvey ? post3MonthTask : postProgramTask
	return surveys.retrieveSurvey(task.surveyId).questions.reduce((screeners, question) => {
		if (screeners.indexOf(question.batteryId) === -1) {
			screeners.push(question.batteryId)
		}
		return screeners
	}, [])
}

/**
 *
 * @param {Object} userState | User state from server response
 * @returns {Array} itemsList
 */
export function getRTWItemsList(userState, ctx) {
	const firstItem = {title: "Post Program Overview", header: "Welcome to Post program survey", active: true}
	const lastItem = {title: "All Done", header: "🎉 Congratulation! All done.", active: false}
	let screeners = []

	if (userState) {
		screeners = getScreeners(userState, ctx).map(i => {
			return {
				id: i,
				title: ctx.isThreeMonthSurvey ? RTW_3_MONTH_SURVEY_LABELS[i] : RTW_SURVEY_LABELS[i],
				header: ctx.isThreeMonthSurvey ? `Post 3-month Assessment` : `Post-program Assessment`,
				active: false
			}
		})
	}
	return [firstItem, ...screeners, lastItem]
}
