const constraints = {
	email: {
		presence: {
			message: "^Please enter an email address"
		},
		email: {
			message: "^Please enter a valid email address",
			allowEmpty: false
		}
	},
	accessCode: {
		presence: {
			message: "^Please enter an access code",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^access code must be at least 1 characters"
		}
	},
	name: {
		presence: {
			message: "^Please enter a name",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^Your name must be at least 1 characters"
		}
	},
	phone: {
		presence: {
			message: "^Please enter a phone number",
			allowEmpty: false
		},
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 3,
			message: "Must be at least 3 digits in length"
		}
	},
	password: {
		presence: {
			message: "^Please enter a password",
			allowEmpty: false
		},
		length: {
			minimum: 10,
			message: "^Your password must be at least 10 characters"
		},
		format: {
			pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/, //eslint-disable-line
			flags: "i",
			message: "^Your password must contain a minimum of 10 characters included with at least 1 upper case letter, 1 lower case letter, 1 number and 1 special character from #,?,!,@,$,%,^,&,*, and - (other special characters are not supported)."
		}
	}
}

export default constraints
