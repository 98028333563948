import React, {useState} from "react"
import {func} from "prop-types"
import Styled from "styled-components"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {Button} from "@uprise/button"
import {H3} from "@uprise/headings"
import {Row, Col} from "@uprise/grid"
import {P} from "@uprise/text"

const Skill = Styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

`

const SkillsImage = Styled.img`
      margin-top: 50px;
      margin-bottom: 50px;
      width: 244px;
      height: 180px;
`

export const OnboardingIntro = ({children, handleNext}) => {
	const [isLoading, setIsLoading] = useState(false)

	return (
		<Card
			border={`1px solid ${extended.purple.five}`}
			shadow={false}
			backgroundColor={backgrounds.white}
			padding={spacing.s5}
			width='100%'>
			<Row>
				<Col className='col-lg-4 col-md-12'>
					<Skill>
						<SkillsImage src={require(`assets/images/art/learn-skills@3x.png`)} />
						<H3 className='m-b-3' textAlign='center'>
							Learn Skills
						</H3>
						<P textAlign='center' width='260px'>
							Skills help you cope better with everyday problems
						</P>
					</Skill>
				</Col>

				<Col className='col-lg-4 col-md-12'>
					<Skill>
						<SkillsImage src={require(`assets/images/art/practice-skills@3x.png`)} />
						<H3 className='m-b-3' textAlign='center'>
							Practice Skills
						</H3>
						<P textAlign='center' width='260px'>
							Chat and audio exercises will help you apply these skills to your life problems
						</P>
					</Skill>
				</Col>

				<Col className='col-lg-4 col-md-12'>
					<Skill>
						<SkillsImage src={require(`assets/images/art/talk-to-a-coach@3x.png`)} />
						<H3 className='m-b-3' textAlign='center'>
							Talk to a Coach (optional)
						</H3>
						<P textAlign='center' width='260px'>
							An Uprise coach is always available if you are stressed out or need someone to talk to
						</P>
					</Skill>
				</Col>
			</Row>
			<Row className='justify-content-center align-items-center'>
				<Button
					data-testid='get-started-button'
					className='m-t-10 m-b-7'
					variant='primary'
					title='Get Started'
					size='large'
					fullWidth={false}
					width='360px'
					isLoading={isLoading}
					disabled={false}
					onClick={() => handleNext(1)}
				/>
			</Row>
		</Card>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
OnboardingIntro.propTypes = {
	handleNext: func.isRequired
}

OnboardingIntro.defaultProps = {}
