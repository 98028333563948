const constraints = {
	others: {
		presence: {
			message: "^Please enter a custom topic",
			allowEmpty: false
		},
		length: {
			minimum: 3,
			message: "^Topic must be at least 3 characters"
		}
	}
}

export default constraints
