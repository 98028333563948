import React from "react"
import {HorizontalBar} from "react-chartjs-2"
import styled from "styled-components"
import {primary, secondary} from "@uprise/colors"

const Wrapper = styled.div`
	height: 400px;
	width: min(100%, 700px);
	margin: 15px auto 0;
`

const options = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true
				}
			}
		]
	},

	legend: {
		// display: false,
		position: "bottom",

		labels: {
			padding: 40,
			fontSize: 20,
			fontColor: "#000"
		}
	}
}

function GroupedBarChart({
	data = {
		labels: ["1", "2", "3", "4", "5", "6"],
		datasets: [
			{
				label: "Wellbeing Levels",
				data: [12, 19, 3, 5, 2, 3],
				backgroundColor: primary.purple
			},
			{
				label: "Industry Wellbeing Levels",
				data: [2, 3, 20, 5, 1, 4],
				backgroundColor: secondary.electricLightOrange
			}
		]
	}
}) {
	return (
		<Wrapper>
			<HorizontalBar data={data} options={options} height={400} width={700} />
		</Wrapper>
	)
}

export default GroupedBarChart
