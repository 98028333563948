import React from "react"
import {object, array} from "prop-types"
import Styled from "styled-components"
import {connect} from "react-redux"
import {spacing} from "@uprise/spacing"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {ContainerFluid, Container, Col, Row} from "@uprise/grid"
import {Main} from "components/Shared/Panels"
import PreOnboardingForm from "components/Shared/PreOnboarding"

const Header = Styled.section`
	width: 100%;
	height: 100px;
	padding: ${spacing.s5}
`

const UpriseLogo = Styled.div`
	width: 106px;
	height: 30px;
	background-image: url(${require(`assets/images/logos/logo-blue.svg`)});
	background-repeat: no-repeat;
`

const PreOnboarding = ({userState}) => {
	return (
		<ContainerFluid height='100vh' backgroundColor={extended.purple.six}>
			<Container>
				<Row className='flex-nowrap h-100'>
					<Header>
						<UpriseLogo />
					</Header>
				</Row>
				<Row>
					<Main className='h-100'>
						<PreOnboardingForm userState={userState} />
					</Main>
				</Row>
			</Container>
		</ContainerFluid>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(PreOnboarding)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
PreOnboarding.propTypes = {
	userState: object.isRequired,
	activity: array
}

PreOnboarding.defaultProps = {}
