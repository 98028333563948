import React, {Fragment, useState, useEffect} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
// Actions
import {assignCoach, getCoachByEmail} from "actions/coachActions"
// Content
import OnboardingOverview from "components/Rtw/Onboarding/OnboardingOverview"
import OnboardingAssessment from "components/Rtw/Onboarding/OnboardingAssesment"
import OnboardingBooking from "components/Rtw/Onboarding/OnboardingBooking"
// UI
import {H5} from "@uprise/headings"

function checkProgress(surveyResults) {
	let screener = "RTWDEMOG"
	if (surveyResults) {
		for (const result of surveyResults) {
			if (result.taskId === "survey-onboarding-rtw") {
				if (!result.complete) {
					const keys = Object.keys(result.answers)
					screener = keys[keys.length - 1]
				}
			}
		}
	}
	return screener
}

function OnboardingRTWSteps({
	progressCheckpoints,
	handleNext,
	handleComplete,
	userState,
	activeIndex,
	getCoachByEmail,
	coach,
	assignCoach
}) {
	const [activeScreener, setActiveScreener] = useState(checkProgress(userState.surveyResults))

	// Fetch RTW coach
	useEffect(() => {
		if (userState.coach === undefined) {
			assignCoach(userState.timezone)
		}

		if (userState.coach && userState.coach !== coach.email) {
			getCoachByEmail(userState.coach)
		}
	}, [userState.coach])

	useEffect(() => {
		progressCheckpoints.forEach((checkpoint, index) => {
			if (index === activeIndex && index > 0 && index < progressCheckpoints.length - 1) {
				console.log("checkpoint", checkpoint)
				setActiveScreener(checkpoint.id)
			}
		})
	}, [activeIndex, progressCheckpoints])

	const introCheckpoint = progressCheckpoints[0]
	const bookingCheckpoint = progressCheckpoints[progressCheckpoints.length - 1]

	const isSurveyActive = activeIndex > 0 && activeIndex < progressCheckpoints.length - 1
	const nextCheckpoint = progressCheckpoints.findIndex(c => c.id === activeScreener)

	return (
		<Fragment>
			{introCheckpoint && introCheckpoint["active"] && (
				<OnboardingOverview handleNext={() => handleNext(nextCheckpoint)} />
			)}

			{isSurveyActive && (
				<OnboardingAssessment
					userState={userState}
					handleChange={delta => handleNext(activeIndex + delta)}
					activeScreener={activeScreener}
				/>
			)}

			{bookingCheckpoint && bookingCheckpoint["active"] && (
				<OnboardingBooking
					selectedCoach={coach}
					dialogueId='onboarding'
					surveyResults={userState.surveyResults}
					handleNext={handleComplete}
				/>
			)}
		</Fragment>
	)
}

OnboardingRTWSteps.propTypes = {
	activeIndex: PropTypes.number.isRequired,
	handleComplete: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	progressCheckpoints: PropTypes.array.isRequired,
	userState: PropTypes.object.isRequired
}

export default connect(state => ({coach: state.coach}), {assignCoach, getCoachByEmail})(OnboardingRTWSteps)
