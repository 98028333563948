import React, {useState, useEffect, Fragment} from "react"
import Styled, {keyframes} from "styled-components"
import "rc-slider/assets/index.css"
import Slider from "rc-slider"
import "styles/slider.css"

// colors
import {extended, primary} from "@uprise/colors"
import {isWhiteLabeledApp} from "helpers/utils"

const SliderWrap = Styled.section`
	padding: 30px 40px 40px 20px;
      width: 100%;
`

const SliderInput = ({options, type, handleValue}) => {
	const [value, setValue] = useState(3)

	const marks = {}

	if (type === "percentage") {
		for (let x = 0; x <= 100; x += 10) {
			options[x] = {label: `${x}%`}
		}
		options.forEach((option, index) => {
			marks[index] = {label: option.label, style: {color: primary.purple, marginRight: "5px"}}
		})
	} else {
		options.forEach((option, index) => {
			marks[index] = {label: option.label, style: {color: primary.purple}}
		})
	}

	const _handleValue = value => {
		setValue(value)
		handleValue(value)
	}

	return (
		<SliderWrap>
			<Slider
				min={0}
				max={options.length - 1}
				trackStyle={[{backgroundColor: primary.purple, height: 7}]}
				railStyle={{backgroundColor: isWhiteLabeledApp() ? extended.purple.one : extended.purple, height: 7}}
				dotStyle={{display: "none"}}
				handleStyle={{borderColor: primary.purple, backgroundColor: primary.purple}}
				value={value}
				marks={marks}
				onChange={value => _handleValue(value)}
				step={1}
			/>
		</SliderWrap>
	)
}

export default SliderInput
