import React from "react"
import Picker from "react-month-picker"
import {MonthBox, PickerStyles} from "./index.style"

function MonthRangePicker({value = {from: {year: 2020, month: 11}, to: {year: 2021, month: 2}}, onChange}) {
	const rangePicker = React.useRef()

	const [rangeValue, setRangeValue] = React.useState(value)

	React.useEffect(() => {
		setRangeValue(value)
	}, [value])

	const pickerLang = {
		months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		from: "From",
		to: "To"
	}

	const makeText = m => {
		if (m && m.year && m.month) return pickerLang.months[m.month - 1] + ". " + m.year
		return "?"
	}

	const _handleClickRangeBox2 = e => {
		rangePicker.current.show()
	}

	const handleRangeChange2 = (value, text, listIndex) => {
		console.log({value, text, listIndex})

		let newValue
		if (listIndex === 0) {
			newValue = {...rangeValue, from: {year: value, month: text}}
		} else {
			newValue = {...rangeValue, to: {year: value, month: text}}
		}

		// if (onChange) {
		// 	onChange(newValue)
		// }
		setRangeValue(newValue)
	}
	const handleRangeDissmis2 = value => {
		setRangeValue(value)
		if (onChange) {
			onChange(rangeValue)
		}
	}

	return (
		<PickerStyles>
			<Picker
				ref={rangePicker}
				years={{
					min: {year: new Date().getFullYear() - 5},
					max: {year: new Date().getFullYear(), month: new Date().getMonth()}
				}}
				value={rangeValue}
				lang={pickerLang}
				onChange={handleRangeChange2}
				onDismiss={handleRangeDissmis2}>
				<MonthBox className='box' onClick={_handleClickRangeBox2}>
					<label>{makeText(rangeValue.from) + " ~ " + makeText(rangeValue.to)}</label>
				</MonthBox>
			</Picker>
		</PickerStyles>
	)
}

export default MonthRangePicker
