import React, {Fragment, useState} from "react"
import Styled from "styled-components"
import RadioOption from "./RadioOption"
import Text from "./Text"

// spacing
import {spacing} from "@uprise/spacing"

const Row = Styled.div`
	padding: ${spacing.s5} ${spacing.s5} 0rem;
`

const InputsWrap = Styled.div`
	display: flex;
	flex-direction: row;
`

const OptionWrap = Styled.div` 
	display: flex;
	flex-direction: column;
	width: 100%;
`

const TextInputWrap = Styled.div` 
	display: flex;
	flex-direction: row;
`

const TextWrap = Styled.div` 
	max-width: 100px;
	max-height: 50px;

`

const RadioOptionWithInputsWrapper = Styled.div`
	display: flex;
	flex-wrap: wrap;

 `

const ValidationMessage = Styled.span`
	display: block !important;
`

const RadioOptionWithInputs = ({options, valid, handleChange, value, id, name, questionIndex}) => {
	const [optionInputs, setOptionsInputs] = useState({})
	const [selectedOption, setSelectedOption] = useState()

	useState(() => {
		const initOptionInputs = optionInputs

		options.forEach((option, optionIndex) => {
			if (option?.inputs) {
				option.inputs.forEach((input, index) => {
					if (initOptionInputs[optionIndex]) {
						initOptionInputs[optionIndex][`input${index}`] = 0
					} else {
						initOptionInputs[optionIndex] = {}
						initOptionInputs[optionIndex][`input${index}`] = 0
					}
				})
			}
		})

		setOptionsInputs(initOptionInputs)
	}, [])

	const _handleInputChange = (value, optionIndex, index) => {
		const newOptionInputs = optionInputs

		newOptionInputs[optionIndex][`input${index}`] = value

		setOptionsInputs[newOptionInputs]

		if (selectedOption === optionIndex) {
			const answer = {
				value: selectedOption,
				...newOptionInputs[optionIndex]
			}

			handleChange(answer, id, questionIndex)
		}
	}

	const _handleClick = value => {
		setSelectedOption(value)

		let answer = {
			value
		}

		if (optionInputs[value]) {
			answer = {...answer, ...optionInputs[value]}
		}

		handleChange(answer, id, questionIndex)
	}

	const optionsArray = options.map((option, optionIndex) => {
		return (
			<OptionWrap key={optionIndex} className='col-12'>
				<RadioOption
					type='radio'
					className='mb-3'
					name={name}
					index={optionIndex}
					onClick={value => _handleClick(value)}
					value={value?.value}
					label={option.label.toString()}
				/>

				{option?.inputs && (
					<TextInputWrap className='m-b-4'>
						{option?.inputs?.map((input, index) => {
							return (
								<InputsWrap className='mr-5' key={index}>
									<TextWrap>
										<Text
											label={input}
											placeholder=' '
											value={optionInputs[optionIndex][`input${index}`]}
											handleChange={value => _handleInputChange(value, optionIndex, index)}
										/>
									</TextWrap>

									<span className=''>{input}</span>
								</InputsWrap>
							)
						})}
					</TextInputWrap>
				)}
			</OptionWrap>
		)
	})

	return (
		<Fragment>
			<RadioOptionWithInputsWrapper
				className={`btn-list btn-inputs h-auto form-control mt-2 ${
					valid ? (valid.valid ? "is-valid" : "is-invalid") : ""
				}`}>
				<Row className='row'>{optionsArray}</Row>
			</RadioOptionWithInputsWrapper>
			{valid && valid.message && (
				<ValidationMessage className='invalid-feedback'>{valid.message}</ValidationMessage>
			)}
		</Fragment>
	)
}

export default RadioOptionWithInputs
