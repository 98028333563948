// Types
import {STORE_TASK} from "./types"
// Actions
import {setSkill} from "actions/skillActions"
import {updateUserState} from "actions/userStateActions"

export const completeTask = (userState, skillId) => {
	return dispatch => {
		dispatch(setSkill(userState, skillId))
		dispatch(updateUserState(userState))
	}
}

export const setTask = (task, taskId) => {
	return dispatch => {
		if (task) {
			task.taskId = taskId
		}

		dispatch(storeTask(task))
	}
}

export const storeTask = task => {
	return {
		type: STORE_TASK,
		task: task
	}
}
