export const retrainingThinkingBeliefs = [
	{
		_id: 0,
		text: "Welcome to retraining thinking for beliefs",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "This exercise is easier if you’ve already watched the video about the skill.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 4,
			optionType: "radio",
			replyType: "survey",
			questions: [0],
			recurring: true,
			surveyId: "retraining-thinking-beliefs-v1",
			taskId: "exercise-retraining-thinking-beliefs",
			values: [
				{
					label: "Show me the video that explains Beliefs",
					videoURI:
						"https://player.vimeo.com/external/218729127.sd.mp4?s=e54125fc9c694aaa0352ee3a3a8e35a4822d555f&profile_id=165",
					vimeoId: "218729127",
					value: "video",
					taskId: "video-retraining",
					replyType: "survey",
					quickReplies: {
						_id: 3,
						_next: 4,
						optionType: "radio",
						replyType: "survey",
						questions: [0],
						recurring: true,
						surveyId: "retraining-thinking-beliefs-v1",
						taskId: "exercise-retraining-thinking-beliefs",
						values: [
							{
								label: "Thanks, i've watched the video",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve already watched it. Let’s get started.",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "Now, let’s look for the belief that is underlying the thought.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_next: 6,
		text: "I’ll ask you the same question until you get to a belief.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text:
			"You’ll know it’s a belief if you start to feel some emotion about it or if it feels true to you and you can’t go any further with the question.",
		createdAt: new Date(),
		replyType: "dialogue",
		questions: [1, 2, 3, 4, 5],
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			replyType: "survey",
			questions: [1, 2, 3, 4, 5],
			recurring: true,
			surveyId: "retraining-thinking-beliefs-v1",
			taskId: "exercise-retraining-thinking-beliefs",
			values: [
				{
					label: "👍 ok",
					value: "start",
					questionIndex: 1,
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text: "Thanks for doing that with me.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		text: "The next step would be to retrain the belief.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text: "I’d recommend doing one of the retraining thinking exercises next using this belief.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_next: 12,
		text: "The goal of this exercise was to identify a core belief.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		_next: 13,
		text:
			"Often unhelpful thoughts keep reappearing until we identify the core beliefs that are driving them and retrain them.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		_end: true,
		text: "👋 See you next time, I'll take you back.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
