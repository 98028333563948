import {STORE_BOOKINGS, LOADING_BOOKINGS, STORE_DURATION} from "actions/types"

const INITIAL_STATE = {loading: false, data: [], duration: 30}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case LOADING_BOOKINGS: {
			return {...state, ...action.payload}
		}
		case STORE_BOOKINGS: {
			return {...state, ...action.bookings}
		}
		case STORE_DURATION: {
			return {...state, ...action.payload}
		}
		default:
			return state
	}
}
