import React from "react"
import {array} from "prop-types"
import Styled from "styled-components"
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Accordian} from "@uprise/accordian"
import {backgrounds} from "@uprise/colors"

const FaqsStyle = Styled.section`
      width: 100%;
      height: 100%;
`

const Content = Styled.section`
	width: 100%;
	padding: 25px;
`

export const FaqsTask = ({className, faqs}) => {
	const content = faqs.map((faq, index) => {
		return {header: faq.q, content: faq.a}
	})

	return (
		<FaqsStyle>
			<H3 className='m-b-4'>FAQs</H3>
			<Card backgroundColor={backgrounds.white}>
				<Content>
					<Accordian content={content} />{" "}
				</Content>
			</Card>
		</FaqsStyle>
	)
}

FaqsTask.propTypes = {
	faqs: array.isRequired
}
