export const coronaBeliefsAboutHealthAnxietyChecking = [
	{
		_id: 0,
		text:
			"Repeatedly focusing on whether you have symptoms or constantly gathering information about a disease or illness online is a normal part of health anxiety but it usually makes you feel worse over time",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"So if you are feeling ready to stop or reduce checking but need some help then I can show you how to deal with the thoughts that drive your checking",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			surveyId: "survey-corona-beliefs-about-health-anxiety-checking-v1",
			taskId: "exercise-corona-beliefs-about-health-anxiety-checking",
			questions: [0, 1, 2],
			recurring: true,
			values: [
				{
					label: "👍",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text:
			"Ok, now let’s see if I can help you to retrain your thoughts about the subconscious goal that’s coming from health anxiety…",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			surveyId: "survey-corona-beliefs-about-health-anxiety-checking-v1",
			taskId: "exercise-corona-beliefs-about-health-anxiety-checking",
			questions: [3, 4],
			recurring: true,
			values: [
				{
					label: "Sounds good",
					value: "okay",
					questionIndex: 3,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text: "Now let’s look at the negative consequences of checking",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			surveyId: "survey-corona-beliefs-about-health-anxiety-checking-v1",
			taskId: "exercise-corona-beliefs-about-health-anxiety-checking",
			questions: [5, 6, 7, 8],
			recurring: true,
			values: [
				{
					label: "Ok",
					value: "okay",
					questionIndex: 5,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		text:
			"Thanks for doing the exercise with me. The goal was to help you to be more aware of the beliefs that are driving you to do something that might not be helping you much.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_end: true,
		text: "Taking you back to the skill dashboard.. 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
