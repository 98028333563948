import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// Colors
import {backgrounds} from "@uprise/colors"
import {Container, ContainerFluid, Row} from "@uprise/grid"

export const Wrap = styled.section`
	width: calc(100vw - 310px - 250px);

	@media (max-width: 1200px) {
		width: calc(100vw - 250px - 250px);
	}

	@media (max-width: 1024px) {
		width: calc(100vw - 250px - 80px);
	}

	@media (max-width: 991px) {
		width: calc(100vw - 80px);
	}

	@media (max-width: 475px) {
		width: 100vw;
	}
`

export const Layout = ({children, backgroundColor, containerClassName, rowClassName}) => {
	return (
		<ContainerFluid height='100vh' backgroundColor={backgroundColor}>
			<Row className={rowClassName}>{children}</Row>
		</ContainerFluid>
	)
}

Layout.propTypes = {
	backgroundColor: PropTypes.string,
	containerClassName: PropTypes.string,
	rowClassName: PropTypes.string
}

Layout.defaultProps = {
	backgroundColor: backgrounds.fadedPurple,
	rowClassName: "flex-nowrap"
}
