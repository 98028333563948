import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {text} from "@uprise/typography"
import {backgrounds, primary, secondary, extended} from "@uprise/colors"
import {P} from "@uprise/text"
import {H5, H6} from "@uprise/headings"

const BlogPostContainer = Styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, auto);

	@media (max-width: 1024px) {
    grid-template-columns: repeat(1, auto);
	}
`

const BlogPost = Styled.div`
  display: flex;
  padding-bottom: ${props => (props.addBorder !== true ? 0 : spacing.s5)};

  border-bottom: ${props => (props.addBorder === true ? `1px solid ${extended.charcoal.five}` : `none`)};
`

const ImageWrapper = Styled.div``

const BlogPostImageContainer = Styled.div`
  width: 185px;
  height: 104px;
`
const BlogPostImage = Styled.img`
  padding-right: ${spacing.s5};

  border-radius: 5px;
`

const BlogPostDesc = Styled.div`
  * {
    width: 100%;
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const BlogHeading = Styled.div`
  color: ${primary.charcoal} !important;
  margin: 0 !important;
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: 600 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`

const BlogDate = Styled.div`
  color: ${extended.charcoal.one} !important;
  margin: 0 !important;
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
`

export {
	BlogPost,
	ImageWrapper,
	BlogPostImage,
	BlogPostDesc,
	BlogPostContainer,
	BlogPostImageContainer,
	BlogHeading,
	BlogDate
}
