/* Combine all available reducers to a single root reducer.
 */
import {combineReducers} from "redux"

import userStateReducer from "./userState"
import surveyStateReducer from "./surveyState"
import skillStateReducer from "./skillState"
import taskStateReducer from "./taskState"
import coachReducer from "./coachState"
import coachesReducer from "./coachesState"
import calendarReducer from "./calendarState"
import codeReducer from "./codeState"
import capReducer from "./capState"
import suggestedReducer from "./suggestedState"
import bookingsReducer from "./bookingsState"
import hrdashboardReducer from "./hrdashboardState"
import topicsReducer from "./topicsState"

export default history =>
	combineReducers({
		userState: userStateReducer,
		skill: skillStateReducer,
		task: taskStateReducer,
		surveyState: surveyStateReducer,
		coach: coachReducer,
		coaches: coachesReducer,
		calendar: calendarReducer,
		code: codeReducer,
		cap: capReducer,
		suggested: suggestedReducer,
		bookings: bookingsReducer,
		hrdashboard: hrdashboardReducer,
		topics: topicsReducer
	})
