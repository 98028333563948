import React, {Fragment, useState, useEffect} from "react"
import FormValidate from "form-validate.js"
import Styled from "styled-components"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, semantic} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Small} from "@uprise/text"
import {H3, H4} from "@uprise/headings"
import {text} from "@uprise/typography"
// Inputs
import {TextInput} from "@uprise/form"
// Icons
import Icons from "constants/Icons"
// Validation
import constraints from "validation/coachingTopics"

const Topic = Styled(Medium)`
    width: 100%;
    display: flex;
    padding: ${spacing.s3} 0;
    cursor: pointer;
`

const TopicList = Styled.div`
    width: 100%;
    display: flex;
    height: 400px;
    overflow-y: auto;
    flex-flow: column;
    background: ${backgrounds.fadedPurple};
`

const TopicCont = Styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0 ${spacing.s10};
	background-color: ${props => (props.active ? `${extended.purple.five}` : `${backgrounds.fadedPurple}`)};
    border: 1px solid ${backgrounds.fadedPurple};
	border-color: ${props => (props.active ? `${extended.purple.three}` : `${backgrounds.fadedPurple}`)};
`

const HR = Styled.div`
	width: 100%;
	display: flex;
	height: 1px;
    background-color: ${extended.purple.five};
`

const H4Styled = Styled(H4)`
    padding: 0 ${spacing.s10};
    padding-bottom: ${spacing.s10};
    padding-top: ${spacing.s5};
`

const ButtonCont = Styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: ${spacing.s5} ${spacing.s10};
`

const DownIcon = Styled.img`
	width: 10px;
	height: 6px;
`

const ParentCont = Styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
    padding: ${spacing.s3} ${spacing.s10};
`

const TitleWrap = Styled.div`
	font-size: ${text.t7}
	font-family: "Proxima Nova Semibold";
`

const FadeWrap = Styled.div`
	animation: fadeIn 2s;
	transition: all 2s linear;
	display: ${props => (props.visible ? "block" : "none")};
	visibility: ${props => (props.visible ? "visible" : "hidden")};
	padding: 10px 0;
`

const TextInputStyled = Styled(TextInput)`
    & label {
        display: none;
    }
`

const Error = Styled(Small)`
    margin-bottom: 21px;
    color: ${semantic.error};
    text-align: left;
`

export const TopicModal = ({
	isOpen,
	handleClose,
	handleConfirm,
	isLoading,
	topic,
	setTopic,
	setOthers,
	others,
	topics
}) => {
	const [selected, setSelected] = useState()
	const [parentsSelected, setParentsSelected] = useState([])
	const [disabled, setDisabled] = useState(true)
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [submitted, setSubmitted] = useState(false)
	const [errorMsg, setErrorMsg] = useState()

	useEffect(() => {
		setParentsSelected(topics.topics.map(parent => parent.index))
	}, [])

	useEffect(() => {
		let validator = new FormValidate({}, null, {})
		if (topic === "Other") {
			validator = new FormValidate(constraints, null, {
				others
			})
		}
		setValidator(validator)
	}, [topic])

	const handleClick = (id, text) => {
		setSelected(id)
		setTopic(text)
		setDisabled(false)
	}

	const handleParents = id => {
		const arr = [...parentsSelected]
		const idx = arr.indexOf(id)
		if (idx !== -1) {
			arr.splice(idx, 1)
			setParentsSelected(arr)
		} else {
			arr.push(id)
			setParentsSelected(arr)
		}
	}

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		if (topic !== "Other") {
			setErrorMsg()
			handleConfirm()
			return
		}
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				setErrorMsg(controls.others.errors[0])
				// a good place to update the validation being displayed.
			})
			return
		} else {
			setErrorMsg()
			handleConfirm()
		}
	}

	const _handleClose = () => {
		setSelected()
		setParentsSelected(topics.topics.map(parent => parent.index))
		setDisabled(true)
		setValidation({})
		setValidator()
		setSubmitted(false)
		setErrorMsg()
		handleClose()
	}

	return (
		<Modal
			padding={"0"}
			backgroundColor={backgrounds.white}
			width='800px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={_handleClose}>
			<H3 className='m-b-1 m-t-3'>Select a Topic</H3>

			<TopicList>
				<H4Styled textAlign={"left"}>
					Tell your (counsellor/coach) what this session will be about to support you better
				</H4Styled>
				{topics.topics.map((sec, idx) => (
					<Fragment key={idx}>
						<ParentCont onClick={() => handleParents(sec.index)}>
							<TitleWrap>{sec.title}</TitleWrap>
							<DownIcon className='m-t-1' src={Icons.chevronDownPurple} />
						</ParentCont>
						<FadeWrap visible={parentsSelected.includes(sec.index)}>
							{sec.data.map(item => (
								<TopicCont
									key={item.id}
									onClick={() => handleClick(item.id, item.text)}
									active={selected === item.id}>
									<Topic>{item.text}</Topic>
									{topic === "Other" && item.id === selected && (
										<>
											<form onChange={event => _validateForm(event)}>
												<TextInputStyled
													onChange={e => setOthers(e.target.value)}
													validation={validation}
													value={others}
													validateControl='others'
													id='others'
													type='text'
													name='others'
													label='Others'
													isRequired
													isLast={true}
												/>
											</form>
											{errorMsg && <Error>{errorMsg || ""}</Error>}
										</>
									)}
									<HR />
								</TopicCont>
							))}
						</FadeWrap>
					</Fragment>
				))}
			</TopicList>

			<ButtonCont>
				<Button
					variant='primary'
					size='large'
					width={"200px"}
					title='Confirm Booking'
					onClick={_handleSubmit}
					isLoading={isLoading}
					disabled={disabled}
				/>
			</ButtonCont>
		</Modal>
	)
}
