// Codes
import codes from "./data/codes"

const constraints = {
	phone: {
		presence: {
			message: "^Please enter your phone number"
		}
	},
	companyCode: {
		presence: {
			message: "^Please enter your company code, please request from your HR director.",
			allowEmpty: false
		},
		inclusion: {
			within: codes,
			message: "^Company code is not recognised. Please check the spelling and try again."
		}
	}
}

export default constraints
