import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {array, string} from "prop-types"
import Styled from "styled-components"
// UI
import {Container, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H2, H3, H5} from "@uprise/headings"
import {ExtraSmall} from "@uprise/text"
import {List} from "@uprise/list"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"

const SummaryStyle = Styled.section`
      width: 100%;
      height: 100%;
`

const Content = Styled.section`
	width: 100%;
	padding: 25px;
`

export const SummaryTask = ({className, summary}) => {
	return (
		<SummaryStyle>
			<H3 className='m-b-4'>Summary</H3>
			<Card backgroundColor={backgrounds.white}>
				<Content dangerouslySetInnerHTML={{__html: summary}} />
			</Card>
		</SummaryStyle>
	)
}

SummaryTask.propTypes = {
	summary: string.isRequired
}
