import React from "react"
import {backgrounds} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import styled from "styled-components"
import {Card} from "@uprise/card"
const SectionContainerWrap = styled.div`
	margin: ${spacing.s4};
`
const SectionContainer = ({children, className = "mb-5"}) => {
	return (
		<SectionContainerWrap
			className={className}
			shadow={false}
			backgroundColor={backgrounds.fadedPurple}
			padding={spacing.s10}
			width='100%'>
			{children}
		</SectionContainerWrap>
	)
}

const CardWrap = styled.div`
	transition: 0.3s ease;
	&:hover {
		transform: translateY(-1px) scale(1.01);
	}
`

const PurpleBox = ({children, withWrap = false, className = "", padding = spacing.s10}) => {
	if (withWrap) {
		return (
			<CardWrap>
				<Card
					className={className}
					shadow={false}
					backgroundColor={backgrounds.fadedPurple}
					padding={padding}
					width='100%'>
					{children}
				</Card>
			</CardWrap>
		)
	}

	return (
		<Card className='mb-5' shadow={false} backgroundColor={backgrounds.fadedPurple} padding={padding} width='100%'>
			{children}
		</Card>
	)
}

const ModalOpenButtonContainer = styled.div`
	padding-left: 8px;
	padding-right: 8px;
`

const InfoText = styled.span`
	font-size: 12px;
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

export {SectionContainer, PurpleBox, ModalOpenButtonContainer, InfoText, Center}
