export const coronaAnxietyPositives = [
	{
		_id: 0,
		text:
			"If you’re feeling stressed about the coronavirus then I can show you how to retrain some of your thoughts about it.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			surveyId: "survey-corona-anxiety-positives-v1",
			taskId: "exercise-corona-anxiety-positives",
			questions: [0],
			recurring: true,
			values: [
				{
					label: "Ok",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"Thanks for letting me know. Now let’s look at the reasons why the opposite of your thought could be true.",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			surveyId: "survey-corona-anxiety-positives-v1",
			taskId: "exercise-corona-anxiety-positives",
			questions: [1, 2, 3, 4, 5, 6],
			recurring: true,
			values: [
				{
					label: "Ok",
					value: "okay",
					questionIndex: 1,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text:
			"Thanks for doing the exercise with me. The goal was to help you identify any unhelpful thoughts then retrain them by considering how the opposite thought might be true.",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"If you notice yourself getting pulled into unhelpful thoughts then try to remind yourself about a few of these so that you don’t get sucked in too far.",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "Thanks for the tip",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_end: true,
		text: "I've placed your notes in your notebook 👍",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
