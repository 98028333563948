import React, {Fragment} from "react"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
// Colors
import {backgrounds, extended} from "@uprise/colors"
import {Medium} from "@uprise/text"
import {H3} from "@uprise/headings"
import CoachCard from "components/Shared/Coach/CoachCard"
import GetACoach from "components/Shared/GetACoach"
// spacing
import {spacing} from "@uprise/spacing"
import {getCoachType} from "helpers/utils"

const RecommendedCoachStyles = Styled.section`
`

const Content = Styled.div`
	padding: ${spacing.s7}
      display: flex;
      flex-direction: row;
`

const CoachInfoWrap = Styled.div`
      display: flex;
      flex-direction: column;
`

const CoachHeader = Styled.div`
      display: flex;
      flex-direction: column;
`

const CoachBody = Styled.div`
      display: flex;
      flex-direction: row;
`

const CoachLeft = Styled.div`

`

const CoachRight = Styled.div`

`

const CoachTimes = Styled.div`

`
const CoachBio = Styled.div`
	display: flex;
	flex-direction: column;
`

const ButtonsWrap = Styled.div`
	display: flex;
	flex-direction: row;
	float: right;
`

const CoachPhoto = Styled.img`
      height: 142px;
      min-width: 142px;
      border-radius: 10px;
`

const SpecIcon = Styled.img`
	margin-left: ${props => props.marginLeft};
	margin-top: ${props => props.marginTop};
      height: ${props => props.height};
	width: ${props => props.width};
      min-width: 11px;
`

const Ul = Styled.ul`
	width: 145px;
	margin-top: -1px;
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
	flex-direction: row;
      margin-bottom: ${spacing.s5};
`

const OffDay = Styled.div`
	padding: 5px;
	max-height: 24px;
	border-radius: 5px;
 	margin-right: 4px;
  	background-color: ${backgrounds.fadedPurple};
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Day = Styled.div`
	padding: 5px;
	max-height: 24px;
	line-height: 1;
	border-radius: 5px;
	margin-right: 4px;
  	background-color: ${extended.purple.five};
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Availability = Styled.div`
	display: flex;
	flex-direction: 'row';
	width: 400px;

`

const RecommendedCoach = ({className, coach, bookings}) => {
	return (
		<Fragment>
			{coach && Object.entries(coach).length > 0 ? (
				<RecommendedCoachStyles className={className}>
					<H3>Your recommended {getCoachType(bookings.duration)}</H3>
					<Medium className='m-b-4'>
						We’ve picked a coach for you based on your responses during signup
					</Medium>
					<CoachCard coach={coach} recommended={true} />
				</RecommendedCoachStyles>
			) : (
				<GetACoach className='m-b-10' />
			)}
		</Fragment>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coach: state?.coach,
		bookings: state?.bookings
	}
}

export default connect(mapStateToProps, null)(RecommendedCoach)
