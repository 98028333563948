import React, {Fragment, useEffect, useState} from "react"
import {object, func} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation, Redirect} from "react-router-dom"
import {connect} from "react-redux"
// Actions
import {setTask} from "actions/taskActions"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// UI
import {Layout} from "components/Shared/Layout"
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"

// Components
import {SurveyScores} from "components/Shared/Chat/SurveyScores"
import {SkillProgress} from "components/Shared/Skill/SkillProgress"
import {TaskList} from "components/Shared/Library/TaskList"
import YourHealth from "components/Shared/YourHealth"

const Content = Styled.section`
	width: 100%;
	padding: 35px 20px;
	min-height: 100vh;
`

const Wrap = Styled.section`	background-color: ${extended.purple.six}; 
        width: 100%;
`

const ScoreWrap = Styled.div`
	display: flex;
	justify-content: center;
`

const ScoreContent = Styled.div`
	width: 65%;
	align-self: center;
`

const ChatBotScore = ({userState, skill, skillId}) => {
	let history = useHistory()
	let location = useLocation()
	const title = "Your results"

	const screener = history.location.state

	// If the user refreshed the page just redirect them to the 'You' section for results
	if (!screener) {
		return (
			<Redirect	to={{pathname: "/settings"}} />
		)
	}

	const {meta} = skill

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={1} />
			</LeftPanel>
			<Wrap>
				<Header title={title} textAlign='left' width='100%' />
				<Main>
					<Card
						border={`1px solid ${extended.purple.five}`}
						shadow={false}
						backgroundColor={backgrounds.white}
						padding={spacing.s5}
						width='100%'>
						<ScoreWrap>
							<ScoreContent>{screener && <SurveyScores screener={screener} />}</ScoreContent>
						</ScoreWrap>
					</Card>

					<Row className='justify-content-center align-items-center'>
						<Button
							className='m-t-10'
							variant='primary'
							title='Dashboard'
							size='large'
							fullWidth={false}
							width='360px'
							disabled={false}
							onClick={() => history.push("/dashboard")}
						/>
					</Row>
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='250px'>
				<YourHealth
					className='m-b-7'
					surveyResults={userState.surveyResults}
					hideStress={screener.dialogueId === "stressCheck" ? true : false}
					hideWellbeing={screener.dialogueId === "wellbeingCheck" ? true : false}
				/>
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	const {userState, skill, task} = state

	return {
		userState,
		skill,
		task
	}
}

const mapDispatchToProps = dispatch => ({
	setTask: (task, taskId) => dispatch(setTask(task, taskId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotScore)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
ChatBotScore.propTypes = {
	userState: object.isRequired,
	match: object.isRequired,
	skill: object.isRequired
}

ChatBotScore.defaultProps = {}
