export const retrainingThinkingRevision = [
	{
		_id: 0,
		text: "This exercise is easier if you’ve already watched the video about the skill.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 3,
			optionType: "radio",
			replyType: "survey",
			recurring: true,
			surveyId: "retraining-thinking-revision-v1",
			taskId: "exercise-retraining-thinking-revision",
			values: [
				{
					label: "Show me the video",
					videoURI:
						"https://player.vimeo.com/external/218729113.sd.mp4?s=8e3f73f332bca4871345a883706fcbccaf691327&profile_id=165",
					vimeoId: "218729113",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 2,
						_next: 3,
						optionType: "radio",
						replyType: "survey",
						recurring: true,
						surveyId: "retraining-thinking-revision-v1",
						taskId: "exercise-retraining-thinking-revision",
						values: [
							{
								label: "I've watched the video",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve watched it. Let’s get started.",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		text: "So the first step is to look at the costs of this thought.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "Here are some common costs of unhelpful thoughts.",
		recurring: true,
		surveyId: "retraining-thinking-revision-v1",
		taskId: "exercise-retraining-thinking-revision",
		questionIndex: 1,
		questions: [1],
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_next: 6,
		text:
			"In this step, we look for all of the reasons why the thought isn’t 100% true in all situations at all times.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text: "The more reasons that you can think of, the more likely you will be to change this thought.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			questions: [2, 3, 4, 5, 6],
			surveyId: "retraining-thinking-revision-v1",
			taskId: "exercise-retraining-thinking-revision",
			values: [
				{
					label: "👍",
					value: "start",
					replyType: "dialogue",
					questionIndex: 2
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text: "Thanks for letting me know and for doing the exercise with me. 🏋️",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		text:
			"Hopefully it’s helped you to feel a little better but don’t worry if the unhelpful thought isn’t fully resolved. Thoughts occur in clusters of related beliefs. So you might still need to retrain some of the other thoughts in the cluster.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_next: 11,
		text:
			"The goal was to help you learn the basics of retraining your thinking by questioning an unhelpful thought.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
