import React from "react"
import Styled from "styled-components"
// Colors
import {backgrounds} from "@uprise/colors"
// Components
import {H3} from "@uprise/headings"
import {Card} from "@uprise/card"
import {Accordian} from "@uprise/accordian"
// constants
import {faqCoaching, faqCounselling} from "helpers/constants"

const FaqsStyles = Styled.section`
`

const Content = Styled.section`
	width: 100%;
	padding: 25px;
`

export const Faqs = ({className, duration}) => {
	return (
		<FaqsStyles className={className}>
			<H3 className='m-b-6'>Frequently Asked Questions</H3>
			<Card backgroundColor={backgrounds.white}>
				<Content>
					{duration === 30 && <Accordian content={faqCoaching} />}
					{duration === 60 && <Accordian content={faqCounselling} />}
				</Content>
			</Card>
		</FaqsStyles>
	)
}
