import React, {useState, useEffect} from "react"
import {object, number} from "prop-types"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import {connect} from "react-redux"
import moment from "moment-timezone"
import {get, post} from "helpers/api"
import "react-dates/initialize"
import {DayPickerSingleDateController} from "react-dates"
// Actions
import {updateUserState, fetchAdditionalSessions} from "actions/userStateActions"
import {assignCoach, getCoachByEmail} from "actions/coachActions"
import {fetchBookings, setDuration} from "actions/bookingsActions"
// Styles
import "react-dates/lib/css/_datepicker.css"
import "styles/react-dates.css"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// UI
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
import {Medium, P, Small, Bold} from "@uprise/text"
import {H3, H4, H6} from "@uprise/headings"
import {ContainerFluid, Row, Col} from "@uprise/grid"
// Components
import {ConfirmModal} from "components/Shared/Coach/ConfirmModal"
import {TopicModal} from "components/Shared/Coach/TopicModal"
import {MoreInfoModal} from "components/Shared/Coach/MoreInfoModal"
import {ChangeNumberModal} from "components/Shared/Coach/ChangeNumberModal"
import CoachCard from "components/Shared/Coach/CoachCard"
// spacing
import {spacing} from "@uprise/spacing"
// Icons
import Icons from "constants/Icons"
// Utils
import {isTherapyEnabled, isCoachingEnabled, getCoachingType, getCoachType} from "helpers/utils"

const BookCallStyles = Styled.section`
`

const Content = Styled.div`
	padding: ${spacing.s7}
  	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: -50px;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
`

const CalendarWrap = Styled.div`

`

const AvailableTimes = Styled.div`
	width: 100%;
`

const TimeSlots = Styled.div`
	display: flex;
	flex-direction: row;
`
const PhoneIcon = Styled.img`
	width: 22px;
	height: 22px;
	min-width: 22px;
`

const InfoHeader = Styled.div`
	display: flex;
	flex-direction: row;
    background: ${backgrounds.fadedPurple};
    align-items: center;
    border-radius: 10px;
    padding: ${spacing.s5};
`

const SlotHeader = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid ${extended.purple.five};
`

const AMSlots = Styled.div`
	display: flex;
	flex-flow: row wrap;

	@media (max-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const PMSlots = Styled.div`
	display: flex;
	flex-flow: row wrap;

	@media (max-width: 1024px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const MessageWrap = Styled.div`
	padding: ${spacing.s7} 0;
`

const CoachStyled = Styled(CoachCard)`
	padding-top: 28px;
`

const BookingToggle = Styled.div`
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	border: 1px solid ${extended.purple.four};
	border-radius: 10px;
    margin-bottom: ${spacing.s5};
`

const BtnWrap = Styled.div`
	width: 100%;
	padding: ${spacing.s5};
	display: grid;
	grid-template-rows: 1fr 1fr;
	cursor: pointer;
	background-color: ${props => (props.active ? `${backgrounds.fadedPurple}` : "white")};
	&: nth-child(1){
		border-bottom: 1px solid ${extended.purple.four};
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	&: nth-child(2){
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
`

const TitleWrap = Styled.div`
	display: flex;
	align-items: center;
`

const Circle = Styled.div`
	height: 10px;
	width: 10px;
	border-radius: 50%;
	margin-right: 10px;
	border: ${props => (props.active ? `3px solid ${extended.purple.one}` : `1px solid ${extended.charcoal.one}`)};
`

const ChangeNumberBtn = Styled(Button)`
    padding: 0;
    display: unset;
    height: unset;
    margin-left: 10px;
`

const AlertStyled = Styled(Alert)`
    border: 1px solid #FFC275;
`

const HR = Styled.div`
	width: 100%;
	display: flex;
	height: 1px;
	margin: 4px 0 4px 0;
  	background-color: ${extended.purple.four};
`

const BookCall = ({
	className,
	duration,
	userState,
	coach,
	callLimitReached,
	callsRemaining,
	callCapLimit,
	callApproved,
	reschedule,
	fetchBookings,
	code,
	cap,
	therapyCallsRemaining,
	combinedCallRemaining,
	updateUserState,
	bookings,
	setDuration,
	fetchAdditionalSessions,
	topics
}) => {
	let history = useHistory()

	const [date, setDate] = useState()
	const [slotsAMIndex, setSlotsAMIndex] = useState()
	const [slotsPMIndex, setSlotsPMIndex] = useState()
	const [openSlots, setOpenSlots] = useState()
	const [selectedTime, setSelectedTime] = useState()
	const [dateOpenSlots, setDateOpenSlots] = useState([])
	const [showTime, setShowTime] = useState()
	const [showConfirm, setShowConfirm] = useState(false)
	const [bookingLoading, setBookingLoading] = useState(false)
	const [booking, setBooking] = useState()
	const [showMoreInfo, setShowMoreInfo] = useState()
	const [changeNumber, setChangeNumber] = useState()
	const [dateWithSlots, setDateWithSlots] = useState({})
	const [capsText, setCapsText] = useState("coaching and counselling")
	const [topic, setTopic] = useState(topics?.topics?.[0]?.data?.[0]?.text)
	const [others, setOthers] = useState("")
	const [maskedNumber, setMaskedNumber] = useState()
	const [showTopic, setShowTopic] = useState(false)

	useEffect(() => {
		setDate(new Date())
	}, [])

	useEffect(() => {
		const len = userState.phone.length
		let masked = [...userState.phone].map((num, idx) => {
			if (len - idx >= 2 && len - idx <= 4) return "X"
			else return num
		})
		setMaskedNumber(masked.join(""))
	}, [userState.phone])

	useEffect(() => {
		if (openSlots && openSlots.length) {
			const map = getDatesWithSlot()
			setDateWithSlots(map)
			// Reset available slots
			updateAvailableSlots(moment())
		}
	}, [openSlots])

	useEffect(() => {
		get(`coach/availability/${userState.coach}/${duration}`)
			.then(resp => {
				setAvailableSlots(resp)
			})
			.catch(error => {
				console.log({error})
			})
	}, [coach?.id, duration])

	const minBookingNotice = () => {
		return moment().add(24, "hours")
	}

	const updateAvailableSlots = date => {
		setSlotsAMIndex([])
		setSlotsPMIndex([])

		const dateOpenSlots = {}

		openSlots.forEach(openSlot => {
			if (openSlot.start.format("DD/MM/YY") === date.format("DD/MM/YY") && openSlot.start > minBookingNotice()) {
				dateOpenSlots[openSlot.start.format("X")] = openSlot
			}
		})

		const ordered = {}
		Object.keys(dateOpenSlots)
			.sort()
			.forEach(function(key) {
				ordered[key] = dateOpenSlots[key]
			})

		setDateOpenSlots(ordered)
		setDate(date)
		setSelectedTime(Object.values(ordered)[0] ? Object.values(ordered)[0] : null)
	}

	useEffect(() => {
		if (Object.values(dateOpenSlots).length > 0) {
			setIndexs()
		}
	}, [dateOpenSlots])

	const setIndexs = () => {
		let slotsIndex = []

		slotsIndex = Object.entries(dateOpenSlots)
			.filter(openslot => {
				if (openslot[1].start.format("a") === "am") {
					return true
				}
			})
			.map(openslot => {
				if (openslot[1].start.format("a") === "am") {
					return false
				}
			})

		setSlotsAMIndex(slotsIndex)

		slotsIndex = Object.entries(dateOpenSlots)
			.filter(openslot => {
				if (openslot[1].start.format("a") === "pm") {
					return true
				}
			})
			.map(openslot => {
				if (openslot[1].start.format("a") === "pm") {
					return false
				}
			})

		setSlotsPMIndex(slotsIndex)
	}

	useEffect(() => {
		handleSelectTime()

		if (slotsAMIndex && selectedTime) {
			if (slotsAMIndex.indexOf(true) === -1) {
				selectSlot(selectedTime, 0)
			}
		} else if (slotsPMIndex && selectedTime) {
			if (slotsPMIndex.indexOf(true) === -1) {
				selectSlot(selectedTime, 0)
			}
		}
	}, [slotsPMIndex, slotsAMIndex])

	const setAvailableSlots = coachSlots => {
		setOpenSlots(
			coachSlots.map(s => ({
				start: moment(s.start),
				end: moment(s.end)
			}))
		)
	}

	const selectSlot = (slot, index) => {
		let slotsAMIndexNew = slotsAMIndex
		let slotsPMIndexNew = slotsPMIndex

		for (let slot in slotsAMIndex) {
			slotsAMIndexNew[slot] = false
		}

		for (let slot in slotsPMIndex) {
			slotsPMIndexNew[slot] = false
		}

		if (slot.start.format("a") === "am") {
			if (slotsAMIndexNew[index]) {
				slotsAMIndexNew[index] = false
			} else {
				slotsAMIndexNew[index] = true
			}

			setSlotsAMIndex(slotsAMIndexNew)
		} else {
			if (slotsPMIndexNew[index]) {
				slotsPMIndexNew[index] = false
			} else {
				slotsPMIndexNew[index] = true
			}

			setSlotsPMIndex(slotsPMIndexNew)
		}

		const selectedTime = slot
		setSelectedTime(selectedTime)
	}

	const handleSelectTime = () => {
		setShowTime(true)
	}

	const getAMOpenSlots = () => {
		if (dateOpenSlots && slotsAMIndex.length > 0) {
			const openSlots = Object.entries(dateOpenSlots).map((openslot, index) => {
				if (openslot[1].start.format("a") === "am") {
					return (
						<Button
							size='tiny'
							className='m-b-3 m-r-3'
							fullWidth={false}
							borderRadius='5px'
							variant={`${slotsAMIndex[index] ? "primary" : "tertiary"}`}
							onClick={() => selectSlot(openslot[1], index)}
							key={index}
							title={`${openslot[1].start.format("h:mm A")}`}
						/>
					)
				}
			})

			return openSlots.length ? openSlots : false
		}
	}

	const getPMOpenSlots = () => {
		if (dateOpenSlots && slotsPMIndex.length > 0) {
			const openSlots = Object.entries(dateOpenSlots).map((openslot, index) => {
				if (openslot[1].start.format("a") === "pm") {
					return (
						<Button
							size='tiny'
							className='m-b-3 m-r-3'
							fullWidth={false}
							borderRadius='5px'
							variant={`${slotsPMIndex[index] ? "primary" : "tertiary"}`}
							onClick={() => selectSlot(openslot[1], index)}
							key={index}
							title={`${openslot[1].start.format("h:mm A")}`}
						/>
					)
				}
			})

			return openSlots.length ? openSlots : false
		}
	}

	const getDatesWithSlot = () => {
		const map = {}

		openSlots &&
			openSlots.forEach(slot => {
				const date = moment(slot.start).format("DD/MM")
				if (!map[date]) {
					map[date] = true
				}
			})

		return map
	}

	const handleTopic = () => {
		setShowTopic(true)
	}

	const _handleBook = () => {
		setBookingLoading(true)
		const issues = topic === "Other" ? others : topic
		post("makeBooking", {
			clientEmail: userState.email,
			coachId: coach.id,
			startTime: selectedTime.start,
			purpose: "coach call",
			issues,
			callDuration: parseInt(duration)
		})
			.then(resp => {
				console.log(resp, "booking_response")
				if (resp.error === "BOOKING_CALL_CAPPING") {
					history.push("booking")
				} else if (resp.error === "BOOKING_COACH_CALENDAR_BUSY") {
					alert("The time you have selected is no longer available, please select another time")
				} else if (resp.error === "SlotUnavailable") {
					alert("This slot is no longer available, please try another one")
				} else if (resp.error === "ValidationFail") {
					alert("validation error")
				} else if (resp.error === "Failed to create event!") {
					alert("Failed to create event, please try a different coach or contact tech support")
				} else if (resp.error === "EXPIRED_ACCESS_CODE") {
					alert("Cannot make booking, your employer code is expired")
				} else if (!resp.error) {
					const booking = {
						coach,
						selectedTime
					}
					setBooking(booking)
					fetchBookings()
					setShowConfirm(true)
				} else {
					alert("Failed to create event, contact tech support")
				}

				setBookingLoading(false)
				setShowTopic(false)
				if (reschedule)
					history.push("make-booking", {
						duration: duration,
						from: location.pathname
					})
			})
			.catch(error => {
				console.error("Failed to make booking, reason: ", error)
				setBookingLoading(false)
			})
	}

	const _handleReschedule = () => {
		setBookingLoading(true)
		post("cancelBooking", {
			coachId: parseInt(reschedule.coachId),
			eventId: reschedule.calendarData?.data
				? reschedule.calendarData?.data?.id
				: reschedule.calendarData?.id
				? reschedule.calendarData?.id
				: reschedule.data.id,
			duration: reschedule.duration || 30,
			userTimezone: moment.tz.guess()
		}).then(resp => {
			if (resp.error) {
				alert("Unable to reschedule booking. Reason: " + resp.error)
				setBookingLoading(false)
			} else {
				_handleBook()
			}
		})
	}

	const _handleConfirmOpen = () => {
		post("complete", {
			module: "coach-booking",
			completed: true
		}).then(resp => {
			updateUserState(resp.user_state)
		})
	}

	const updateState = () => {
		get("user/additionalSessions")
			.then(resp => {
				if (!resp.error) {
					updateUserState({...userState, ...resp.data})
					if (!resp.data.disableCallCap) history.push("booking")
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const _handleClose = () => {
		setShowConfirm(false)
		fetchAdditionalSessions(userState)
	}

	const _handleTopicClose = () => {
		setTopic(topics.topics[0].data[0].text)
		setShowTopic(false)
	}

	useEffect(() => {
		if (!reschedule && callLimitReached && !callApproved) history.push("booking", {duration, from: "make-booking"})
	}, [callLimitReached, duration, callApproved])

	const getCapsText = () => {
		const {combined} = cap
		let therapyCoachingText = `You have ${callsRemaining} included calls remaining`

		if (combined) {
			therapyCoachingText = `You have ${combinedCallRemaining} included calls remaining`
		} else {
			therapyCoachingText = `You have ${duration === 60 ? therapyCallsRemaining : callsRemaining} included ${
				duration === 60 ? "counselling" : "coaching"
			} calls remaining`
		}

		setCapsText(therapyCoachingText)
	}

	useEffect(() => {
		getCapsText()
	}, [code, callsRemaining, therapyCallsRemaining, duration])

	const sessionType = duration === 60 ? "session" : "call"

	const {extraSessions, sessionsLeft} = userState

	return (
		<BookCallStyles className={className}>
			<H3>{reschedule ? "Reschedule a call" : "Book a call"}</H3>
			<Medium className='m-b-4'>
				Uprise is 4x more effective with {getCoachingType(duration).toLowerCase()}
			</Medium>

			<MessageWrap>
				{callCapLimit && !callApproved && (
					<AlertStyled type='warning'>
						<P fontSize='16px' weight='bold'>
							{capsText}
						</P>
					</AlertStyled>
				)}
				{callCapLimit && callApproved && (
					<Alert type='warning'>
						<P fontSize='16px' weight='bold'>
							Your HR team has approved your request for an additional {extraSessions}{" "}
							{getCoachingType(duration).toLowerCase()} {sessionType}. You have {sessionsLeft}{" "}
							{sessionType} available.
						</P>
					</Alert>
				)}
			</MessageWrap>

			<Card backgroundColor={backgrounds.white} shadow={true}>
				<Content>
					<Row>
						<Col className='col-12 col-md-12 col-lg-12'>
							<CoachStyled coach={coach} pick={false} shadow={false} />
						</Col>
						<Col className='col-12 col-md-12 col-lg-12 m-b-5'>
							<HR />
						</Col>
						<Col className='col-12 col-md-12 col-lg-12'>
							{isTherapyEnabled(userState?.features) && isCoachingEnabled(userState?.features) && (
								<BookingToggle>
									<BtnWrap active={duration === 30} onClick={() => setDuration(30)}>
										<TitleWrap>
											<Circle active={duration === 30} />
											<H4>Coaching (30 mins)</H4>
										</TitleWrap>
										<Medium className='m-l-5'>
											Your coach will show you how to use the skills for your specific situation.
											&nbsp;
											<Bold color={extended.purple.one} onClick={() => setShowMoreInfo(true)}>
												Learn More
											</Bold>
										</Medium>
									</BtnWrap>
									<BtnWrap active={duration === 60} onClick={() => setDuration(60)}>
										<TitleWrap>
											<Circle active={duration === 60} />
											<H4>Counselling (60 mins)</H4>
										</TitleWrap>
										<Medium className='m-l-5'>
											Your coach will focus on the specific problem that you bring out and will
											try to help you make changes in your life as needed, fostering your
											wellbeing. &nbsp;
											<Bold color={extended.purple.one} onClick={() => setShowMoreInfo(true)}>
												Learn More
											</Bold>
										</Medium>
									</BtnWrap>
								</BookingToggle>
							)}
						</Col>
						<Col className='col-6 col-md-6 col-lg-6 m-t-5 m-b-5'>
							<DayPickerSingleDateController
								isOutsideRange={currentDate => {
									return (
										currentDate < moment().subtract(1, "d") ||
										!dateWithSlots[currentDate.format("DD/MM")]
									)
								}}
								onDateChange={date => updateAvailableSlots(date)} // PropTypes.func.isRequired
								isDayHighlighted={day => day.isSame(date, "d")}
							/>
						</Col>

						<Col className='col-6 col-md-6 col-lg-6 m-t-5 m-b-5'>
							<SlotHeader>
								<H6 className='m-t-0 m-b-3' fontSize='16px' weight='bold'>
									{moment(date).format("DD MMMM YYYY")}
								</H6>
							</SlotHeader>

							{showTime && selectedTime && (
								<>
									<TimeSlots className='m-t-4'>
										<AMSlots className='m-t-0'>
											{getAMOpenSlots()} {getPMOpenSlots()}
										</AMSlots>
									</TimeSlots>
									<Small>All times in {moment.tz(moment.tz.guess()).zoneAbbr()}</Small>
								</>
							)}

							{!selectedTime && (
								<Small className='m-t-10' color={extended.charcoal.two} textAlign='center'>
									Sorry, no slots available on this day.
									<br />
									Please select another date.
								</Small>
							)}

							<Medium className='m-t-10' color={extended.charcoal.one} textAlign='left'>
								Your coach is unavailable at your preferred time/date?
								<br />
								<Button
									className='m-t-0'
									variant='text'
									title={`Change ${getCoachType(bookings.duration)}`}
									size='medium'
									style={{padding: 0, height: "unset"}}
									width='full'
									onClick={() => history.push("select-coach", {from: location.pathname})}
								/>
							</Medium>
						</Col>
						<Col className='col-12 col-md-12 col-lg-12 m-t-5 m-b-5'>
							<InfoHeader>
								<PhoneIcon className='m-r-1' src={Icons.telephone} />
								<H6 fontSize='16px' className='m-l-1 m-t-0 m-b-0'>
									Your {getCoachType(duration).toLowerCase()} will call you at {maskedNumber}
									<ChangeNumberBtn
										className='m-l-1'
										variant='tertiary'
										title={`Change Number`}
										size='medium'
										width='full'
										onClick={() => setChangeNumber(true)}
									/>
								</H6>
							</InfoHeader>
							<Button
								className='m-b-3 m-t-5'
								fullWidth={true}
								size='medium'
								disabled={typeof selectedTime === "undefined" || selectedTime === null}
								variant='primary'
								isLoading={bookingLoading}
								onClick={() => handleTopic()}
								title={`${reschedule ? "Reschedule call" : "Confirm Booking"}`}
							/>
							<ChangeNumberModal
								isOpen={changeNumber}
								updateUserState={updateUserState}
								handleClose={() => setChangeNumber(false)}
								phone={userState.phone}
							/>

							<ConfirmModal
								data={booking}
								isOpen={showConfirm}
								onOpen={() => _handleConfirmOpen()}
								handleClose={() => _handleClose()}
							/>
							<TopicModal
								data={booking}
								isOpen={showTopic}
								others={others}
								setOthers={setOthers}
								setTopic={setTopic}
								topic={topic}
								topics={topics}
								handleConfirm={() => (reschedule ? _handleReschedule() : _handleBook())}
								handleClose={() => _handleTopicClose()}
								isLoading={bookingLoading}
							/>
						</Col>
					</Row>
				</Content>
			</Card>
			<MoreInfoModal
				isOpen={showMoreInfo}
				handleClose={() => setShowMoreInfo(false)}
				duration={bookings.duration}
			/>
		</BookCallStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		code: state?.code,
		cap: state?.cap,
		bookings: state.bookings,
		topics: state.topics
	}
}

const mapDispatchToProps = dispatch => ({
	assignCoach: () => dispatch(assignCoach()),
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state)),
	fetchBookings: () => dispatch(fetchBookings()),
	setDuration: duration => dispatch(setDuration(duration)),
	fetchAdditionalSessions: userState => dispatch(fetchAdditionalSessions(userState))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookCall)

BookCall.propTypes = {
	coach: object.isRequired,
	duration: number.isRequired
}

BookCall.defaultProps = {
	reschedule: false,
	duration: 30
}
