import React from "react"
import {object, string, array} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"

// UI
import {H1, H2, H3, H4, H6} from "@uprise/headings"
import {Card} from "@uprise/card"
import {Medium} from "@uprise/text"
import {TaskButton} from "@uprise/button"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// icons
import Icons from "assets/images/icons/svg"
// Spacing
import {spacing} from "@uprise/spacing"

const BonusStyle = Styled.section`
	padding: 0 ${spacing.s10} 0 ${spacing.s10};
	width: 100%;
	
	@media (max-width: 1024px) {
		padding: ${spacing.s8} ${spacing.s8} 0 ${spacing.s8};
	}
`

const Bonus = ({className, bonus, activity, skillId, task}) => {
	const history = useHistory()
	let location = useLocation()

	// Icons
	const completedIcon = Icons.taskCompleted
	const audioIcon = Icons.audio
	const exerciseIcon = Icons.exercise
	const videoIcon = Icons.overview
	const workedExample = Icons.workedExample

	if (bonus.length > 0) {
		return (
			<BonusStyle className={className}>
				<H6 className='m-t-0 m-b-3'>Bonus</H6>
				<Card backgroundColor={backgrounds.white} width=' '>
					{bonus.map((bonusTask, index) => {
						const active = bonusTask.taskId === task?.taskId ? true : false
						console.log(bonusTask)
						let iconLeft, handleClick
						switch (bonusTask.type) {
							case "audio-file":
								iconLeft = audioIcon
								handleClick = () =>
									history.push(`/skill/${skillId}/audio/${bonusTask.taskId}`, {
										from: location.pathname
									})
								break
							case "video":
								iconLeft = videoIcon
								handleClick = () =>
									history.push(`/skill/${skillId}/video/${bonusTask.taskId}`, {
										from: location.pathname
									})
								break
							case "survey":
								iconLeft = exerciseIcon
								handleClick = () =>
									history.push(`/skill/${skillId}/bot/${bonusTask.taskId}`, {from: location.pathname})
								break
							case "tips":
								iconLeft = exerciseIcon
								handleClick = () =>
									history.push(`/skill/${skillId}/tips/${bonusTask.taskId}`, {
										from: location.pathname
									})
								break
						}

						return (
							<TaskButton
								last={index === bonus.length - 1 ? true : false}
								type='bonus'
								active={active}
								key={index}
								index={index}
								activity={activity}
								task={bonusTask}
								skillId={skillId}
								iconLeft={iconLeft}
								completedIcon={completedIcon}
								handleClick={() => handleClick()}
							/>
						)
					})}
				</Card>
			</BonusStyle>
		)
	} else {
		return null
	}
}

function mapStateToProps(state) {
	const {task} = state
	return {task}
}

export default connect(mapStateToProps, null)(Bonus)

Bonus.propTypes = {
	bonus: array.isRequired
}
