import React from "react"
import {array, object, string} from "prop-types"
import {TaskButton} from "@uprise/button"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Spacing
import {spacing} from "@uprise/spacing"
// UI
import {Card} from "@uprise/card"
import {H6} from "@uprise/headings"
// icons
import Icons from "assets/images/icons/svg"

const ReadingsStyle = Styled.section`
	padding: 0 ${spacing.s10} 0 ${spacing.s10};
	width: 100%;

	
	@media (max-width: 1024px) {
		padding: ${spacing.s8} ${spacing.s8} 0 ${spacing.s8};
	}
`

const Readings = ({className, faqs, summary, practice, science, examples, skillId, activity, task}) => {
	const history = useHistory()
	let location = useLocation()

	// Icons
	const exerciseIcon = Icons.exercise

	const taskId = location.pathname.split("/")[1]

	const shouldShowReadings =
		faqs?.length > 0 || practice?.length > 0 || summary?.length > 0 || science?.length > 0 || examples?.length > 0

	if (shouldShowReadings) {
		return (
			<ReadingsStyle className={className}>
				{shouldShowReadings && <H6 className='m-t-0 m-b-3'>Readings</H6>}
				<Card backgroundColor={backgrounds.white} width=' '>
					{summary?.length > 0 && (
						<TaskButton
							type='summary'
							active={taskId === "summary"}
							activity={activity}
							task={summary}
							last={faqs?.length > 0 || practice?.length > 0 ? false : true}
							skillId={skillId}
							iconLeft={exerciseIcon}
							handleClick={() => history.push(`/summary/${skillId}`, {from: location.pathname})}
						/>
					)}
					{faqs?.length > 0 && (
						<TaskButton
							type='faqs'
							activity={activity}
							task={faqs}
							active={taskId === "faqs"}
							skillId={skillId}
							last={practice?.length > 0 ? false : true}
							iconLeft={exerciseIcon}
							handleClick={() => history.push(`/faqs/${skillId}`, {from: location.pathname})}
						/>
					)}
					{practice?.length > 0 && (
						<TaskButton
							type='practice'
							active={taskId === "practice"}
							activity={activity}
							task={practice}
							skillId={skillId}
							last={true}
							iconLeft={exerciseIcon}
							handleClick={() => history.push(`/practice/${skillId}`, {from: location.pathname})}
						/>
					)}
					{science?.length > 0 && (
						<TaskButton
							type='science'
							active={taskId === "science"}
							activity={activity}
							task={science}
							skillId={skillId}
							last={true}
							iconLeft={exerciseIcon}
							handleClick={() => history.push(`/science/${skillId}`, {from: location.pathname})}
						/>
					)}
					{examples?.length > 0 && (
						<TaskButton
							type='examples'
							active={taskId === "examples"}
							activity={activity}
							task={examples}
							skillId={skillId}
							last={true}
							iconLeft={exerciseIcon}
							handleClick={() => history.push(`/examples/${skillId}`, {from: location.pathname})}
						/>
					)}
				</Card>
			</ReadingsStyle>
		)
	} else {
		return null
	}
}

function mapStateToProps(state) {
	const {task} = state
	return {task}
}

export default connect(mapStateToProps, null)(Readings)

Readings.propTypes = {
	skillId: string.isRequired,
	activity: object.isRequired
}
