import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import {useHistory, useParams} from "react-router-dom"
import {NavLink} from "react-router-dom"
import FormValidate from "form-validate.js"
import Styled from "styled-components"
import moment from "moment-timezone"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {allianzColors, backgrounds, primary, semantic} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {H6} from "@uprise/headings"
import {ExtraSmall, Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
import {Dropdown} from "@uprise/dropdown"

// Validation
import constraints from "validation/allianzSignup"
// Inputs
import {TextInput, Select} from "@uprise/form"
// Api
import {post, get} from "helpers/api"
// Utils
import {handleError} from "./utils"
// Country Codes
import CountryCodes from "utils/countryCodes"
import CountryList from "utils/countryList"
import Datepicker from "../DatePicker"
import FullWidthDropdown from "../FullWidthDropdown"
import {
	InfoIcon,
	InfoImage,
	InfoText,
	PolicyInputContainer,
	PolicyToolTipContainer,
	PolicyWrapper,
	ToolTip
} from "./index.style"
import {text} from "@uprise/typography"

const PhoneInputWrapper = Styled.div`
	display : flex;
`

const TextInputWrapper = Styled(TextInput)`
	width: 100%;
	input[id="phone"]{
		margin-top:6px;
	}
`
const NameInputWrapper = Styled.div`
	display:flex;
	justify-content:space-between;
`
const PasswordMessage = Styled.div`
	font-size:${text.t3};
	margin-top:-15px;
	margin-bottom:${spacing.s4};
	color:${allianzColors.black2}
`
const PhoneNumberMessage = Styled.div`
	font-size:${text.t3};
	margin-top:-15px;
	margin-bottom:${spacing.s4};
	color:${allianzColors.black2}
`

const SmallNote = Styled(Note)`
	font-size:12px;
	margin-top:20px;
	margin-bottom:20px;
`
const userTypes = [
	{
		label: "Domestic Student",
		value: "domestic_student"
	},
	{
		label: "International Student",
		value: "international_student"
	},
	{
		label: "Staff",
		value: "staff"
	}
]

const GENDER_LIST = [
	{
		label: "Male",
		value: "male"
	},
	{
		label: "Female",
		value: "female"
	},
	{
		label: "GenderX",
		value: "genderx"
	}
]
const destinationCountryList = [
	// {label: "Australia", value: "AU", name: "+61"},
	{label: "New Zealand", value: "NZ", name: "+64"}
]
const SIGNUP_TYPE = [
	{label: "Policy Number", value: "policy_number"},
	{label: "Access Code", value: "access_code"}
]

const AllianzSignup = ({children}) => {
	let history = useHistory()
	const {code = ""} = useParams()

	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [countryCode, setCountryCode] = useState(CountryCodes[132].label)
	const [countryName, setCountryName] = useState(CountryCodes[132].name)
	const [phone, setPhone] = useState("")
	const [password, setPassword] = useState("")
	const [accessCode, setAccessCode] = useState(code)
	const [error, setError] = useState({type: "", value: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [typeName, setTypeName] = useState()
	const [typeValue, setTypeValue] = useState()
	const [institutions, setInstitutions] = useState([])
	const [institutionName, setInstitutionName] = useState("")
	const [institutionValue, setInstitutionValue] = useState("")
	const [policyNumber, setPolicyNumber] = useState("")
	const [dob, setDOB] = useState()

	const [selectedDestinationCountry, setSelectedDestinationCountry] = useState(CountryCodes[132].label)
	const [selectedDestinationCountryName, setSelectedDestinationCountryName] = useState(CountryCodes[132].name)

	const [selectedNationality, setSelectedNationality] = useState()
	const [selectedNationalityName, setSelectedNationalityName] = useState()

	const [selectedGender, setSelectedGender] = useState()
	const [selectedGenderName, setSelectedGenderName] = useState()

	const [selectedSignupWith, setSelectedSignupWith] = useState("")

	const [studentId, setStudentId] = useState()

	const [customValidation, setCustomValidation] = useState({
		dob: "",
		institutionName: "",
		typeName: "",
		isOtherInvalid: "",
		destinationCountry: "",
		gender: "",
		nationality: "",
		signupWith: "",
		policyNumber: "",
		studentId: ""
	})

	const [showToolTip, setShowToolTip] = useState(false)

	useEffect(() => {
		const validator = new FormValidate(constraints, null, {
			accessCode: code
		})
		setValidator(validator)
		getInstitutions()
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			console.log(valid, controls)
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		let error = {
			typeName: "",
			institutionName: "",
			dob: "",
			isOtherInvalid: false,
			destinationCountry: "",
			signupWith: "",
			policyNumber: "",
			studentId: ""
		}
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				console.log(controls)
				setValidation(controls)
				error.isOtherInvalid = true
				// a good place to update the validation being displayed.
			})
		}
		if (!dob) {
			error["dob"] = "Please select valid dob"
		}
		if (!institutionName || institutionName.length <= 0) {
			error["institutionName"] = "Please select valid institution"
		}
		if (!typeName || typeName.length <= 0) {
			error["typeName"] = "Please select valid type"
		}
		if (!selectedDestinationCountry) {
			error["destinationCountry"] = "Please select valid destination country"
		}
		if (
			selectedDestinationCountry &&
			selectedDestinationCountry === "AU" &&
			(!policyNumber || policyNumber.toString().length < 2)
		) {
			error["policyNumber"] = "Please enter valid policy number"
		}
		if (
			selectedDestinationCountry &&
			selectedDestinationCountry === "NZ" &&
			(!studentId || studentId.toString().length < 2)
		) {
			error["studentId"] = "Please enter valid student id"
		}
		if (
			error.dob ||
			error.typeName ||
			error.institutionName ||
			error.isOtherInvalid ||
			error.destinationCountry ||
			error.signupWith ||
			error.policyNumber ||
			error.studentId
		) {
			setCustomValidation(error)
			return
		} else {
			_handleSignUp()
		}
	}

	const _handleSignUp = () => {
		setIsLoading(true)
		let data = {
			password,
			email: email.toLowerCase().trim(),
			firstName,
			lastName,
			phone: phone.trim(),
			countryCode,
			countryName,
			agreeTerms: true,
			institutionId: institutionValue,
			type: typeValue,
			policyNumber: policyNumber || "",
			dob: moment(dob).format("DD/MM/YYYY"),
			timezone: moment.tz.guess() || "Australia/Sydney",
			destinationCountry: selectedDestinationCountryName,
			studentId,
			gender: selectedGenderName,
			nationality: selectedNationalityName
		}
		console.log(data)
		post("signup-allianz", data)
			.then(response => {
				console.log(response)
				if (response?.code === "success") {
					history.push("/allianz-signup-confirmation", {
						email: email.toLowerCase().trim()
					})
				} else {
					setError(handleError(response?.error || "Error while signing up, please try again"))
				}
			})
			.catch(error => {
				console.error(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const getInstitutions = async () => {
		const institutions = await get("get-institutions")
		if (institutions && institutions.data && institutions.data.length > 0) {
			let values = []
			institutions.data.map((el, index) => {
				values.push({
					label: el.institutionName,
					value: el.institutionName
				})
			})
			setInstitutions(values)
		}
	}

	return (
		<>
			<Fragment>
				<H6 className='m-b-10' textAlign='center'>
					Sign up using the details provided on Your
					<br />
					Allianz Inbound Medical and Travel Insurance policy
				</H6>
				<Card
					className='m-b-4'
					backgroundColor={backgrounds.white}
					shadow={true}
					padding={spacing.s5}
					width='100%'>
					{error?.text?.length > 0 && (
						<Alert className='m-b-5' type={error.type}>
							{error.text}
						</Alert>
					)}

					<form onChange={event => _validateForm(event)}>
						<NameInputWrapper>
							<TextInput
								onChange={e => setFirstName(e.target.value)}
								validation={validation}
								value={firstName}
								validateControl='name'
								id='firstName'
								name='firstName'
								label='First Name'
								isFirst
								isRequired
								style={{
									flex: 0.49
								}}
							/>
							<TextInput
								onChange={e => setLastName(e.target.value)}
								validation={validation}
								value={lastName}
								validateControl='name'
								id='lastName'
								name='lastName'
								label='Last Name'
								isFirst
								isRequired
								style={{
									flex: 0.49
								}}
							/>
						</NameInputWrapper>
						<Datepicker
							date={dob}
							onChange={e => setDOB(e.target.value)}
							isRequired={true}
							allowPreviousDates={true}
							disableFutureDates={true}
							label='Date of Birth'
							validation={customValidation}
							name='dob'
							id='dob'
							placeholder=''
						/>
						<FullWidthDropdown
							validation={customValidation}
							id='institutionName'
							name='institutionName'
							isFullWidth={true}
							title={"University | Institution | Organisation*"}
							items={institutions}
							label=''
							onChange={value => {
								setInstitutionName(value.label)
								setInstitutionValue(value.value)
								if (value)
									setCustomValidation({
										...customValidation,
										institutionName: ""
									})
							}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
						/>
						<FullWidthDropdown
							initialSelectedItem={destinationCountryList[0]}
							validation={customValidation}
							id='destinationCountry'
							name='destinationCountry'
							isFullWidth={true}
							title={"Destination Country*"}
							items={destinationCountryList}
							label=''
							onChange={value => {
								console.log(value)
								setSelectedDestinationCountry(value.value)
								setSelectedDestinationCountryName(value.label)
								if (value)
									setCustomValidation({
										...customValidation,
										destinationCountry: ""
									})
							}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
						/>
						<PasswordMessage>
							Destination Country where your University, Institution or Organisation who is providing you
							with this service.
						</PasswordMessage>
						<FullWidthDropdown
							validation={customValidation}
							id='typeName'
							name='typeName'
							isFullWidth={true}
							title={"Status*"}
							items={userTypes}
							label=''
							onChange={value => {
								setTypeName(value.label)
								setTypeValue(value.value)
								if (value)
									setCustomValidation({
										...customValidation,
										typeName: ""
									})
							}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
						/>
						{selectedDestinationCountry === "AU" && (
							<>
								<PolicyWrapper>
									<PolicyInputContainer>
										<TextInput
											onChange={e => {
												setPolicyNumber(e.target.value)
												if (e.target.value) {
													setCustomValidation({
														...customValidation,
														policyNumber: ""
													})
												}
											}}
											validation={customValidation}
											value={policyNumber}
											id='policyNumber'
											name='policyNumber'
											label='Policy Number'
											isRequired
											isLast
										/>
									</PolicyInputContainer>
									<PolicyToolTipContainer>
										<InfoIcon onClick={() => setShowToolTip(true)}>
											<InfoImage src={require("assets/images/allianz/info-icon.png")} />
										</InfoIcon>
									</PolicyToolTipContainer>
								</PolicyWrapper>
								<div style={{marginBottom: 21, color: semantic.error, fontSize: 12, marginTop: 10}}>
									{customValidation?.policyNumber || ""}
								</div>
							</>
						)}

						<>
							<TextInput
								onChange={e => {
									setStudentId(e.target.value)
									if (e.target.value) {
										setCustomValidation({
											...customValidation,
											studentId: ""
										})
									}
								}}
								style={{
									marginBottom: 10
								}}
								value={studentId}
								id='studentId'
								name='studentId'
								label={`Student Id${selectedDestinationCountry === "AU" ? "(Optional)" : "*"}`}
								validation={customValidation}
							/>
							<div style={{marginBottom: 21, color: semantic.error, fontSize: 12}}>
								{customValidation?.studentId || ""}
							</div>
						</>
						<TextInput
							onChange={e => setEmail(e.target.value)}
							validation={validation}
							value={email}
							id='email'
							name='email'
							label='Email Address'
							isRequired
						/>

						<PhoneInputWrapper>
							<FullWidthDropdown
								isFullWidth={false}
								className='m-r-2'
								title={"Country code"}
								items={CountryCodes}
								label='Select Code'
								onChange={value => {
									setCountryCode(value.label)
									setCountryName(value.name)
								}}
								initialSelectedItem={CountryCodes[132]}
								menuStyle={{position: "absolute", zIndex: 2}}
								customListItemRender={item => (
									<div style={{display: "flex", justifyContent: "space-between"}}>
										<p>{item.label}</p>
									</div>
								)}
							/>
							<TextInputWrapper
								onChange={e => setPhone(e.target.value)}
								validation={validation}
								value={phone}
								id='phone'
								type='text'
								name='phone'
								label='Phone number'
								isRequired
							/>
						</PhoneInputWrapper>
						<PhoneNumberMessage>
							Phone number is required for the Uprise Coach to contact you.
						</PhoneNumberMessage>
						<TextInput
							onChange={e => setPassword(e.target.value)}
							validation={validation}
							value={password}
							type='password'
							id='password'
							name='password'
							label='Password'
							isRequired
						/>
						<PasswordMessage>
							Write down your password to help you remember next time you Log In.
						</PasswordMessage>
						<FullWidthDropdown
							validation={customValidation}
							id='gender'
							name='gender'
							isFullWidth={true}
							title={"Gender (Optional)"}
							items={GENDER_LIST}
							label=''
							onChange={value => {
								setSelectedGender(value.value)
								setSelectedGenderName(value.label)
								if (value)
									setCustomValidation({
										...customValidation,
										gender: ""
									})
							}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
						/>
						<FullWidthDropdown
							validation={customValidation}
							id='nationality'
							name='nationality'
							isFullWidth={true}
							title={"Nationality (Optional)"}
							items={CountryList}
							label=''
							onChange={value => {
								setSelectedNationality(value.value)
								setSelectedNationalityName(value.label)
								if (value)
									setCustomValidation({
										...customValidation,
										nationality: ""
									})
							}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
							isLast={true}
						/>
					</form>
				</Card>
				<ExtraSmall className='m-b-4'>
					By continuing, you agree to the{" "}
					<a
						href={`https://uprise.co/terms`}
						target='__blank'
						rel='noopener noreferrer'
						style={{color: primary.purple, fontWeight: "bold"}}>
						Terms
					</a>{" "}
					and{" "}
					<a
						href={`https://uprise.co/privacy`}
						target='__blank'
						rel='noopener noreferrer'
						style={{color: primary.purple, fontWeight: "bold"}}>
						Privacy Policy
					</a>
					.
				</ExtraSmall>
				<Button
					data-testid='sign-up-button'
					variant='primary'
					className='m-b-4'
					title='Sign up'
					size='large'
					fullWidth={true}
					isLoading={isLoading}
					disabled={false}
					onClick={() => _handleSubmit()}
				/>
				<Note>
					<NavLink to={`/sign-in`} style={{color: primary.purple, fontWeight: "bold"}}>
						{"Sign in >"}
					</NavLink>
				</Note>
				<SmallNote>
					The Allianz Mental Wellbeing app, including any services are provided, managed and administered by
					Uprise Services Pty Ltd ACN 610 179 229. AWP Services New Zealand Limited, trading as Allianz
					Partners, nor any of its related bodies corporate, manage, administer or have any liability in
					respect to the services provided by Uprise Services Pty Ltd.
					<br/>
					<b>IMPORTANT</b>: The Allianz Mental Wellbeing app, does not provide clinical counselling or
					therapy, medical advice, treatment or diagnosis. The Allianz Mental Wellbeing app is not a
					substitute for medical advice or treatment. The services provided by Uprise are not designed for
					crisis management. If you are in need of medical treatment or emergency care, please seek immediate
					assistance from a medical or mental health professional.
				</SmallNote>
			</Fragment>
			{showToolTip && (
				<ToolTip>
					<InfoText>
						<b>Policy Number Information</b>
					</InfoText>
					<InfoText>
						<b>Australia</b> - Allianz Mental Wellbeing New Zealand - OSHC Policy holders can find the
						policy number (e.g. P000000000) via Allianz MyHealth app or refer to your Welcome Letter.
					</InfoText>
					<InfoText className='last'>
						<b>New Zealand</b> - InsurancesafeNZ - For Student Safe use Student ID (or Policy Number if
						Student ID is unknown)
					</InfoText>
					<Button
						data-testid='sign-up-button'
						variant='primary'
						className='m-b-4'
						title='OK'
						size='large'
						fullWidth={true}
						disabled={false}
						onClick={() => setShowToolTip(false)}
					/>
				</ToolTip>
			)}
		</>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
AllianzSignup.propTypes = {}

AllianzSignup.defaultProps = {}

export default AllianzSignup
