import React from "react"
import styled from "styled-components"
import EmptyStarIcon from "components/Icons/EmptyStarIcon"
import HalfStar from "components/Icons/HalfStar"
import StarIcon from "components/Icons/StarIcon"

const StarWrapper = styled.span`
	height: 16px;
	width: 16px;
	margin: 3px;
`

const StarRating = ({rating = 0, total = 5}) => {
	const halfFilled = rating % 1 < 0.3 ? 0 : 1
	const filledStars = parseInt(rating)
	const emptyStars = total - (filledStars + halfFilled)

	return (
		<>
			{[...new Array(filledStars).keys()].map((_, index) => (
				<StarWrapper key={`filled_star${index}`}>
					<StarIcon />
				</StarWrapper>
			))}
			{halfFilled >= 0.3 && (
				<StarWrapper>
					<HalfStar />
				</StarWrapper>
			)}
			{[...new Array(emptyStars).keys()].map((_, index) => (
				<StarWrapper key={`empty_star${index}`}>
					<EmptyStarIcon />
				</StarWrapper>
			))}
		</>
	)
}

export default StarRating
