import {AccordianContent, AccordianHeader, AccordianItem} from "@uprise/accordian"
import {Col, Container, Row} from "@uprise/grid"
import {H2, H5, H6} from "@uprise/headings"
import {Medium, P} from "@uprise/text"
import React from "react"

function HelpMeInterpretModal() {
	return (
		<Container>
			<Row>
				<H2>Help me interpret this</H2>
			</Row>
			<Row>
				<H5>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consequat orci quis dignissim
					egestas. Nulla fringilla libero a tempor consequat. Nulla facilisi. Duis tempus egestas orci, vitae
					viverra leo interdum at. In nec dui nec nunc ornare imperdiet. Aliquam venenatis lectus quis elit
					dignissim, quis cursus velit ultrices. Nulla tincidunt iaculis tortor id maximus. In hac habitasse
					platea dictumst. Nunc pharetra ante ligula, eu molestie ligula convallis sed. Maecenas sit amet eros
					id lectus suscipit fringilla. Donec dignissim sapien quis leo dictum, at tincidunt est porta. Nulla
					ac pulvinar nisl. Morbi sodales purus ut luctus luctus. Nulla facilisi. Curabitur magna urna,
					blandit sit amet velit eget, aliquet porttitor eros. Phasellus consequat facilisis quam. Mauris
					pharetra tellus quis turpis efficitur mattis. Ut lacinia odio eget neque convallis auctor. Orci
					varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Interdum et
					malesuada fames ac ante ipsum primis in faucibus. Suspendisse.
				</H5>
			</Row>
			<Row>
				<AccordianItem className='container'>
					<AccordianHeader>Title 1</AccordianHeader>
					<AccordianContent>Content 1</AccordianContent>
				</AccordianItem>
				<AccordianItem className='container'>
					<AccordianHeader>Title 1</AccordianHeader>
					<AccordianContent>Content 1</AccordianContent>
				</AccordianItem>
				<AccordianItem className='container'>
					<AccordianHeader>Title 1</AccordianHeader>
					<AccordianContent>Content 1</AccordianContent>
				</AccordianItem>
			</Row>
		</Container>
	)
}

export default HelpMeInterpretModal
