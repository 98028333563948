export const wellbeingCheckRuokday = [
	{
		_id: 0,
		text: "Hi welcome to the wellbeing check 🙂",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "Please, answer the following questions to check in on how you're going:",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text:
			"We provide de-identified group data back to your employer but those reports never name anyone individually.",
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "wellbeing-check-v1",
		taskId: "survey-wellbeing-check",
		questions: [0, 1, 2, 3, 4, 5],
		questionIndex: 0,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_end: true,
		text: "Thanks for your update.. Calculating your scores, please wait..  📊",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
