import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {P} from "@uprise/text"
import {H3, H5} from "@uprise/headings"
import {Card} from "@uprise/card"
import {extended, backgrounds} from "@uprise/colors"
import {spacing} from "@uprise/spacing"

import {LogosHeader} from "./LogosHeader"
import ContactsForm from "./ContactsForm"

const PreOnboardingConsentWrapper = Styled.div`
	max-width: 950px;
	margin: 0 auto;
`

function PreOnboardingConsent({handleNext}) {
	return (
		<PreOnboardingConsentWrapper>
			<Card backgroundColor={backgrounds.white} padding={spacing.s10}>
				<LogosHeader />

				<H3 className='m-t-5 text-center'>A pilot trial of the Workable by Uprise program</H3>
				<H5 className='m-t-10'>
					Thank you for agreeing to take part in the pilot trial of the Workable program by Uprise.
				</H5>
				<P>
					In order to aid your recovery, it may be helpful for Uprise to contact other professionals involved
					in your care. Your Uprise coach will discuss this with you prior to making contact with any other
					service providers assisting you.
				</P>
				<br />
				<P>
					<b>
						Please write in the table below the names and contact details of any service providers that you
						agree to Uprise contacting.{" "}
					</b>
				</P>
				<br />
				<P>
					By providing the names and/or contact details of these service providers, you are giving permission
					for Uprise to contact these service providers in order to collect any personal or health information
					that would be helpful to manage your recovery, and to discuss the best ways to assist you.{" "}
				</P>
				<br />
				<P>
					Any information collected by Uprise will remain confidential. If you do not know the contact details
					of your service providers now, you can provide them to your Uprise coach later. You can change or
					withdraw your consent at any time by speaking to your Uprise coach.
				</P>
				<br />
				<P>
					However, please remember that in the unlikely event that your safety is at risk, your Uprise coach
					will need to contact relevant services to ensure that you receive appropriate support, regardless of
					your responses below.
				</P>

				<ContactsForm className='m-t-20' />
			</Card>
		</PreOnboardingConsentWrapper>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(PreOnboardingConsent)
