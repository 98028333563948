import React, {useEffect} from "react"
import Styled from "styled-components"
import {useHistory, useLocation, useParams} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended} from "@uprise/colors"

// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"

// Actions
import {getCoachByEmail} from "actions/coachActions"

// UI
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import CoachCard from "components/Shared/Coach/CoachCard"

const Wrap = Styled.section`	
	background-color: ${extended.purple.six}; 
	width: 100%;
`

const CoachDetail = ({userState, getCoachByEmail, coach}) => {
	let history = useHistory()
	let location = useLocation()
	let params = useParams()

	let coachEmail = React.useMemo(() => params.email, [])

	useEffect(() => {
		if (coachEmail) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail])

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={3} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Coach Details'
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
					handleBack={() => history.push(location?.state?.from)}
				/>
				<Main>
					<section className='m-t-4 m-b-10'>
						<CoachCard className='m-b-10' coach={coach} pick={userState.coach !== coachEmail} />
					</section>
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-none d-xl-block' width='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach
	}
}

const mapDispatchToProps = dispatch => ({
	getCoachByEmail: email => dispatch(getCoachByEmail(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoachDetail)
