import PropTypes from "prop-types"
import React, {Fragment} from "react"
// Components
import BotLoader from "components/Shared/Chat/BotLoader"
// Content
import {OnboardingIntro} from "components/Shared/Onboarding/intro"
import {OnboardingScores} from "components/Shared/Onboarding/scores"

const OnboardingSteps = ({progressCheckpoints, handleNext, handleComplete, userState, dialogueId}) => {
	const [
		introCheckpoint,
		wellbeingCheckCheckpoint,
		stressCheckCheckpoint,
		personalisationCheckPoint,
		scoreCheckpoint
	] = progressCheckpoints

	const showChatBot =
		wellbeingCheckCheckpoint["active"] || stressCheckCheckpoint["active"] || personalisationCheckPoint["active"]

	return (
		<Fragment>
			{introCheckpoint && introCheckpoint["active"] && <OnboardingIntro handleNext={id => handleNext(id)} />}

			{showChatBot && (
				<BotLoader userState={userState} handleNext={() => handleNext(4)} dialogueId={dialogueId} />
			)}

			{scoreCheckpoint && scoreCheckpoint["active"] && (
				<OnboardingScores
					dialogueId={dialogueId}
					surveyResults={userState.surveyResults}
					handleNext={() => handleComplete()}
				/>
			)}
		</Fragment>
	)
}

OnboardingSteps.propTypes = {
	handleComplete: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
	progressCheckpoints: PropTypes.array.isRequired,
	userState: PropTypes.object.isRequired
}

export default OnboardingSteps
