import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"

// Components
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H3, H5} from "@uprise/headings"
import {Medium} from "@uprise/text"
// Colors
import {extended, backgrounds, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// helpers
import {isWhiteLabeledApp} from "helpers/utils"

const CardWrap = Styled.div`
	transition: .3s ease;
	&:hover {
		transform: translateY(-1px) scale(1.01);
	}
`

const FeaturedSkillStyles = Styled.section`

	@media (max-width: 1024px) {
		position: relative;
	}

	@media (max-width: 475px) {
		position: relative;
	}
`

const ContentWrap = Styled.section`
  position: relative;
	display: flex;
	flex-direction: row;

	&:hover {
		cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column
	}

	@media (max-width: 475px) {
		flex-direction: column
	}
`

const FeaturedSkillImage = Styled.img`
	border-top-right-radius: 0px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	width: 400px;

	@media (max-width: 1024px) {
		width: 100%;
		min-width: 100%;
	}

	@media (max-width: 475px) {
		width: 100%;
		min-width: 100%;
		min-height: 218px;
	}
`

const ContentRight = Styled.div`
	padding: ${spacing.s7} ${spacing.s9};
	width: 100%;

	@media (max-width: 1024px) {
		position: absolute;
		padding: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@media (max-width: 475px) {
		position: absolute;
		padding: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`

const SkillDetails = Styled.div`
	background-color: ${backgrounds.white};

	@media (max-width: 1024px) {
		margin-right: ${spacing.s5};
		margin-left: ${spacing.s5};
		padding: ${spacing.s5};
		border-radius: 10px;
	}

	@media (max-width: 475px) {
		background-color: rgba(255, 255, 255, 0.9);
		margin-right: ${spacing.s5};
		margin-left: ${spacing.s5};
		padding: ${spacing.s5};
		border-radius: 10px;
	
		.hide-xs {
			display: none;
		}
	}
`

const ButtonWrapper = Styled.div`
	width: 100%;

	@media (min-width: 768px) {
		width: 171px;
	}
`

const FeaturedSkill = ({className}) => {
	const history = useHistory()
	const [moduleData, setModuleData] = useState({
		id: "healthAndDiseaseAnxiety",
		title: "Worried about coronavirus?",
		thumb: "health-and-disease-anxiety",
		subText: "Coping with stress, grief, and uncertainty",
		description: `
			We’re living in a stressful time. It’s important to take care of our mental
			health.
		</span>
		&nbsp;
		<span className='hide-xs'>
			In this course, learn skills to deal with coronavirus anxiety , working from
			home, diagnosis, and job loss.
			
		`
	})
	let location = useLocation()

	useEffect(() => {
		const isWhiteLabel = isWhiteLabeledApp()
		if (!isWhiteLabel) {
			setModuleData({
				id: "cybercrimeUprise",
				title: "Cybercrime",
				thumb: "cybercrime-blue",
				subText: "Be supported for different impacts of cybercrime.",
				description:
					"If you've been impacted by cybercrime, it is common to experience stress, worry, anger or sleeplessness. This course is designed to support you and your resilience."
			})
		}
	}, [])

	const _handleClick = () => {
		history.push(`/skill/${moduleData.id}`, {from: location.pathname})
	}

	return (
		<FeaturedSkillStyles className={className}>
			<H3 className='m-b-4'>{moduleData.title}</H3>
			<CardWrap>
				<Card onClick={_handleClick} shadow={true} backgroundColor={backgrounds.white}>
					<ContentWrap>
						<FeaturedSkillImage src={thumbnails[`${moduleData.thumb}@3x`]} />
						<ContentRight>
							<SkillDetails className='m-b-xs-4 m-b-sm-4'>
								<H5 weight='bold' color={primary.charcoal} className='m-t-0 m-b-3 m-b-xs-1'>
									{moduleData.subText}
								</H5>
								<Medium
									lineHeight={1.33}
									color={extended.charcoal.one}
									className='m-b-6 m-b-xs-3 line-clamp-xs-2'
									>
									<span dangerouslySetInnerHTML={{__html: moduleData.description}} />
								</Medium>
								<ButtonWrapper>
									<Button
										size='medium'
										fullWidth={true}
										borderRadius='10px'
										variant={"primary"}
										onClick={_handleClick}
										title='Start now'
									/>
								</ButtonWrapper>
							</SkillDetails>
						</ContentRight>
					</ContentWrap>
				</Card>
			</CardWrap>
		</FeaturedSkillStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(FeaturedSkill)
