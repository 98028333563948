import React, {useState, useEffect, useRef} from "react"
// import ProgressCircle from "react-native-progress-circle"
// import Image from "react-native-remote-svg"
import {Doughnut} from "react-chartjs-2"

// Components
import {Card} from "@uprise/card"
import {Container, Col} from "@uprise/grid"
// colors
import {backgrounds, primary, extended, secondary} from "@uprise/colors"
import Styled, {css} from "styled-components"

// icons
import Icons from "assets/images/icons/svg"

const Title = Styled.h2`
	margin-bottom: 15px;
	font-size: 24px;
`

const ChartWrap = Styled.div`
	display: flex;
	width: 177px;
	height: 177px;
	align-self: center;
	margin-bottom: 30px;
`

const Content = Styled.div`
	display: flex;
	flex-direction: column;
`

const Emoji = Styled.img`
	height: 100px;
	width: 100px;
	margin-top: 45px;
	margin-left: 38px;
	position: absolute;
`

export const SurveyScores = ({onNext, onNextLabel, screener}) => {
	let color, shadow, image

	if (screener.dialogueId === "stressCheck") {
		if (screener.score < 28) {
			image = require("assets/images/emojis/emoji-high-score-2x.png")
		} else if (screener.score <= 50) {
			image = require("assets/images/emojis/emoji-mid-score-2x.png")
		} else {
			color = primary.purple
			image = require("assets/images/emojis/emoji-low-score-2x.png")
		}
	} else if (screener.dialogueId === "wellbeingCheck") {
		if (screener.score < 28) {
			image = require("assets/images/emojis/emoji-low-score-2x.png")
		} else if (screener.score <= 50) {
			image = require("assets/images/emojis/emoji-mid-score-2x.png")
		} else {
			image = require("assets/images/emojis/emoji-high-score-2x.png")
		}
	}

	const data = {
		datasets: [
			{
				data: [screener.score, 100 - screener.score],
				backgroundColor: [primary.purple, backgrounds.fadedPurple],
				borderColor: [primary.purple, backgrounds.fadedPurple],
				hoverBackgroundColor: [primary.purple, backgrounds.fadedPurple],
				borderWidth: 0,
				weight: 1
			}
		]
	}

	const options = {
		cutoutPercentage: 74,
		maintainAspectRatio: false,
		tooltips: {enabled: false}
	}

	return (
		<Card className='text-center' padding='15px' backgroundColor={backgrounds.white}>
			<Content>
				<Title>
					{screener.title} score: {screener.score}%
				</Title>
				<ChartWrap>
					<Emoji src={image} />
					<Doughnut cutoutPercentage={10} width={100} height={100} data={data} options={options} />
				</ChartWrap>
				<p>{screener.text}</p>
			</Content>
		</Card>
	)
}
