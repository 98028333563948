export const faqCounselling = [
	{
		header: "Why should I book a counselling call?",
		content:
			"Counselling sessions are helpful to focus on specific problems, changes in life adjustments and fostering your wellbeing. Using this service is a step forward to feeling better."
	},
	{
		header: "What happens on a counselling call?",
		content:
			"Counselling is guided by the user and each session is different and personal. <br /><br />People can explore aspects of identity, spirituality, relationships with self and others, past experiences, parenting, grief and loss, trauma, use of alcohol and other substances, depression, anxiety, and other experiences. Changes facilitated by counselling include change in perspective, new insight, new ways of thinking about situations, new awareness of feelings, enhanced capacity to tolerate and regulate feelings, new actions or behaviours, and new decisions about life."
	},
	{
		header: "Who are the clinicians?",
		content: "All our clinicians are registered psychologists or counsellors."
	},
	{
		header: "Can I keep my clinician or change my clinician?",
		content:
			"You can keep the same clinician all the way through your program or change your clinician at any time."
	},
	{
		header: "How long does a counselling call take?",
		content: "A counselling call takes only 50-60 mins."
	},
	{
		header: "What do I need to do before a counselling call?",
		content:
			"We recommend you come prepared with the specific topic you want to explore during your session. Make sure you are in a quiet place to take your session."
	},
	{
		header: "How many counselling calls can I book?",
		content:
			"That depends on your organisation’s subscription plan. If there is a limit, you’ll see a message about it when booking a counselling call. When your calls have depleted a button to request additional sessions will show up. This triggers an anonymous request to your organisation."
	},
	{
		header: "Can I cancel/reschedule a counselling call?",
		content:
			"Yes, you can cancel or reschedule a counselling call up to 24 hours before it happens. If you cancel or reschedule a session within 24 hours, the call will be deducted from the total of sessions available to you."
	},
	{
		header: "What about my privacy? Are counselling calls recorded.",
		content:
			"No, we take your privacy seriously. Counselling calls are not recorded anywhere, and what you discuss with your clinician remains confidential, except if your own or another person’s safety is at risk."
	}
]

export const faqCoaching = [
	{
		header: "Why should I book a coaching call?",
		content:
			"Coaching calls make you feel more balanced. Your coach will show you how to personalise the skills in the app  so you can use them as hacks to get through your specific life challenges much smoother. Calls are more effective if you complete skills in the app before your call. Coaching calls are rated 4.6 out of 5."
	},
	{
		header: "What happens on a coaching call?",
		content:
			"Your coach will personalise the Uprise app skills to your current situation so you can apply evidence-based tools in a practical way and move from the theory to a real impact in your life. Coaching is guided by the user and each session is different and personal.<br /><br />Coaching can help you to:<ul><li><strong>Keep accountable for personal, work or wellbeing goals</strong> such as improving habits, becoming more productive, losing weight and other goals that help you feel a better version of yourself; </li><li><strong>Improve your relationships with others and yourself</strong> like preparing for a difficult conversation, becoming more confident, letting go of things you cannot control, developing your communication and people management skills; </li><li><strong>Reassessing your values and motivation</strong> to feel more balanced, </li><li><strong>learn healthy ways to cope with stress, fatigue and life balancing to avoid exhaustion</strong></li></ul>"
	},
	{
		header: "Who are the coaches?",
		content: "All our coaches are registered psychologists or counsellors."
	},
	{
		header: "Can I keep my coach or change my coach?",
		content: "You can keep the same coach all the way through your program or change your coach at any time."
	},
	{
		header: "How long does a coaching call take?",
		content: "A coaching call takes 25-30 mins."
	},
	{
		header: "What do I need to do before a coaching call?",
		content:
			"Before a coaching call, you should complete a skill on Uprise, so you can discuss it with your coach. Let your coach know of the skills and exercises you completed in the app and bring questions or reflections about how the skills relate to your life."
	},
	{
		header: "How many coaching calls can I book?",
		content:
			"That depends on your organisation’s subscription plan. If there is a limit, you’ll see a message about it when booking a coaching call."
	},
	{
		header: "Can I cancel/reschedule a coaching call?",
		content: `Yes, you can cancel or reschedule a coaching or counselling session up to 24 hours before it happens. If you cancel or reschedule a session within 24 hours,
			the call will be deducted from the total of sessions available to you.
			<br/><br/>
			Go to the Bookings section of the menu to manage your calls. For more information, contact us at team@uprise.co or 1300 209 371.`
	},
	{
		header: "What about my privacy? Are coaching calls recorded.",
		content:
			"No, we take your privacy seriously. Coaching calls are not recorded anywhere and what you discuss with your coach, remains confidential, except if your own or another person’s safety is at risk."
	}
]

export const COACHING_TOPICS = [
	"Relationship Issue (friend, family, or partner)",
	"Financial Stress",
	"Health issue (self or other)",
	"Work Stress",
	"Study Stress",
	"Job Loss",
	"Personal Mental Health Issue",
	"Lack of Support or Resources",
	"Traumatic or Stressful Live Event",
	"Loneliness/Isolation",
	"Caring for another",
	"Parenting",
	"Recent or upcoming major change or decision",
	"Grief or loss",
	"Bullying",
	"Struggling with Identity",
	"Pressure to perform",
	"Others"
]

/** As the survey component is common for all types of surveys whether it's wellbeing or some module exercise surveys.
 * so, These are particulry the dialogues for which wellbeing or stress scores gets updated. */
export const DIALOGUES_AFFECTING_SCORES = [
	"onboarding-corporate",
	"onboarding-students",
	"ruokday",
	"wellbeingStressWeekly",
	"wellbeingStressMonthly",
	"wellbeingCheck",
	"stressCheck"
]

export const FILTERS = [
	{
		value: "DATE",
		label: "Earliest Availability"
	},
	{
		value: "EXP",
		label: "Years of Experience"
	}
]

export const COACHING_TOPICS_LIST = [
	{
		title: "Relationships",
		index: 0,
		data: [{id: 0, parent: 0, text: "Relationship Issue (friend, family, or partner)"}]
	},
	{
		title: "Personal",
		index: 1,
		data: [
			{id: 1, parent: 1, text: "Identity challenges (culture, gender, etc)"},
			{id: 2, parent: 1, text: "Financial Coaching"},
			{id: 3, parent: 1, text: "Financial stress"},
			{id: 4, parent: 1, text: "Grief / Loss"},
			{id: 5, parent: 1, text: "Loneliness / Isolation"},
			{id: 6, parent: 1, text: "Major life event / Change"},
			{id: 7, parent: 1, text: "Personal growth / Goals / Uprise skills"},
			{id: 8, parent: 1, text: "Self-doubt / Confidence"},
			{id: 9, parent: 1, text: "Spirituality"},
			{id: 10, parent: 1, text: "Trauma"}
		]
	},
	{
		title: "Work-related",
		index: 2,
		data: [
			{id: 11, parent: 2, text: "Career growth & goals"},
			{id: 12, parent: 2, text: "Conflict / Bullying"},
			{id: 13, parent: 2, text: "Job loss"},
			{id: 14, parent: 2, text: "Work stress or dissatisfaction"}
		]
	},
	{
		title: "Health",
		index: 3,
		data: [
			{id: 15, parent: 3, text: "Mental health concern"},
			{id: 16, parent: 3, text: "Physical health issue (self or other)"},
			{id: 17, parent: 3, text: "Substance Use/ Addictions"}
		]
	},
	{
		title: "Other",
		index: 4,
		data: [{id: 18, parent: 4, text: "Other"}]
	}
]
