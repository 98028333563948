import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {Route, Redirect} from "react-router-dom"
import moment from "moment-timezone"
import {useHistory} from "react-router-dom"

// Actions
import {onLoggedIn} from "actions/userStateActions"
// API
import {get} from "helpers/api"
// Components
import {Loader} from "@uprise/loader"

const PrivateRoute = ({location, component, auth, path, userState, onLoggedIn}) => {
	let history = useHistory()

	// redirect ruokday to /ruokday
	// if (window.location.hostname === "ruokday.uprise.co" && window.location.pathname === "/") {
	// 	window.location.replace("/ruokday")
	// }

	const [authState, setAuthState] = useState(false)
	const [loadLogin, setLoadLogin] = useState(false)
	const [errors, setErrors] = useState({})
	const [nextPath] = useState(location.pathname)

	let Component = component

	useEffect(() => {
		if (!authState || !auth) {
			tryLogin()
		}
	}, [])

	const tryLogin = () => {
		return get("state")
			.then(resp => {
				if (resp.error) {
					if (resp.error === "Unauthorised") {
						setLoadLogin(true)
					} else if (resp.error.errors) {
						setLoadLogin(true)
						setErrors(resp.error.errors)
					} else {
						setLoadLogin(true)
						setErrors("An error has occured please contact Technical support")
					}
				} else {
					// Check if employer Code and course set
					if (
						nextPath !== "pre-onboarding-sso" &&
						(!resp.user_state?.employerCode ||
							resp.user_state?.phone?.length === 0 ||
							resp.user_state.name.length === 0)
					) {
						onLoggedIn(resp, "pre-onboarding-sso", history)
						setAuthState(true)
						return
					}

					if (
						/* Redirect the user to onboarding */
						// Corporate
						resp.user_state.activity["survey-onboarding"]?.completed ||
						resp.user_state.activity["onboarding-page"]?.completed ||
						resp.user_state.activity["onboarding-corporate"]?.completed ||
						// RTW
						resp.user_state.activity["onboarding-rtw"]?.completed ||
						// Students
						resp.user_state.activity["onboarding-students"]?.completed
					) {
						onLoggedIn(resp, nextPath, history)
						setAuthState(true)
						return
					} else if (moment(resp.createdAt).format("X") > moment(new Date("5/15/2018")).format("X")) {
						onLoggedIn(resp, "onboarding", history)
						setAuthState(true)
						return
					} else {
						onLoggedIn(resp, nextPath, history)
						setAuthState(true)
						return
					}
				}
			})
			.catch(err => {
				console.log(err)
				setLoadLogin(true)
				setErrors("An error has occured please contact Technical support")
			})
	}

	// handle if trial
	let loadUpgrade = false

	if (userState.features && userState.features.trialLength) {
		const now = moment(new Date())
		const createdAt = moment(userState.createdAt)
		const diff = now.diff(createdAt)
		const timeLeft = moment.duration(userState.features.trialLength * (86400 * 1000) - diff)
		loadUpgrade = timeLeft < 0 ? true : false
	}

	return (
		<Route
			path={path}
			render={props => {
				if (loadUpgrade && path !== "/settings") {
					return authState || auth ? (
						<div {...props} userState={userState}>
							Please contact tech support
						</div>
					) : (
						<div>loading</div>
					)
				} else if (loadLogin) {
					return (
						<Redirect
							to={{
								pathname: "/sign-in",
								state: {
									from: nextPath,
									errors: errors
								}
							}}
						/>
					)
				} else {
					return authState && auth ? (
						<Component {...props} userState={userState} courseType={userState.courseType} />
					) : (
						<Loader />
					)
				}
			}}
		/>
	)
}

const mapDispatchToProps = dispatch => ({
	onLoggedIn: (resp, path, history) => dispatch(onLoggedIn(resp, path, history))
})

const mapStateToProps = state => {
	const {userState} = state
	return {userState}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute))
