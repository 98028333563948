import moment from "moment-timezone"
import {VALIDATION_UPDATE, LOGGED_OUT, PENDING_REQUEST, FORGOT_DONE, FORGOT_CLEAR, STORE_USER_STATE} from "./types"
// Helpers
import {post, setJwt} from "helpers/api"
import {getCorporateCourse} from "helpers/utils"
import {get} from "helpers/api"

export function getUrlQueryVariable(variable) {
	var query = window.location.search.substring(1)
	var vars = query.split("&")
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=")
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1])
		}
	}
	return ""
}

export const loginUser = (email, password) => {
	// delete any cached session cookies
	document.cookie = "connect.sid=; expires = Thu, 01 Jan 1970 00:00:00 GMT;secure"

	return post("login", {
		password,
		email
	})
		.then(response => {
			setJwt(response.jwt)
			// Error handling
			if (response.error) {
				return response
			} else if (response) {
				return response
			}
		})
		.catch(err => {
			// something wrong server side if error here, check logs
			return {error: "network"}
		})
}

export function storeuserState(userState) {
	let courseType
	const corporateCourse = getCorporateCourse()
	console.log(corporateCourse)
	if (userState.course?.toLowerCase().includes("students")) {
		courseType = "students"
	} else if (userState.course?.toLowerCase().includes("trial")) {
		courseType = "corporate-trial"
	} else if (userState.course?.toLowerCase().includes("rtw")) {
		courseType = "rtw"
	} else if (userState.course?.toLowerCase().includes(corporateCourse)) {
		courseType = "corporate"
	} else {
		courseType = null
	}

	const bookings = userState.bookings.map(b => ({
		...b,
		start: moment(b.start),
		end: moment(b.end)
	}))

	userState = {
		...userState,
		locale: moment.locale(),
		courseType: courseType,
		bookings: bookings
	}

	return {
		type: STORE_USER_STATE,
		userState
	}
}

export const stateUser = type => {
	return get(type === "azure" ? "state-azure" : "state")
		.then(response => {
			if (response?.error) {
				if (response.error === "Unauthorised") {
					console.log(response.error)
					return response.error
				} else if (response.error.errors) {
					console.log(response.error.errors)
					return response.error.errors
				}
			} else {
				return response
			}
		})
		.catch(err => {
			console.log(err)
		})
}

export function validationUpdate(errors) {
	return {type: VALIDATION_UPDATE, errors}
}

export function loggedOut() {
	return {type: LOGGED_OUT}
}

export function pendingRequest(counterDelta) {
	return {type: PENDING_REQUEST, counterDelta}
}

export function clearForgot() {
	return {type: FORGOT_CLEAR}
}

export function forgotDone(forgotEmail, emailKnown) {
	return {type: FORGOT_DONE, forgotEmail, emailKnown}
}
