import React, {Fragment} from "react"
import DotOption from "./DotOption"

const DotSlider = ({options, valid, handleChange, value, id, name, questionIndex}) => {
	const onClick = value => {
		handleChange(value, id, questionIndex)
	}

	const optionsArray = options.map((option, index) => (
		<DotOption
			key={index}
			type='radio'
			name={name}
			index={index}
			onClick={() => onClick(index)}
			label={option.toString()}
			fill={index === value}
			checked={index === value}
		/>
	))

	return (
		<Fragment>
			<div className={`dot-slider dot-inputs mt-4 form-control ${valid ? valid.valid ? 'is-valid' : 'is-invalid' : ''} `}>
 				{optionsArray}
			</div>
			{ valid && valid.message &&
				<span className='invalid-feedback'>{valid.message}</span>
			}
		</Fragment>
	)
}

export default DotSlider
