import React, {Fragment, useEffect} from "react"
import {func, string} from "prop-types"
import Player from "@vimeo/player"
import Styled, {css} from "styled-components"

const IframeStyle = Styled.iframe`
      border-radius: 10px;
 	width: 700px;
	
	@media (max-width: 1024px) {
		width: 500px;
	}
`

export const VimeoPlayer = ({id, onTimeUpdate, videoURI}) => {
	useEffect(() => {
		const player = new Player(id)
		player.loadVideo(id)
		player.on("timeupdate", onTimeUpdate)
	})

	const language = window.navigator.userLanguage || window.navigator.language

	return (
		<Fragment>
			<IframeStyle id={id} src={`https://player.vimeo.com/video/${id}`} frameBorder='0' allowFullScreen={true} />
			{language === "zh" && <span className='vimeo-wrapper__chinese'>请点选影片右下角CC符号来选择中文字幕</span>}
		</Fragment>
	)
}

VimeoPlayer.propTypes = {
	id: string,
	videoURI: string.isRequired,
	onTimeUpdate: func.isRequired,
	playVideo: func
}
