export const testForHazardousDrinking = [
	{
		_id: 0,
		text:
			"This is a test for whether or not drinking might be a problem for you. It’s a measure that’s recommended by the World Health Organisation called the AUDIT.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "There are 10 questions so it should only a minute or so",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			surveyId: "self-test-hazardous-drinking-v1",
			taskId: "exercise-self-test-hazardous-drinking",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			values: [
				{
					label: "That’s ok",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		createdAt: new Date(),
		replyType: "calcScore",
		questionRange: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		nextRange: [5, 8, 13, 18],
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 0
	{
		_id: 5,
		text: "You scored in the ‘Low risk’ range for drinking. It’s not likely that you have a problem with drinking.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 6,
			_next: 7,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_next: 23,
		text: "Some basic education about hazardous drinking can always help if you are in this low range.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 1
	{
		_id: 8,
		text: "You scored in the ‘Risky or Hazardous’ range for drinking.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		text:
			"We’d recommend starting to set some goals around your drinking and doing an exercise called ‘Motivational Interviewing’ about your drinking. ",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 11,
			_next: 12,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		_next: 23,
		text:
			"You might also meet criteria for a diagnosis of ‘Alcohol Dependence’ so we recommend researching this further and seeking professional help if you do.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 2
	{
		_id: 13,
		text:
			"You scored in the ‘High Risk or Harmful’ range for drinking. This is the second highest possible level of problem drinking.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 14,
			_next: 15,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		text:
			"We’d recommend seeking professional help rather than trying to recover on your own. It’s possible that you might meet criteria for a diagnosis of ‘Alcohol Dependence’ so please talk with your doctor to check properly.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 16,
			_next: 17,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 17,
		_next: 23,
		text:
			"Check if there is a drug and alcohol hotline in your country or area. This is a good place to start to find out about services that are available and to get starting advice.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// range 3
	{
		_id: 18,
		text:
			"You scored in the ‘High risk’ range for drinking. This is the highest possible level for problem drinking and you would almost definitely meet criteria for a diagnosis of ‘Alcohol Dependence’.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 19,
			_next: 20,
			optionType: "radio",
			values: [
				{
					label: "What would you recommend I do?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 20,
		text:
			"We’d recommend that you seek professional help as soon as possible to give yourself the best chance of recovering.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 21,
			_next: 22,
			optionType: "radio",
			values: [
				{
					label: "Anything else?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 22,
		_next: 23,
		text:
			"Check if there is a drug and alcohol hotline in your country or area. This is a good place to start to find out about services that are available and to get starting advice.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	// continue dialogue
	{
		_id: 23,
		text:
			"Thanks for doing the test with me. There are millions of people who struggle with drinking, partly because it’s seen as normal to drink a lot in many cultures. If you want to be part of changing this then you might want to join one of the new movements about sobriety or ‘sober curious’ lifestyles.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 24,
			_next: 25,
			optionType: "radio",
			values: [
				{
					label: "What’s that about?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 25,
		_next: 26,
		text:
			"They aim to help change the stigma for people who have problems with drinking or promote lifestyles without a reliance on drinking.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 26,
		_end: true,
		text: "I’ll take you back so you can learn and practice more Uprise skills",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
