export const retrainingThinkingFocus = [
	{
		_id: 0,
		text: "Welcome to retraining thinking for focus",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		_next: 2,
		text:
			"Building your capacity to stay focused is partly about understanding and retraining thoughts that cause procrastination.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 2,
			_next: 3,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		text: "So let’s take a look at the thoughts that might be causing you to distract yourself or procrastinate",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 4,
			_next: 5,
			optionType: "radio",
			values: [
				{
					label: "Let’s do it",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_next: 6,
		text: "Here are some common thoughts that lead to procrastination:",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text: `"This is too hard. I’m not smart enough to do this"`,
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_next: 8,
		text: `"This project is huge. I don’t even know where to start."`,
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text: `"It’s not that bad if I check my phone each time I get a notification"`,
		createdAt: new Date(),
		replyType: "dialogue",
		surveyId: "retraining-thinking-focus-v1",
		taskId: "exercise-retraining-thinking-focus",
		questions: [0],
		questionIndex: 0,
		recurring: true,
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		text: "Now let’s look at understanding the costs of this thought over your full lifetime.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 10,
			_next: 11,
			recurring: true,
			optionType: "radio",
			questions: [1, 2, 3],
			questionIndex: 1,
			surveyId: "retraining-thinking-focus-v1",
			taskId: "exercise-retraining-thinking-focus",
			values: [
				{
					label: "Ok",
					value: "okay",
					replyType: "dialogue",
					questionIndex: 1
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 11,
		_next: 12,
		text:
			"Thanks for that. Now let’s look at whether you can tolerate the feeling that is causing you to distract.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 12,
			_next: 13,
			questions: [4],
			questionIndex: 4,
			surveyId: "retraining-thinking-focus-v1",
			taskId: "exercise-retraining-thinking-focus",
			recurring: true,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "okay",
					replyType: "dialogue",
					questionIndex: 4
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 13,
		_next: 14,
		text: "Would you be willing to try to tolerate this feeling next time instead of procrastinating?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 14,
			_next: 15,
			optionType: "radio",
			values: [
				{
					label: "What do you mean?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 15,
		_next: 16,
		text: "This is a skill that psychologists call ‘distress tolerance’",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 16,
			_next: 17,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 17,
		_next: 18,
		text:
			"It refers to your ability to accept that you are feeling something by noticing how you physically feel in your body and accepting that as ok",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 18,
			_next: 19,
			optionType: "radio",
			values: [
				{
					label: "I can try that",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 19,
		_next: 20,
		text:
			"Then once you’ve accepted it as a physical feeling, you still take an action based on your values rather than doing what the feeling wants, which is usually to distract or avoid.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 20,
			_next: 21,
			optionType: "radio",
			values: [
				{
					label: "Got it",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 21,
		_next: 22,
		text:
			"It takes time to build the skill of distress tolerance. So I’d recommend that you try to identify thoughts that cause you to distract, then question them like we did here.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 22,
			_next: 23,
			optionType: "radio",
			values: [
				{
					label: "I’ll give it a try",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 23,
		_next: 24,
		text:
			"Then see whether you can physically notice the feeling you are trying to avoid and accept that as ok and bring yourself back to focus on the task.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 24,
			_next: 25,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 25,
		_next: 26,
		text: "Thanks for doing the exercise.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 26,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
