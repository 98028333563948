import React, {Fragment, useEffect, useState} from "react"
import {object, func} from "prop-types"
import Styled from "styled-components"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"
// Actions
import {setTask} from "actions/taskActions"
import {setSkill} from "actions/skillActions"
// Colors
import {extended} from "@uprise/colors"
// UI
import {Layout} from "components/Shared/Layout"
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {SkillProgress} from "components/Shared/Skill/SkillProgress"

// Component
import BotLoader from "components/Shared/Chat/BotLoader"
import {TaskList} from "components/Shared/Library/TaskList"

const Wrap2 = Styled.section`	
	background-color: ${extended.purple.six}; 
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`
	background-color: ${extended.purple.six}; 
	width: 100%;
	height: 100vh;
`

const ChatBot = ({userState, match, skill, task, setTask, setSkill}) => {
	let history = useHistory()
	let location = useLocation()
	let {taskId, skillId} = match.params

	const [showRightPanel, setShowRightPanel] = useState(true)
	const [title, setTitle] = useState("")
	const [alignHeaderText, setAlignHeaderText] = useState("left")

	useEffect(() => {
		setTask(null, null)

		// Store skill in application state
		if (skillId) {
			setSkill(userState, skillId)
		}
	}, [skillId])

	useEffect(() => {
		// Store task in application state
		setTask(userState.tasks[taskId], taskId)
	}, [taskId])

	const {meta} = skill

	useEffect(() => {
		let newTitle
		// Check to show right panel
		if (
			taskId === "wellbeingCheck" ||
			taskId === "stressCheck" ||
			taskId === "wellbeingStressWeekly" ||
			taskId === "wellbeingStressMonthly" ||
			(taskId === "survey-progress" && !skillId)
		) {
			setShowRightPanel(false)
			setAlignHeaderText("center")
		}

		if (["wellbeingCheck", "wellbeingStressWeekly"].includes(taskId)) {
			newTitle = "Wellbeing check"
		}

		if (taskId === "wellbeingStressMonthly") {
			newTitle = "Wellbeing check Monthly"
		}

		if (taskId === "stressCheck") {
			newTitle = "Stress check"
		}

		setTitle(newTitle || task?.title || meta?.title)
	}, [task])

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={1} />
			</LeftPanel>
			<Wrap2>
				<Wrap>
					<Header
						title={title}
						textAlign='center'
						width='100%'
						back={location?.state?.from.length ? true : false}
						handleBack={() => history.push(location?.state?.from)}
					/>
					<Main padding='10px 25px 10px 25px'>
						<BotLoader userState={userState} skillId={skillId} dialogueId={taskId} />
					</Main>
				</Wrap>
				{showRightPanel && (
					<RightPanel
						className='d-none d-sm-none d-md-none d-lg-none d-xl-block'
						width='407px'
						widthLg='100%'
						widthMd='100%'
						widthSm='100%'
						widthXs='100%'>
						{Object.values(skill).length && (
							<Fragment>
								<SkillProgress
									className='d-none d-sm-none d-md-none d-lg-none d-xl-flex'
									completedTasks={meta.completedTasks}
									totalTasks={meta.totalTasks}
								/>
								<TaskList className='m-t-10' skill={skill} skillId={skillId} userState={userState} />
							</Fragment>
						)}
					</RightPanel>
				)}
			</Wrap2>
		</Layout>
	)
}

function mapStateToProps(state) {
	const {userState, skill, task} = state

	return {
		userState,
		skill,
		task
	}
}

const mapDispatchToProps = dispatch => ({
	setTask: (task, taskId) => dispatch(setTask(task, taskId)),
	setSkill: (userState, skillId) => dispatch(setSkill(userState, skillId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
ChatBot.propTypes = {
	userState: object.isRequired,
	match: object.isRequired,
	skill: object.isRequired
}

ChatBot.defaultProps = {}
