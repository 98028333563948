import {Card} from "@uprise/card"
// Colors
import {backgrounds} from "@uprise/colors"
import {H3, H4, H5, H6} from "@uprise/headings"
import {Bold, ExtraSmall} from "@uprise/text"
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {fetchRecentBlogPosts} from "actions/hrdashboardActions"
import {ContentWrap, UpNextStyles} from "../index.style"
import {
	BlogPost,
	BlogPostContainer,
	BlogPostDesc,
	BlogPostImage,
	BlogPostImageContainer,
	BlogHeading,
	BlogDate
} from "./index.style"
import Preloader from "components/Shared/Preloader/Preloader"

function UpriseBlog() {
	const dispatch = useDispatch()
	React.useEffect(() => {
		dispatch(fetchRecentBlogPosts())
	}, [])

	const recentBlogPostData = useSelector(state => state.hrdashboard.recentBlogPosts.data)
	const recentBlogPostLoading = useSelector(state => state.hrdashboard.recentBlogPosts.loading)

	return (
		<UpNextStyles>
			<H3 className='m-b-5'>From the Uprise blog</H3>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap className='m-4'>
					{recentBlogPostLoading ? (
						<Preloader center={true} className={"w-100"} />
					) : recentBlogPostData?.length ? (
						<BlogPostContainer>
							{recentBlogPostData.map(({name, date, image, link}, idx, arr) => (
								<a href={link} target='_blank' rel='noreferrer' key={idx}>
									<BlogPost
										className='m-2'
										addBorder={idx !== arr.length - 1 && idx !== arr.length - 2}>
										<BlogPostImageContainer>
											<BlogPostImage src={image} width='185px' height='104px' />{" "}
										</BlogPostImageContainer>
										<BlogPostDesc className='m-2'>
											<BlogHeading>{name}</BlogHeading>
											<BlogDate>{date}</BlogDate>
										</BlogPostDesc>
									</BlogPost>
								</a>
							))}
						</BlogPostContainer>
					) : (
						<BlogPostContainer>No data to display.</BlogPostContainer>
					)}
				</ContentWrap>
			</Card>
		</UpNextStyles>
	)
}

export default UpriseBlog
