import React from "react"
import {Card} from "@uprise/card"
import {backgrounds} from "@uprise/colors"
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {CardWrap, ContentRight, ContentWrap, SkillDetails, UpNextStyles} from "../index.style"
import {ImageWrap, PrivacyImage} from "../UpriseAndPrivacy/index.style"

function HowToBestUseThisDashboard() {
	return (
		<UpNextStyles>
			<H3 className='m-b-5'>How to best use this dashboard</H3>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<PrivacyImage src={require(`assets/images/hrdashboard/information.svg`)} />
					</ImageWrap>

					<ContentRight>
						<SkillDetails className='m-b-xs-4 m-b-sm-4 m-t-sm-0'>
							<Medium className='m-b-4'>
								{/* {`• It's important to track people with low wellbeing levels (employees at risk).
									Check the tab on risk for more information.`} */}
								{`• It's important to track people with low wellbeing levels (employees at risk).`}
							</Medium>
							<Medium className='m-b-4'>
								{`• A lot of factors contribute to stress. Check the psychosocial hazards to find
									actionable areas.`}
							</Medium>
							<Medium>
								{`• Take the benchmark average with a grain of salt. Even organisations in the same
									industry have a lot of differences between them.`}
							</Medium>
						</SkillDetails>
					</ContentRight>
				</ContentWrap>
			</Card>
		</UpNextStyles>
	)
}

export default HowToBestUseThisDashboard
