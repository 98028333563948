import {STORE_CAPS} from "actions/types"
import moment from "moment"

const initialState = {
	coachingCallCap: 0,
	therapyCallCap: 0,
	startingDate: moment().toISOString(),
	combined: true
}

export default function(state = initialState, action) {
	switch (action.type) {
		case STORE_CAPS: {
			return {...state, ...action.caps}
		}
		default:
			return {
				...state
			}
	}
}
