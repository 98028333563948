export const experimentAfter = [
	{
		_id: 0,
		text: "Welcome to the exercise on experimentation",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			surveyId: "survey-after-experiment-v1",
			taskId: "exercise-after-experiment",
			questions: [0, 1],
			recurring: true,
			values: [
				{
					label: "Let's do it",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_end: true,
		text: "Thank you for completing the exercise",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_end: true,
		text: "Taking you back now",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
