import React, {Fragment} from "react"
import Styled from "styled-components"
import RadioOption from "./RadioOption"
// spacing
import {spacing} from "@uprise/spacing"

const Row = Styled.div`
	padding: ${spacing.s5} ${spacing.s5} 0rem;
`

const RadioChoiceWrapper = Styled.div`
	display: flex;
	flex-wrap: wrap;
`

const ValidationMessage = Styled.span`
	display: block !important;
`

const RadioChoice = ({options, valid, handleChange, value, id, name, questionIndex}) => {
	const onClick = value => {
		handleChange(value, id, questionIndex)
	}

	const optionsArray = options.map((option, index) => (
		<RadioOption
			key={index}
			className='col-6 mb-3'
			type='radio'
			name={name}
			index={index}
			onClick={value => onClick(value)}
			value={value}
			label={option.toString()}
		/>
	))

	return (
		<Fragment>
			<RadioChoiceWrapper className={`btn-list btn-inputs h-auto form-control mt-2 ${valid ? (valid.valid ? "is-valid" : "is-invalid") : ""}`}>
				<Row className='row'>
					{optionsArray}
				</Row>
			</RadioChoiceWrapper>
			{valid && valid.message && (
				<ValidationMessage className='invalid-feedback'>{valid.message}</ValidationMessage>
			)}
		</Fragment>
	)
}

export default RadioChoice
