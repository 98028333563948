import React from "react"
import {bool, func, oneOf, string} from "prop-types"
import Styled from "styled-components"
// spacing
import {spacing} from "@uprise/spacing"

const OptionButtonLabel = Styled.label`
	& input {
		margin-right: ${spacing.s5};
	}
`

const RadioOption = ({className, disabled, label, name, value, onClick, type, index}) => {
	return (
		<OptionButtonLabel className={className}>
			<input
				type={type}
				name={name}
				value={value}
				checked={index === value}
				onClick={() => onClick(index)}
				disabled={disabled}
				readOnly
			/>
			<span className=''>{label}</span>
		</OptionButtonLabel>
	)
}

RadioOption.propTypes = {
	checked: bool.isRequired,
	className: string,
	disabled: bool,
	group: string,
	label: string.isRequired,
	name: string,
	onClick: func.isRequired,
	type: oneOf(["radio", "checkbox"]).isRequired
}

RadioOption.defaultProps = {
	className: "",
	group: "",
	name: "",
	checked: false
}

export default RadioOption
