import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
// UI
import {ContainerFluid, Row} from "@uprise/grid"

import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {SeriesSet} from "components/Shared/Library/SeriesSet"

const Wrap = Styled.section`	background-color: ${extended.purple.six}; 
        width: 100%;
`

const Series = ({userState, match}) => {
	let history = useHistory()
	let location = useLocation()

	// Get skill set and skill id from url params
	const {seriesId} = match.params
	const {skills, series, meta} = userState

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={1} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Series'
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
					handleBack={() => history.push(location?.state?.from)}
				/>

				<Main>
					<SeriesSet series={series[seriesId]} skills={skills} meta={meta} />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='250px'>
				<YourHealth className='m-b-7' surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(Series)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Series.propTypes = {}

Series.defaultProps = {}
