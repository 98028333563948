import React from "react"
import {connect} from "react-redux"
import Styled from "styled-components"

// UI
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
// utils
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"
import {retrieveResults} from "uprise-surveys/lib/surveys/getScore"
// Components
import ScoreCard from "./ScoreCard"

const YourHealthStyle = Styled.section`
	padding: 30px;
`

const Content = Styled.section`
      display: flex;
	flex-direction: row;
`

const YourHealth = ({className, surveyResults, hideStress, hideWellbeing}) => {
	let who5 = []
	let stress = []
	let who5Result
	let stressResult

	for (const result of surveyResults) {
		if (result.completed) {
			switch (result.taskId) {
				case "survey-onboarding":
				case "survey-stress-wellbeing-weekly":
				case "survey-stress-wellbeing-monthly":
					who5.push(retrieveResults(result).who5)
					stress.push(retrieveResults(result).stress)
					break
				case "survey-progress":
					who5.push(retrieveResults(result).who5)
					stress.push(retrieveResults(result).stress)
					break
				case "survey-stress-check":
					stress.push(retrieveResults(result).stress)
					break
				case "survey-wellbeing-check":
					who5.push(retrieveResults(result).who5)
					break
				case "survey-preprogram":
					// check if preprogram contains WHO5 and PSS4 answers
					if (result.answers.WHO5 && result.answers.PSS4) {
						who5Result = retrieveSurvey("wellbeing-check-v1").analysis({WHO5: result.answers.WHO5})
						stressResult = retrieveSurvey("stress-check-v1").analysis({STRESS: result.answers.PSS4})

						who5.push({
							score: who5Result?.WHO5?.WHO5,
							dialogueId: "wellbeingCheck",
							timestamp: result.timestamp
						})
						stress.push({
							score: stressResult.STRESS_SCORE,
							dialogueId: "stressCheck",
							timestamp: result.timestamp
						})
					}
			}
		}
	}

	who5.sort(function(a, b) {
		return b.timestamp - a.timestamp
	})

	stress.sort(function(a, b) {
		return b.timestamp - a.timestamp
	})

	who5 = who5[0] || {dialogueId: "wellbeingCheck"}
	stress = stress[0] || {dialogueId: "stressCheck"}

	return (
		<YourHealthStyle className={className}>
			<H3 className='m-b-0-5'>Your Mental Health</H3>
			<Medium className='m-b-6'>Here are your current scores</Medium>

			{!hideWellbeing && (
				<ScoreCard
					className='m-b-7'
					screeners={[who5, stress]}
					width={110}
					height={110}
					cutoutPercentage={72}
					emojix={32.5}
					emojiy={26.5}
					emojiWidth={57.1}
					emojiHeight={57.1}
				/>
			)}
		</YourHealthStyle>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(YourHealth)
