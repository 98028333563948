import React, {useState} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {Alert} from "@uprise/alert"
import LargeSurvey from "components/Rtw/survey/large/LargeSurvey"
import {getScreeners} from "./utils"
import {Col, Row} from "@uprise/grid"
import {H5} from "@uprise/headings"

const PreOnboardingAssessmentWrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

function PreOnboardingAssessment(props) {
	const [alertType, setAlertType] = useState("primary")
	const task = props.userState.modules.preOnboarding.tasks.assessment.tasks["survey-pre-onboarding-rtw"]
	const screeners = getScreeners(props.userState)

	return (
		<PreOnboardingAssessmentWrapper className='onboarding-assesment rtw-onboarding-assesment'>
			<Row>
				<Col>
					<Alert className='m-b-5' textAlign='center' type={alertType}>
						All questions are mandatory
					</Alert>
					<H5 className='m-b-5'>Please answer the questions below to proceed</H5>

					<LargeSurvey
						module='survey-pre-onboarding-rtw'
						taskId='survey-pre-onboarding-rtw'
						surveyId={task.surveyId}
						task={task}
						surveyResults={props.userState.surveyResults}
						screener={props.activeScreener}
						screeners={screeners}
						done='booking'
						handleChange={props.handleChange}
						setAlertType={setAlertType}
					/>
				</Col>
			</Row>
		</PreOnboardingAssessmentWrapper>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(PreOnboardingAssessment)
