import React, {Fragment, useEffect} from "react"
import SelectOptions from "./SelectOptions"

const SelectBox = ({options, valid, handleChange, value, id, name, questionIndex}) => {
	// Set default value
	useEffect(() => {
		if (value === undefined || value === null) {
			selectChange(options[0]?.toString())
		}
	}, [])

	const selectChange = value => {
		handleChange(value, id, questionIndex)
	}

	const optionsArray = options.map((option, index) => (
		<SelectOptions key={index} name={name} index={index} value={option.toString()} />
	))

	return (
		<Fragment>
			<select
				className={`m-t-1 form-control  ${valid ? (valid.valid ? "is-valid" : "is-invalid") : ""}`}
				value={value}
				onChange={e => selectChange(e.target.value)}>
				{optionsArray}
			</select>
			{valid && valid.message && <span className='invalid-feedback'>{valid.message}</span>}
		</Fragment>
	)
}

export default SelectBox
