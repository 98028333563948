import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {connect} from "react-redux"
import {useLocation, useHistory} from "react-router-dom"
import moment from "moment-timezone"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Actions
import {assignCoach, getCoachByEmail} from "actions/coachActions"
import {stateUser} from "actions/loginActions"
import {fetchAdditionalSessions} from "actions/userStateActions"
import {fetchCode} from "actions/codesActions"
import {fetchCaps} from "actions/capsActions"
import {getTopics} from "actions/topicsActions"
// Components
import YourHealth from "components/Shared/YourHealth"
import RecommendedCoach from "components/Shared/Coach/RecommendedCoach"
import CallsCapped from "components/Shared/Coach/CallsCapped"
import {Faqs} from "components/Shared/Coach/Faqs"
import BookingsToggle from "components/Shared/BookingsToggle"
// UI
import {Layout} from "components/Shared/Layout"
import {P, Medium, ExtraSmall, Note} from "@uprise/text"
import {RightPanel, Main, LeftPanel} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
// Actions
import {fetchBookings} from "actions/bookingsActions"
// helpers
import {getCoachingType} from "helpers/utils"
import {get} from "helpers/api"

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
	width: 100%;
`

const Booking = ({
	userState,
	assignCoach,
	getCoachByEmail,
	coachEmail,
	bookings,
	fetchCode,
	fetchCaps,
	fetchBookings,
	code,
	cap,
	fetchAdditionalSessions,
	coach,
	getTopics,
	topics
}) => {
	let location = useLocation()
	let history = useHistory()
	const [reachedCapLimit, setReachedCapLimit] = useState(false)
	const [reachedCapLimitTherapy, setReachedCapLimitTherapy] = useState(false)
	const [codeLoading, setCodeLoading] = useState(true)
	const [totalValidBookings, setTotalBookings] = useState(true)
	const [therapyCallsRemaining, setTherapyCallsRemaining] = useState(0)
	const [combinedCallsRemaining, setCombinedCallsRemaining] = useState(0)
	const [capLimit, setCapLimit] = useState(0)
	const [validBookings, setValidBookings] = useState(0)
	const [callsRemaining, setCallsRemaining] = useState(0)

	const {skills, meta} = userState
	const firstLoad = true

	useEffect(() => {
		fetchAdditionalSessions(userState)
		fetchBookings()

		// get employer code data
		fetchCode(userState.employerCode)
		fetchCaps(userState.employerCode)
		getTopics()

		// Assign a coach automatically if one not found
		// if (!coachEmail) {
		// 	assignCoach(userState.timezone)
		// }
	}, [])

	useEffect(() => {
		if (Object.values(code).length) {
			setCodeLoading(false)
		}
	}, [code])

	useEffect(() => {
		if (coachEmail) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail])

	useEffect(() => {
		const {coachingCallCap, therapyCallCap, startingDate, combined} = cap
		let callCap = coachingCallCap
		const duration = location?.state?.duration
		if (duration === 60) callCap = therapyCallCap
		let therapyBookings = []
		let totalValidBookings =
			bookings?.data
				?.filter(booking => {
					return booking?.id
				})
				.filter(booking => moment(booking.createdAt).diff(moment(startingDate), "days") >= 0)
				.filter(booking => {
					if (["within24", "within_24", "userNoShow", "user_no_show"].includes(booking.cancelReason))
						return true
					else return !booking.cancelled
				}) || []

		const combinedCallRemaining = [...totalValidBookings]

		//therapy and coaching calls counted separately
		therapyBookings = totalValidBookings.filter(booking => booking.duration === 60)
		totalValidBookings = totalValidBookings.filter(booking => booking.duration === 30)

		setCapLimit(coachingCallCap)

		// Check if calls are capps + check if users bookings exceeds codes call cap limit
		if (!code?.caps?.capCalls) {
			setReachedCapLimit(false)
			setReachedCapLimitTherapy(false)
		} else {
			if (totalValidBookings.length >= parseInt(coachingCallCap)) {
				setReachedCapLimit(true)
			} else {
				setReachedCapLimit(false)
				if (bookings.duration === 30 && !combined) history.push("/make-booking", {duration: bookings.duration})
			}

			if (therapyBookings.length >= parseInt(therapyCallCap)) {
				setReachedCapLimitTherapy(true)
			} else {
				setReachedCapLimitTherapy(false)
				if (bookings.duration === 60 && !combined) history.push("/make-booking", {duration: bookings.duration})
			}
		}

		if (code?.caps?.capCalls) {
			setCallsRemaining(parseInt(coachingCallCap) - totalValidBookings.length)
			setTherapyCallsRemaining(parseInt(therapyCallCap) - therapyBookings.length)
			setCombinedCallsRemaining(parseInt(therapyCallCap) - combinedCallRemaining.length)
		}
		if (combined) {
			if (combinedCallRemaining.length >= parseInt(callCap)) {
				setReachedCapLimitTherapy(true)
				setReachedCapLimit(true)
			} else {
				setReachedCapLimitTherapy(false)
				setReachedCapLimit(false)
			}
		}
		if (duration === 30) setValidBookings(totalValidBookings)
		else setValidBookings(therapyBookings)
	}, [bookings, code, cap])

	const renderCallsCapped = location?.state?.duration === 60 ? reachedCapLimitTherapy : reachedCapLimit

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={3} />
			</LeftPanel>
			<Wrap>
				<Header
					title={getCoachingType(location?.state?.duration)}
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
					handleBack={() => history.push("/")} // prevent redirection loop
				/>
				<Main>
					{renderCallsCapped && !codeLoading && !userState.disableCallCap && !topics.loading ? (
						<>
							<BookingsToggle />
							<CallsCapped
								requested={userState.increase_cap_token}
								totalValidBookings={totalValidBookings.length}
								code={code}
								cap={cap}
								isTherapy={bookings.duration === 60}
								callLimitReached={bookings.duration === 60 ? reachedCapLimitTherapy : reachedCapLimit}
								userState={userState}
								reachedCapBoth={reachedCapLimit && reachedCapLimitTherapy}
								therapyCapped={reachedCapLimitTherapy}
								coachingCapped={reachedCapLimit}
								className='m-b-6'
								coach={coach}
								duration={bookings.duration}
							/>
						</>
					) : !codeLoading ? (
						<RecommendedCoach className='m-b-6' />
					) : (
						<Medium>Loading..</Medium>
					)}
					<Faqs className='m-t-10' duration={location?.state?.duration || 30} />
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-none d-xl-block' width='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

const mapStateToProps = state => {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		code: state?.code,
		cap: state?.cap,
		bookings: state?.bookings,
		topics: state.topics
	}
}

const mapDispatchToProps = dispatch => ({
	assignCoach: timezone => dispatch(assignCoach(timezone)),
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	fetchCode: code => dispatch(fetchCode(code)),
	fetchCaps: code => dispatch(fetchCaps(code)),
	fetchBookings: () => dispatch(fetchBookings()),
	fetchAdditionalSessions: userState => dispatch(fetchAdditionalSessions(userState)),
	getTopics: () => dispatch(getTopics())
})

export default connect(mapStateToProps, mapDispatchToProps)(Booking)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Booking.propTypes = {}

Booking.defaultProps = {}
