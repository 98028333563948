import React, {Fragment, useEffect, useState} from "react"
import {useSelector} from "react-redux"
// Navigation
import left from "navigation/left"
// icons
import Icons from "assets/images/icons/svg"
// Colors

import {primary} from "@uprise/colors"
import {LeftMenuStyles, ItemTitle, MenuItem, Icon, Collapse, ArrowIcon, SubMenuWrap, SubMenuItem} from "./index.style"
// helpers
import {isCoachingEnabled, isWhiteLabeledApp, getWhiteLabelDomain, isTherapyEnabled} from "helpers/utils"

const LeftMenu = ({
	active, // index of current item in Object.values(left.json *NAVIGATION_JSON*)
	subMenuActive = undefined, // id of submenu
	color = primary.charcoal,
	className = "",
	width = "",
	textAlign = "",
	...props
}) => {
	const coach = useSelector(state => state?.userState?.coach)
	const isDashboardViewEnabled = useSelector(state => state?.userState?.isDashboardViewEnabled)
	const features = useSelector(state => state?.userState?.features)

	const _setActive = (items = left, active = 0, subMenuActive = null) => {
		return items.map(menuItem => {
			let newItem = {...menuItem}

			newItem.active = newItem.id === Object.values(items)[active].id
			newItem.isToggled = newItem.id === Object.values(items)[active].id

			const isBookingsEnabled = isCoachingEnabled(features) || isTherapyEnabled(features)

			if (newItem.path.includes("dashboard")) newItem.enabled = isDashboardViewEnabled
			if (newItem.title === "Bookings") newItem.enabled = isBookingsEnabled
			if (newItem.path === "booking") newItem.path = coach ? "make-booking" : "booking"

			if (!menuItem.children?.length || !subMenuActive) return newItem

			newItem.children = newItem.children.map(submenuItem => ({
				...submenuItem,
				active: submenuItem.id === subMenuActive
			}))
			return newItem
		})
	}
	const [navItems, setNavItems] = useState(_setActive(left, active, subMenuActive))

	const _handleClick = (e, item) => {
		e.persist()
		setNavItems(navItems => navItems.map(_ => (_.id === item.id ? {..._, isToggled: !_.isToggled} : _)))
	}

	useEffect(() => {
		setNavItems(navItems => {
			return navItems.map(item => {
				switch (true) {
					case item.path.includes("hr-dashboard"):
						return {...item, enabled: isDashboardViewEnabled}

					case item.title === "Coaching":
						return {...item, enabled: isCoachingEnabled(features)}

					case item.path === "booking":
						return {
							...item,
							path: coach ? "make-booking" : "booking"
						}

					default:
						return item
				}
			})
		})
	}, [coach])

	let activeIconTag
	if (isWhiteLabeledApp()) {
		if (getWhiteLabelDomain() === "Allianz") {
			activeIconTag = "Allianz"
		}
	} else {
		activeIconTag = "Purple"
	}

	return (
		<LeftMenuStyles {...props}>
			{navItems
				.filter(x => x.enabled)
				.map((item, index) => {
					return (
						<Fragment key={`menu-item-${index}`}>
							<MenuItem
								data-testid={`${item.title}-button`}
								key={index}
								active={item.active ? 1 : 0}
								to={`/${item.path}`}
								onClick={e => _handleClick(e, item)}>
								<Icon src={Icons[item.active ? `${item.icon}${activeIconTag}` : `${item.icon}Grey`]} />
								<ItemTitle active={item.active}>{item.title}</ItemTitle>
								{item.children?.length ? (
									<Collapse>
										<ArrowIcon toggle={item.isToggled} />
									</Collapse>
								) : null}
							</MenuItem>
							{Boolean(item.children?.length) && (
								<SubMenuWrap toggle={item.isToggled}>
									{item.children.map((subitem, subindex) => (
										<SubMenuItem key={subindex} active={subitem.active} to={`/${subitem.path}`}>
											<Icon
												src={
													Icons[
														subitem.active ? `${subitem.icon}Purple` : `${subitem.icon}Grey`
													]
												}
											/>
											<ItemTitle active={subitem.active}>{subitem.title}</ItemTitle>
										</SubMenuItem>
									))}
								</SubMenuWrap>
							)}
						</Fragment>
					)
				})}
		</LeftMenuStyles>
	)
}

export default LeftMenu
