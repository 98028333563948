import React, {Fragment, useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import FormValidate from "form-validate.js"
// Api
import {post} from "helpers/api"
// Actions
import {updateUserState} from "actions/userStateActions"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {TextInputHorizontal} from "@uprise/form"
import {Switch} from "@uprise/switch"
import {Alert} from "@uprise/alert"
import {Button} from "@uprise/button"
// Colors
import {primary, extended, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Validation
import constraints from "validation/account"

const AccountStyles = Styled.section`
`

const HeaderStyles = Styled.section`
      display: flex;
	flex-direction: row;
      justify-content: space-between;
`

const Edit = Styled.div`
      text-align: right;
      align-self: flex-end;

      &:hover {
            cursor: pointer
      }
`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: row;

      &:hover {
            cursor: pointer
      }
`

const SwitchWrap = Styled.div`

`

const Form = Styled.form`
      width: 100%;
`

const Table = Styled.table`
      width: 100%;
`

const Tbody = Styled.tbody`
`

const Tr = Styled.tr`
      margin-bottom: ${spacing.s3};

      &:first-child {
            margin-top: 0;
      }
`

const Td = Styled.td`
      border-bottom: 1px solid ${extended.purple.five};
      padding-bottom: ${spacing.s3};
      padding-top: ${spacing.s3};
`

const Account = ({className, userState, updateUserState, employerCodeData}) => {
	const [edit, setEdit] = useState(false)

	// Form
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [error, setError] = useState({type: "", value: ""})
	const [success, setSuccess] = useState({type: "", value: ""})
	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [confirm, setConfirm] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			setValidation(controls)
		})
	}

	const _handleSave = () => {
		setError("")
		setSuccess("")

		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			post("settings/user/updatePassword", {
				currentPassword: currentPassword,
				newPassword: newPassword,
				confirm: confirm
			})
				.then(resp => {
					if (resp.error === "BadPassword") {
						setError("Incorrect password")
					} else {
						updateUserState(resp.user_state)
						setSuccess("Success: Password changed")
						setEdit(false)
					}
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					setIsLoading(false)
				})
		}
	}

	const showAccountType = userState?.courseType !== "rtw"

	const type =
		Array.isArray(userState?.features) &&
		!!(
			userState?.features?.find(feature => feature?.id === "coaching-sessions")?.enabled ||
			userState?.features?.find(feature => feature?.id === "therapy-sessions")?.enabled
		)

	return (
		<AccountStyles className={className}>
			<HeaderStyles>
				<H3 className='m-b-4'>Account Details</H3>
				{edit ? (
					<Edit className='m-b-2 m-r-2' onClick={() => _handleSave()}>
						<Medium color={extended.purple.two}>Save</Medium>
					</Edit>
				) : (
					<Edit className='m-b-2 m-r-2' onClick={() => setEdit(true)}>
						<Medium color={extended.purple.two}>Edit</Medium>
					</Edit>
				)}
			</HeaderStyles>
			<Card shadow={true} backgroundColor={backgrounds.white} padding={spacing.s7}>
				<ContentWrap>
					<Form className='m-b-4' onChange={event => _validateForm(event)}>
						<Table>
							<Tbody>
								<Tr>
									<Td width='20%'>
										<Medium weight='bold' color={primary.charcoal}>
											Current Password
										</Medium>
									</Td>
									<Td>
										{edit ? (
											<Fragment>
												<TextInputHorizontal
													value={currentPassword}
													onChange={e => setCurrentPassword(e.target.value)}
													name='password'
													id='password'
													placeholder='*********'
													type='password'
													isRequired={true}
													validation={validation}
												/>
											</Fragment>
										) : (
											<Medium>••••••••••</Medium>
										)}
									</Td>
									<Td></Td>
								</Tr>
								{edit && (
									<Fragment>
										<Tr>
											<Td width='20%'>
												<Medium weight='bold' color={primary.charcoal}>
													New Password
												</Medium>
											</Td>
											<Td>
												<Fragment>
													<TextInputHorizontal
														value={newPassword}
														onChange={e => setNewPassword(e.target.value)}
														name='newPassword'
														id='newPassword'
														placeholder='*********'
														type='password'
														isRequired={true}
														validation={validation}
													/>
												</Fragment>
											</Td>
										</Tr>
										<Tr>
											<Td width='20%'>
												<Medium weight='bold' color={primary.charcoal}>
													Confirm Password
												</Medium>
											</Td>
											<Td>
												<Fragment>
													<TextInputHorizontal
														value={confirm}
														onChange={e => setConfirm(e.target.value)}
														name='confirm'
														id='confirm'
														placeholder='*********'
														type='password'
														isRequired={true}
														validation={validation}
													/>
												</Fragment>
											</Td>
										</Tr>
									</Fragment>
								)}
								{showAccountType && (
									<Tr>
										<Td>
											<Medium weight='bold' color={primary.charcoal}>
												Account type
											</Medium>
										</Td>
										<Td>
											<Button
												title={type ? "Coach Guided" : "Self Guided"}
												variant='secondary'
												disabled={true}
												size='small'
												hover={false}
												fullWidth={false}
											/>
										</Td>
									</Tr>
								)}
							</Tbody>
						</Table>
					</Form>
				</ContentWrap>
				{error.length > 0 && (
					<ContentWrap className='m-t-5'>
						<Alert type='error'>{error}</Alert>
					</ContentWrap>
				)}
				{success.length > 0 && (
					<ContentWrap className='m-t-5'>
						<Alert type='success'>{success}</Alert>
					</ContentWrap>
				)}
			</Card>
		</AccountStyles>
	)
}

const mapDispatchToProps = dispatch => ({
	updateUserState: state => dispatch(updateUserState(state))
})

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch,
		employerCodeData: state.code.data
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
