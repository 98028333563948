import React from "react"
import {string} from "prop-types"
import Styled from "styled-components"

// Colors
import {backgrounds, extended} from "@uprise/colors"

import LogoBlack from "assets/images/logos/logo-black.svg"
import LogoTransparent from "assets/images/logos/uprise-transparent-64x64.png"
import AllianzLogo from "assets/images/allianz/logo.png"
import {isWhiteLabeledApp} from "helpers/utils"

const PanelWrap = Styled.aside`
      position: relative;
      width: ${props => props.width};
	min-width: ${props => props.width};
	z-index: 1;
	@media (max-width: 1024px) {
		width: 80px;
		min-width: 80px;
	}

	@media (max-width: 475px) {
		height: 50px;
		width: 100%;
		bottom: 0;
		position: fixed;
		z-index: 1;
	}

`

const PanelStyle = Styled.div`
	flex-direction: column;
	top: 0;
      position: fixed;
      height: 100vh;
      background-color: ${backgrounds.white};
      color: ${props => props.color};
	box-shadow: inset -1px 0 0 0 ${extended.purple.five};
	width: ${props => props.width};
	min-width: ${props => props.width};
	
	@media (max-width: 1024px) {
		width: 80px;
		min-width: 80px;
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
	}

	@media (max-width: 475px) {
		top: auto;
		width: 100%;
		flex-direction: column;
	}
`

const PanelLogo = Styled.div`
      margin-left: 40px;
      margin-top: 40px;
	width: ${props => (props.isWhiteLabeledApp ? "130px" : "106px")};
	height: ${props => (props?.isWhiteLabeledApp ? "25px" : "30px")};
	margin-bottom: 60px;
	background-image: ${props => (props?.isWhiteLabeledApp ? `url(${AllianzLogo})` : `url(${LogoBlack})`)};
	background-repeat: ${props => (props?.isWhiteLabeledApp ? "round" : "no-repeat")};
	
	@media (max-width: 1024px) {
		background-image: ${props => (props?.isWhiteLabeledApp ? `url(${AllianzLogo})` : `url(${LogoTransparent})`)};
		background-repeat: no-repeat;
		background-size: cover;
		width: 40px;
		height: 40px;
		margin-left: 0;
	}

	@media (max-width: 475px) {
		display: ${props => (props.LogoMobile ? "block" : "none")};
		height: 49.5px;
 		position: fixed;
		margin: 0;
		top: 0;
		width: 100%;
		background-color: ${backgrounds.white};
		background-size: contain;
		background-size: 30px 30px;
		background-position: center;
		border-bottom: 1px solid ${extended.purple.five};
	}

`

export const LeftPanel = ({children, className, width, textAlign, weight, color, LogoMobile}) => {
	return (
		<PanelWrap className={className} width={width}>
			<PanelStyle className={className} width={width} weight={weight} textAlign={textAlign} color={color}>
				<PanelLogo LogoMobile={LogoMobile} isWhiteLabeledApp={isWhiteLabeledApp()} />
				{children}
			</PanelStyle>
		</PanelWrap>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
LeftPanel.propTypes = {
	weight: string,
	color: string,
	width: string,
	textAlign: string
}

LeftPanel.defaultProps = {
	weight: "normal",
	color: extended.charcoal.two,
	width: "250px",
	textAlign: "",
	LogoMobile: false
}
