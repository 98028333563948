import React, {Fragment} from "react"

// Components
import SingleOptions from "components/Shared/Chat/QuickReply/SingleOptions"
import TextAreaOption from "components/Shared/Chat/QuickReply/TextAreaOption"
import SliderOption from "components/Shared/Chat/QuickReply/SliderOption"
import MultiOptions from "components/Shared/Chat/QuickReply/MultiOptions"
import MultiTriggerOptions from "components/Shared/Chat/QuickReply/MultiTriggerOptions"
import SingleWithOtherOptions from "components/Shared/Chat/QuickReply/SingleWithOtherOptions"
import TextAreaWithMoreEnd from "components/Shared/Chat/QuickReply/TextAreaWithMoreEnd"
import MultiWithOther from "components/Shared/Chat/QuickReply/MultiWithOther"
import TextAreaWithMore from "components/Shared/Chat/QuickReply/TextAreaWithMore"
import TextAreaWithEnd from "components/Shared/Chat/QuickReply/TextAreaWithEnd"

const QuickReply = ({quickReply, onQuickReply}) => {
	return (
		<Fragment>
			{quickReply?.quickReplies?.optionType === "radio" && (
				<SingleOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "multiRadio" && (
				<SingleOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "checkboxSkip" && (
				<MultiOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "checkbox" && (
				<MultiOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "slider" && (
				<SliderOption handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "percentageSlider" && (
				<SliderOption type='percentage' handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "multiWithOther" && (
				<MultiWithOther handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "textarea" && (
				<TextAreaOption handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "textAreaWithEnd" && (
				<TextAreaWithEnd handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "textAreaWithMoreEnd" && (
				<TextAreaWithMoreEnd handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "textAreaWithMore" && (
				<TextAreaWithMore handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "triggerMulti" && (
				<MultiTriggerOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "triggerMultiFollowup" && (
				<SingleWithOtherOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "triggerTextAreaFollowUp" && (
				<TextAreaOption handleSend={onQuickReply} currentMessage={quickReply} />
			)}
			{quickReply?.quickReplies?.optionType === "singleWithOther" && (
				<SingleWithOtherOptions handleSend={onQuickReply} currentMessage={quickReply} />
			)}
		</Fragment>
	)
}

export default QuickReply
