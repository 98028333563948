import {STORE_CALENDAR, LOADING_CALENDAR} from "../actions/types"

const INITIAL_STATE = {
	loading: false,
	data: []
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case STORE_CALENDAR:
			return {...state, ...{data: action.payload}}
		case LOADING_CALENDAR:
			return {...state, loading: action.payload}
		default:
			return state
	}
}
