import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import {useLocation, useHistory, NavLink} from "react-router-dom"
import {useParams} from "react-router"
import Styled from "styled-components"
import FormValidate from "form-validate.js"
import {connect} from "react-redux"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {ContainerFluid, Row, Col} from "@uprise/grid"
import {H2, H3} from "@uprise/headings"
import {P, ExtraSmall, Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
// Validation
import constraints from "validation/resetPassword"
// Inputs
import {TextInput} from "@uprise/form"
// Api
import {post} from "helpers/api"
// Actions
import {loginUser, storeuserState} from "actions/loginActions"

const ContentLeft = Styled.section`
`
const ContentRight = Styled.section`
	margin-top: 166px;
`

const Image = Styled.img`
	width: 106px;
	height: 30px;
	margin-bottom: 116px;
	cursor: pointer;
`
const Art = Styled.img`
	width: 480px;
	height: 376px;
	margin-bottom: 155px;

	@media (max-width: 1024px) {
		width: 350px;
	}
`

const Reset = ({children, match, errors}) => {
	let history = useHistory()
	const {email, token} = useParams()
	// Form
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [message, setMessage] = useState("")
	const [error, setError] = useState({type: "", value: ""})
	const [success, setSuccess] = useState({type: "", value: ""})
	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [confirm, setConfirm] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			setValidation(controls)
		})
	}

	const _handleSubmit = () => {
		setSubmitted(true)
		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			_handleSave()
		}
	}

	const _handleSave = () => {
		setError("")
		setSuccess("")
		setIsLoading(true)

		post("forgot/updatepassword", {
			email: email,
			token: token,
			password: newPassword
		})
			.then(resp => {
				if (resp.error) {
					_handleError(resp)
				} else {
					setTimeout(() => {
						history.push("/sign-in")
					}, 2500)
					setMessage("Password updated! Redirecting you to the login page...")
				}
				setIsLoading(false)
			})
			.catch(err => {
				console.error(err)
			})
	}

	const _handleError = resp => {
		if (resp.error) {
			let errors = {}

			console.log(resp)

			switch (resp.error) {
				case "Reset link has expired or is invalid":
					errors.email = {
						type: "error",
						text: "Reset link has expired or is invalid"
					}
					break
				case "UnknownEmail":
					errors.email = {
						type: "error",
						text: "No account exists for that email address, please try another address"
					}

					break
				case "ValidationFail":
					switch (resp.errors[0].property) {
						case "instance.email":
							errors.name = {type: "error", text: "Please enter valid email"}
							break
					}
					break
				default:
					errors.name = {type: "error", text: resp.error}
			}

			setError(Object.values(errors)[0])
			return errors
		}
	}

	return (
		<Fragment>
			<H3 className='m-b-10'>Reset your password</H3>
			<Card className='m-b-4' backgroundColor={backgrounds.white} shadow={true} padding={spacing.s5} width='100%'>
				{error?.text?.length > 0 && (
					<Alert className='m-b-5' type={error.type}>
						{error.text}
					</Alert>
				)}
				{message?.length > 0 && (
					<Alert className='m-b-5' type='success'>
						{message}
					</Alert>
				)}

				<form onChange={event => _validateForm(event)}>
					<TextInput
						label='Password'
						value={newPassword}
						onChange={e => setNewPassword(e.target.value)}
						name='newPassword'
						id='newPassword'
						placeholder=''
						type='password'
						isRequired={true}
						isFirst
						validation={validation}
					/>
					<TextInput
						label='Confirm Password'
						value={confirm}
						onChange={e => setConfirm(e.target.value)}
						name='confirm'
						id='confirm'
						placeholder=''
						type='password'
						isRequired={true}
						validation={validation}
						isLast
					/>
				</form>
			</Card>
			<Button
				variant='primary'
				className='m-b-4'
				title='Save'
				size='large'
				fullWidth={true}
				isLoading={isLoading}
				disabled={false}
				onClick={() => _handleSubmit()}
			/>
			<Note>
				Remember your credentials? <NavLink to={`/sign-in`}>Sign in</NavLink>.
			</Note>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Reset.propTypes = {}

Reset.defaultProps = {}

const mapDispatchToProps = dispatch => ({
	storeuserState: state => dispatch(storeuserState(state))
})

export default connect(null, mapDispatchToProps)(Reset)
