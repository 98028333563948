import {STORE_COACHES, LOADING_COACHES, STORE_SORT_BY, STORE_FILTERS} from "../actions/types"

const INITIAL_STATE = {
	loading: false,
	data: [],
	sort: "DATE",
	languages: [],
	locations: [],
	specialization: [],
	availability: null,
	reset: true
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case STORE_COACHES:
			return {...state, ...{data: action.payload}}
		case LOADING_COACHES:
			return {...state, loading: action.payload}
		case STORE_SORT_BY:
			return {...state, sort: action.payload}
		case STORE_FILTERS:
			return {...state, ...action.payload}
		default:
			return state
	}
}
