import React, {Fragment} from "react"
import {string} from "prop-types"
import Styled from "styled-components"
import {useHistory, useLocation} from "react-router-dom"
import {retrieveResults} from "uprise-surveys/lib/surveys/getScore"

// Colors
import {extended} from "@uprise/colors"
// UI
import {Layout} from "components/Shared/Layout"
import {Container, Row, Col} from "@uprise/grid"
// Components
import {Header} from "components/Shared/Panels"
import BotLoader from "components/Shared/Chat/BotLoader"
import {Main} from "components/Shared/Panels"
// API
import {post} from "helpers/api"

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const BotWrap = Styled.div`
	width: 980px;
	height: 100vh;

	@media (max-width: 1200px) {
		width: 100%;
	}
`

const RuokdaySurvey = () => {
	let history = useHistory()
	const location = useLocation()
	const {companyCode, phone} = location.state

	const _handleNext = results => {
		history.push("/ruokday/results", {results})
	}

	const _completeDialog = async (recurring, answers, surveyId, taskId, next) => {
		const results = retrieveResults({answers, surveyId, taskId})

		results.answers = answers

		try {
			post("store-ruokday-data", {
				companyCode: companyCode,
				results: results,
				phone: phone
			})

			setTimeout(() => {
				next(results)
			}, 3000)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<Wrap>
			<Header
				title='Wellbeing Check'
				textAlign='center'
				width='100%'
				back={true}
				handleBack={() => history.push("/")}
				hideMobile={true}
			/>
			<BotWrap>
				<Main className='10px 25px 10px 25px h-100'>
					<BotLoader
						handleNext={results => _handleNext(results)}
						completeDialog={(recurring, answers, surveyId, taskId, next) =>
							_completeDialog(null, answers, "wellbeing-check-v1", "survey-wellbeing-check", next)
						}
						dialogueId='ruokday'
					/>
				</Main>
			</BotWrap>
		</Wrap>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
RuokdaySurvey.propTypes = {}

RuokdaySurvey.defaultProps = {}

export default RuokdaySurvey
