import React, {useState, useEffect} from "react"
import moment from "moment-timezone"

// UI
import {Card} from "@uprise/card"
import {Row, Col} from "@uprise/grid"
import {H5} from "@uprise/headings"
import {Note} from "@uprise/text"
import {ProgressiveImage} from "@uprise/image"
// Colors
import {primary, backgrounds} from "@uprise/colors"
// styles
import {ContentWrap, CoachPhoto, ContentRight, H6Styled} from "components/Shared/Bookings/index.style"

const CancelledCard = ({booking, coaches}) => {
	const [coach, setCoach] = useState()

	useEffect(() => {
		setCoach(coaches.find(item => item.id === booking.coachId))
	}, [booking, coaches])

	return (
		<Card shadow={true} backgroundColor={backgrounds.white} className='m-b-4'>
			<ContentWrap>
				<CoachPhoto>
					<ProgressiveImage
						className='m-r-6 m-b-4'
						borderRadius='10px'
						height={100}
						width={100}
						src={
							coach?.photos?.avatarURL
								? coach?.photos?.imageURL
								: require("assets/images/coach/default-coach.png")
						}
						overlaySrc={
							coach?.photos?.avatarURI
								? coach?.photos?.avatarURI
								: require("assets/images/coach/default-coach.png")
						}
					/>
				</CoachPhoto>
				<ContentRight>
					<H5 color={primary.charcoal} className='m-b-0 m-t-0'>
						{booking.coachName}
					</H5>

					<Row>
						<Col className='d-flex align-items-center m-t-1 m-b-1'>
							<img src={require("assets/images/icons/svg/cancelled.svg")} className='m-r-3' />
							<Note>
								{`Cancelled: ${moment(booking?.start).format("Do MMMM, h:mm a")} (${booking.timezone ||
									moment.tz.guess()})`}
							</Note>
						</Col>
					</Row>
				</ContentRight>
			</ContentWrap>
		</Card>
	)
}

export default CancelledCard
