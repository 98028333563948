import React from "react"
import propTypes from "prop-types"
import Styled from "styled-components"
import {TextInput} from "@uprise/form"
import {Note} from "@uprise/text"

const TextInputExtended = Styled(TextInput)`
	height: 50px;
	min-height: auto;
	margin-bottom: 0;
`

const TextWrapper = Styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: baseline;
	position: relative;

	& > div {
		width: 80%;
		margin-bottom: 0;
	}

	& .invalid-feedback {
		display: block;
		position: absolute;
		bottom: -20px;
		left: 0;
	}
`

const Text = ({value, valid, units, handleChange, placeholder, height}) => {
	return (
		<TextWrapper height={height}>
			<TextInputExtended
				type='text'
				className={`p-2 text-input form-control ${valid ? (valid.valid ? "is-valid" : "is-invalid") : ""} ${
					units ? "form-control-inline" : null
				} input-sm`}
				placeholder={units === "years" ? "Age in Years" : placeholder ? placeholder : "Your answer"}
				value={value}
				onChange={e => handleChange(e.target.value)}
			/>
			{valid && valid.message && <Note className='invalid-feedback'>{valid.message}</Note>}
			{units && <span>/ {units}</span>}
		</TextWrapper>
	)
}

Text.propTypes = {
	handleChange: propTypes.func.isRequired
}

Text.defaultProps = {
	value: ""
}

export default Text
