import React from "react"
import Styled from "styled-components"
// Components
import Lessons from "components/Shared/Library/TaskList/Lessons"
import Homework from "components/Shared/Library/TaskList/Homework"
import Readings from "components/Shared/Library/TaskList/Readings"
import Bonus from "components/Shared/Library/TaskList/Bonus"

const TaskListStyles = Styled.section`
`

export const TaskList = ({className, skillId, skill, userState}) => {
	return (
		<TaskListStyles className={className}>
			<Lessons className='m-b-6' skillId={skillId} lessons={skill.lessons} activity={userState.activity} />
			<Homework
				className='m-t-0 m-b-4'
				homework={skill.homework}
				skillId={skillId}
				activity={userState.activity}
			/>
			<Bonus className='m-t-0 m-b-4' bonus={skill.bonus} skillId={skillId} activity={userState.activity} />
			<Readings
				className='m-t-0 m-b-4'
				faqs={skill.faqs}
				summary={skill.summary}
				practice={skill.practice}
				science={skill.science}
				examples={skill.examples}
				skillId={skillId}
				activity={userState.activity}
			/>
		</TaskListStyles>
	)
}
