export const buildingEmpathy = [
	{
		_id: 0,
		text: "If you feel frustrated with someone else then one way to let go of that is to build empathy for them.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "If it means I can feel less annoyed then I’m ready",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text:
			"Building empathy can mean understanding the reasons why they act they way they do. So I’ll show you an exercise to help with that",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			surveyId: "building-empathy-v1",
			taskId: "exercise-building-empathy",
			questions: [0, 1, 2, 3],
			recurring: true,
			values: [
				{
					label: "Great",
					value: "start",
					replyType: "dialogue",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text:
			"If you feel ready, take a deep breath and check if you are ready to forgive the person for their behaviour or not. If you are, find a way to let go of the frustration towards them with by saying “I let go of my frustration towards you” or whatever works for you.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "Ok, I’ll give it a try",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "I don’t think that’s the right thing for me to do",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"Thanks for doing the exercise. I hope that it helps you to see their behaviour with more empathy and maybe be more self-compassionate if you see yourself doing a behaviour you don’t like towards them.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_end: true,
		text: "Taking you back now, i've placed your notes in your notebook 👍",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
