import React from "react"
import Styled from "styled-components"
// UI
import {H2} from "@uprise/headings"
import {P} from "@uprise/text"
import {RetinaProgressiveImage} from "@uprise/image"

// Images
const studentsLandingArtSmall = require("assets/images/art/home-students/home-students.png")
const studentsLandingArtMedium = require("assets/images/art/home-students/home-students@2x.png")
const studentsLandingArtLarge = require("assets/images/art/home-students/home-students@3x.png")

const Art = Styled.div`
	width: 480px;
	height: 376px;
	margin-bottom: 155px;

	@media (max-width: 1024px) {
		width: 350px;
	}
`

const RightContentStudents = () => {
	return (
		<div>
			<H2 className='m-b-5' width='458px'>
				Be happier, healthier and more productive with Uprise.
			</H2>
			<P width='405px' mediumWidth='305px' className='m-b-10'>
				Uprise is a mental health app to support you during your studies.
			</P>

			<Art>
				<RetinaProgressiveImage
					srcSets={{
						small: studentsLandingArtSmall,
						medium: studentsLandingArtMedium,
						large: studentsLandingArtLarge
					}}
					overlaySrc={studentsLandingArtSmall}
				/>
			</Art>
		</div>
	)
}

export default RightContentStudents
