import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {NavLink, useHistory} from "react-router-dom"
import {connect} from "react-redux"
// Navigation
import left from "navigation/left"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
import {Suggested} from "components/Shared/Suggested"

// UI
import {ContainerFluid, Row} from "@uprise/grid"

import {LeftPanel, Main, RightPanel} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import {Track} from "components/Shared/Library/Track"

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
	width: 100%;
`

const Library = ({userState, suggested}) => {
	const {skills, series, meta, tracks, surveyResults} = userState

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={1} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Library'
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
				/>

				<Main>
					{Object.values(suggested).length > 0 && (
						<Suggested suggested={suggested} meta={meta} skills={skills} />
					)}

					{Object.values(tracks).map((track, index) => {
						if (track.items.length && Object.keys(tracks)[index] !== 'allianzVideo') {
							return (
								<Track
									key={index}
									track={track}
									skills={skills}
									meta={meta}
									trial={userState.course.includes("trial") ? true : false}
									courseType={userState.courseType}
								/>
							)
						}
					})}
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='250px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		suggested: state?.suggested
	}
}

export default connect(mapStateToProps, null)(Library)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Library.propTypes = {}

Library.defaultProps = {}
