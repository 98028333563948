import React from "react"
import PropTypes from "prop-types"
import Styled from "styled-components"
// Utils
import {getThumbnailId} from "helpers/utils"
import {useDimension} from "helpers/customHooks"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds} from "@uprise/colors"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// Components
import {Card} from "@uprise/card"
import {Button} from "@uprise/button"
import {Note} from "@uprise/text"
import {H3} from "@uprise/headings"
import {RetinaProgressiveImage} from "@uprise/image"

const SkillLandingWrapper = Styled.div`
			margin: 0 auto;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (min-width: 1160px) {
				max-width: 900px;
			}
`

const ImageWrapper = Styled.div`
		position: relative;
		width: 100%;
		border-radius: 10px;
		overflow: hidden;
		height: ${props => props.height};
`

const SkillOverview = Styled.div`
  padding: ${spacing.s7};
`

const SkillTitle = Styled.div`
  margin-bottom: ${spacing.s6};
`

const CTAWrapper = Styled.div`
	 width: 100%;
	 display: flex;
	 margin-top: ${spacing.s6};
	 & button {
		width: 100%;
	 }

	 @media (min-width: 768px) {
		 justify-content: center;
		& button {
			width: auto;
			min-width: 375px;
		 	
		} 
	 }

`

const IMAGE_HEIGHT_TO_WIDTH_RATIO = 0.559
const IPAD_WIDTH = 768

const SkillLanding = ({nextTask, skillId, skillTitle, skillDescription, ctaLabel, handleNext}) => {
	const [width] = useDimension(`card-${skillId}`)

	const thumbnailId = getThumbnailId(skillId === 'cybercrimeUprise' ? 'cybercrime-blue' : skillId)
	const isMobile = window.innerWidth < IPAD_WIDTH
	const imageWrapperHeight = width * IMAGE_HEIGHT_TO_WIDTH_RATIO

	return (
		<SkillLandingWrapper>
			<ImageWrapper className='m-b-10' height={`${imageWrapperHeight}px`}>
				<RetinaProgressiveImage
					srcSets={{
						small: thumbnails[`${thumbnailId}-large`],
						medium: thumbnails[`${thumbnailId}-large@2x`],
						large: thumbnails[`${thumbnailId}-large@3x`]
					}}
					overlaySrc={thumbnails[`${thumbnailId}-placeholder`]}
				/>
			</ImageWrapper>

			<Card id={`card-${skillId}`} backgroundColor={backgrounds.white} shadow={true} width='100%'>
				<SkillOverview>
					<SkillTitle>
						<H3>{skillTitle}</H3>
					</SkillTitle>
					<Note>{skillDescription}</Note>
				</SkillOverview>
			</Card>

			<CTAWrapper>
				<Button
					data-testid={`start-button`}
					title={ctaLabel}
					variant='primary'
					size={isMobile ? "medium" : "large"}
					fullWidth={true}
					onClick={handleNext}
				/>
			</CTAWrapper>
		</SkillLandingWrapper>
	)
}

SkillLanding.propTypes = {
	ctaLabel: PropTypes.string.isRequired,
	handleNext: PropTypes.func.isRequired,
	skillDescription: PropTypes.string.isRequired,
	skillId: PropTypes.string.isRequired,
	skillTitle: PropTypes.string.isRequired
}

export default SkillLanding
