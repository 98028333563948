import React from "react"
import {connect} from "react-redux"

// Components
import CancelledCard from "components/Shared/Bookings/Cancelled/CancelledCard"
// UI
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
// Colors
import {extended} from "@uprise/colors"
// styles
import {CancelledStyles} from "components/Shared/Bookings/index.style"

const Cancelled = ({bookings, coaches}) => {
	return (
		<CancelledStyles className='m-b-4'>
			{!!bookings.length && (
				<>
					<H3 className='m-b-0-5'>Cancelled</H3>
				</>
			)}
			{bookings.map(booking => (
				<CancelledCard booking={booking} key={booking.id} coaches={coaches} />
			))}
		</CancelledStyles>
	)
}

export default connect(null, null)(Cancelled)
