const itemsList = [
	{title: "Program Overview", header: "Welcome to Uprise", active: true},
	{title: "Wellbeing Check", header: "Wellbeing check", active: false},
	{title: "Stress Fitness Check", header: "Stress check", active: false},
	{title: "Personalisation", header: "Personalisation", active: false},
	{title: "Your score", header: "Your Wellbeing and Stress Score", active: false}
]

export function getCorporateItemsList() {
	return [...itemsList]
}
