const constraints = {
	email: {
		presence: {
			message: "^Please enter an email address"
		},
		email: {
			message: "^Please enter a valid email address",
			allowEmpty: false
		}
	},
	name: {
		presence: {
			message: "^Please enter a name",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^Your name must be at least 1 characters"
		}
	},
	phone: {
		presence: {
			message: "^Please enter a phone number",
			allowEmpty: false
		},
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 8,
			message: "Must be at least 8 digits in length"
		}
	}
}

export default constraints
