import React, {useState, Fragment} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"

// surveys
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"
// Components
import {Card} from "@uprise/card"
import {H3, H4} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// actions
import {saveSurvey} from "actions/surveyActions"

const CardWrap = Styled.div`
`

const PersonaliseStyles = Styled.section`

	@media (max-width: 1024px) {
		position: relative;
	}

	@media (max-width: 475px) {
		position: relative;
	}
`

const ButtonWrap = Styled.section`
      display: flex;
	flex-direction: row;
      flex-wrap: wrap;

      &:hover {
            cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column
	}

	@media (max-width: 475px) {
		flex-direction: column
	}
`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: row;
	padding: ${spacing.s7}

      &:hover {
            cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column
	}

	@media (max-width: 475px) {
		flex-direction: column
	}
`

const PersonaliseImage = Styled.img`
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
      min-width: 144px;
      width: 144px;
      height: 119px;
      margin-right: ${spacing.s8};

	@media (max-width: 1024px) {
		width: 100%;
		min-width: 100%;
	}

	@media (max-width: 475px) {
		width: 100%;
		min-width: 100%;
	}
`

const ContentRight = Styled.div`
 	width: 100%;
      margin-left: ${spacing.s5};

	@media (max-width: 1024px) {
		position: absolute;
		padding: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@media (max-width: 475px) {
		position: absolute;
		padding: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`

const Personalise = ({className, personalisationResults, personalisationIndex, updateUserState}) => {
	const [selected, setSelected] = useState(personalisationResults)
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	const survey = retrieveSurvey("personalisation-v1")

	const options = survey.questions[0].options
	// check if reply exists
	const _sameOption = currentOption => {
		return option => {
			return option === currentOption
		}
	}

	const _diffOption = currentOption => {
		return option => {
			return option !== currentOption
		}
	}

	const _handlePress = option => {
		const options = selected
		if (options.find(_sameOption(option))) {
			setSelected(selected.filter(_diffOption(option)))
		} else {
			setSelected([...options, option])
		}
	}

	const _handleSubmit = () => {
		setIsLoading(true)
		const answers = {}
		answers["PERSONALISATION"] = [selected]

		saveSurvey(true, answers, "personalisation-v1", personalisationIndex, "survey-personalisation").then(resp => {
			if (resp.status === "success") {
				updateUserState(resp.user_state)
				setSubmitted(true)
				setIsLoading(false)
			}
		})
	}

	return (
		<PersonaliseStyles className={className}>
			<H3 className='m-b-4'>What do you want help with?</H3>

			<CardWrap>
				<Card shadow={true} backgroundColor={backgrounds.white}>
					<ContentWrap>
						<PersonaliseImage
							src={
								submitted
									? require(`assets/images/art/recommendation-done/recommendation-done@3x.png`)
									: require(`assets/images/art/recommendation/recommendation@3x.png`)
							}
						/>
						<ContentRight>
							<H4 className={`${submitted ? "m-t-0 m-b-5" : "m-t-0 m-b-1"}`}>
								{submitted ? "Thank you" : "It helps us give you better course recommendations"}
							</H4>

							{!submitted ? (
								<Fragment>
									<Medium color={extended.charcoal.one} className='m-b-6'>
										I’m looking for
									</Medium>

									<ButtonWrap className='m-b-3'>
										{options.map((option, index) => {
											const isSelected = selected.find(_sameOption(option))

											return (
												<Button
													className='m-b-3 m-r-3'
													key={index}
													paddingLeft={spacing.s5}
													paddingRight={spacing.s5}
													variant={`${isSelected ? "primary" : "secondary"}`}
													fullWidth={false}
													title={option}
													onClick={() => _handlePress(option)}
												/>
											)
										})}
									</ButtonWrap>
								</Fragment>
							) : (
								<Alert type='warning' className='m-b-6'>
									We’ll use your answer to improve Uprise for you. Watch out for your course
									recommendations in your dashboard
								</Alert>
							)}

							{!submitted && (
								<Button
									className='float-right m-b-3'
									onClick={() => _handleSubmit()}
									variant='primary'
									size='medium'
									width='183px'
									fullWidth={false}
									isLoading={isLoading}
									title='Submit'
								/>
							)}
						</ContentRight>
					</ContentWrap>
				</Card>
			</CardWrap>
		</PersonaliseStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(Personalise)
