import React from "react"
import {bool, func, string, number} from "prop-types"

// const baseClass = 'btn-cta card__module is-fullwidth has-banner'
const baseClass = "btn__task"

const TaskButton = ({
	// May need change
	action,
	groupId, // used for coloring per module
	isActive,
	isCompleted,
	isDisabled,
	type,
	// isNext,
	label,
	time,
	id
}) => {
	const className =
		baseClass +
		(isActive ? " is-active" : "") +
		// (isCompleted ? ' is-completed btn-success' : '') +
		(isCompleted ? "" : "") +
		// (isNext ? ' is-next' : '') +
		(groupId ? ` group-${groupId}` : "")

	return (
		<button className={className} onClick={type !== "plaintext" ? action : null} disabled={isDisabled}>
			<span className='task__tick task__tick--module'>
				{isCompleted ? (
					<span className='tick'>
						<i className='fa fa-check' />
					</span>
				) : (
					<span className='tick incomplete'>{id}</span>
				)}
			</span>
			<span
				className='task__label'
				dangerouslySetInnerHTML={{
					__html: label
				}}></span>
			<span className='task__time'>
				<h4 className='text-regular'>{time}</h4>
			</span>
		</button>
	)
}

TaskButton.propTypes = {
	action: func.isRequired,
	groupId: string,
	isActive: bool,
	isCompleted: bool,
	isDisabled: bool,
	isNext: bool,
	label: string.isRequired,
	totalExercises: number,
	completedExercises: number
}

TaskButton.defaultProps = {
	isActive: false,
	isCompleted: false,
	isDisabled: false,
	isNext: false
}

export default TaskButton
