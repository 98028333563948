import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Components
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
// Icons
import Icons from "constants/Icons"

const SuccessImage = Styled.img`
      height: 90px;
      width: 90px;
`

const Date = Styled.span`

`

const DateBar = Styled.div`
	padding: 9px 0;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	background-color: ${extended.lightOrange.five};
`

export const ConfirmModal = ({isOpen, handleClose, data, ...props}) => {
	let history = useHistory()

	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='center'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-1'>Your call is booked</H3>
			<Medium className='m-b-10'>All done, {data?.coach?.name?.split(" ")[0]} will call you</Medium>
			<SuccessImage className='m-b-7' src={Icons.success} />
			<P className='m-b-1' fontSize='14px'>
				Your call is booked with {data?.coach?.name.split(" ")[0]} on
			</P>
			<DateBar className='m-b-4'>
				<H5 lineHeight='1.2' className='m-t-0 m-b-0'>
					<Date>{moment(data?.selectedTime.start).format("DD MMMM")}, </Date>
					<Date>{moment(data?.selectedTime.start).format("h:mm A")} </Date>
					<Date>({moment.tz.zone(moment.tz.guess()).abbr(data?.selectedTime.start)})</Date>
				</H5>
			</DateBar>

			<Medium className='m-b-4' textAlign='left'>
				Remember, you can reschedule/cancel this call anytime from the &rdquo;you&rdquo; section.
			</Medium>

			<Medium className='m-b-10' textAlign='left'>
				We’ll remind you about this call with a notification.
			</Medium>

			<Button
				variant='secondary'
				size='medium'
				title='Back to dashboard'
				onClick={() => history.push("dashboard")}
			/>
		</Modal>
	)
}
