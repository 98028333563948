import React, {useState, useEffect} from "react"
import Styled from "styled-components"
import {connect} from "react-redux"
// Colors
import {backgrounds, extended} from "@uprise/colors"
// Components
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
import {P, Medium, Bold, Small} from "@uprise/text"
import {H5} from "@uprise/headings"
import {RequestConfirmModal} from "components/Shared/Coach/RequestConfirmModal"
import Bookings from "components/Shared/Bookings"
import {YourCoach} from "components/Shared/Coach/YourCoach"
// spacing
import {spacing} from "@uprise/spacing"
// Actions
import {updateUserState} from "actions/userStateActions"
// API
import {post} from "helpers/api"
import {isTherapyEnabled, isCoachingEnabled, isAdditionalSessionsEnabled} from "helpers/utils"

const CappedCallsStyles = Styled.section`
`
const MessageWrap = Styled.div`
	padding: ${spacing.s7}
`

const Content = Styled.div`
	padding: ${spacing.s7}
      display: flex;
      flex-direction: row;
`

const CappedCallsImage = Styled.img`
      height: 149px;
      min-width: 178px;
      border-radius: 10px;
`

const CappedCallsInfo = Styled.div`
      display: flex;
      flex-direction: column;
`

const SendAnother = Styled.div`
	display: inline-block;
	cursor: pointer;
`

const CappedCalls = ({
	className,
	userState,
	totalValidBookings,
	requested,
	code,
	cap,
	isTherapy,
	reachedCapBoth,
	therapyCapped,
	coachingCapped,
	coach,
	duration
}) => {
	const {therapyCallCap, coachingCallCap, combined} = cap
	const [sent, setSent] = useState(false)
	const [callRequested, setCallRequested] = useState(requested)
	const [showRequestConfirm, setShowRequestConfirm] = useState(false)
	const [loadingRequest, setLoadingRequest] = useState(false)
	const [error, setError] = useState(false)
	const [remainingText, setRemainingText] = useState(() => (
		<>
			coaching calls to <Bold>{coachingCallCap} per employee.</Bold> Since you’ve already completed{" "}
			<Bold>{totalValidBookings} calls</Bold>, no more coaching calls are available.
		</>
	))

	const calcCallsRemaing = () => {
		let totalCalls = `${coachingCallCap} coaching and ${therapyCallCap} counselling`
		const therapyEnabled = isTherapyEnabled(userState?.features)
		const coachingEnabled = isCoachingEnabled(userState?.features)

		if (therapyEnabled && !coachingEnabled) totalCalls = `${therapyCallCap} counselling`
		else if (!therapyEnabled && coachingEnabled) totalCalls = `${coachingCallCap} coaching`

		if (combined && reachedCapBoth) {
			setRemainingText(
				<>
					calls to <Bold>{coachingCallCap} per employee</Bold>. Since you’ve already completed{" "}
					{coachingCallCap} calls, no more calls are available.
				</>
			)
		} else if (reachedCapBoth && isTherapyEnabled(userState?.features) && isCoachingEnabled(userState?.features)) {
			setRemainingText(
				<>
					calls to <Bold>{totalCalls} calls per employee.</Bold> Since you’ve already completed{" "}
					<Bold>
						{therapyCallCap} counselling and {coachingCallCap} coaching calls
					</Bold>
					, no more calls are available.
				</>
			)
		} else if ((isTherapy && combined) || (!isTherapy && combined)) {
			setRemainingText(
				<>
					calls to <Bold>{coachingCallCap} per employee.</Bold> Since you’ve already completed{" "}
					<Bold>{totalValidBookings} calls</Bold>, no more calls are available.
				</>
			)
		} else if (!isTherapy && coachingCapped) {
			//coaching capped
			setRemainingText(
				<>
					calls to <Bold>{totalCalls} calls per employee.</Bold> Since you’ve already completed{" "}
					<Bold>{totalValidBookings} coaching calls</Bold>, no more coaching calls are available.
					{therapyEnabled && !therapyCapped
						? ` You can still book counselling calls from the home page.`
						: ""}
				</>
			)
		} else if (isTherapy && therapyCapped) {
			//therapy capped
			setRemainingText(
				<>
					calls to <Bold>{totalCalls} calls per employee.</Bold> Since you’ve already completed{" "}
					<Bold>{totalValidBookings} counselling calls</Bold>, no more counselling calls are available.
					{coachingEnabled && !coachingCapped ? ` You can still book coaching calls from the home page.` : ""}
				</>
			)
		}
	}

	useEffect(() => {
		calcCallsRemaing()
	}, [therapyCallCap, coachingCallCap])

	const _request = () => {
		setLoadingRequest(true)
		const userEmail = userState.email

		// If HR approves and cap is disabled for this user, make sure to re-enable cap on next booking.
		// Performed in makeBooking function of API
		post(`requestAddCall`, {email: userEmail}).then(resp => {
			if (resp.error) {
				if (resp.error === "ValidationFail") {
					setError("Sorry an error has occured please contact technical support")
				}
			} else {
				setShowRequestConfirm(true)
				updateUserState(resp.user_state)
				setCallRequested(true)
			}

			setSent(true)
			setLoadingRequest(false)
		})
	}

	return (
		<CappedCallsStyles className={className}>
			<Card backgroundColor={backgrounds.white} shadow={true} className='m-b-5'>
				<Content>
					<CappedCallsImage
						src={require("assets/images/art/capped-calls/capped-calls@3x.png")}
						className='m-l-4 m-r-10 m-t-4'
					/>

					<CappedCallsInfo>
						<H5 className='m-t-0 m-b-3'>You’ve hit the ceiling!</H5>
						<Medium className='m-b-3'>
							Sorry <Bold>{userState.name.split(" ")[0]}</Bold>, your organisation has limited number of{" "}
							{remainingText}
						</Medium>
						{isAdditionalSessionsEnabled(userState?.features) && (
							<Medium>
								You can <Bold>anonymously</Bold> request your HR team to allocate more coaching calls to
								your account. Your request will remain completely private and will not include any
								identifiable information.
							</Medium>
						)}

						{!callRequested && isAdditionalSessionsEnabled(userState?.features) && (
							<Button
								onClick={() => _request()}
								className='m-t-6'
								isLoading={loadingRequest}
								variant='primary'
								size='medium'
								width='172px'
								title='Send request'
							/>
						)}
					</CappedCallsInfo>
				</Content>

				<MessageWrap>
					{callRequested && isAdditionalSessionsEnabled(userState?.features) && (
						<Alert type='primary'>
							<P fontSize='15px' inline={true} weight='bold'>
								Your anonymous additional coaching calls request has been sent to your HR manager. When
								it’s approved, we’ll send you an email alert.
							</P>

							{!sent && (
								<SendAnother onClick={() => _request()}>
									<Small className='m-l-1' color={extended.purple.two}>
										Send another
									</Small>
								</SendAnother>
							)}
						</Alert>
					)}
					{error.length > 0 && <Alert type='error'>{error}</Alert>}
				</MessageWrap>
			</Card>
			<YourCoach className='m-b-10' coach={coach} duration={duration} />
			<Bookings />
			<RequestConfirmModal
				hrEmail={code?.data?.contact}
				isOpen={showRequestConfirm}
				handleClose={() => setShowRequestConfirm(false)}
			/>
		</CappedCallsStyles>
	)
}

export default CappedCalls

CappedCalls.propTypes = {}

CappedCalls.defaultProps = {}
