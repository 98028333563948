import React, {Fragment, useState} from "react"
import sanitizeHtml from "sanitize-html"

// styles
import {EndSequence, MoreInfoWrap, OtherText, OptionsWrap, Send, SendIcon} from "../styles"

const TextAreaWithEnd = ({handleSend, placeholder, currentMessage}) => {
	const [text, setText] = useState("")

	const _handleEnd = () => {
		const {surveyId, taskId, recurring} = currentMessage.quickReplies

		let _id = currentMessage._id
		let _next = currentMessage.quickReplies._next ? currentMessage.quickReplies._next : currentMessage._next

		handleSend([
			{
				replyType: "questionEnd",
				label: currentMessage.endLabel,
				_id,
				_next
			}
		])
	}

	const _handleSubmit = () => {
		const {
			questionIndex,
			optionType,
			surveyId,
			taskId,
			recurring,
			_id,
			_next,
			questions
		} = currentMessage.quickReplies
		const {replyType, moreInfo, triggerResponse} = currentMessage

		const response = {
			_id: Math.random(),
			_next: _next,
			optionType: optionType,
			moreInfo: moreInfo,
			triggerResponse: triggerResponse,
			surveyId,
			label: text,
			taskId,
			recurring,
			replyType: replyType,
			questionIndex: questionIndex,
			questions: questions
		}

		handleSend([response])
	}

	return (
		<Fragment>
			<OptionsWrap>
				<OtherText
					placeholder={currentMessage.answerLabel}
					value={text}
					onChange={e => setText(sanitizeHtml(e.target.value))}
				/>
				<MoreInfoWrap>
					<EndSequence onClick={_handleEnd}>{currentMessage.endLabel}</EndSequence>
				</MoreInfoWrap>
			</OptionsWrap>

			<Send onClick={_handleSubmit} data-testid='send-button'>
				<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
			</Send>
		</Fragment>
	)
}

export default TextAreaWithEnd
