import moment from "moment"
import {
	LOADING_WELLBEING_GRAPH_DATA,
	LOADING_POPULAR_COACHES,
	LOADING_POPULAR_SKILLS,
	STORE_WELLBEING_GRAPH_DATA,
	STORE_POPULAR_COACHES,
	STORE_POPULAR_SKILLS,
	LOADING_RECENT_BLOG_POSTS,
	STORE_RECENT_BLOG_POSTS,
	LOADING_WELLBEING_LEVEL,
	STORE_WELLBEING_LEVEL,
	LOADING_WELLBEING_LEVEL_FOR_MONTH,
	STORE_WELLBEING_LEVEL_FOR_MONTH,
	LOADING_WELLBEING_LEVEL_FOR_ENGAGED,
	STORE_WELLBEING_LEVEL_FOR_ENGAGED,
	LOADING_STRESS_GRAPH_DATA,
	STORE_STRESS_GRAPH_DATA,
	LOADING_STRESS_LEVEL,
	STORE_STRESS_LEVEL,
	LOADING_STRESS_LEVEL_FOR_MONTH,
	STORE_STRESS_LEVEL_FOR_MONTH,
	LOADING_STRESS_LEVEL_FOR_ENGAGED,
	STORE_STRESS_LEVEL_FOR_ENGAGED,
	LOADING_DATA_UTILIZATION_STATS,
	STORE_DATA_UTILIZATION_STATS,
	LOADING_DATA_UTILIZATION,
	STORE_DATA_UTILIZATION,
	LOADING_HR_FEEDBACK_STATS,
	STORE_HR_FEEDBACK_STATS,
	LOADING_HR_ROI_STATS,
	STORE_HR_ROI_STATS,
	LOADING_HR_RISK_STATS,
	STORE_HR_RISK_STATS
} from "actions/types"

export default function(
	state = {
		wellbeingGraph: {loading: true},
		popularSkills: {loading: true},
		popularCoaches: {loading: true},
		recentBlogPosts: {loading: true},
		wellbeingLevel: {loading: true},
		wbLevelForMonth: {loading: true},
		wbLevelForEngaged: {loading: true},
		stressGraph: {loading: true},
		stressLevel: {loading: true},
		stressLevelForMonth: {loading: true},
		stressLevelForEngaged: {loading: true},
		organizationStats: {loading: true},
		organizationUtilization: {loading: true},
		feedbackStats: {loading: true},
		roiStats: {loading: true}
	},
	action
) {
	switch (action.type) {
		case LOADING_WELLBEING_GRAPH_DATA: {
			return {...state, wellbeingGraph: {...state.wellbeingGraph, ...action.payload}}
		}
		case STORE_WELLBEING_GRAPH_DATA: {
			return {...state, wellbeingGraph: {...state.wellbeingGraph, ...action.payload}}
		}
		case LOADING_POPULAR_SKILLS: {
			return {...state, popularSkills: {...state.popularSkills, ...action.payload}}
		}
		case STORE_POPULAR_SKILLS: {
			return {...state, popularSkills: {...state.popularSkills, ...action.payload}}
		}
		case LOADING_POPULAR_COACHES: {
			return {...state, popularCoaches: {...state.popularCoaches, ...action.payload}}
		}
		case STORE_POPULAR_COACHES: {
			return {...state, popularCoaches: {...state.popularCoaches, ...action.payload}}
		}
		case LOADING_RECENT_BLOG_POSTS: {
			return {...state, recentBlogPosts: {...state.recentBlogPosts, ...action.payload}}
		}
		case STORE_RECENT_BLOG_POSTS: {
			return {
				...state,
				recentBlogPosts: {
					...state.recentBlogPosts,
					data: action.payload.data
						.filter(post => post.full_slug !== "blog/")
						.map(post => ({
							name: post.name,
							date: moment(post.published_at).format("DD MMM YYYY"),
							image: post.content?.body?.[1]?.media,
							link: `https://uprise.co/${post.full_slug}`
						}))
						.slice(0, 6)
				}
			}
		}

		case LOADING_WELLBEING_LEVEL: {
			return {...state, wellbeingLevel: {...state.wellbeingLevel, ...action.payload}}
		}
		case STORE_WELLBEING_LEVEL: {
			return {...state, wellbeingLevel: {...state.wellbeingLevel, ...action.payload}}
		}
		case LOADING_WELLBEING_LEVEL_FOR_MONTH: {
			return {...state, wbLevelForMonth: {...state.wbLevelForMonth, ...action.payload}}
		}
		case STORE_WELLBEING_LEVEL_FOR_MONTH: {
			return {...state, wbLevelForMonth: {...state.wbLevelForMonth, ...action.payload}}
		}
		case LOADING_WELLBEING_LEVEL_FOR_ENGAGED: {
			return {...state, wbLevelForEngaged: {...state.wbLevelForEngaged, ...action.payload}}
		}
		case STORE_WELLBEING_LEVEL_FOR_ENGAGED: {
			return {...state, wbLevelForEngaged: {...state.wbLevelForEngaged, ...action.payload}}
		}

		case LOADING_STRESS_LEVEL: {
			return {...state, stressLevel: {...state.stressLevel, ...action.payload}}
		}
		case STORE_STRESS_LEVEL: {
			return {...state, stressLevel: {...state.stressLevel, ...action.payload}}
		}
		case LOADING_STRESS_GRAPH_DATA: {
			return {...state, stressGraph: {...state.stressGraph, ...action.payload}}
		}
		case STORE_STRESS_GRAPH_DATA: {
			return {...state, stressGraph: {...state.stressGraph, ...action.payload}}
		}
		case LOADING_STRESS_LEVEL_FOR_MONTH: {
			return {...state, stressLevelForMonth: {...state.stressLevelForMonth, ...action.payload}}
		}
		case STORE_STRESS_LEVEL_FOR_MONTH: {
			return {...state, stressLevelForMonth: {...state.stressLevelForMonth, ...action.payload}}
		}
		case LOADING_STRESS_LEVEL_FOR_ENGAGED: {
			return {...state, stressLevelForEngaged: {...state.stressLevelForEngaged, ...action.payload}}
		}
		case STORE_STRESS_LEVEL_FOR_ENGAGED: {
			return {...state, stressLevelForEngaged: {...state.stressLevelForEngaged, ...action.payload}}
		}

		case LOADING_DATA_UTILIZATION_STATS: {
			return {...state, organizationStats: {...state.organizationStats, ...action.payload}}
		}
		case STORE_DATA_UTILIZATION_STATS: {
			return {...state, organizationStats: {...state.organizationStats, ...action.payload}}
		}
		case LOADING_DATA_UTILIZATION: {
			return {...state, organizationUtilization: {...state.organizationUtilization, ...action.payload}}
		}
		case STORE_DATA_UTILIZATION: {
			return {...state, organizationUtilization: {...state.organizationUtilization, ...action.payload}}
		}
		case LOADING_HR_FEEDBACK_STATS: {
			return {
				...state,
				feedbackStats: {...state.feedbackStats, ...action.payload}
			}
		}
		case STORE_HR_FEEDBACK_STATS: {
			return {
				...state,
				feedbackStats: {...state.feedbackStats, ...action.payload}
			}
		}

		case LOADING_HR_ROI_STATS: {
			return {
				...state,
				roiStats: {...state.roiStats, ...action.payload}
			}
		}

		case STORE_HR_ROI_STATS: {
			return {
				...state,
				roiStats: {...state.roiStats, ...action.payload}
			}
		}
        
		
		case LOADING_HR_RISK_STATS: {
			return {
				...state,
				riskStats: {...state.riskStats, ...action.payload}
			}
		}

		case STORE_HR_RISK_STATS: {
			return {
				...state,
				riskStats: {...state.riskStats, ...action.payload}
			}
		}

		default:
			return {
				...state
			}
	}
}
