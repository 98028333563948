import React, {Fragment} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"

// Components
import {Card} from "@uprise/card"
import {Container, ContainerFluid, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Medium, ExtraSmall} from "@uprise/text"
import {H2, H3, H4, H5} from "@uprise/headings"
import SkillCard from "components/Shared/Library/SkillCard"

const SeriesSetStyles = Styled.section`
`

const SeriesSetWrap = Styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
`

export const SeriesSet = ({className, meta, skills, series}) => {
	let history = useHistory()

	const cards = series.skills.map(item => {
		return {type: "skill", id: item, content: skills[item]}
	})

	return (
		<SeriesSetStyles className={className}>
			<H3>{series.title} </H3>
			<Medium className='m-b-6'>{series.longDesc}</Medium>
			<SeriesSetWrap>
				{Object.values(cards).map((item, index) => {
					return (
						<SkillCard
							className={`m-b-10 m-r-5`}
							key={index}
							index={index}
							skills={skills}
							skillId={item.id}
							meta={meta}
						/>
					)
				})}
			</SeriesSetWrap>
		</SeriesSetStyles>
	)
}
