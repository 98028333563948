import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import ReactPlayer from "react-player"

// UI
import {Card} from "@uprise/card"
import {AudioDescription} from "./AudioDescription"
import {Transcript} from "./Transcript"
// Actions
import {completeTask} from "actions/taskActions"
// Api
import {post} from "helpers/api"

const AudioStyle = Styled.section`
      width: 100%;
      height: 100%;
`

const PlayerWrapStyle = Styled.section`
	height: 500px;
      width: 100%;
      align-self: flex-end;
`

const PlayerContent = Styled.section`
	width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
`

const Content = Styled.section`
      width: 100%;
      padding: 20px 20px;
`

const AudioTask = ({className, audio, skillId, activity, completeTask}) => {
	const [sendRequest, setSendRequest] = useState(false)

	useEffect(() => {
		const {taskId} = audio

		// Send complete task API request
		if (sendRequest) {
			post("complete", {
				module: taskId,
				completed: true
			})
				.then(resp => {
					completeTask(resp.user_state, skillId)
				})
				.catch(error => {
					console.log("AudioTask -> error", error)
				})
				.finally(() => setSendRequest(false))
		}
	}, [sendRequest])

	const onTimeUpdate = ({played}) => {
		const {taskId} = audio

		if (played > 0.8 && !activity[taskId]?.completed && !sendRequest) {
			setSendRequest(true)
		} else setSendRequest(false)
	}

	return (
		<AudioStyle className={className}>
			<Card className='m-b-10' shadow={true} backgroundImage={require("assets/images/art/audio@3x.png")}>
				<PlayerContent>
					<PlayerWrapStyle>
						<ReactPlayer
							url={audio.fileURL}
							onProgress={onTimeUpdate}
							controls={true}
							width='100%'
							autoPlay={false}
							height='30px'
							style={{
								height: "auto",
								positon: "relative",
								marginTop: "469px",
								bottom: 0
							}}
							config={{
								file: {
									attributes: {
										controlsList: "nodownload",
										onContextMenu: e => e.preventDefault()
									}
								}
							}}
						/>
					</PlayerWrapStyle>
				</PlayerContent>
			</Card>
			<AudioDescription className='m-b-6' audio={audio} />
			{audio.transcript && <Transcript transcript={audio.transcript} />}
		</AudioStyle>
	)
}

const mapDispatchToProps = dispatch => ({
	completeTask: (state, skillId) => dispatch(completeTask(state, skillId))
})

export default connect(null, mapDispatchToProps)(AudioTask)
