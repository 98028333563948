import React from "react"
import Styled from "styled-components"
// Utils
import {getCoachType} from "helpers/utils"
// Components
import {Medium} from "@uprise/text"
import {H3} from "@uprise/headings"
import CoachCard from "components/Shared/Coach/CoachCard"

const CoachStyles = Styled.section`
`

export const YourCoach = ({className, coach, pick, change, duration}) => {
	if (coach) {
		return (
			<CoachStyles className={className}>
				<H3 className='m-b-6'>Your {getCoachType(duration)}</H3>
				<CoachCard coach={coach} recommended={true} />
			</CoachStyles>
		)
	} else {
		return <Medium>Loading</Medium>
	}
}
