import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {object} from "prop-types"
import Styled, {css} from "styled-components"
// UI
import {Container, Row, Col} from "@uprise/grid"
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H2, H3, H5} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
import {List} from "@uprise/list"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// Spacing
import {spacing} from "@uprise/spacing"

const TipsStyle = Styled.section`
      width: 100%;
      height: 100%;
`

const Content = Styled.section`
	width: 100%;
	padding: 25px;
`

export const TipsTask = ({className, tips}) => {
	const items = tips.tips.map(tip => {
		return {title: tip}
	})

	return (
		<TipsStyle>
			<H3 className='m-b-4'>Tips</H3>
			<Card backgroundColor={backgrounds.white}>
				<Content>
					<List items={items} />
				</Content>
			</Card>
		</TipsStyle>
	)
}

TipsTask.propTypes = {
	tips: object.isRequired
}
