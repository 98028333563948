import React, {useState, useEffect} from "react"
import moment from "moment"
import {connect} from "react-redux"
// Actions
import {fetchBookings} from "actions/bookingsActions"
// Components
import Upcoming from "components/Shared/Bookings/Upcoming"
import Completed from "components/Shared/Bookings/Completed"
import Cancelled from "components/Shared/Bookings/Cancelled"
// API
import {get} from "helpers/api"

const Bookings = ({fetchBookings, bookings}) => {
	const [upcoming, setUpcoming] = useState([])
	const [completed, setCompleted] = useState([])
	const [cancelled, setCancelled] = useState([])
	const [coaches, setCoaches] = useState([])

	useEffect(() => {
		get("coaches")
			.then(coaches => setCoaches(coaches))
			.catch(err => console.log(err))
		fetchBookings()
	}, [])

	useEffect(() => {
		// Get next booking object
		let completedBookings = []
		let next = []
		let cancelledBookings = []
		bookings?.data?.map(booking => {
			if (booking.cancelled) cancelledBookings.push(booking)
			else if (moment(booking.start).isAfter(moment())) next.push(booking)
			else completedBookings.push(booking)
		})
		setUpcoming(next)
		setCompleted(completedBookings)
		setCancelled(cancelledBookings)
	}, [bookings])

	return (
		<>
			<Upcoming bookings={upcoming} coaches={coaches} />
			<Completed bookings={completed} coaches={coaches} />
			<Cancelled bookings={cancelled} coaches={coaches} />
		</>
	)
}

function mapStateToProps(state) {
	return {
		bookings: state?.bookings
	}
}

const mapDispatchToProps = dispatch => ({
	fetchBookings: () => dispatch(fetchBookings())
})

export default connect(mapStateToProps, mapDispatchToProps)(Bookings)
