import React, {useState, useEffect} from "react"
import Styled from "styled-components"
// UI
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {Row, Col} from "@uprise/grid"
import {H3} from "@uprise/headings"
// Colors
import {backgrounds} from "@uprise/colors"
// Icons
import Icons from "constants/Icons"

const IconWrap = Styled.img`
    height: 200px;
`

const EmptyCoach = ({setIsOpen}) => {
    return (
        <Card backgroundColor={backgrounds.white} shadow={true}>
            <Row className='d-flex text-center'>
                <Col className='col-12 col-md-12 col-lg-12 m-t-10 m-b-5'>
                    <IconWrap className='m-t-1' src={Icons.emptyCoach} />
                </Col>
                <Col className='col-12 col-md-12 col-lg-12'>
                    <H3>No coach matches your filter</H3>
                </Col>
                <Col className='col-12 col-md-12 col-lg-12 d-flex justify-content-center m-t-5 m-b-10'>
                    <Button
                        className='m-t-0'
                        variant='primary'
                        title={`Change Filter`}
                        size='large'
                        width='200px'
                        onClick={() => setIsOpen(true)}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default EmptyCoach
