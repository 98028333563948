import React, {Fragment, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import moment from "moment"
import {extended, primary, secondary} from "@uprise/colors"
import {H1, H3, H4} from "@uprise/headings"
import {Button} from "@uprise/button"
import DoughnutGraph from "components/Shared/Graphs/Doughnut"
import GroupedBarChart from "components/Shared/Graphs/GroupedBar"
import DropDownBorderless from "components/Shared/DropDown"
import Preloader from "components/Shared/Preloader/Preloader"
import {
	fetchSixMonthStressGraphData,
	fetchStressLevel,
	fetchStressLevelForEngaged,
	fetchStressLevelForMonth
} from "actions/hrdashboardActions"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"
import ToggleModal from "components/Shared/ToggleModal"
import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import {SectionContainer, PurpleBox, ModalOpenButtonContainer} from "../index.style"

function Stress() {
	const dispatch = useDispatch()
	const {employerCode} = useSelector(state => state.userState)

	useEffect(() => {
		dispatch(fetchSixMonthStressGraphData({employerCode}))
	}, [employerCode])

	const {data: barChartData, loading: barChartLoading} = useSelector(state => state.hrdashboard.stressGraph)

	useEffect(() => {
		dispatch(fetchStressLevel({employerCode}))
	}, [employerCode])
	const {data: stressLevel, loading: stressLevelLoading} = useSelector(state => state.hrdashboard.stressLevel)

	const now = new moment()

	const last6Months = [...Array(6)].map((_, i) => {
		let tempMoment = now
			.clone()
			.startOf("month")
			.subtract(i + 1, "month")

		return {
			value: tempMoment.format("YYYY-MM-DDThh:mm:ss.SSS"),
			label: tempMoment.format("MMMM")
		}
	})

	const [month, setMonth] = useState(last6Months[0])

	useEffect(() => {
		dispatch(
			fetchStressLevelForMonth({
				employerCode,
				monthNumber: moment(month.value, "YYYY-MM-DDThh:mm:ss.SSS")
					.utc()
					.unix()
			})
		)
	}, [employerCode, month])
	const {data: stressLevelForMonth, loading: stressLevelpmLoading} = useSelector(
		state => state.hrdashboard.stressLevelForMonth
	)

	console.log({stressLevelForMonth, stressLevelpmLoading})

	useEffect(() => {
		dispatch(fetchStressLevelForEngaged({employerCode}))
	}, [employerCode])
	const {data: stressLevelForEngaged, loading: stressLevelForEngagedLoading} = useSelector(
		state => state.hrdashboard.stressLevelForEngaged
	)

	return (
		<>
			<SectionContainer>
				<TitleWrapper>
					<H3 className='m-b-5'>Organisation Stress Level</H3>
					<ToggleModal
						toggle={show => (
							<Button
								className='m-b-5'
								title={
									<ModalOpenButtonContainer>
										<img
											src={require(`assets/images/hrdashboard/information-symbol.svg`)}
											className='mr-2'
										/>
										Help me interpret this
									</ModalOpenButtonContainer>
								}
								fullWidth={false}
								size='medium'
								onClick={show}
								variant='tertiary'
								disabled={false}
								icon={true}
							/>
						)}
						content={hide => <HelpMeInterpretModal hide={hide} />}
					/>
				</TitleWrapper>
				<PurpleBox>
					{stressLevel && !stressLevelLoading ? (
						<Fragment>
							<H1 color={primary.purple} textAlign='center' className='mb-3'>
								{stressLevel}
							</H1>
							<H4 textAlign='center' color={extended.charcoal.one}>
								Last 30 days
							</H4>
						</Fragment>
					) : stressLevelLoading ? (
						<Preloader />
					) : (
						<H1 color={primary.purple} textAlign='center' className='mb-3'>
							Loaded with errors
						</H1>
					)}
				</PurpleBox>
			</SectionContainer>
			<SectionContainer>
				<H3 className='mb-4'>Stress levels - Uprise compared to benchmark average</H3>
				<div className='row'>
					<div className='col-6'>
						<DropDownBorderless
							fullWidth
							onChange={e => setMonth(e)}
							items={last6Months}
							initialSelectedItem={month}
							menuStyle={{position: "absolute", zIndex: 2}}
							containerStyle={{background: "white", width: "400px"}}
							customListItemRender={item => (
								<div style={{display: "flex", justifyContent: "space-between"}}>
									<p>{item.label}</p>
								</div>
							)}
						/>
					</div>
					<div className='col-6'></div>
				</div>

				<div className='row mt-4'>
					<div className='col-6'>
						<PurpleBox>
							<H3 textAlign='center' className='mb-3'>
								{employerCode.charAt(0).toUpperCase() + employerCode.slice(1)}
							</H3>
							{stressLevelForMonth &&
							!stressLevelpmLoading &&
							!stressLevelForMonth.stressLevelForMonthByEmpCode?.every(item => item === 0) ? (
								<DoughnutGraph
									data={{
										labels: ["Low", "Medium", "High"],
										datasets: [
											{
												data: stressLevelForMonth.stressLevelForMonthByEmpCode,
												backgroundColor: [
													secondary.electricLightOrange,
													secondary.electricBlue,
													primary.purple
												],
												borderWidth: 0
											}
										]
									}}
								/>
							) : stressLevelpmLoading ? (
								<Preloader />
							) : (
								<H1 color={primary.purple} textAlign='center' className='mb-3'>
									No data to display
								</H1>
							)}
							<H4 textAlign='center'>{moment(month.value).format("MMMM YYYY")}</H4>
							<H4 textAlign='center' color={extended.charcoal.one}>
								All users in Uprise
							</H4>
						</PurpleBox>
					</div>
					<div className='col-6'>
						<PurpleBox>
							<H3 textAlign='center' className='mb-3'>
								Benchmark average
							</H3>
							{stressLevelForMonth &&
							!stressLevelpmLoading &&
							!stressLevelForMonth.stressLevelForMonthAll?.every(item => item === 0) ? (
								<DoughnutGraph
									data={{
										labels: ["Low", "Medium", "High"],
										datasets: [
											{
												data: stressLevelForMonth.stressLevelForMonthAll,
												backgroundColor: [
													secondary.electricLightOrange,
													secondary.electricBlue,
													primary.purple
												],
												borderWidth: 0
											}
										]
									}}
								/>
							) : stressLevelpmLoading ? (
								<Preloader />
							) : (
								<H1 color={primary.purple} textAlign='center' className='mb-3'>
									No data to display
								</H1>
							)}
							<H4 textAlign='center'>{moment(month.value).format("MMMM YYYY")}</H4>
							<H4 textAlign='center' color={extended.charcoal.one}>
								All Uprise users in Financial Services
							</H4>
						</PurpleBox>
					</div>
				</div>
			</SectionContainer>
			<SectionContainer>
				<H3 className='mb-4'>Stress changes for engaged Uprise users</H3>
				<div className='row'>
					<div className='col-6'>
						{/* <Select
									backgroundColor={backgrounds.white}
									onChange={e => setmonth2(e.target.value)}
									value={month2}
									id='month2'
									options={last6Months}
									name='month2'
									label='Month2'
									isRequired
								/> */}
					</div>
					<div className='col-6'></div>
				</div>
				<div className='row mt-4'>
					<div className='col-6'>
						<PurpleBox>
							<H3 textAlign='center' className='mb-3'>
								Pre-Uprise
							</H3>
							{stressLevelForEngaged && !stressLevelForEngagedLoading ? (
								<DoughnutGraph
									data={{
										labels: ["Low", "Medium", "High"],
										datasets: [
											{
												data: stressLevelForEngaged.stressLevelPreUprise,
												backgroundColor: [
													secondary.electricLightOrange,
													secondary.electricBlue,
													primary.purple
												],
												borderWidth: 0
											}
										]
									}}
								/>
							) : stressLevelForEngagedLoading ? (
								<Preloader />
							) : (
								<H1 color={primary.purple} textAlign='center' className='mb-3'>
									Loaded with errors
								</H1>
							)}

							<H4 textAlign='center' color={extended.charcoal.one} style={{height: "40px"}}>
								Scores during onboarding
							</H4>
						</PurpleBox>
					</div>
					<div className='col-6'>
						<PurpleBox>
							<H3 textAlign='center' className='mb-3'>
								Post Uprise
							</H3>
							{stressLevelForEngaged && !stressLevelForEngagedLoading ? (
								<DoughnutGraph
									data={{
										labels: ["Low", "Medium", "High"],
										datasets: [
											{
												data: stressLevelForEngaged.stressLevelPostUprise,
												backgroundColor: [
													secondary.electricLightOrange,
													secondary.electricBlue,
													primary.purple
												],
												borderWidth: 0
											}
										]
									}}
								/>
							) : stressLevelForEngagedLoading ? (
								<Preloader />
							) : (
								<H1 color={primary.purple} textAlign='center' className='mb-3'>
									Loaded with errors
								</H1>
							)}
							<H4 textAlign='center' color={extended.charcoal.one} style={{height: "40px"}}>
								Scores after 2 coaching calls or completing 2 Uprise skills
							</H4>
						</PurpleBox>
					</div>
				</div>
				{Boolean(stressLevelForEngaged && !stressLevelForEngagedLoading) && (
					<div className='row'>
						<div className='col-12'>
							<H4 textAlign='center' color={extended.charcoal.one} className='mt-3'>
								Eligible users: {stressLevelForEngaged.eligibleUsers}
							</H4>
						</div>
					</div>
				)}
			</SectionContainer>
			<PurpleBox>
				<H3 className='mb-4'>Trend data for Stress Levels -</H3>
				<H3 className='mb-4'>Average Stress vs Industry Average</H3>
				<div className='mt-5'>
					{!barChartLoading && barChartData && Object.keys(barChartData).length === 3 ? (
						<GroupedBarChart
							data={{
								labels: barChartData.monthLabels,
								datasets: [
									{
										label: "Monthly Average",
										data: barChartData.averageStressByEmpCode,
										fill: false,
										backgroundColor: primary.purple,
										borderColor: primary.purple
									},
									{
										label: "Industry Monthly Average",
										data: barChartData.averageStressAll,
										fill: false,
										backgroundColor: secondary.electricLightOrange,
										borderColor: secondary.electricLightOrange
									}
								]
							}}
						/>
					) : barChartLoading ? (
						<Preloader />
					) : (
						<H1 color={primary.purple} textAlign='center' className='mb-3'>
							Loaded with errors
						</H1>
					)}
				</div>
			</PurpleBox>
		</>
	)
}

export default Stress
