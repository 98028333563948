import React from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {Route} from "react-router-dom"

class CourseTypeRoute extends React.Component {
	constructor(props) {
		super(props)

		let courseType = "corporate"
		switch (window.location.hostname) {
			case "students.uprise.co":
				courseType = "students"
				break
			case "staging-students.uprise.co":
				courseType = "students"
				break
			case "testing-students.uprise.co":
				courseType = "students"
				break
			case "workable.uprise.co":
				courseType = "rtw"
				break
			case "testing-rtw.uprise.co":
				courseType = "rtw"
				break
		}

		this.state = {
			courseType
		}
	}

	render() {
		const Component = this.props.component
		return (
			<Route
				path={this.props.path}
				render={props => <Component {...props} courseType={this.state.courseType} />}
			/>
		)
	}
}

const mapStateToProps = state => {
	const {userState} = state
	return {userState}
}

export default withRouter(connect(mapStateToProps, null)(CourseTypeRoute))
