import React from "react"
import Styled from "styled-components"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
import "components/Rtw/survey/styles/main.css"

const ListWrapper = Styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0px;

	& li {
		display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	}

	@media (min-width: 1024px) {
		flex-direction: row;
		justify-content: center;
	}
`

function PostProgramOverview(props) {
	return (
		<Card
			border={`1px solid ${extended.purple.five}`}
			shadow={false}
			backgroundColor={backgrounds.white}
			padding={spacing.s10}
			width='100%'>
			<div className='onboarding-introduction rtw-onboarding-introduction'>
				<Row className='row'>
					<Col className='col-md-12 flex flex-column align-items-center'>
						<H3 textAlign='center' className='onboarding__title'>
							Workable progress
						</H3>

						<ListWrapper className='onboarding__how-helps mt-5 w-100'>
							<li>
								<img
									className='onboarding__how-helps-img block mb-4'
									src={require("assets/images/rtw/icons/icon-postprogram.png")}
								/>
								<Medium>Post program assessment</Medium>
							</li>
						</ListWrapper>

						<div className='w-100 mt-4 mb-5'>
							<hr className='mb-3' />
							<Row className='justify-content-between'>
								<Col className=''>
									<Medium textAlign='center'>Progress check</Medium>
								</Col>
							</Row>
						</div>

						<Row className='justify-content-center'>
							<Col className='col-sm-6'>
								<Button
									className='m-auto px-4'
									title='Start post program assessment'
									fullWidth={false}
									onClick={props.handleNext}
								/>
							</Col>
						</Row>
						<Medium textAlign='center' className='mt-4'>
							All your information is confidential
						</Medium>
					</Col>
				</Row>
			</div>
		</Card>
	)
}
export default PostProgramOverview
