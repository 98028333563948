import React, {Fragment, useEffect, useState} from "react"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import {NavLink} from "react-router-dom"
import FormValidate from "form-validate.js"
import {connect} from "react-redux"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds} from "@uprise/colors"
// Components
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Note} from "@uprise/text"
import {Button} from "@uprise/button"
import {Alert} from "@uprise/alert"
// Validation
import constraints from "validation/email"
// Inputs
import {TextInput} from "@uprise/form"
// Api
import * as api from "helpers/api"
// Actions
import {storeuserState} from "actions/loginActions"
import {isWhiteLabeledApp} from "helpers/utils"

const YourReCaptchaComponent = ({handleSubmit, isLoading}) => {
	const {executeRecaptcha} = useGoogleReCaptcha()

	// Create an event handler so you can call the verification on button click event or form submit
	const _handleReCaptchaVerify = async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available")
			return
		}

		const token = await executeRecaptcha("resetPass")
		// Do whatever you want with the token
		handleSubmit(token)
	}

	return (
		<Button
			variant='primary'
			className='m-b-4'
			title='Send reset link'
			size='large'
			fullWidth={true}
			isLoading={isLoading}
			disabled={false}
			onClick={() => _handleReCaptchaVerify()}
		/>
	)
}

const Forgot = ({children, errors}) => {
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [email, setEmail] = useState("")
	const [message, setMessage] = useState("")
	const [error, setError] = useState({type: "", value: ""})
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [submittedSuccess, setSubmittedSuccess] = useState(false)

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handleSubmit = token => {
		setSubmitted(true)

		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else if (token.length) {
			_handleForgot(token)
		} else {
			alert("failed robot test")
		}
	}

	const _handleForgot = token => {
		setIsLoading(true)
		let data = {
			email: email.toLowerCase(),
			token: token
		}
		if (isWhiteLabeledApp()) {
			data["company"] = "ALLIANZ"
		}

		api.post("forgot", data).then(resp => {
			console.log(resp)
			if (resp.status === "success") {
				setMessage("A Password reset link has been sent to your email")
				setSubmittedSuccess(true)
			} else if (resp.error) {
				_handleError(resp)
			}
			setIsLoading(false)
		})
	}

	const _handleError = resp => {
		if (resp.error) {
			let errors = {}
			switch (resp?.error?.error) {
				case "USER_UNKNOWN_EMAIL":
					errors.email = {
						type: "error",
						text:
							resp?.error?.error?.message ||
							"No account exists for that email address, please try another address"
					}

					break
				case "ValidationFail":
					switch (resp.errors[0].property) {
						case "instance.email":
							errors.name = {type: "error", text: "Please enter valid email"}
							break
					}
					break
				default:
					errors.name = {type: "error", text: "An error has occured please contact Technical support"}
			}

			setError(Object.values(errors)[0])
			return errors
		}
	}

	return (
		<Fragment>
			<H3 className='m-b-10'>Forgot your password</H3>

			<Card className='m-b-4' backgroundColor={backgrounds.white} shadow={true} padding={spacing.s5} width='100%'>
				{error?.text?.length > 0 && (
					<Alert className='m-b-5' type={error.type}>
						{error.text}
					</Alert>
				)}
				{message?.length > 0 && (
					<Alert className='m-b-5' type='success'>
						{message}
					</Alert>
				)}

				<form onChange={event => _validateForm(event)}>
					<TextInput
						onChange={e => setEmail(e.target.value)}
						validation={validation}
						value={email}
						validateControl='email'
						id='email'
						name='email'
						label='Email'
						isFirst
						isRequired
						isLast
					/>
				</form>
			</Card>

			{!submittedSuccess && <YourReCaptchaComponent handleSubmit={_handleSubmit} isLoading={isLoading} />}
			<Note>
				Remember your credentials? <NavLink to={`/sign-in`}>Sign in</NavLink>.
			</Note>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Forgot.propTypes = {}

Forgot.defaultProps = {}

const mapDispatchToProps = dispatch => ({
	storeuserState: state => dispatch(storeuserState(state))
})

export default connect(null, mapDispatchToProps)(Forgot)
