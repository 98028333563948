export const willingnessTrustLettingGoAddictedSelf = [
	{
		_id: 0,
		text:
			"The more that you pay attention to your ‘addicted self’, the more that you’ll notice that it’s not under conscious control",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "I’ve noticed that",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "I haven’t done the sections about the ‘self’ vs ‘person yet. Take me back to the course.",
					value: "back",
					replyType: "dialogue",
					response: "Ok sure.. taking you back"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"Have you done the section of this course about how addiction occurs at a level below consciousness so that at some point you will convince yourself that you can drink or use again?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			values: [
				{
					label: "Yes, i've done the section about how we are automatically wired to use",
					value: "next",
					replyType: "dialogue"
				},
				{
					label: "Take me back to the course",
					value: "back",
					replyType: "dialogue",
					response: "Ok sure.. taking you back"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text: "Thanks for that",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"In this exercise, I’ll be getting you to reflect on the times when you acted from the “I want” self versus times when you were willing to hand it over and trust.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "What do you mean?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		text:
			"The “I want” self is the part of you that feels convinced that something needs to happen in a certain way.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		text: "It’s the pull towards needing something or someone or making effort to have something happen",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 11,
			_next: 12,
			optionType: "radio",
			values: [
				{
					label: "But isn’t it ok to want things?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		text:
			"Wanting things is completely normal and natural. However, if addiction has changed the brain then what you want comes from your addicted self.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 13,
			_next: 14,
			optionType: "radio",
			values: [
				{
					label: "It seems weird to not want anything anymore",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		text: "It’s hard to start with so this exercise is about making a start.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 13,
			_next: 14,
			optionType: "radio",
			values: [
				{
					label: "Ok, let’s do it",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		text:
			"Think through the last full day. I’ll list the times and you check whether you mostly did what you felt you wanted…",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 13,
			_next: 14,
			optionType: "radio",
			values: [
				{
					label: "🙂",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 14,
		text:
			"…and compare that with times when you remembered to hand it over and trust that things would work out without your constant effort and control.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 15,
			_next: 16,
			optionType: "radio",
			replyType: "survey",
			surveyId: "willingness-trust-letting-go-addicted-self-v1",
			taskId: "exercise-willingness-trust-letting-go-addicted-self",
			questions: [0, 1, 2, 3],
			recurring: true,
			values: [
				{
					label: "I’m ready",
					value: "start",
					replyType: "dialogue",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 16,
		text: "Thanks for doing that with me. Most people find it confusing to let go of self-will.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 17,
			_next: 18,
			optionType: "radio",
			values: [
				{
					label: "It is a bit weird. I’ve been doing it all my life",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 18,
		text: "I get it. As much as you can practice letting go of the urge to get what you want.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 19,
			_next: 20,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 20,
		text:
			"The more that you let go of your urges the more that you’ll find that trust can bring you the things you need instead of what your ‘addicted self’ needs.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 21,
			_next: 22,
			optionType: "radio",
			values: [
				{
					label: "I’ll do my best",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 22,
		_next: 23,
		text: "Thanks for completing this exercise. ",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 23,
		_end: true,
		text: "Sending you back, I've placed your notes in your notebook 👍",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
