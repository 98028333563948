export const problemDefinition = [
	{
		_id: 0,
		text: "Welcome to the problem definition exercise",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			surveyId: "survey-problem-definition-v1",
			taskId: "exercise-problem-definition",
			questions: [0, 1, 2, 3],
			recurring: true,
			values: [
				{
					label: "Let's do it",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text: "Thanks for completing this exercise. ",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		text: "I hope this has helped you define the problem you are facing.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "A copy of this exercise will be available in the notebook section",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_end: true,
		text: "Taking you back now",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
