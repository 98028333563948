export const handleError = resp => {
	if (resp.error) {
		let errors = {}

		switch (resp.error) {
			case "PASSWORD_SIGN_ON_NOT_SUPPORTED":
				errors.signInType = {
					type: "error",
					text: "Password signup not supported, please sign up with Azure"
				}
				break
			case "AlreadyExists":
				errors.email = {
					type: "error",
					text: "This email is already in use"
				}
				break
			case "BadEmployerCode":
				errors.employerCode = {
					type: "error",
					text:
						"UNKNOWN EMPLOYER CODE. For further support, please contact the Operations Team via team@uprise.co or call 1300 209 371"
				}
				break
			case "ExpiredEmployerCode":
				errors.employerCode = {
					type: "error",
					text:
						"This code has expired. For further support, please contact the Operations Team via team@uprise.co or call 1300 209 371"
				}
				break
			case "DisabledEmployerCode":
				errors.employerCode = {
					type: "error",
					text:
						"This code is disabled. For further support, please contact the Operations Team via team@uprise.co or call 1300 209 371"
				}
				break
			case "OutOfCodesLimit":
				errors.employerCode = {
					type: "error",
					text:
						"This employer code is full. Please communicate with your organisation or contact Uprise. For further support, please contact the Operations Team via team@uprise.co or call 1300 209 371"
				}
				break
			case "OutOfCodesLimitLendlease":
				errors.employerCode = {
					type: "error",
					text:
						"Sorry, there are a limited number of positions and this Uprise cohort is over-subscribed. \n\n We apologise for the inconvenience. A new cohort will be launch in due course, which you&#39;re welcome to join.\n\n In the meantime, you can contact your local EAP if you need any assistance. \n\n If you need further support and instructions, please contact the Operations Team via team@uprise.co or call 1300 209 371."
				}
				break
			case "WeakPassword":
				errors.password = {type: "error", text: resp.message}
				break
			case "ValidationFail":
				switch (resp.errors[0].property) {
					case "instance.name":
						errors.name = {type: "error", text: "Please enter valid name"}
						break
					case "instance.password":
						errors.password = {type: "error", text: "Please enter a 10 character length password"}
						break
				}
				break
			default:
				errors.networkError = {
					type: "error",
					text:
						"Something went wrong, reload the page or come back later. If you need further support and instructions, please contact the Operations Team via team@uprise.co or call 1300 209 371."
				}
		}

		return Object.values(errors)[0]
	}
}
