import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {NavLink, useHistory} from "react-router-dom"
import {connect} from "react-redux"
// Utils
import {taskIdWhitelist} from "helpers/utils"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
import ExerciseResults from "components/Shared/ExerciseResults"
// UI
import {ContainerFluid, Row} from "@uprise/grid"
// Spacing
import {spacing} from "@uprise/spacing"

import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {H2} from "@uprise/headings"
import {Button} from "@uprise/button"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"

const Wrap = Styled.section`	
	background-color: ${extended.purple.six}; 
      width: 100%;
`

const Art = Styled.img`
	width: 262px;
	height: 212px;
`

const ContentWrap = Styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Notebook = ({userState}) => {
	let history = useHistory()

	const exercises = Object.values(userState.surveyResults).filter(result => {
		return result.taskId.includes("exercise") && result.completed && taskIdWhitelist.includes(result.surveyId)
	})

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={2} />
			</LeftPanel>
			<Wrap>
				<Header
					title='Notebook'
					textAlign='left'
					width='100%'
					back={location?.state?.from.length ? true : false}
				/>
				<Main>
					{exercises.length > 0 ? (
						<ExerciseResults exercises={exercises} />
					) : (
						<ContentWrap>
							<Art
								className='m-t-10 m-b-10'
								src={require("assets/images/art/empty-notebook/empty-notebook@3x.png")}
							/>

							<H2 className='m-b-10'>Your notebook is still empty</H2>

							<Button
								type='primary'
								title='Browse Library'
								paddingLeft={spacing.s5}
								paddingRight={spacing.s5}
								fullWidth={false}
								onClick={() => history.push("library")}
							/>
						</ContentWrap>
					)}
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-block' width='310px' widthLg='250px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(Notebook)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Notebook.propTypes = {}

Notebook.defaultProps = {}
