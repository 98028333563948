import React from "react"
import {Card} from "@uprise/card"
import {backgrounds, primary} from "@uprise/colors"
import {H3} from "@uprise/headings"
import {Bold, Medium} from "@uprise/text"
import {generateRandomName} from "./nameUtil"
import {CardWrap, UpNextStyles} from "../index.style"
import {MediumStyled} from "../UpriseAndPrivacy/index.style"
import {Table, Td, Tr} from "./index.style"

const DummyActivityLog = [
	{name: generateRandomName(), image: require(`assets/images/hrdashboard/icon-coach.svg`), time: `11:59 AM`},
	{name: generateRandomName(), image: require(`assets/images/hrdashboard/medal.svg`), time: `11:59 AM`},
	{name: generateRandomName(), image: require(`assets/images/hrdashboard/telephone.svg`), time: `11:59 AM`}
]

function RecentActivity() {
	return (
		<UpNextStyles>
			<H3 className='m-b-5'>Recent Activity</H3>

			<CardWrap>
				<Card shadow={true} backgroundColor={backgrounds.white}>
					<Table>
						{DummyActivityLog?.length ? (
							DummyActivityLog.map(({name, image, time}, idx) => (
								<Tr key={idx}>
									<Td>
										<img src={image} />
									</Td>
									<Td>
										<MediumStyled>
											<Bold color={primary.charcoal}>{name}</Bold> did something.{" "}
											<a href=''>Link</a>
											{`  <== RATING COMPONENT ==>  `}
										</MediumStyled>
									</Td>
									<Td alignRight={true}>
										<Medium>{time}</Medium>
									</Td>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={3}>No Logs</Td>
							</Tr>
						)}
					</Table>
				</Card>
			</CardWrap>
		</UpNextStyles>
	)
}

export default RecentActivity
