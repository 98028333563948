import React, {useState} from "react"

// Component
import SliderInput from "components/Shared/Chat/QuickReply/inputTypes/SliderInput"
// styles
import {SliderWrap, SliderInputWrap, Send, SendIcon} from "../styles"

const SliderOption = ({currentMessage, type, handleSend}) => {
	const [value, setValue] = useState(3)

	const _handleValue = value => {
		setValue(value)
	}

	const _handleSend = () => {
		const {
			questionIndex,
			optionType,
			surveyId,
			taskId,
			recurring,
			_id,
			_next,
			questions
		} = currentMessage.quickReplies

		const {moreInfo, triggerResponse, replyType} = currentMessage

		const reply = {
			label: value.toString(),
			value: value,
			_id: Math.random(),
			_next: _next,
			optionType: optionType,
			moreInfo: moreInfo,
			triggerResponse: triggerResponse,
			surveyId,
			taskId,
			recurring,
			replyType: replyType,
			questionIndex: questionIndex,
			questions: questions
		}

		handleSend([reply])
	}

	return (
		<SliderWrap>
			<SliderInputWrap>
				<SliderInput options={currentMessage.quickReplies.values} type={type} handleValue={_handleValue} />
			</SliderInputWrap>
			<Send onClick={() => _handleSend()} data-testid='send-button'>
				<SendIcon src={require("assets/images/icons/svg/send.svg")} alt='send' />
			</Send>
		</SliderWrap>
	)
}

export default SliderOption
