
import React, {useState} from "react"
import {useTable, useFilters, useSortBy} from "react-table"
import "./style.css"

function Table({columns, data}) {
	const [filterInput, setFilterInput] = useState("")
	// Use the state and functions returned from useTable to build your UI
	const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setFilter} = useTable(
		{
			columns,
			data
		},
		useFilters,
		useSortBy
	)

	const handleFilterChange = e => {
		const value = e.target.value || undefined
		setFilter("show.name", value)
		setFilterInput(value)
	}

	// Render the UI for your table
	return (
		<>
			{/* <input value={filterInput} onChange={handleFilterChange} placeholder={"Search name"} /> */}
			<table {...getTableProps()} className="role-table">
				<thead>
					{headerGroups.map((headerGroup,index) => (
						<tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column,index) => (
								<th
                                    key={index}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className={column.isSorted ? (column.isSortedDesc ? "sort-desc" : "sort-asc") : ""}>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()} key={i}>
								{row.cells.map((cell,index) => {
									return <td {...cell.getCellProps()} key={index}>{cell.render("Cell")}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
		</>
	)
}

export default Table