import Styled from "styled-components"
import ReactStars from "react-stars"
import {Accordian} from "@uprise/accordian"
// UI
import {Medium} from "@uprise/text"
import {Button} from "@uprise/button"
import {H6} from "@uprise/headings"
// Colors
import {extended} from "@uprise/colors"

export const HR = Styled.div`
	margin: 7px 0;
	border-bottom: 1px solid ${extended.purple.five};
	width: 100%;
`

export const ButtonWarning = Styled(Button)`
	color: #e52121;
	background-color: background-color: rgba(229, 33, 33, 0.08);
	margin-right: unset;
	&:hover{
		color: #e52121;
	}
`

export const CompletedStyles = Styled.section`
`

export const UpcomingStyles = Styled.section`
`

export const CancelledStyles = Styled.section`
`

export const ContentWrap = Styled.section`
    display: flex;
    flex-direction: row;
	padding: 24px;
`

export const ImageWrap = Styled.section`
    width: 100px;
    height: 100px;
`

export const ButtonWrap = Styled.section`
    display: flex;
    flex-direction: row;
	margin-top: 12px;
`

export const CalIcon = Styled.div`
	padding-right: 15px;
	margin-top: 6px;
`

export const ContentRight = Styled.div`
    width: 100%;
`

export const Date = Styled.span`

`

export const DateBar = Styled.div`
	display: flex;
	padding: 12px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid ${extended.lightOrange.one};
	background-color: ${extended.lightOrange.five};
	margin-bottom: 12px;
`

export const TimezoneStyled = Styled(Medium)`
	font-size: 12px;
	line-height: 1.1;
`

export const CoachPhoto = Styled.div`
	height: 100px;
	width: 100px;
	border-radius: 10px;
	margin-right: 25px;
	
	@media (max-width: 1024px) {
		height: 100px;
		min-width: 100px;
		max-width: 100px;
		margin-right: 0;
	}

	@media (max-width: 475px) {
		height: 100px;
		min-width: 100px;
		max-width: 100px;
		margin-right: 0;
	}
`

export const H6Styled = Styled(H6)`
	margin: 0;
	margin-right: 12px;
`

export const StarsStyled = Styled(ReactStars)`
	margin-right: 12px;
	& span {
		margin: 0 2px;
		line-height: 1;
		top: -2px;
	}
`

export const AccordianStyled = Styled(Accordian)`
	> div > div:first-child {
		background: unset;
	}
	& h4 {
		width: 100%;
		font-size: 16px;
		margin-top: 8px;
	}
	& img {
		margin-top: 1rem;
	}
`
