import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {connect} from "react-redux"
import {useLocation, useHistory} from "react-router-dom"
import moment from "moment-timezone"

// Colors
import {backgrounds} from "@uprise/colors"
// UI
import {ContainerFluid, Row, Col} from "@uprise/grid"
import {H2} from "@uprise/headings"
import {P} from "@uprise/text"
// Components
import {Layout} from "components/Shared/Layout"
import WellbeingLogin from "components/Shared/User/WellbeingLogin"
import Footer from "components/Shared/Home/Footer"

// Utils
import {tryLogin} from "helpers/utils"

const ContentLeft = Styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const Main = Styled.div`
`

const ContentRight = Styled.section`
	margin-top: 166px;
`

const Image = Styled.img`
	width: 106px;
	height: 30px;
	margin-bottom: 116px;
	cursor: pointer;
`
const Art = Styled.img`
	width: 400px;
	height: 300px;
	margin-bottom: 155px;

	@media (max-width: 1024px) {
		width: 350px;
	}
`

const Home = () => {
	let location = useLocation()
	let history = useHistory()

	const [checkingAuth, setCheckingAuth] = useState(true)
	// Check whether we are good to login or not
	useEffect(() => {
		tryLogin({
			history,
			onSuccess: () => setCheckingAuth(false),
			onError: () => setCheckingAuth(false)
		})
	}, [])

	if (checkingAuth) {
		return <div>Loading...</div>
	} else {
		return (
			<Layout rowClassName='justify-content-center h-100'>
				<Col className='col-lg-6 col-md-12'>
					<Row className='justify-content-center h-100' backgroundColor={backgrounds.fadedPurple}>
						<Col className='col-lg-8 col-md-12'>
							<ContentLeft>
								<Main>
									<Image
										className='m-t-5'
										src={require("assets/images/logos/logo-black.svg")}
										onClick={() => history.push("/")}
									/>
									<WellbeingLogin />
								</Main>
								<Footer className='m-b-2' />
							</ContentLeft>
						</Col>
					</Row>
				</Col>
				<Col className='col-12 col-lg-6 d-none d-lg-block'>
					<Row className='justify-content-center h-100' backgroundColor={backgrounds.white}>
						<Col className='col-lg-8'>
							<ContentRight>
								<H2 className='m-b-5' width='auto'>
									The Wellbeing Check is confidential and no identifying information will be shared
									with your company.
								</H2>
								<P width='auto' mediumWidth='auto' className='m-b-10'>
									Your confidential response will help your company know what the stressors at work
									and may provide a business case for leaders
								</P>
								<Art src={require("assets/images/art/wellbeing@3x.png")} />
							</ContentRight>
						</Col>
					</Row>
				</Col>
			</Layout>
		)
	}
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Home.propTypes = {}

Home.defaultProps = {}

export default connect(null, null)(Home)
