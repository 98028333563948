import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
// Actions
import {setSortBy, setFilters} from "actions/coachActions"

// UI
import {spacing} from "@uprise/spacing"
import {backgrounds} from "@uprise/colors"
import {Medium} from "@uprise/text"
import {Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {Modal} from "@uprise/modal"
// components
import Dropdown from "components/Shared/DropDown"
import Filters from "components/Shared/Coach/Filters"
// utils
import {FILTERS} from "helpers/constants"

const Header = ({setSortBy, coaches, isOpen, setIsOpen}) => {
	const [sort, setSort] = useState(coaches.sort)

	useEffect(() => {
		setSort(coaches.sort)
	}, [coaches.sort])

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleSort = el => {
		setSort(el.value)
		setSortBy(el.value)
	}

	return (
		<Row className='d-flex flex-row justify-content-end flex-nowrap  align-items-center'>
			<Col className='col-2 col-md-2 col-lg-2 d-flex'>
				<Medium>Sort By</Medium>
			</Col>
			<Col className='col-6 col-md-6 col-lg-6'>
				<Dropdown
					className='m-r-2'
					fullWidth={true}
					items={FILTERS}
					onChange={handleSort}
					initialSelectedItem={FILTERS[0]}
					menuStyle={{position: "absolute", zIndex: 2}}
				/>
			</Col>
			<Col className='col-3 col-md-3 col-lg-3'>
				<Button
					variant='secondary'
					title={`Filters`}
					size='medium'
					width='auto'
					onClick={() => setIsOpen(!isOpen)}
				/>
			</Col>
			<Modal
				padding={spacing.s10}
				backgroundColor={backgrounds.white}
				width='800px'
				textAlign='center'
				isOpen={isOpen}
				handleClose={handleClose}
				scroll={true}>
				<Filters closeModal={handleClose} />
			</Modal>
		</Row>
	)
}

const mapStateToProps = state => {
	return {
		coaches: state?.coaches
	}
}

const mapDispatchToProps = dispatch => ({
	setSortBy: sort => dispatch(setSortBy(sort))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
