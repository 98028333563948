import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import FormValidate from "form-validate.js"
import {P} from "@uprise/text"
import {H5, H3} from "@uprise/headings"
import {Card} from "@uprise/card"
import {Button} from "@uprise/button"
import {CheckBox} from "@uprise/form"
import {extended, backgrounds} from "@uprise/colors"
import {spacing} from "@uprise/spacing"

// Components
import {LogosHeader} from "./LogosHeader"
// Validation
import constraints from "validation/workableConsent"
// API
import {post} from "helpers/api"
// Actions
import {updateUserState} from "actions/userStateActions"

const FormWrap = Styled.div`
`

const Ul = Styled.ul``

const Li = Styled.li`
      color: ${extended.charcoal.one}
`

const PreOnboardingConsentOneWrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

function PreOnboardingConsentOne({handleNext, updateUserState}) {
	const [validation, setValidation] = useState({})

	const [validator, setValidator] = useState()
	const [submitted, setSubmitted] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [consentOne, setConsentOne] = useState({
		question: "I understand I am being asked to provide consent to participate in this research study",
		value: false
	})
	const [consentTwo, setConsentTwo] = useState({
		question:
			"I have read the Participant Information Sheet or it has been provided to me in a language that I understand",
		value: false
	})
	const [consentThree, setConsentThree] = useState({
		question:
			"I provide my consent for the information collected about me to be used for the purpose of this research study only",
		value: false
	})
	const [consentFour, setConsentFour] = useState({
		question:
			"I understand that if necessary I can ask questions and the research team will respond to my questions.",
		value: false
	})
	const [consentFive, setConsentFive] = useState({
		question:
			"I freely agree to participate in this research study as described and understand that I am free to withdraw at any time during the study and withdrawal will not affect my relationship with any of the named organisations and/or research team members",
		value: false
	})
	const [consentSix, setConsentSix] = useState({
		question:
			"I would like to receive a copy of the study results via email or post, I have provided my details below and ask that they be used for this purpose only",
		value: false
	})
	const [consentSeven, setConsentSeven] = useState({
		question:
			`I understand that I can download a copy of this consent form from <a target="_blank" href='https://uprise-workable.s3-ap-southeast-2.amazonaws.com/Workable+Participant+Information+Statement.pdf'>here</a>`,
		value: false
	})

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	const _handlePisConsent = () => {
		setSubmitted(true)

		if (!validator.valid()) {
			// The same callback used in the validate function can be used here
			validator.touchAll((valid, controls) => {
				setValidation(controls)
				// a good place to update the validation being displayed.
			})
			return
		} else {
			setIsLoading(true)

			post("workable/pis-consent", {
				c1: consentOne,
				c2: consentTwo,
				c3: consentThree,
				c4: consentFour,
				c5: consentFive,
				c6: consentSix,
				c7: consentSeven
			})
				.then(resp => {
					// Mark it as completed...
					post("complete", {
						module: "consent-rtw",
						completed: true
					})
						.then(res => {
							updateUserState(res.user_state)
						})
						.catch(error => console.error(error))

					handleNext()
					setIsLoading(false)
				})
				.catch(err => {
					setIsLoading(false)
				})
		}
	}

	return (
		<PreOnboardingConsentOneWrapper>
			<Card backgroundColor={backgrounds.white} padding={spacing.s10}>
				<LogosHeader />

				<form onChange={event => _validateForm(event)}>
					<H5>What is the research study about?</H5>
					<P>
						You are invited to take part in this research study of the Workable by Uprise program which aims
						to evaluate the acceptability and effectiveness of the program. Preliminary steps of this
						research study include the pilot testing of the intervention to ensure the program is acceptable
						and useful for injured workers such as yourself. An additional aim of the study is to
						investigate the effect of the program on return-to-work outcomes.
					</P>
					<br />
					<P>
						Uprise is a mental health tech company that provides evidence-based, digital mental resilience
						programs for employees. The Workable program aims to assist injured workers who experience early
						mental health symptoms as an impediment to returning to work to reduce their return to work time
						compared to care as usual.
					</P>
					<br />
					<P>
						Your invitation to participate in the research study does not necessarily imply that you are
						experiencing a mental illness. Workable by Uprise is a preventative approach to mental health
						and thus, not for people who have been clinically diagnosed with a mental illness.
					</P>
					<H5>To participate in this research study you need to meet the following inclusion criteria:</H5>
					<Ul>
						<Li>You currently live in Australia </Li>

						<Li>You are 18 years of age or older</Li>

						<Li>
							You are employed, but currently on leave from work due to a work-related physical or
							psychological injury (leave duration at recruitment to be between 2 and 26 weeks)
						</Li>

						<Li>You are not currently severely depressed or psychotic</Li>

						<Li>You are not be engaged in legal action against your employer</Li>

						<Li>You are not actively suicidal or planning to harm yourself or someone else</Li>
						<Li>You have a good understanding of English</Li>
					</Ul>
					<H5>What are the possible benefits to participation?</H5>
					<P>
						By participating in the study you will gain access to a program that may help you attain a
						speedier and successful return to work.
					</P>
					<br />
					<P>
						Uprise Workable program is the modified version of a program evaluated in Sweden and the
						Netherlands that has demonstrated a reduction in time to RTW compared to care as usual and we
						are hoping to see similar results to participants in this research.
					</P>
					<br />
					<P>
						The information obtained from this study will be used to guide future programs to support
						injured workers.
					</P>
					<br />
					<H5>Do I have to take part in this research study?</H5>
					<P>
						Participation in any research study is voluntary. If you do not want to take part, you do not
						have to. Your decision will not affect your relationship with Uprise, the Black Dog Institute,
						The University of New South Wales, EML, iCare or your employer.
					</P>
					<br />
					<H5>If you decide you want to take part in the research study, you will be asked to:</H5>
					<Ul>
						<Li>Read the information carefully (ask questions via email if necessary);</Li>

						<Li>Complete the online questionnaire.</Li>
					</Ul>
					<H5>What does participation in this research require, and are there any risks involved?</H5>
					<P>
						The Uprise program involves completing one video module per week and a coaching call. Modules
						require ~ 15 minutes per week. Homework exercises are optional but recommended and can take up
						to one hour per week. The coaching calls are mandatory and can be booked between 9am – 5pm
						Monday to Friday and run for up to 60 minutes.
					</P>
					<br />
					<P>
						Also, you will be required to answer online questionnaires before starting and after the
						program, as well as 18 weeks after starting. These will include questions about your mental
						health, services that you have accessed, your capacity for work and whether or not you are
						currently working.
					</P>
					<br />
					<P>
						Aside from assigning some time within your day to complete the program, we do not expect that
						there will be any risks or costs associated with taking part in this study. None of the
						information you supply will be shared with your employer.
					</P>
					<br />
					<P>
						However, sometimes it can be helpful for your Uprise coach to discuss the best ways to assist
						you with the other service providers involved in your care. Your Uprise coach will discuss this
						with you, and you will later be given the opportunity to fill out a separate online form to
						indicate which of your service providers you agree to Uprise contacting. This is completely
						optional. You do not have to provide consent for Uprise to contact your other service providers
						in order to participate in this study.
					</P>
					<br />
					<P>
						However, in the unlikely event that your safety is at risk, the Uprise coach will have to
						contact relevant services to ensure that you receive appropriate support. This would include
						your injury management team including injury management specialists, injury management advisors
						and case managers, as well as any other relevant services in order to ensure your safety.
					</P>
					<br />
					<P>
						If you experience discomfort or feelings of distress while participating in the research and you
						require support, you can stop participating at any time. You can also tell a member of the
						research team and they will refer you to appropriate support services. The contact details for
						the Research Team are provided below.
					</P>
					<H5>What will happen to information about me?</H5>
					<P>
						By clicking the ‘agree to participate’ button you are providing your permission for the research
						team to collect and use information that you provide for the research study.
					</P>
					<br />
					<P> Your data will be kept for 7 years.</P>
					<br />
					<P>
						Your information will be stored securely on encrypted Amazon Web Services servers and your
						identity/information will be kept strictly confidential.
					</P>
					<H5>How and when will I find out what the results of the research study are?</H5>
					<P>
						The research team intend to publish and/ report the results of the research study in a variety
						of ways.
					</P>
					<P>
						You can tell us that you wish to receive feedback by providing us with an email address at the
						end of the study. This feedback will be in the form of a brief lay summary plus information
						about upcoming related projects. You will receive and read this feedback after the study is
						finished.
					</P>

					<H5>What if I want to withdraw from the research study? </H5>
					<P>
						If you do consent to participate, you may withdraw at any time. You can do so by emailing the
						team at <b>Joanna.crawford@blackdog.org.au</b> or you can contact the Chief Investigator
						(details below). If you decide to leave the research study, the researchers will not collect
						additional information from you. You can withdraw your questionnaire responses any time before
						you have submitted the questionnaire. If you decide to leave the research study, the researchers
						will destroy any information that has already been collected and no additional information will
						be collected from you.
					</P>
					<br />
					<P>
						Your decision not to participate or to withdraw from the study, will not affect your
						relationship with Uprise, the Black Dog Institute, or UNSW Sydney.
					</P>

					<H5>What should I do if I have further questions about my involvement in the research study?</H5>
					<P>
						If you require further information regarding this study or if you have any problems which may be
						related to your involvement in the study, you can contact the following member/s of the research
						team:
					</P>
					<P>Research Team Contact Name: A/Prof Samuel Harvey</P>
					<br />
					<P> Position: Chief Investigator and Head of Workplace</P>
					<br />
					<P>Mental Health Research Group</P>
					<br />
					<P> Telephone: (02) 9382 4517 </P>
					<br />
					<P>Email: s.harvey@unsw.edu.au </P>
					<br />
					<P>Name: Dr Jo Crawford</P>
					<br />
					<P>Position: Research Officer </P>
					<br />
					<P>Telephone: (02) 9065 9112 </P>
					<br />
					<P>Email: joanna.crawford@blackdog.org.au </P>
					<br />
					<P>
						If at any stage during the project you become distressed or require additional support from
						someone not directly involved in the research, please call:
					</P>
					<br />
					<P>Contact for feelings of distress Name/Organisation: beyondblue - www.beyondblue.org.au</P>
					<br />
					<P>Position: Counsellors/information</P>
					<P> Telephone: 1300 22 4636 </P>
					<br />
					<P>Email: www.beyondblue.org.au</P>
					<br />
					<H5>What if I have a complaint or any concerns about the research study?</H5>
					<P>
						If you have any complaints about any aspect of the project, the way it is being conducted, then
						you may contact:
					</P>
					<br />
					<P>Complaints Contact Position: Human Research Ethics Coordinator</P>
					<br />
					<P>Telephone: + 61 2 9385 6222</P>
					<br />
					<P>Email: humanethics@unsw.edu.au </P>
					<br />
					<P>HC Reference Number: HC190114 </P>

					<H3 className='m-t-10 text-center'>Consent</H3>

					<FormWrap className='m-t-10'>
						<CheckBox
							name='consentOne'
							id='consentOne'
							value={consentOne.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentOne.value = !consentOne.value
								setConsentOne(consentOne)
							}}
							label={consentOne.question}
						/>
						<CheckBox
							name='consentTwo'
							id='consentTwo'
							value={consentTwo.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentTwo.value = !consentTwo.value

								setConsentTwo(consentTwo)
							}}
							label={consentTwo.question}
						/>
						<CheckBox
							name='consentThree'
							id='consentThree'
							value={consentThree.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentThree.value = !consentThree.value
								setConsentThree(consentThree)
							}}
							label={consentThree.question}
						/>
						<CheckBox
							name='consentFour'
							id='consentFour'
							value={consentFour.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentFour.value = !consentFour.value
								setConsentFour(consentFour)
							}}
							label={consentFour.question}
						/>
						<CheckBox
							name='consentFive'
							id='consentFive'
							value={consentFive.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentFive.value = !consentFive.value

								setConsentFive(consentFive)
							}}
							label={consentFive.question}
						/>
						<CheckBox
							name='consentSix'
							id='consentSix'
							value={consentSix.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentSix.value = !consentSix.value

								setConsentSix(consentSix)
							}}
							label={consentSix.question}
						/>
						<CheckBox
							name='consentSeven'
							id='consentSeven'
							value={consentSeven.value}
							isRequired={true}
							validation={validation}
							onChange={event => {
								consentSeven.value = !consentSeven.value

								setConsentSeven(consentSeven)
							}}
							label={consentSeven.question}
						/>
					</FormWrap>
				</form>
				<Button
					className='m-t-10'
					paddingLeft={spacing.s10}
					paddingRight={spacing.s10}
					variant='primary'
					isLoading={isLoading}
					size='medium'
					title='I agree, start questionaire'
					onClick={() => _handlePisConsent()}
					fullWidth={false}
				/>
			</Card>
		</PreOnboardingConsentOneWrapper>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, {updateUserState})(PreOnboardingConsentOne)
