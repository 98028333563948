export const focusQuestion = [
	{
		_id: 0,
		_next: 1,
		text: "Experts who study time management and achievement recommend a key focusing question to drive results",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "What's that?",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		_next: 3,
		text:
			"The question is: “What’s the one Thing you can do, such that by doing it everything else will be easier or unnecessary?”",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text:
			"You can use it in different areas of your life, so I’ll ask you this question in a few different ones so you can get some clarity on which goals might be most important to you",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text: "Here we'll help you find the problem solution",
		quickReplies: {
			_id: 7,
			_next: 8,
			surveyId: "survey-focus-question-v1",
			taskId: "exercise-focus-question",
			optionType: "radio",
			questions: [0, 11],
			recurring: true,
			values: [
				{
					label: "👍",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		_next: 9,
		text:
			"The goal of this exercise was to help you clarify the key goal that will help you to prioritise your activities and actions.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 9,
		_next: 10,
		text: "Thanks for completing this exercise. ",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		_end: true,
		text: "Taking you back now",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
