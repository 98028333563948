import React, {Fragment, useEffect, useState} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import FormValidate from "form-validate.js"
import {useHistory} from "react-router-dom"
import {Row, Col} from "@uprise/grid"
import {spacing} from "@uprise/spacing"
import {Button} from "@uprise/button"
import {TextInput} from "@uprise/form"
import {P, Note} from "@uprise/text"
import {Alert} from "@uprise/alert"
// Validation
import constraints from "validation/workableConsentContacts"
// API
import {post} from "helpers/api"
// Actions
import {updateUserState} from "actions/userStateActions"

const NoteWrap = Styled.div`
	margin-top: 25px;
	display: flex;
	justify-content: flex-end;
`

const ButtonWrap = Styled.div`
	flex-direction: row;
	display: flex;
 	justify-content: flex-end;
`

const FormWrap = Styled.div`

`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: row;

      &:hover {
            cursor: pointer
      }
`

const Table = Styled.table`
      width: 100%;
`

const Tbody = Styled.tbody`
`

const Tr = Styled.tr`
      margin-bottom: ${spacing.s3};

      &:first-child {
            margin-top: 0;
      }
`

const Td = Styled.td`
      padding-bottom: ${spacing.s3};
	padding-top: ${spacing.s3};
	padding: 0 ${spacing.s6};
`

const ContactsForm = ({className, updateUserState}) => {
	const [validation, setValidation] = useState({})
	const [validator, setValidator] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [error, setError] = useState("")
	const [gpName, setGpName] = useState("")
	const [gpPhone, setGpPhone] = useState("")
	const [gpEmail, setGpEmail] = useState("")
	const [alliedEmail, setAlliedEmail] = useState("")
	const [alliedName, setAlliedName] = useState("")
	const [alliedPhone, setAlliedPhone] = useState("")
	const [rehabEmail, setRehabEmail] = useState("")
	const [rehabName, setRehabName] = useState("")
	const [rehabPhone, setRehabPhone] = useState("")

	let history = useHistory()

	const _validateForm = event => {
		validator.validate(event.nativeEvent, (valid, controls) => {
			if (submitted) {
				setValidation(controls)
			}
		})
	}

	useEffect(() => {
		const validator = new FormValidate(constraints)
		setValidator(validator)
	}, [])

	const _handleSubmit = e => {
		e.preventDefault()
		setSubmitted(true)

		if (!validator.valid()) {
			validator.touchAll((valid, controls) => {
				setValidation(controls)
			})
			return
		} else {
			post("workable/consent-contacts", {
				gpName,
				gpPhone,
				gpEmail,
				alliedEmail,
				alliedName,
				alliedPhone,
				rehabEmail,
				rehabName,
				rehabPhone
			})
				.then(res => {
					if (res.error) {
						setError("Error")
					} else {
						// Mark it as completed...
						post("complete", {
							module: "consent-contacts-rtw",
							completed: true
						})
							.then(res => {
								if (res.error) {
									setError("Error")
								} else {
									updateUserState(res.user_state)
									history.push("onboarding")
									setIsLoading(false)
								}
							})
							.catch(error => console.error(error))
					}
				})
				.catch(err => {
					setIsLoading(false)
				})
		}
	}

	return (
		<FormWrap className='m-t-10'>
			{error.length > 0 && (
				<ContentWrap className='m-t-5 m-b-5'>
					<Alert type='error'>{error}</Alert>
				</ContentWrap>
			)}
			<form onChange={event => _validateForm(event)}>
				<Table>
					<Tbody>
						<Tr>
							<Td>
								<P weight='bold'>General practitioner (GP)</P>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='gpName'
									id='gpName'
									label='Name'
									value={gpName}
									onChange={value => setGpName(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='gpPhone'
									id='gpPhone'
									label='Phone'
									value={gpPhone}
									onChange={value => setGpPhone(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='gpEmail'
									id='gpEmail'
									label='Email'
									value={gpEmail}
									onChange={value => setGpEmail(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<P weight='bold'>Allied health care practitioner</P>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='alliedName'
									id='alliedName'
									label='Name'
									value={alliedName}
									onChange={value => setAlliedName(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='alliedPhone'
									id='alliedPhone'
									label='Phone'
									value={alliedPhone}
									onChange={value => setAlliedPhone(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='alliedEmail'
									id='alliedEmail'
									label='Email'
									value={alliedEmail}
									onChange={value => setAlliedEmail(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
						</Tr>
						<Tr>
							<Td>
								<P weight='bold'>Workplace rehabilitation provider</P>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='rehabName'
									id='rehabName'
									label='Name'
									value={rehabName}
									onChange={value => setRehabName(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='rehabPhone'
									id='rehabPhone'
									label='Phone'
									value={rehabPhone}
									onChange={value => setRehabPhone(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
							<Td>
								<TextInput
									type='text'
									name='rehabEmail'
									id='rehabEmail'
									label='Email'
									value={rehabEmail}
									onChange={value => setRehabEmail(value.target.value)}
									outline={false}
									validation={validation}
								/>
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</form>
			<Row>
				<Col>
					<ButtonWrap className='m-t-10'>
						<Button
							className='m-r-10'
							title='Skip'
							fullWidth={false}
							onClick={() => {
								history.push("onboarding")
							}}
							size='medium'
							variant='text'
							disabled={false}
						/>
						<Button
							title='Yes, Continue'
							paddingLeft={spacing.s10}
							paddingRight={spacing.s10}
							fullWidth={false}
							isLoading={isLoading}
							size='medium'
							onClick={e => _handleSubmit(e)}
							variant='primary'
							disabled={false}
						/>
					</ButtonWrap>
					<NoteWrap>
						<Note>
							I consent to Uprise contacting the service providers that I have provided with the details
							above.
						</Note>
					</NoteWrap>
				</Col>
			</Row>
		</FormWrap>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, {updateUserState})(ContactsForm)
