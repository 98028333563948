import React, {useEffect, useState} from "react"
import {object, array} from "prop-types"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"
import {connect} from "react-redux"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import {ContainerFluid, Row} from "@uprise/grid"
import {LeftPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import {VerticalProgress} from "components/Shared/Onboarding/VerticalProgress"
// RTW
import OnboardingRtwSteps from "components/Rtw/PreOnboarding"
import {getRTWItemsList} from "components/Rtw/PreOnboarding/utils"
// Actions
import {updateUserState} from "actions/userStateActions"

const Wrap2 = Styled.section`
	background-color: ${extended.purple.six};
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	@media (max-width: 1200px) {
		flex-direction: column;
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
`

const Wrap = Styled.section`
	background-color: ${extended.purple.six};
	width: 100%;
	height: 100%;
`

const Onboarding = ({userState}) => {
	const [activeIndex, setActiveIndex] = useState(0)

	const courseType = userState.courseType
	const [progressCheckpoints, setProgressCheckpoints] = useState([])

	useEffect(() => {
		if (progressCheckpoints.length === 0) {
			setProgressCheckpoints(getRTWItemsList(userState))
		}
	}, [userState])

	const containerHeight = courseType === "rtw" ? "auto" : null

	const _handleNext = id => {
		let newItems = [...progressCheckpoints]

		newItems.map((item, index) => {
			item.active = false
			if (index === id) {
				item.active = true
			}
		})

		setActiveIndex(id)
		setProgressCheckpoints(newItems)
	}

	const _updateHeader = header => {
		let newItems = [...progressCheckpoints]

		newItems[activeIndex].header = header
		setProgressCheckpoints(newItems)
	}

	if (progressCheckpoints.length === 0) {
		return <p>Loading ...</p>
	}

	return (
		<ContainerFluid height='100vh' backgroundColor={backgrounds.fadedPurple}>
			<Row className='flex-nowrap h-100'>
				<LeftPanel width='250px' className='d-none d-sm-none d-md-none d-lg-none d-xl-flex'>
					<VerticalProgress items={progressCheckpoints} />
				</LeftPanel>
				<Wrap2>
					<Wrap className='h-100'>
						<Header
							title={`${progressCheckpoints[activeIndex].header}`}
							textAlign='center'
							width='100%'
							back={location?.state?.from.length ? true : false}
						/>

						<Main className='10px 25px 10px 25px h-100' containerHeight={containerHeight}>
							{courseType === "rtw" && (
								<OnboardingRtwSteps
									progressCheckpoints={progressCheckpoints}
									handleNext={_handleNext}
									updateHeader={_updateHeader}
									activeIndex={activeIndex}
									userState={userState}
								/>
							)}
						</Main>
					</Wrap>
				</Wrap2>
			</Row>
		</ContainerFluid>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		surveyState: state.surveyState
	}
}

export default connect(mapStateToProps, {updateUserState})(Onboarding)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Onboarding.propTypes = {
	userState: object.isRequired,
	activity: array
}

Onboarding.defaultProps = {}
