import React from "react"
import Styled from "styled-components"

const Header = Styled.header`
	width: 100%;
	flex-direction: row;
	display: flex;
	justify-content: center;
`

const BlackDog = Styled.img`
	height: 70px;
	width: 135px;
	margin-top: 15px;
	margin-left: 15px;
`

const Unsw = Styled.img`
	margin-top: 35px;
	margin-left: 30px;
	height: 45px;
	width: 110px;
`

const UpriseLogo = Styled.img`
	margin-top: 30px;
	height: 50px;
	min-width: 50px;
	width: 100px;
`

const LogosWrap = Styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
`

export const LogosHeader = () => {
	return (
		<Header>
			<LogosWrap>
				<UpriseLogo src={require(`assets/images/logos/logo-blue.svg`)} />
				<BlackDog src={require(`assets/images/logos/partners/black-dog.png`)} />
				<Unsw src={require(`assets/images/logos/partners/unsw.png`)} />
			</LogosWrap>
		</Header>
	)
}
