import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled, {css} from "styled-components"
import {useHistory, useLocation} from "react-router-dom"

// Components
import {ProgressBar} from "@uprise/charts"
import {Card} from "@uprise/card"
import {H3} from "@uprise/headings"
import {Medium, ExtraSmall} from "@uprise/text"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// Thumbnails
import thumbnails from "assets/images/thumbnails"
// Utils
import {getNextTask, getThumbnailId} from "helpers/utils"

const CardWrap = Styled.div`
      transition: .3s ease;
      &:hover {
            transform: translateY(-1px) scale(1.01);
      }
`

const UpNextStyles = Styled.section`

	@media (max-width: 1024px) {
		position: relative;
	}

	@media (max-width: 475px) {
		position: relative;
	}
`

const ContentWrap = Styled.section`
      display: flex;
	flex-direction: row;

      &:hover {
            cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column
	}

	@media (max-width: 475px) {
		flex-direction: column
	}
`

const UpNextImage = Styled.img`
	border-top-right-radius: 0px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	width: 400px;

	@media (max-width: 1024px) {
		width: 100%;
		min-width: 100%;
	}

	@media (max-width: 475px) {
		width: 100%;
		min-width: 100%;
		min-height: 218px;
	}
`

const ContentRight = Styled.div`
	padding: ${spacing.s7} ${spacing.s9};
	width: 100%;

	@media (max-width: 1024px) {
		position: absolute;
		padding: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@media (max-width: 475px) {
		position: absolute;
		padding: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`

const SkillDetails = Styled.div`
	background-color: ${backgrounds.white};

	@media (max-width: 1024px) {
		margin-right: ${spacing.s5};
		margin-left: ${spacing.s5};
		padding: ${spacing.s5};
		border-radius: 10px;
	}

	@media (max-width: 475px) {
		background-color: rgba(255, 255, 255, 0.9);
		margin-right: ${spacing.s5};
		margin-left: ${spacing.s5};
		padding: ${spacing.s5};
		border-radius: 10px;
	}
`

const UpNext = ({className, skills, meta, firstLoad, handleClick, userState}) => {
	const history = useHistory()
	let location = useLocation()

	let nextSkillId

	Object.entries(meta).forEach(skill => {
		if (!skill[1].completed && !nextSkillId) {
			nextSkillId = skill[0]
		}
	})

	const thumbnailId = getThumbnailId(nextSkillId)
	const [nextTask] = getNextTask(userState, nextSkillId)
	const nextSkill = meta[nextSkillId]
	const percentage = (nextSkill.completedTasks / nextSkill.totalTasks) * 100

	const _handleClick = () => {
		history.push(`skill/${nextSkillId}`, {from: location.pathname})
	}

	return (
		<UpNextStyles className={className}>
			<H3 className='m-b-0-5'>Up Next</H3>
			{firstLoad ? (
				<Medium color={extended.charcoal.one} className='m-b-6'>
					Let’s get started
				</Medium>
			) : nextSkill.totalTasks === 0 && nextSkill.completedTasks === 0 ? (
				<Medium color={extended.charcoal.one} className='m-b-6'>
					You’re making great progress!
				</Medium>
			) : (
				<Medium color={extended.charcoal.one} className='m-b-6'>
					Keep going
				</Medium>
			)}
			<CardWrap>
				<Card onClick={() => _handleClick()} shadow={true} backgroundColor={backgrounds.white}>
					<ContentWrap>
						<UpNextImage src={thumbnails[`${thumbnailId}@3x`]} />
						<ContentRight>
							<SkillDetails className='m-b-xs-4 m-b-sm-4'>
								<Medium weight='bold' color={extended.charcoal.two} className='m-b-0-1'>
									{nextTask?.type?.includes("video") ? "Video" : "Exercise"}:
								</Medium>
								{firstLoad ? (
									<H3 className='m-b-2'>Get started with {nextSkill.title}</H3>
								) : nextSkill.totalTasks === 0 && nextSkill.completedTasks === 0 ? (
									<H3 className='m-b-2'>Start with {nextSkill.title}</H3>
								) : (
									<H3 className='m-b-2'>Continue with {nextSkill.title}</H3>
								)}
								<Medium color={extended.charcoal.one} className='m-b-6 m-b-xs-0 m-b-sm-0'>
									{nextSkill.shortDesc}
								</Medium>
								<ProgressBar className='m-b-4 d-none' percentage={percentage} height='5px' />
							</SkillDetails>

							<Card
								className='p-sm-1 p-2'
								backgroundColor={backgrounds.fadedPurple}
								borderRadius='0 0 10px 10px'>
								<ExtraSmall color={extended.charcoal.one}>
									Tip: grab your headphone before it begins
								</ExtraSmall>
							</Card>
						</ContentRight>
					</ContentWrap>
				</Card>
			</CardWrap>
		</UpNextStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(UpNext)
