export const onboardingStudents = [
	{
		_id: 0,
		text: "Hi {{name}} 😄, It's great to meet you!",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text:
			"Uprise is an evidence based mental health app that helps you understand your feelings, reduce stress and be more productive!",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"Before we begin, it's important that we tell you that all your interactions with Uprise will be completely private, anonymized and confidential.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		text:
			"While Uprise has been clinically proven to be effective, it is not a crisis service or a replacement for medical intervention or therapy.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text:
			"Let's begin by measuring your current Wellbeing levels. We're going to ask you 11 questions and this will take only 2-3 minutes of your time.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 7,
			optionType: "radio",
			keepIt: false,
			surveyId: "onboarding-students-v1",
			taskId: "survey-onboarding",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			recurring: false,
			values: [
				{
					label: "Okay",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				},
				{
					label: "Why do we need to measure this?",
					value: "more",
					replyType: "dialogue",
					response:
						"What gets measured gets improved! We also use your current mental fitness levels to tailor the program for you!",
					quickReplies: {
						_id: 6,
						_next: 7,
						optionType: "radio",
						replyType: "survey",
						keepIt: false,
						surveyId: "onboarding-students-v1",
						taskId: "survey-onboarding",
						questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
						recurring: false,
						values: [
							{
								label: "Okay",
								value: "start",
								questionIndex: 0,
								replyType: "dialogue"
							}
						]
					}
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_end: true,
		text: "Thanks for your update.. Calculating your scores, please wait..  📊",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
