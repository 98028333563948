import moment from "moment-timezone"

import {STORE_ACTIVE_SCREENER} from "actions/types"

var signedInSent = false

export default function(state = {}, action) {
	switch (action.type) {
		case STORE_ACTIVE_SCREENER: {
			return {...state, activeScreener: action.screener}
		}

		default:
			return {
				...state
			}
	}
}
