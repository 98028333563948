import React, {Fragment} from "react"
import {post} from "helpers/api"
import {VimeoPlayer} from "components/Shared/Player/Vimeo"
import Styled from "styled-components"
import ReactPlayer from "react-player"
import {connect} from "react-redux"

// Actions
import {completeTask} from "actions/taskActions"

const VideoWrapper = Styled.div`
      width: 100%;
      height: 100%;
`

export const VideoReply = ({activity, taskId, skillId, videoURI, completeTask}) => {
	const onTimeUpdate = ({played}) => {
		if (played > 0.8 && !activity[taskId]?.completed) {
			post("complete", {
				module: taskId,
				completed: true
			}).then(resp => {
				completeTask(resp.user_state, skillId)
			})
		}
	}

	return (
		<VideoWrapper>
			<ReactPlayer
				url={videoURI}
				config={{
					file: {
						attributes: {
							controlsList: "nodownload",
							onContextMenu: e => e.preventDefault()
						}
					}
				}}
				playing
				muted={true}
				autoPlay={true}
				controls={true}
				onProgress={onTimeUpdate}
				width='100%'
				height='100%'
			/>
		</VideoWrapper>
	)
}

const mapDispatchToProps = dispatch => ({
	completeTask: (state, skillId) => dispatch(completeTask(state, skillId))
})

export default connect(null, mapDispatchToProps)(VideoReply)
