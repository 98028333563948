import React from "react"
import styled from "styled-components"
import loaderGif from "./ellipsis-purple.gif"

const Wrapper = styled.div``
const Image = styled.img`
	display: block;
	margin: ${props => (props.center ? "0 auto" : "0")};
`

function Preloader({className = "m-4", center = true, width = "60px"}) {
	return (
		<Wrapper className={className}>
			<Image src={loaderGif} width={width} height={width} center={center} />
		</Wrapper>
	)
}

export default Preloader
