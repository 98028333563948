// UI
import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {extended, primary} from "@uprise/colors"
import {text} from "@uprise/typography"
import {H3} from "@uprise/headings"

export const HR = Styled.div`
	width: 100%;
	display: flex;
	height: 1px;
	margin: 4px 0 4px 0;
	background-color: ${extended.purple.five};
`

export const CheckBoxWrap = Styled.label`
	width: 100%;
	display: flex;
	align-items: center;
	padding: ${spacing.s1} 0;
`

export const ParentCont = Styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	position: relative;
`

export const ChildCont = Styled.div`
	width: 100%;
	display: flex;
`

export const Label = Styled.span`
	display: flex;
	margin-left: 10px;
	color: ${extended.charcoal.one}
`

export const DownIcon = Styled.img`
	transform: ${props => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
	transition: transform 0.1s linear;
	width: 10px;
	height: 6px;
`

export const TitleWrap = Styled.div`
	font-size: ${text.t7}
	font-family: "Proxima Nova Semibold";
`

export const FadeWrap = Styled.div`
	animation: fadeIn 2s;
	transition: all 2s linear;
	display: ${props => (props.visible ? "block" : "none")};
	visibility: ${props => (props.visible ? "visible" : "hidden")};
	padding: 10px 0;
`

export const MoreWrap = Styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`

export const MoreWrapAlz = Styled.div`
	width: 120px;
	display: flex;
	align-items: center;
	position: relative;
`

export const H3Styled = Styled(H3)`
	padding: ${spacing.s5} 0;
`

export const FooterWrap = Styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
`

export const ArrowIcon = Styled.span`
	border: solid ${primary.purple};
	border-width: 0 1px 1px 0;
	padding: 3px;
	float: right;
	display: inline-block;
	transform: ${props => (props.isOpen ? "rotate(225deg)" : "rotate(45deg)")};
	transition: transform 0.1s linear;
	position: absolute;
	right: 12px;
	top: 35%;
`
