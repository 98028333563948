import React, {useState} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"

// Api
import {get} from "helpers/api"
// Components
import {Button} from "@uprise/button"

// actions
import {loggedOut} from "actions/loginActions"

const LogoutStyles = Styled.section`
`

const Logout = ({className, loggedOut}) => {
	let history = useHistory()
	const [isLoading, setIsLoading] = useState(false)

	const _handleLogout = () => {
		setIsLoading(true)
		get("logout")
			.then(() => {
				loggedOut()
				setIsLoading(false)
				_clearIntercomSessions()
				if(window.Intercom){
					window.Intercom("shutdown")
				}
				
				history.push("/sign-in")
			})
			.catch(e => {
				console.log("Error while logging out", e)
				setIsLoading(false)
			})
	}

	const _clearIntercomSessions = () => {
		var theCookies = document.cookie.split(";")
		for (var i = 1; i <= theCookies.length; i++) {
			if (theCookies[i - 1].includes("intercom-session")) {
				document.cookie =
					theCookies[i - 1].split("=")[0] + "=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;Max-Age=0; "
			}
		}
	}

	return (
		<LogoutStyles className={className}>
			<Button
				data-testid='sign-out-button'
				title='Logout'
				variant='primary'
				size='small'
				width='150px'
				isLoading={isLoading}
				onClick={() => _handleLogout()}
			/>
		</LogoutStyles>
	)
}

export default connect(null, {loggedOut})(Logout)
