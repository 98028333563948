export const stringTemplateParser = (expression, valueObj) => {
	const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g

	let text = expression?.toString().replace(templateMatcher, (substring, value) => {
		value = valueObj[value]
		return value
	})

	return text
}

export const checkSurveyIncomplete = (taskId, surveyResults) => {
	let taskIdResults =
		surveyResults?.filter(result => {
			// filter incomplete result
			return !result.completed
		}) || []

	if (taskIdResults.length > 0) {
		return true
	}

	return false
}

export const optionType = type => {
	switch (type) {
		case "singleWithOther":
			return "singleWithOther"
		case "textAreaWithMore":
			return "textAreaWithMore"
		case "textAreaWithMoreEnd":
			return "textAreaWithMoreEnd"
		case "textAreaWithEnd":
			return "textAreaWithEnd"
		case "triggerRadio":
			return "triggerRadio"
		case "triggerMulti":
			return "triggerMulti"
		case "triggerMultiFollowup":
			return "triggerMultiFollowup"
		case "triggerTextAreaFollowUp":
			return "triggerTextAreaFollowUp"
		case "multiRadio":
			return "multiRadio"
		case "multiSkip":
			return "checkboxSkip"
		case "multi":
			return "checkbox"
		case "radio":
			return "radio"
		case "slider":
			return "slider"
		case "textarea":
			return "textarea"
		case "multiWithOther":
			return "multiWithOther"
		case "percentageSlider":
			return "percentageSlider"
	}
}

export const calculateScore = (survey, answers, range) => {
	let score = 0
	range.forEach(q => {
		const question = survey.questions[q]
		const screenerId = question.batteryId
		const answer = answers[screenerId][q]
		const point = survey.questions[q].pointScale[answer]
		score += point
	})
	return score
}
