import React, {Fragment} from "react"
import Styled from "styled-components"
import {
	FacebookShareButton,
	FacebookIcon,
	LinkedinIcon,
	LinkedinShareButton,
	EmailIcon,
	EmailShareButton,
	TwitterIcon,
	TwitterShareButton
} from "react-share"

// import {bool, func, string} from "prop-types"
import {connect} from "react-redux"
import {H2, H5} from "@uprise/headings"
import {P} from "@uprise/text"
import {backgrounds, primary} from "@uprise/colors"
import {Button} from "@uprise/button"
import {text} from "@uprise/typography"
import {Card} from "@uprise/card"

const PromoCard = Styled.img`
	width: 470px;
	height: 246px;
	 
	@media (max-width: 1200px) {
		width: 385px;
		height: 202px;
	}

	@media (max-width: 480px) {
		width: 345px;
		height: 202px;
	}
 `

const SocialShareWrap = Styled.div`
	display: flex;
	flex-direction: row;
 `

const CardExtended = Styled(Card)`
	margin-bottom: 100px;
	
	@media (max-width: 992px) {
		background-color: #fff
		padding: 15px;
	}
 `

const ChipsWrap = Styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media (max-width: 1200px) {
		background-color: #fff
 		flex-direction: column;
	}

	@media (max-width: 480px) {
		background-color: #fff
 		flex-direction: column;
	}
 `

const ButtonExtended = Styled(Button)`
	@media (max-width: 1200px) {
		width: 100%;
	}

	@media (max-width: 480px) {
		width: 100%;
 	}
 `

const RuokdayResultsLeft = ({children, errors}) => {
	return (
		<Fragment>
			<H2 className='m-b-3'>Thanks for checking in!</H2>
			<P fontSize={text.t6} className='m-b-6'>
				Share with your teams, organisation and network that you checked in on your wellbeing and help reduce
				the stigma of talking about mental health in the workplace.
			</P>
			<PromoCard className='m-b-1' src={require(`assets/images/art/ruokday/today-i-asked.png`)} />

			<SocialShareWrap className='m-b-1'>
				<H5>Share on</H5>
				<FacebookShareButton
					url='https://ruokday.uprise.co'
					className='m-l-4 m-r-3'
					quote='I know that when I prioritise checking in with myself, I am in a better place to check in with friends, family and colleagues. When was the last time you checked in?'
					hash='#RUOKDay'>
					<FacebookIcon
						size={32}
						round={true}
						bgStyle={{fill: primary.purple}}
						iconFillColor={backgrounds.white}
					/>
				</FacebookShareButton>

				<LinkedinShareButton
					url='https://ruokday.uprise.co'
					title='I know that when I prioritise checking in with myself, I am in a better place to check in with friends, family and colleagues. When was the last time you checked in?'>
					<LinkedinIcon
						size={32}
						round={true}
						bgStyle={{fill: primary.purple}}
						iconFillColor={backgrounds.white}
					/>
				</LinkedinShareButton>
			</SocialShareWrap>

			<CardExtended>
				<H5 className='m-t-0 m-b-2'>Resources to boost your wellbeing</H5>
				<ChipsWrap>
					<ButtonExtended
						className='m-b-2 m-r-3 m-r-xs-0'
						title='Mindset Skill'
						onClick={() => window.open("https://vimeo.com/218729113/a496e4cc06", "_blank")}
						fullWidth={false}
						width='150px'
						variant='tertiary'
						size='extra-small'
					/>
					<ButtonExtended
						className='m-b-2 m-r-3 m-r-xs-0'
						title='Taking care workbook'
						onClick={() =>
							window.open(
								"https://a.storyblok.com/f/65490/x/de745dbcac/taking_care_of_your_mental_health_during_the_pandemic050620.pdf",
								"_blank"
							)
						}
						fullWidth={false}
						width='150px'
						variant='tertiary'
						size='extra-small'
					/>
					<ButtonExtended
						className='m-b-2 m-r-3 m-r-xs-0'
						title='Head to health'
						onClick={() => window.open("https://headtohealth.gov.au/", "_blank")}
						fullWidth={false}
						width='150px'
						variant='tertiary'
						size='extra-small'
					/>
					<ButtonExtended
						title='Mindspot clinic'
						onClick={() => window.open("https://mindspot.org.au/", "_blank")}
						fullWidth={false}
						width='150px'
						variant='tertiary'
						size='extra-small'
					/>
				</ChipsWrap>
			</CardExtended>
		</Fragment>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
RuokdayResultsLeft.propTypes = {}

RuokdayResultsLeft.defaultProps = {}

export default connect(null, null)(RuokdayResultsLeft)
