export const worryTimeExercise = [
	{
		_id: 0,
		text:
			"This is a space for you to write down your worries but it helps if you’ve already watched the Worry Time Video.",
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 3,
			optionType: "radio",
			surveyId: "worry-time-exercise-v1",
			taskId: "exercise-worry-time-exercise",
			questions: [0],
			values: [
				{
					label: "Please show me the video",
					videoURI:
						"https://player.vimeo.com/external/329050820.hd.mp4?s=e49a3e3eb3076608bb263a7becee164802b78675&profile_id=174",
					vimeoId: "329050820",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 2,
						_next: 3,
						optionType: "radio",
						replyType: "dialogue",
						surveyId: "worry-time-exercise-v1",
						taskId: "exercise-worry-time-exercise",
						questions: [0],
						values: [
							{
								label: "Thanks i've watched the video now",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "I’ve seen it already",
					value: "start",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 3,
		_next: 4,
		text:
			"The idea is that you tell yourself you can worry about something later when you write out the worry. Later on, worry as much as you like for 5 minutes",
		replyType: "dialogue",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "I’ll give it a try",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_next: 7,
		text: "Thanks for doing the exercise.",
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 7,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
