import React, {Fragment, useEffect, useState} from "react"
// import {bool, func, string} from "prop-types"
import Styled from "styled-components"
import {useLocation, useHistory} from "react-router-dom"
import {connect} from "react-redux"
import moment from "moment-timezone"

// Colors
import {extended, backgrounds} from "@uprise/colors"
// Components
import {Layout} from "components/Shared/Layout"
import YourHealth from "components/Shared/YourHealth"
import {YourCoach} from "components/Shared/Coach/YourCoach"
import BookCall from "components/Shared/Coach/BookCall"
import {AccessSupport} from "components/Shared/AccessSupport"
import {Faqs} from "components/Shared/Coach/Faqs"
import Bookings from "components/Shared/Bookings"
import BookingsToggle from "components/Shared/BookingsToggle"
// Actions
import {assignCoach, getCoachByEmail} from "actions/coachActions"
import {updateUserState, fetchAdditionalSessions} from "actions/userStateActions"
import {fetchCode} from "actions/codesActions"
import {fetchCaps} from "actions/capsActions"
import {fetchBookings, setDuration} from "actions/bookingsActions"
import {stateUser} from "actions/loginActions"
import {getTopics} from "actions/topicsActions"
// UI
import {ContainerFluid, Row, Col} from "@uprise/grid"
import {Medium} from "@uprise/text"

// Components
import {LeftPanel, RightPanel, Main} from "components/Shared/Panels"
import {Header} from "components/Shared/Panels"
import LeftMenu from "components/Shared/Menus/Left"
import GetACoach from "components/Shared/GetACoach"
// helpers
import {getCoachingType, getDuration as getCodeDuration} from "helpers/utils"
import {get} from "helpers/api"

const Wrap = Styled.section`	background-color: ${extended.purple.six};
        width: 100%;
`

const getDuration = ({location, courseType, duration, features}) => {
	if (location?.state?.duration) {
		duration = location.state.duration
	} else {
		duration = getCodeDuration(features)
	}
	// Override based on course type
	if (courseType === "rtw") {
		duration = 60
	}
	return duration
}

const MakeBooking = ({
	userState,
	coach,
	assignCoach,
	getCoachByEmail,
	coachEmail,
	code,
	cap,
	courseType,
	match,
	fetchCode,
	fetchCaps,
	fetchBookings,
	setDuration,
	bookings,
	fetchAdditionalSessions,
	updateUserState,
	getTopics
}) => {
	let location = useLocation()
	let history = useHistory()

	const [reachedCapLimit, setReachedCapLimit] = useState(false)
	const [reachedCapLimitTherapy, setReachedCapLimitTherapy] = useState(false)
	const [callsRemaining, setCallsRemaining] = useState(0)
	const [therapyCallsRemaining, setTherapyCallsRemaining] = useState(0)
	const [combinedCallsRemaining, setCombinedCallsRemaining] = useState(0)
	const [codeLoading, setCodeLoading] = useState(true)
	const [durations, setDurations] = useState(
		getDuration({location, courseType, duration: bookings.duration, features: userState?.features})
	)
	const {skills, meta} = userState
	const [capLimit, setCapLimit] = useState(0)
	const [validBookings, setValidBookings] = useState(0)

	const reschedule = location?.state?.reschedule

	useEffect(() => {
		setDuration(durations)
	}, [durations])

	useEffect(() => {
		if (coachEmail) {
			getCoachByEmail(coachEmail)
		}
	}, [coachEmail])

	useEffect(() => {
		fetchAdditionalSessions(userState)
		fetchBookings()
		// check if user is reschudling a call

		// get employer code data
		fetchCode(userState.employerCode)
		fetchCaps(userState.employerCode)
		getTopics()

		// Assign coach if none set
		// if (!coachEmail) {
		// 	assignCoach()
		// }
	}, [])

	useEffect(() => {
		if (Object.values(code).length) {
			setCodeLoading(false)
		}
	}, [code])

	const countRemainingCalls = count => {
		if (count < 0) return 0
		else return count
	}

	const manageCapsCount = () => {
		const {coachingCallCap, therapyCallCap, startingDate, combined} = cap
		let callCap = coachingCallCap
		const duration = location?.state?.duration
		if (duration === 60) callCap = therapyCallCap
		let therapyBookings = []
		let totalValidBookings =
			bookings?.data
				?.filter(booking => {
					return booking?.id
				})
				.filter(booking => moment(booking.createdAt).diff(moment(startingDate), "minutes") >= 0)
				.filter(booking => {
					if (["within24", "within_24", "userNoShow", "user_no_show"].includes(booking.cancelReason))
						return true
					else return !booking.cancelled
				}) || []

		const combinedCallRemaining = [...totalValidBookings]

		//therapy and coaching calls counted separately
		therapyBookings = totalValidBookings.filter(booking => booking.duration === 60)
		totalValidBookings = totalValidBookings.filter(booking => booking.duration === 30)

		setCapLimit(coachingCallCap)

		// Check if calls are capps + check if users bookings exceeds codes call cap limit
		if (!code?.caps?.capCalls) {
			setReachedCapLimit(false)
			setReachedCapLimitTherapy(false)
		} else {
			if (totalValidBookings.length >= parseInt(coachingCallCap)) {
				setReachedCapLimit(true)
			} else setReachedCapLimit(false)

			if (therapyBookings.length >= parseInt(therapyCallCap)) {
				setReachedCapLimitTherapy(true)
			} else setReachedCapLimitTherapy(false)
		}

		if (code?.caps?.capCalls) {
			setCallsRemaining(countRemainingCalls(parseInt(coachingCallCap) - totalValidBookings.length))
			setTherapyCallsRemaining(countRemainingCalls(parseInt(therapyCallCap) - therapyBookings.length))
			setCombinedCallsRemaining(countRemainingCalls(parseInt(therapyCallCap) - combinedCallRemaining.length))
		}
		if (combined) {
			if (combinedCallRemaining.length >= parseInt(callCap)) {
				setReachedCapLimitTherapy(true)
				setReachedCapLimit(true)
			} else {
				setReachedCapLimitTherapy(false)
				setReachedCapLimit(false)
			}
		}
		if (duration === 30) setValidBookings(totalValidBookings)
		else setValidBookings(therapyBookings)
	}

	useEffect(() => {
		manageCapsCount()
	}, [bookings, cap])

	const handleBookTherapy = () => {
		setDurations(60)
		window.scrollTo({left: 0, top: 100, behavior: "smooth"})
	}

	const callApproved =
		durations === 60
			? reachedCapLimitTherapy && userState.disableCallCap
			: reachedCapLimit && userState.disableCallCap

	return (
		<Layout>
			<LeftPanel>
				<LeftMenu active={3} />
			</LeftPanel>
			<Wrap>
				<Header
					title={getCoachingType(bookings.duration || durations)}
					textAlign='left'
					width='100%'
					back={location?.state?.from?.length ? true : false}
					handleBack={() => history.push(location?.state?.from)}
				/>

				<Main>
					{codeLoading ? (
						<Medium>Loading</Medium>
					) : (
						<Fragment>
							{coachEmail && Object.values(coach).length ? (
								<Fragment>
									<BookCall
										className='m-t-4 m-b-10'
										duration={bookings.duration || durations}
										reschedule={reschedule}
										callsRemaining={callsRemaining}
										therapyCallsRemaining={therapyCallsRemaining}
										combinedCallRemaining={combinedCallsRemaining}
										totalValidBookings={validBookings?.length}
										callLimitReached={
											bookings.duration === 60 ? reachedCapLimitTherapy : reachedCapLimit
										}
										callCapLimit={code?.caps?.capCalls && capLimit}
										callApproved={callApproved}
									/>
									<Bookings />
								</Fragment>
							) : (
								<GetACoach className='m-b-10' />
							)}

							{courseType !== "rtw" && (
								<AccessSupport className='m-b-10' handleBookTherapy={handleBookTherapy} />
							)}
							<Faqs duration={bookings.duration} />
						</Fragment>
					)}
				</Main>
			</Wrap>
			<RightPanel className='d-none d-sm-none d-md-none d-lg-none d-xl-block' width='310px'>
				<YourHealth surveyResults={userState.surveyResults} />
			</RightPanel>
		</Layout>
	)
}

function mapStateToProps(state) {
	return {
		courseType: state?.userState?.courseType,
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		code: state?.code,
		cap: state?.cap,
		bookings: state?.bookings
	}
}

const mapDispatchToProps = dispatch => ({
	assignCoach: () => dispatch(assignCoach()),
	getCoachByEmail: email => dispatch(getCoachByEmail(email)),
	updateUserState: state => dispatch(updateUserState(state)),
	fetchCode: code => dispatch(fetchCode(code)),
	fetchCaps: code => dispatch(fetchCaps(code)),
	fetchBookings: () => dispatch(fetchBookings()),
	fetchAdditionalSessions: userState => dispatch(fetchAdditionalSessions(userState)),
	setDuration: duration => dispatch(setDuration(duration)),
	getTopics: () => dispatch(getTopics())
})

export default connect(mapStateToProps, mapDispatchToProps)(MakeBooking)

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
MakeBooking.propTypes = {}

MakeBooking.defaultProps = {}
