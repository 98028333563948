import React, {useEffect, Fragment} from "react"
import {useSelector, useDispatch} from "react-redux"
import {H1, H4, H6} from "@uprise/headings"
import {backgrounds, extended, primary} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import {Card} from "@uprise/card"
import {Col, Row} from "@uprise/grid"
import {Button} from "@uprise/button"
import * as api from "helpers/api"

import HelpMeInterpretModal from "components/Shared/HrDashboard/HelpMeInterpretModal"
import ToggleModal from "components/Shared/ToggleModal"
import {TitleWrapper} from "components/Shared/HrDashboard/OverallWellbeingLevelsAtUprise/index.style"

import {InfoText, ModalOpenButtonContainer, PurpleBox} from "../index.style"
import StarRating from "components/StarRating"
import {fetchHrDashboardFeedbackStats} from "actions/hrdashboardActions"
import Preloader from "components/Shared/Preloader/Preloader"

function Feedback(props) {
	const dispatch = useDispatch()
	const {feedbackStats, loading} = useSelector(state => ({
		feedbackStats: state.hrdashboard.feedbackStats.data,
		loading: state.hrdashboard.feedbackStats.loading
	}))
    const {employerCode} = useSelector(state => state.userState)
	useEffect(() => {
		dispatch(fetchHrDashboardFeedbackStats({employerCode}))
	}, [employerCode])

	return (
		<PurpleBox>
			<TitleWrapper>
				<H4 className='m-b-5'>User feedback at Uprise</H4>
				{/* <ToggleModal
					toggle={show => (
						<Button
							className='m-b-5 bg-transparent'
							title={
								<ModalOpenButtonContainer>
									<img
										src={require(`assets/images/hrdashboard/information-symbol.svg`)}
										className='mr-2'
									/>
									<InfoText>Help me interpret this</InfoText>
								</ModalOpenButtonContainer>
							}
							fullWidth={false}
							size='medium'
							onClick={show}
							variant='tertiary'
							disabled={false}
							icon={true}
						/>
					)}
					content={hide => <HelpMeInterpretModal hide={hide} />}
				/> */}
			</TitleWrapper>
			<Row>
				<Col className='col-md-4'>
					<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s5}>
						{loading ? (
							<Preloader />
						) : (
							<Fragment>
								<H6 textAlign='center'>Uprise Ratings</H6>
								<Fragment>
									<H1 color={primary.purple} textAlign='center' className='mb-3'>
										{feedbackStats?.upriseRating || '0%'}
									</H1>
								</Fragment>
								<div>
									<H6 className='m-b-0' textAlign='center' color={extended.charcoal.one}>
										User ratings for Uprise
									</H6>
									<H6 className='m-t-0 m-b-0' textAlign='center' color={extended.charcoal.one}>
										(How would you rate Uprise out of 10)
									</H6>
								</div>
							</Fragment>
						)}
					</Card>
				</Col>
				<Col className='col-md-4'>
					<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s5}>
						{loading ? (
							<Preloader />
						) : (
							<Fragment>
								<H6 textAlign='center'>Coaching Session Ratings</H6>
								<Fragment>
									<div className='w-100 d-flex justify-content-center mb-2'>
										<StarRating rating={feedbackStats?.coachingSessionRating?.replace("/5", "")} />
									</div>
									<H1 color={primary.purple} textAlign='center' className='mb-3'>
										{feedbackStats?.coachingSessionRating || '0%'}
									</H1>
								</Fragment>
								<H6 textAlign='center' color={extended.charcoal.one}>
									Average score out of five stars for all coaching call sessions since the start of
									the current contract
								</H6>
							</Fragment>
						)}
					</Card>
				</Col>
				<Col className='col-md-4'>
					<Card className='h-100' shadow={true} backgroundColor={backgrounds.white} padding={spacing.s5}>
						{loading ? (
							<Preloader />
						) : (
							<Fragment>
								<H6 textAlign='center'>Employee Engagement Levels</H6>
								<Fragment>
									<H1 color={primary.purple} textAlign='center' className='mb-3'>
										{feedbackStats?.employeeEngagement || '0%'}
									</H1>
								</Fragment>
								<H6 textAlign='center' color={extended.charcoal.one}>
									How likely are you to recommend your company as a great place to work?
								</H6>
							</Fragment>
						)}
					</Card>
				</Col>
			</Row>
		</PurpleBox>
	)
}

export default Feedback
