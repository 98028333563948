import React from "react"
import {bool, func, string} from "prop-types"

const ActionButton = ({action, className, isDisabled, icon, isFullWidth, label, surveyId, location, index}) => (
	<button
		className={`btn-cta btn-action ${className + (isFullWidth ? " is-fullwidth" : "")}`}
		onClick={action}
		disabled={isDisabled}
		id={`${location && surveyId && index ? location + "-" + surveyId + "-Q" + index : null}`}>
		{icon ? <i className={`${icon} btn-action__icon`} /> : ""}
		<span>{label}</span>
	</button>
)

ActionButton.propTypes = {
	action: func,
	className: string,
	isDisabled: bool,
	isFullWidth: bool,
	label: string.isRequired
}

ActionButton.defaultProps = {
	action: () => false,
	className: "",
	isDisabled: false,
	isFullWidth: false
}

export default ActionButton
