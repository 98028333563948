import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"
import moment from "moment-timezone"
import {Modal} from "@uprise/modal"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
// UI
import {Button} from "@uprise/button"
import {Medium, Note, P, Small, Tiny} from "@uprise/text"
import {H2, H3, H4} from "@uprise/headings"
// Components
import {EapSupportForm} from "components/Shared/AccessSupport/EapSupportForm"

export const RequestCallBack = ({isOpen, handleClose, data, ...props}) => {
	return (
		<Modal
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='500px'
			textAlign='left'
			isOpen={isOpen}
			handleClose={handleClose}>
			<H3 className='m-b-5' textAlign='center'>
				Immediate Support
			</H3>
			<EapSupportForm />
		</Modal>
	)
}
