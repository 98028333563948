export const stressCheck = [
	{
		_id: 0,
		text: "Hi welcome to the stress check",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text: "It's really quick. ✅",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "Let's begin by measuring your current stress levels.",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 5,
			optionType: "radio", // or 'checkbox',
			keepIt: false,
			surveyId: "stress-check-v1",
			taskId: "survey-stress-check",
			questions: [0, 1, 2, 3, 4],
			recurring: true,
			values: [
				{
					label: "Okay",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				},
				{
					label: "Why do we need to measure this?",
					value: "more",
					replyType: "dialogue",
					response:
						"What gets measured gets improved! We also use your current mental fitness levels to tailor the program for you!",
					quickReplies: {
						_id: 4,
						_next: 5,
						optionType: "radio", // or 'checkbox',
						replyType: "survey",
						keepIt: false,
						surveyId: "stress-check-v1",
						taskId: "survey-stress-check",
						questions: [0, 1, 2, 3, 4],
						recurring: true,
						values: [
							{
								label: "Okay",
								value: "start",
								questionIndex: 0
							}
						]
					}
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_end: true,
		text: "Thanks for your update.. Calculating your scores, please wait..  📊",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
