import React from "react"
import Styled from "styled-components"
import {H3} from "@uprise/headings"
import {P} from "@uprise/text"
import {useHistory} from "react-router-dom"

const RightContent = Styled.div`
      margin-top: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-items: center;
`

const Content = Styled.div`
      width: 550px;
      align-self: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
            width: 450px;
	}
`

const Header = Styled.div`
      display: flex;
      flex-direction: row;
`

const Image = Styled.img`
      width: 58px;
      height: 58px;
`

const RightContentRTW = () => {
	let history = useHistory()

	return (
		<RightContent>
			<Content>
				<Header className='m-b-6'>
					<Image
						className='m-r-6'
						src={require("assets/images/icons/png/confidential/confidential@3x.png")}
						onClick={() => history.push("/")}
					/>
					<H3 width='405px' mediumWidth='305px'>
						Your responses are completely anonymous.
					</H3>
				</Header>
				<P className='m-b-6'>
					Uprise provides your business with a de-identified report so that your responses stay confidential.
					Your confidential response will help your company know what the stressors are at work.
				</P>
			</Content>
		</RightContent>
	)
}

export default RightContentRTW
