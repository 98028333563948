export const stressTriggers = [
	{
		_id: 0,
		text:
			"Let’s look at your personal triggers for stress and find some ways to deal with things if you are triggered.",
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			surveyId: "stress-triggers-v1",
			taskId: "exercise-stress-triggers",
			questions: [0, 1, 2, 3, 4, 5, 6, 7],
			values: [
				{
					label: "Ok, that could help.",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"Thanks for doing that. This is your personal formula for happiness. Changing the way that you deal with stress is really about noticing triggers and doing something helpful.",
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			values: [
				{
					label: "Ok. I’ll give it a go.",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		_next: 5,
		text: "Thanks for doing the exercise.",
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		_end: true,
		text: "👋 See you next time, I'll take you back..",
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
