import React from "react"
import {bool, func, oneOf, string} from "prop-types"
import Styled from "styled-components"
// spacing
import {spacing} from "@uprise/spacing"

const OptionButtonLabel = Styled.label`
	& input {
		margin-right: ${spacing.s5};
	}
`

const OptionButton = ({checked, className, disabled, group, label, name, onClick, type}) => {
	const combinedClassName =
		"btn-cta btn-option is-fullwidth no-shadow" +
		(group ? ` group-${group}` : "") +
		(className ? className : "") +
		(checked ? " is-filled" : "")

	return (
		<OptionButtonLabel className={combinedClassName}>
			<input type={type} name={name} checked={checked} onClick={onClick} disabled={disabled} readOnly />
			<span>{label}</span>
		</OptionButtonLabel>
	)
}

OptionButton.propTypes = {
	checked: bool.isRequired,
	className: string,
	disabled: bool,
	group: string,
	label: string.isRequired,
	name: string,
	onClick: func.isRequired,
	type: oneOf(["radio", "checkbox"]).isRequired
}

OptionButton.defaultProps = {
	className: "",
	group: "",
	name: ""
}

export default OptionButton
