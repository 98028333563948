import React, {Fragment} from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import Styled from "styled-components"

// Components

import {Medium, ExtraSmall} from "@uprise/text"
import {H2, H3, H4, H5} from "@uprise/headings"
import SkillCard from "components/Shared/Library/SkillCard"

const SuggestedStyles = Styled.section`
`

const SuggestedWrap = Styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	:nth-child(2n) {
   	 margin-right: 0;
}
`

export const Suggested = ({className, meta, suggested, skills}) => {
	const sorted = Object.keys(suggested)
		.sort(function(a, b) {
			return suggested[b] - suggested[a]
		})
		.map(skill => {
			if (suggested[skill] > 0) {
				return skill
			}
		})
		.filter((skill, index) => {
			if (index < 5) {
				return skill
			}
		})

	let cards = sorted.map(skill => {
		return {type: "skill", id: skill, content: skills[skill]}
	})

	if (Object.values(cards).length) {
		return (
			<SuggestedStyles className={className}>
				<H3>Recommended for You</H3>
				<Medium className='m-b-6'>{Suggested.subText}</Medium>
				<SuggestedWrap>
					{Object.values(cards).map((item, index) => {
						return (
							<SkillCard
								className={`m-b-10  m-r-5`}
								key={index}
								index={index}
								skillId={item.id}
								content={item.content}
								meta={meta}
							/>
						)
					})}
				</SuggestedWrap>
			</SuggestedStyles>
		)
	} else {
		return null
	}
}
