// Thumbnails
export default {
	mindset: require("assets/images/thumbnails/mindset/mindset.png"),
	"mindset@2x": require("assets/images/thumbnails/mindset/mindset@2x.png"),
	"mindset@3x": require("assets/images/thumbnails/mindset/mindset@3x.png"),
	"mindset-large": require("assets/images/thumbnails/mindset/mindset-large.png"),
	"mindset-large@2x": require("assets/images/thumbnails/mindset/mindset-large@2x.png"),
	"mindset-large@3x": require("assets/images/thumbnails/mindset/mindset-large@3x.png"),
	"mindset-placeholder": require("assets/images/thumbnails/mindset/mindset-placeholder.png"),
	"personal-values": require("assets/images/thumbnails/personal-values/personal-values.png"),
	"personal-values@2x": require("assets/images/thumbnails/personal-values/personal-values@2x.png"),
	"personal-values@3x": require("assets/images/thumbnails/personal-values/personal-values@3x.png"),
	"personal-values-large": require("assets/images/thumbnails/personal-values/personal-values-large.png"),
	"personal-values-large@2x": require("assets/images/thumbnails/personal-values/personal-values-large@2x.png"),
	"personal-values-large@3x": require("assets/images/thumbnails/personal-values/personal-values-large@3x.png"),
	"personal-values-placeholder": require("assets/images/thumbnails/personal-values/personal-values-placeholder.png"),
	mindfulness: require("assets/images/thumbnails/mindfulness/mindfulness.png"),
	"mindfulness@2x": require("assets/images/thumbnails/mindfulness/mindfulness@2x.png"),
	"mindfulness@3x": require("assets/images/thumbnails/mindfulness/mindfulness@3x.png"),
	"mindfulness-large": require("assets/images/thumbnails/mindfulness/mindfulness-large.png"),
	"mindfulness-large@2x": require("assets/images/thumbnails/mindfulness/mindfulness-large@2x.png"),
	"mindfulness-large@3x": require("assets/images/thumbnails/mindfulness/mindfulness-large@3x.png"),
	"mindfulness-placeholder": require("assets/images/thumbnails/mindfulness/mindfulness-placeholder.png"),
	"stress-management": require("assets/images/thumbnails/stress-management/stress-management.png"),
	"stress-management@2x": require("assets/images/thumbnails/stress-management/stress-management@2x.png"),
	"stress-management@3x": require("assets/images/thumbnails/stress-management/stress-management@3x.png"),
	"stress-management-large": require("assets/images/thumbnails/stress-management/stress-management-large.png"),
	"stress-management-large@2x": require("assets/images/thumbnails/stress-management/stress-management-large@2x.png"),
	"stress-management-large@3x": require("assets/images/thumbnails/stress-management/stress-management-large@3x.png"),
	"stress-management-placeholder": require("assets/images/thumbnails/stress-management/stress-management-placeholder.png"),
	"helping-others": require("assets/images/thumbnails/helping-others/helping-others.png"),
	"helping-others@2x": require("assets/images/thumbnails/helping-others/helping-others@2x.png"),
	"helping-others@3x": require("assets/images/thumbnails/helping-others/helping-others@3x.png"),
	"helping-others-large": require("assets/images/thumbnails/helping-others/helping-others-large.png"),
	"helping-others-large@2x": require("assets/images/thumbnails/helping-others/helping-others-large@2x.png"),
	"helping-others-large@3x": require("assets/images/thumbnails/helping-others/helping-others-large@3x.png"),
	"helping-others-placeholder": require("assets/images/thumbnails/helping-others/helping-others-placeholder.png"),
	"perspective-taking": require("assets/images/thumbnails/perspective-taking/perspective-taking.png"),
	"perspective-taking@2x": require("assets/images/thumbnails/perspective-taking/perspective-taking@2x.png"),
	"perspective-taking@3x": require("assets/images/thumbnails/perspective-taking/perspective-taking@3x.png"),
	"perspective-taking-large": require("assets/images/thumbnails/perspective-taking/perspective-taking-large.png"),
	"perspective-taking-large@2x": require("assets/images/thumbnails/perspective-taking/perspective-taking-large@2x.png"),
	"perspective-taking-large@3x": require("assets/images/thumbnails/perspective-taking/perspective-taking-large@3x.png"),
	"perspective-taking-placeholder": require("assets/images/thumbnails/perspective-taking/perspective-taking-placeholder.png"),
	"autopilot-mode": require("assets/images/thumbnails/autopilot-mode/autopilot-mode.png"),
	"autopilot-mode@2x": require("assets/images/thumbnails/autopilot-mode/autopilot-mode@2x.png"),
	"autopilot-mode@3x": require("assets/images/thumbnails/autopilot-mode/autopilot-mode@3x.png"),
	"autopilot-mode-large": require("assets/images/thumbnails/autopilot-mode/autopilot-mode-large.png"),
	"autopilot-mode-large@2x": require("assets/images/thumbnails/autopilot-mode/autopilot-mode-large@2x.png"),
	"autopilot-mode-large@3x": require("assets/images/thumbnails/autopilot-mode/autopilot-mode-large@3x.png"),
	"autopilot-mode-placeholder": require("assets/images/thumbnails/autopilot-mode/autopilot-mode-placeholder.png"),
	beliefs: require("assets/images/thumbnails/beliefs/beliefs.png"),
	"beliefs@2x": require("assets/images/thumbnails/beliefs/beliefs@2x.png"),
	"beliefs@3x": require("assets/images/thumbnails/beliefs/beliefs@3x.png"),
	"beliefs-large": require("assets/images/thumbnails/beliefs/beliefs-large.png"),
	"beliefs-large@2x": require("assets/images/thumbnails/beliefs/beliefs-large@2x.png"),
	"beliefs-large@3x": require("assets/images/thumbnails/beliefs/beliefs-large@3x.png"),
	"beliefs-placeholder": require("assets/images/thumbnails/beliefs/beliefs-placeholder.png"),
	"control-vs-acceptance": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance.png"),
	"control-vs-acceptance@2x": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance@2x.png"),
	"control-vs-acceptance@3x": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance@3x.png"),
	"control-vs-acceptance-large": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance-large.png"),
	"control-vs-acceptance-large@2x": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance-large@2x.png"),
	"control-vs-acceptance-large@3x": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance-large@3x.png"),
	"control-vs-acceptance-placeholder": require("assets/images/thumbnails/control-vs-acceptance/control-vs-acceptance-placeholder.png"),
	"crisis-situations": require("assets/images/thumbnails/crisis-situations/crisis-situations.png"),
	"crisis-situations@2x": require("assets/images/thumbnails/crisis-situations/crisis-situations@2x.png"),
	"crisis-situations@3x": require("assets/images/thumbnails/crisis-situations/crisis-situations@3x.png"),
	"crisis-situations-large": require("assets/images/thumbnails/crisis-situations/crisis-situations-large.png"),
	"crisis-situations-large@2x": require("assets/images/thumbnails/crisis-situations/crisis-situations-large@2x.png"),
	"crisis-situations-large@3x": require("assets/images/thumbnails/crisis-situations/crisis-situations-large@3x.png"),
	"crisis-situations-placeholder": require("assets/images/thumbnails/crisis-situations/crisis-situations-placeholder.png"),
	"early-warning-signs": require("assets/images/thumbnails/early-warning-signs/early-warning-signs.png"),
	"early-warning-signs@2x": require("assets/images/thumbnails/early-warning-signs/early-warning-signs@2x.png"),
	"early-warning-signs@3x": require("assets/images/thumbnails/early-warning-signs/early-warning-signs@3x.png"),
	"early-warning-signs-large": require("assets/images/thumbnails/early-warning-signs/early-warning-signs-large.png"),
	"early-warning-signs-large@2x": require("assets/images/thumbnails/early-warning-signs/early-warning-signs-large@2x.png"),
	"early-warning-signs-large@3x": require("assets/images/thumbnails/early-warning-signs/early-warning-signs-large@3x.png"),
	"early-warning-signs-placeholder": require("assets/images/thumbnails/early-warning-signs/early-warning-signs-placeholder.png"),
	guilt: require("assets/images/thumbnails/guilt/guilt.png"),
	"guilt@2x": require("assets/images/thumbnails/guilt/guilt@2x.png"),
	"guilt@3x": require("assets/images/thumbnails/guilt/guilt@3x.png"),
	"guilt-large": require("assets/images/thumbnails/guilt/guilt-large.png"),
	"guilt-large@2x": require("assets/images/thumbnails/guilt/guilt-large@2x.png"),
	"guilt-large@3x": require("assets/images/thumbnails/guilt/guilt-large@3x.png"),
	"guilt-placeholder": require("assets/images/thumbnails/guilt/guilt-placeholder.png"),
	metacognition: require("assets/images/thumbnails/metacognition/metacognition.png"),
	"metacognition@2x": require("assets/images/thumbnails/metacognition/metacognition@2x.png"),
	"metacognition@3x": require("assets/images/thumbnails/metacognition/metacognition@3x.png"),
	"metacognition-large": require("assets/images/thumbnails/metacognition/metacognition-large.png"),
	"metacognition-large@2x": require("assets/images/thumbnails/metacognition/metacognition-large@2x.png"),
	"metacognition-placeholder": require("assets/images/thumbnails/metacognition/metacognition-placeholder.png"),
	"metacognition-large@3x": require("assets/images/thumbnails/metacognition/metacognition-large@3x.png"),
	"observer-perspective": require("assets/images/thumbnails/observer-perspective/observer-perspective.png"),
	"observer-perspective@2x": require("assets/images/thumbnails/observer-perspective/observer-perspective@2x.png"),
	"observer-perspective@3x": require("assets/images/thumbnails/observer-perspective/observer-perspective@3x.png"),
	"observer-perspective-large": require("assets/images/thumbnails/observer-perspective/observer-perspective-large.png"),
	"observer-perspective-large@2x": require("assets/images/thumbnails/observer-perspective/observer-perspective-large@2x.png"),
	"observer-perspective-large@3x": require("assets/images/thumbnails/observer-perspective/observer-perspective-large@3x.png"),
	"observer-perspective-placeholder": require("assets/images/thumbnails/observer-perspective/observer-perspective-placeholder.png"),
	"reflective-listening": require("assets/images/thumbnails/reflective-listening/reflective-listening.png"),
	"reflective-listening@2x": require("assets/images/thumbnails/reflective-listening/reflective-listening@2x.png"),
	"reflective-listening@3x": require("assets/images/thumbnails/reflective-listening/reflective-listening@3x.png"),
	"reflective-listening-large": require("assets/images/thumbnails/reflective-listening/reflective-listening-large.png"),
	"reflective-listening-large@2x": require("assets/images/thumbnails/reflective-listening/reflective-listening-large@2x.png"),
	"reflective-listening-large@3x": require("assets/images/thumbnails/reflective-listening/reflective-listening-large@3x.png"),
	"reflective-listening-placeholder": require("assets/images/thumbnails/reflective-listening/reflective-listening-placeholder.png"),
	revision: require("assets/images/thumbnails/revision/revision.png"),
	"revision@2x": require("assets/images/thumbnails/revision/revision@2x.png"),
	"revision@3x": require("assets/images/thumbnails/revision/revision@3x.png"),
	"revision-large": require("assets/images/thumbnails/revision/revision-large.png"),
	"revision-large@2x": require("assets/images/thumbnails/revision/revision-large@2x.png"),
	"revision-large@3x": require("assets/images/thumbnails/revision/revision-large@3x.png"),
	"revision-placeholder": require("assets/images/thumbnails/revision/revision-placeholder.png"),
	"sleep-basics": require("assets/images/thumbnails/sleep-basics/sleep-basics.png"),
	"sleep-basics@2x": require("assets/images/thumbnails/sleep-basics/sleep-basics@2x.png"),
	"sleep-basics@3x": require("assets/images/thumbnails/sleep-basics/sleep-basics@3x.png"),
	"sleep-basics-large": require("assets/images/thumbnails/sleep-basics/sleep-basics-large.png"),
	"sleep-basics-large@2x": require("assets/images/thumbnails/sleep-basics/sleep-basics-large@2x.png"),
	"sleep-basics-large@3x": require("assets/images/thumbnails/sleep-basics/sleep-basics-large@3x.png"),
	"sleep-basics-placeholder": require("assets/images/thumbnails/sleep-basics/sleep-basics-placeholder.png"),
	"sleep-and-relaxation": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation.png"),
	"sleep-and-relaxation@2x": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation@2x.png"),
	"sleep-and-relaxation@3x": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation@3x.png"),
	"sleep-and-relaxation-large": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation-large.png"),
	"sleep-and-relaxation-large@2x": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation-large@2x.png"),
	"sleep-and-relaxation-large@3x": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation-large@3x.png"),
	"sleep-and-relaxation-placeholder": require("assets/images/thumbnails/sleep-and-relaxation/sleep-and-relaxation-placeholder.png"),
	"mindfulness-for-sleep": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep.png"),
	"mindfulness-for-sleep@2x": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep@2x.png"),
	"mindfulness-for-sleep@3x": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep@3x.png"),
	"mindfulness-for-sleep-large": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep-large.png"),
	"mindfulness-for-sleep-large@2x": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep-large@2x.png"),
	"mindfulness-for-sleep-large@3x": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep-large@3x.png"),
	"mindfulness-for-sleep-placeholder": require("assets/images/thumbnails/mindfulness-for-sleep/mindfulness-for-sleep-placeholder.png"),
	stress: require("assets/images/thumbnails/stress/stress.png"),
	"stress@2x": require("assets/images/thumbnails/stress/stress@2x.png"),
	"stress@3x": require("assets/images/thumbnails/stress/stress@3x.png"),
	"stress-large": require("assets/images/thumbnails/stress/stress-large.png"),
	"stress-large@2x": require("assets/images/thumbnails/stress/stress-large@2x.png"),
	"stress-large@3x": require("assets/images/thumbnails/stress/stress-large@3x.png"),
	"stress-placeholder": require("assets/images/thumbnails/stress/stress-placeholder.png"),
	"mental-threats": require("assets/images/thumbnails/mental-threats/mental-threats.png"),
	"mental-threats@2x": require("assets/images/thumbnails/mental-threats/mental-threats@2x.png"),
	"mental-threats@3x": require("assets/images/thumbnails/mental-threats/mental-threats@3x.png"),
	"mental-threats-large": require("assets/images/thumbnails/mental-threats/mental-threats-large.png"),
	"mental-threats-large@2x": require("assets/images/thumbnails/mental-threats/mental-threats-large@2x.png"),
	"mental-threats-large@3x": require("assets/images/thumbnails/mental-threats/mental-threats-large@3x.png"),
	"mental-threats-placeholder": require("assets/images/thumbnails/mental-threats/mental-threats-placeholder.png"),
	"health-and-disease-anxiety": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety.png"),
	"health-and-disease-anxiety@2x": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety@2x.png"),
	"health-and-disease-anxiety@3x": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety@3x.png"),
	"health-and-disease-anxiety-large": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety-large.png"),
	"health-and-disease-anxiety-large@2x": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety-large@2x.png"),
	"health-and-disease-anxiety-large@3x": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety-large@3x.png"),
	"health-and-disease-anxiety-placeholder": require("assets/images/thumbnails/health-and-disease-anxiety/health-and-disease-anxiety-placeholder.png"),

	"cybercrime": require("assets/images/thumbnails/cybercrime/cybercrime.png"),
	"cybercrime@2x": require("assets/images/thumbnails/cybercrime/cybercrime@2x.png"),
	"cybercrime@3x": require("assets/images/thumbnails/cybercrime/cybercrime@3x.png"),
	"cybercrime-large": require("assets/images/thumbnails/cybercrime/cybercrime-large.png"),
	"cybercrime-large@2x": require("assets/images/thumbnails/cybercrime/cybercrime-large@2x.png"),
	"cybercrime-large@3x": require("assets/images/thumbnails/cybercrime/cybercrime-large@3x.png"),
	"cybercrime-placeholder": require("assets/images/thumbnails/cybercrime/cybercrime-placeholder.png"),

	"cybercrime-blue": require("assets/images/thumbnails/cybercrime-blue/cybercrime.png"),
	"cybercrime-blue@2x": require("assets/images/thumbnails/cybercrime-blue/cybercrime@2x.png"),
	"cybercrime-blue@3x": require("assets/images/thumbnails/cybercrime-blue/cybercrime@3x.png"),
	"cybercrime-blue-large": require("assets/images/thumbnails/cybercrime-blue/cybercrime-large.png"),
	"cybercrime-blue-large@2x": require("assets/images/thumbnails/cybercrime-blue/cybercrime-large@2x.png"),
	"cybercrime-blue-large@3x": require("assets/images/thumbnails/cybercrime-blue/cybercrime-large@3x.png"),
	"cybercrime-blue-placeholder": require("assets/images/thumbnails/cybercrime-blue/cybercrime-placeholder.png"),

	"alcohol-addictions-test-yourself": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself.png"),
	"alcohol-addictions-test-yourself@2x": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself@2x.png"),
	"alcohol-addictions-test-yourself@3x": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself@3x.png"),
	"alcohol-addictions-test-yourself-large": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself-large.png"),
	"alcohol-addictions-test-yourself-large@2x": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself-large@2x.png"),
	"alcohol-addictions-test-yourself-large@3x": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself-large@3x.png"),
	"alcohol-addictions-test-yourself-placeholder": require("assets/images/thumbnails/alcohol-addictions-test-yourself/alcohol-addictions-test-yourself-placeholder.png"),

	"alcohol-addictions-get-started": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started.png"),
	"alcohol-addictions-get-started@2x": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started@2x.png"),
	"alcohol-addictions-get-started@3x": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started@3x.png"),
	"alcohol-addictions-get-started-large": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started-large.png"),
	"alcohol-addictions-get-started-large@2x": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started-large@2x.png"),
	"alcohol-addictions-get-started-large@3x": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started-large@3x.png"),
	"alcohol-addictions-get-started-placeholder": require("assets/images/thumbnails/alcohol-addictions-get-started/alcohol-addictions-get-started-placeholder.png"),

	"alcohol-addictions-motivation-getting-help": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help.png"),
	"alcohol-addictions-motivation-getting-help@2x": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help@2x.png"),
	"alcohol-addictions-motivation-getting-help@3x": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help@3x.png"),
	"alcohol-addictions-motivation-getting-help-large": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help-large.png"),
	"alcohol-addictions-motivation-getting-help-large@2x": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help-large@2x.png"),
	"alcohol-addictions-motivation-getting-help-large@3x": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help-large@3x.png"),
	"alcohol-addictions-motivation-getting-help-placeholder": require("assets/images/thumbnails/alcohol-addictions-motivation-getting-help/alcohol-addictions-motivation-getting-help-placeholder.png"),

	"alcohol-addictions-getting-past-the-addicted-self": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self.png"),
	"alcohol-addictions-getting-past-the-addicted-self@2x": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self@2x.png"),
	"alcohol-addictions-getting-past-the-addicted-self@3x": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self@3x.png"),
	"alcohol-addictions-getting-past-the-addicted-self-large": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self-large.png"),
	"alcohol-addictions-getting-past-the-addicted-self-large@2x": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self-large@2x.png"),
	"alcohol-addictions-getting-past-the-addicted-self-large@3x": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self-large@3x.png"),
	"alcohol-addictions-getting-past-the-addicted-self-placeholder": require("assets/images/thumbnails/alcohol-addictions-getting-past-the-addicted-self/alcohol-addictions-getting-past-the-addicted-self-placeholder.png"),

	"alcohol-addictions-mindfulness-skills": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills.png"),
	"alcohol-addictions-mindfulness-skills@2x": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills@2x.png"),
	"alcohol-addictions-mindfulness-skills@3x": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills@3x.png"),
	"alcohol-addictions-mindfulness-skills-large": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills-large.png"),
	"alcohol-addictions-mindfulness-skills-large@2x": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills-large@2x.png"),
	"alcohol-addictions-mindfulness-skills-large@3x": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills-large@3x.png"),
	"alcohol-addictions-mindfulness-skills-placeholder": require("assets/images/thumbnails/alcohol-addictions-mindfulness-skills/alcohol-addictions-mindfulness-skills-placeholder.png"),

	"alcohol-addictions-staying-on-track": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track.png"),
	"alcohol-addictions-staying-on-track@2x": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track@2x.png"),
	"alcohol-addictions-staying-on-track@3x": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track@3x.png"),
	"alcohol-addictions-staying-on-track-large": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track-large.png"),
	"alcohol-addictions-staying-on-track-large@2x": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track-large@2x.png"),
	"alcohol-addictions-staying-on-track-large@3x": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track-large@3x.png"),
	"alcohol-addictions-staying-on-track-placeholder": require("assets/images/thumbnails/alcohol-addictions-staying-on-track/alcohol-addictions-staying-on-track-placeholder.png"),

	"not-feeling-good-enough": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough.png"),
	"not-feeling-good-enough@2x": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough@2x.png"),
	"not-feeling-good-enough@3x": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough@3x.png"),
	"not-feeling-good-enough-large": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough-large.png"),
	"not-feeling-good-enough-large@2x": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough-large@2x.png"),
	"not-feeling-good-enough-large@3x": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough-large@3x.png"),
	"not-feeling-good-enough-placeholder": require("assets/images/thumbnails/not-feeling-good-enough/not-feeling-good-enough-placeholder.png"),

	// RTW
	worry: require("assets/images/thumbnails/worry/worry-time.png"),
	"worry@2x": require("assets/images/thumbnails/worry/worry-time@2x.png"),
	"worry@3x": require("assets/images/thumbnails/worry/worry-time@3x.png"),
	"worry-large": require("assets/images/thumbnails/worry/worry-time-large.png"),
	"worry-large@2x": require("assets/images/thumbnails/worry/worry-time-large@2x.png"),
	"worry-large@3x": require("assets/images/thumbnails/worry/worry-time-large@3x.png"),
	"worry-placeholder": require("assets/images/thumbnails/worry/worry-time-placeholder.png"),
	problemSolving: require("assets/images/thumbnails/problemSolving/overview.png"),
	"problemSolving@2x": require("assets/images/thumbnails/problemSolving/overview@2x.png"),
	"problemSolving@3x": require("assets/images/thumbnails/problemSolving/overview@3x.png"),
	"problemSolving-large": require("assets/images/thumbnails/problemSolving/overview-large.png"),
	"problemSolving-large@2x": require("assets/images/thumbnails/problemSolving/overview-large@2x.png"),
	"problemSolving-large@3x": require("assets/images/thumbnails/problemSolving/overview-large@3x.png"),
	"problemSolving-placeholder": require("assets/images/thumbnails/problemSolving/overview-placeholder.png"),
	problemDefinition: require("assets/images/thumbnails/problemDefinition/problem-definition.png"),
	"problemDefinition@2x": require("assets/images/thumbnails/problemDefinition/problem-definition@2x.png"),
	"problemDefinition@3x": require("assets/images/thumbnails/problemDefinition/problem-definition@3x.png"),
	"problemDefinition-large": require("assets/images/thumbnails/problemDefinition/problem-definition-large.png"),
	"problemDefinition-large@2x": require("assets/images/thumbnails/problemDefinition/problem-definition-large@2x.png"),
	"problemDefinition-large@3x": require("assets/images/thumbnails/problemDefinition/problem-definition-large@3x.png"),
	"problemDefinition-placeholder": require("assets/images/thumbnails/problemDefinition/problem-definition-placeholder.png"),
	solutionSelection: require("assets/images/thumbnails/solutionSelection/solution-selection.png"),
	"solutionSelection@2x": require("assets/images/thumbnails/solutionSelection/solution-selection@2x.png"),
	"solutionSelection@3x": require("assets/images/thumbnails/solutionSelection/solution-selection@3x.png"),
	"solutionSelection-large": require("assets/images/thumbnails/solutionSelection/solution-selection-large.png"),
	"solutionSelection-large@2x": require("assets/images/thumbnails/solutionSelection/solution-selection-large@2x.png"),
	"solutionSelection-large@3x": require("assets/images/thumbnails/solutionSelection/solution-selection-large@3x.png"),
	"solutionSelection-placeholder": require("assets/images/thumbnails/solutionSelection/solution-selection-placeholder.png"),
	experimentation: require("assets/images/thumbnails/experimentation/experimentation.png"),
	"experimentation@2x": require("assets/images/thumbnails/experimentation/experimentation@2x.png"),
	"experimentation@3x": require("assets/images/thumbnails/experimentation/experimentation@3x.png"),
	"experimentation-large": require("assets/images/thumbnails/experimentation/experimentation-large.png"),
	"experimentation-large@2x": require("assets/images/thumbnails/experimentation/experimentation-large@2x.png"),
	"experimentation-large@3x": require("assets/images/thumbnails/experimentation/experimentation-large@3x.png"),
	"experimentation-placeholder": require("assets/images/thumbnails/experimentation/experimentation-placeholder.png"),
	relapseTrac: require("assets/images/thumbnails/relapseTrac/planning-for-success.png"),
	"relapseTrac@2x": require("assets/images/thumbnails/relapseTrac/planning-for-success@2x.png"),
	"relapseTrac@3x": require("assets/images/thumbnails/relapseTrac/planning-for-success@3x.png"),
	"relapseTrac-large": require("assets/images/thumbnails/relapseTrac/planning-for-success-large.png"),
	"relapseTrac-large@2x": require("assets/images/thumbnails/relapseTrac/planning-for-success-large@2x.png"),
	"relapseTrac-large@3x": require("assets/images/thumbnails/relapseTrac/planning-for-success-large@3x.png"),
	"relapseTrac-placeholder": require("assets/images/thumbnails/relapseTrac/planning-for-success-placeholder.png"),
	smartGoals: require("assets/images/thumbnails/smartGoals/smart-goals.png"),
	"smartGoals@2x": require("assets/images/thumbnails/smartGoals/smart-goals@2x.png"),
	"smartGoals@3x": require("assets/images/thumbnails/smartGoals/smart-goals@3x.png"),
	"smartGoals-large": require("assets/images/thumbnails/smartGoals/smart-goals-large.png"),
	"smartGoals-large@2x": require("assets/images/thumbnails/smartGoals/smart-goals-large@2x.png"),
	"smartGoals-large@3x": require("assets/images/thumbnails/smartGoals/smart-goals-large@3x.png"),
	"smartGoals-placeholder": require("assets/images/thumbnails/smartGoals/smart-goals-placeholder.png"),
	painFatigueIntro: require("assets/images/thumbnails/painFatigueIntro/pain-overview.png"),
	"painFatigueIntro@2x": require("assets/images/thumbnails/painFatigueIntro/pain-overview@2x.png"),
	"painFatigueIntro@3x": require("assets/images/thumbnails/painFatigueIntro/pain-overview@3x.png"),
	"painFatigueIntro-large": require("assets/images/thumbnails/painFatigueIntro/pain-overview-large.png"),
	"painFatigueIntro-large@2x": require("assets/images/thumbnails/painFatigueIntro/pain-overview-large@2x.png"),
	"painFatigueIntro-large@3x": require("assets/images/thumbnails/painFatigueIntro/pain-overview-large@3x.png"),
	"painFatigueIntro-placeholder": require("assets/images/thumbnails/painFatigueIntro/pain-overview-placeholder.png"),
	pacing: require("assets/images/thumbnails/pacing/pacing.png"),
	"pacing@2x": require("assets/images/thumbnails/pacing/pacing.png"),
	"pacing@3x": require("assets/images/thumbnails/pacing/pacing.png"),
	"pacing-large": require("assets/images/thumbnails/pacing/pacing-large.png"),
	"pacing-large@2x": require("assets/images/thumbnails/pacing/pacing-large@2x.png"),
	"pacing-large@3x": require("assets/images/thumbnails/pacing/pacing-large@3x.png"),
	"pacing-placeholder": require("assets/images/thumbnails/pacing/pacing-placeholder.png"),
	controlledBreathing: require("assets/images/thumbnails/controlledBreathing/controlled-breathing.png"),
	"controlledBreathing@2x": require("assets/images/thumbnails/controlledBreathing/controlled-breathing@2x.png"),
	"controlledBreathing@3x": require("assets/images/thumbnails/controlledBreathing/controlled-breathing@3x.png"),
	"controlledBreathing-large": require("assets/images/thumbnails/controlledBreathing/controlled-breathing-large.png"),
	"controlledBreathing-large@2x": require("assets/images/thumbnails/controlledBreathing/controlled-breathing-large@2x.png"),
	"controlledBreathing-large@3x": require("assets/images/thumbnails/controlledBreathing/controlled-breathing-large@3x.png"),
	"controlledBreathing-placeholder": require("assets/images/thumbnails/controlledBreathing/controlled-breathing-placeholder.png"),
	stepLadders: require("assets/images/thumbnails/stepLadders/step-ladders.png"),
	"stepLadders@2x": require("assets/images/thumbnails/stepLadders/step-ladders@2x.png"),
	"stepLadders@3x": require("assets/images/thumbnails/stepLadders/step-ladders@3x.png"),
	"stepLadders-large": require("assets/images/thumbnails/stepLadders/step-ladders-large.png"),
	"stepLadders-large@2x": require("assets/images/thumbnails/stepLadders/step-ladders-large@2x.png"),
	"stepLadders-large@3x": require("assets/images/thumbnails/stepLadders/step-ladders-large@3x.png"),
	"stepLadders-placeholder": require("assets/images/thumbnails/stepLadders/step-ladders-placeholder.png"),
	"focus-productivity-procrastination": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination.png"),
	"focus-productivity-procrastination@2x": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination@2x.png"),
	"focus-productivity-procrastination@3x": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination@3x.png"),
	"focus-productivity-procrastination-large": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination-large.png"),
	"focus-productivity-procrastination-large@2x": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination-large@2x.png"),
	"focus-productivity-procrastination-large@3x": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination-large@3x.png"),
	"focus-productivity-procrastination-placeholder": require("assets/images/thumbnails/focus-productivity-procrastination/focus-productivity-procrastination-placeholder.png"),
	"alcohol-and-addictions": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions.png"),
	"alcohol-and-addictions@2x": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions@2x.png"),
	"alcohol-and-addictions@3x": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions@3x.png"),
	"alcohol-and-addictions-large": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions-large.png"),
	"alcohol-and-addictions-large@2x": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions-large@2x.png"),
	"alcohol-and-addictions-large@3x": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions-large@3x.png"),
	"alcohol-and-addictions-placeholder": require("assets/images/thumbnails/alcohol-and-addictions/alcohol-and-addictions-placeholder.png"),
	// Manager Training
	"leading-wellbeing": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing.png"),
	"leading-wellbeing@2x": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing@2x.png"),
	"leading-wellbeing@3x": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing@3x.png"),
	"leading-wellbeing-large": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing-large.png"),
	"leading-wellbeing-large@2x": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing-large@2x.png"),
	"leading-wellbeing-large@3x": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing-large@3x.png"),
	"leading-wellbeing-placeholder": require("assets/images/thumbnails/leading-wellbeing/leading-wellbeing-placeholder.png"),
	"leading-mental-health": require("assets/images/thumbnails/leading-mental-health/leading-mental-health.png"),
	"leading-mental-health@2x": require("assets/images/thumbnails/leading-mental-health/leading-mental-health@2x.png"),
	"leading-mental-health@3x": require("assets/images/thumbnails/leading-mental-health/leading-mental-health@3x.png"),
	"leading-mental-health-large": require("assets/images/thumbnails/leading-mental-health/leading-mental-health-large.png"),
	"leading-mental-health-large@2x": require("assets/images/thumbnails/leading-mental-health/leading-mental-health-large@2x.png"),
	"leading-mental-health-large@3x": require("assets/images/thumbnails/leading-mental-health/leading-mental-health-large@3x.png"),
	"leading-mental-health-placeholder": require("assets/images/thumbnails/leading-mental-health/leading-mental-health-placeholder.png"),
	// Finance
	"mindfulness-for-financial-stress": require("assets/images/thumbnails/mindfulness-for-financial-stress/mindfulness-for-financial-stress.png"),
	"mindfulness-for-financial-stress@2x": require("assets/images/thumbnails/mindfulness-for-financial-stress/mindfulness-for-financial-stress@2x.png"),
	"mindfulness-for-financial-stress@3x": require("assets/images/thumbnails/mindfulness-for-financial-stress/mindfulness-for-financial-stress@3x.png"),
	"mindfulness-for-financial-stress-large": require("assets/images/thumbnails/mindfulness-for-financial-stress/mindfulness-for-financial-stress-large.png"),
	"mindfulness-for-financial-stress-large@2x": require("assets/images/thumbnails/mindfulness-for-financial-stress/mindfulness-for-financial-stress-large@2x.png"),
	"mindfulness-for-financial-stress-large@3x": require("assets/images/thumbnails/mindfulness-for-financial-stress/mindfulness-for-financial-stress-large@3x.png"),
	"financial-worries": require("assets/images/thumbnails/financial-worries/financial-worries.png"),
	"financial-worries@2x": require("assets/images/thumbnails/financial-worries/financial-worries@2x.png"),
	"financial-worries@3x": require("assets/images/thumbnails/financial-worries/financial-worries@3x.png"),
	"financial-worries-large": require("assets/images/thumbnails/financial-worries/financial-worries-large.png"),
	"financial-worries-large@2x": require("assets/images/thumbnails/financial-worries/financial-worries-large@2x.png"),
	"financial-worries-large@3x": require("assets/images/thumbnails/financial-worries/financial-worries-large@3x.png"),
	"financial-skills": require("assets/images/thumbnails/financial-skills/financial-skills.png"),
	"financial-skills@2x": require("assets/images/thumbnails/financial-skills/financial-skills@2x.png"),
	"financial-skills@3x": require("assets/images/thumbnails/financial-skills/financial-skills@3x.png"),
	"financial-skills-large": require("assets/images/thumbnails/financial-skills/financial-skills-large.png"),
	"financial-skills-large@2x": require("assets/images/thumbnails/financial-skills/financial-skills-large@2x.png"),
	"financial-skills-large@3x": require("assets/images/thumbnails/financial-skills/financial-skills-large@3x.png"),
	"financial-stress-and-thinking": require("assets/images/thumbnails/financial-stress-and-thinking/financial-stress-and-thinking.png"),
	"financial-stress-and-thinking@2x": require("assets/images/thumbnails/financial-stress-and-thinking/financial-stress-and-thinking@2x.png"),
	"financial-stress-and-thinking@3x": require("assets/images/thumbnails/financial-stress-and-thinking/financial-stress-and-thinking@3x.png"),
	"financial-stress-and-thinking-large": require("assets/images/thumbnails/financial-stress-and-thinking/financial-stress-and-thinking-large.png"),
	"financial-stress-and-thinking-large@2x": require("assets/images/thumbnails/financial-stress-and-thinking/financial-stress-and-thinking-large@2x.png"),
	"financial-stress-and-thinking-large@3x": require("assets/images/thumbnails/financial-stress-and-thinking/financial-stress-and-thinking-large@3x.png"),
	"recharging-your-battery": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery.png"),
	"recharging-your-battery-placeholder": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery-placeholder.png"),
	"recharging-your-battery@2x": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery@2x.png"),
	"recharging-your-battery@3x": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery@3x.png"),
	"recharging-your-battery-large2x": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery-large.png"),
	"recharging-your-battery-large@2x": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery-large@2x.png"),
	"recharging-your-battery-large@3x": require("assets/images/thumbnails/recharging-your-battery/recharging-your-battery-large@3x.png"),
	"getting-support": require("assets/images/thumbnails/getting-support/getting-support.png"),
	"getting-support-placeholder": require("assets/images/thumbnails/getting-support/getting-support-placeholder.png"),
	"getting-support@2x": require("assets/images/thumbnails/getting-support/getting-support@2x.png"),
	"getting-support@3x": require("assets/images/thumbnails/getting-support/getting-support@3x.png"),
	"getting-support-large": require("assets/images/thumbnails/getting-support/getting-support-large.png"),
	"getting-support-large@2x": require("assets/images/thumbnails/getting-support/getting-support-large@2x.png"),
	"getting-support-large@3x": require("assets/images/thumbnails/getting-support/getting-support-large@3x.png"),
	"wellbeing-foundations": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations.png"),
	"wellbeing-foundations-placeholder": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations-placeholder.png"),
	"wellbeing-foundations@2x": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations@2x.png"),
	"wellbeing-foundations@3x": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations@3x.png"),
	"wellbeing-foundations-large": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations-large.png"),
	"wellbeing-foundations-large@2x": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations-large@2x.png"),
	"wellbeing-foundations-large@3x": require("assets/images/thumbnails/wellbeing-foundations/wellbeing-foundations-large@3x.png")
}
