export const coronaToleratingUncertainty = [
	{
		_id: 0,
		text:
			"A key skill for dealing with stress is about tolerating uncertainty. I’ll show you an exercise that can help you build it up",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "First of all, let’s check to see how your mind is trying to deal with uncertainty.",
		quickReplies: {
			_id: 3,
			_next: 4,
			optionType: "radio",
			surveyId: "survey-corona-tolerating-uncertainty-v1",
			taskId: "exercise-corona-tolerating-uncertainty",
			questions: [0, 1, 2],
			recurring: true,
			values: [
				{
					label: "Alright",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 4,
		text: "Ok, thanks for that. Now let’s look at how to tolerate uncertainty.",
		quickReplies: {
			_id: 5,
			_next: 6,
			optionType: "radio",
			surveyId: "survey-corona-tolerating-uncertainty-v1",
			taskId: "exercise-corona-tolerating-uncertainty",
			questions: [3, 4, 5],
			recurring: true,
			values: [
				{
					label: "👍",
					value: "okay",
					questionIndex: 3,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text:
			"If it’s not easily solvable or not completely in your control to solve, are you open to trying radical acceptance?",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			values: [
				{
					label: "Ok, I’ll give it a try",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		text: "It’s a tricky skill but here are a few steps to try...",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			surveyId: "survey-corona-tolerating-uncertainty-v1",
			taskId: "exercise-corona-tolerating-uncertainty",
			questions: [6, 7, 8],
			recurring: true,
			values: [
				{
					label: "Let’s go",
					value: "okay",
					questionIndex: 6,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		text:
			"Just to clarify some things: radical acceptance doesn’t mean you accept abuse from others. If that’s happening, please get support from a professional",
		quickReplies: {
			_id: 11,
			_next: 12,
			optionType: "radio",
			values: [
				{
					label: "Will do",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		text:
			"Just to clarify some things: radical acceptance doesn’t mean you accept abuse from others. If that’s happening, please get support from a professional",
		quickReplies: {
			_id: 13,
			_next: 14,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 14,
		_end: true,
		text: "Taking you back to the skill dashboard.. 👋",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
