import React, {useState, useEffect, Fragment} from "react"
import Styled from "styled-components"
// Components
import {Card} from "@uprise/card"
// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {backgrounds} from "@uprise/colors"
// Content
import LandingContent from "components/Rtw/PreOnboarding/LandingContent"
import PreOnboardingConsent from "components/Rtw/PreOnboarding/PreOnboardingConsent"
import PreOnboardingConsentContacts from "components/Rtw/PreOnboarding/PreOnboardingConsentContacts"

const Wrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

export default function PreOnboardingLanding({handleNext, updateHeader, surveyResults, name}) {
	const [loading, setLoading] = useState(false)
	const [consentCheckpoint, setConsentCheckpoint] = useState(false)
	const [consentContactsCheckpoint, setConsentContactsCheckpoint] = useState(false)
	const [landingIntro, setLandingIntro] = useState(true)

	useEffect(() => {
		surveyResults.forEach(result => {
			if (
				(result.taskId === "survey-pre-onboarding-rtw" && result.answers["RTW_PRE_SCREENER"][0] === 0) ||
				(result.taskId === "survey-pre-onboarding-rtw" && result.answers["RTW_PRE_SCREENER"][1] === 0) ||
				(result.taskId === "survey-pre-onboarding-rtw" && result.answers["RTW_PRE_SCREENER"][2] === 0) ||
				(result.taskId === "survey-pre-onboarding-rtw" && result.answers["RTW_PRE_SCREENER"][3] === 0) ||
				(result.taskId === "survey-pre-onboarding-rtw" && result.answers["RTW_PRE_SCREENER"][4] === 1) ||
				(result.taskId === "survey-pre-onboarding-rtw" && result.answers["RTW_PRE_SCREENER"][5] === 1)
			) {
				window.location.replace("https://uprise.co/not-eligible-for-rtw")
				setLoading(true)
			}
		})
	}, [])

	const _handleShowConsent = () => {
		updateHeader("Participant Information Statement")
		setConsentCheckpoint(true)
		setLandingIntro(false)
	}

	const _handleShowConsentContacts = () => {
		updateHeader("Consent for Uprise to contact service providers")
		setConsentCheckpoint(false)
		setConsentContactsCheckpoint(true)
	}

	if (loading) {
		return (
			<Wrapper>
				<Card shadow={false} backgroundColor={backgrounds.white} padding={spacing.s6} width='100%'>
					Loading...
				</Card>
			</Wrapper>
		)
	} else {
		return (
			<Fragment>
				{landingIntro && <LandingContent name={name} handleNext={() => _handleShowConsent(2)} />}
				{consentCheckpoint && <PreOnboardingConsent handleNext={() => _handleShowConsentContacts()} />}
				{consentContactsCheckpoint && <PreOnboardingConsentContacts />}
			</Fragment>
		)
	}
}
