export const progress = [
	{
		_id: 0,
		text: "We need your help to complete a 14 question survey. 🙏",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 1,
		text:
			"Without your results our program might not be renewed by your company or institution as they need to know if it’s working or not.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text: "Do you want some information about confidentiality and what we share?",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 4,
			_next: 5,
			optionType: "radio", // or 'checkbox',
			keepIt: false,
			surveyId: "progress-v1",
			taskId: "survey-progress",
			questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			recurring: true,
			values: [
				{
					label: "Yes, I’d like to know what information you share with others",
					value: "more",
					replyType: "dialogue",
					response:
						"We only share de-identified information with people outside of Uprise. That means we never report information that could identify you such as your name, contact information or role. We report overall totals that combine the scores of lots of people together. This allows the people who are paying for you to use this program to see if it’s been effective or not. Sometimes we publish our findings in scientific journals or in our marketing materials. Again, we only publish aggregated data and never use names or identifiable information.",
					quickReplies: {
						_id: 4,
						_next: 5,
						optionType: "radio", // or 'checkbox',
						replyType: "survey",
						keepIt: false,
						surveyId: "progress-v1",
						taskId: "survey-progress",
						questions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
						recurring: true,
						values: [
							{
								label: "Thanks, thats good to hear, let’s start.",
								value: "start",
								questionIndex: 0
							}
						]
					}
				},
				{
					label: "No i’m ok to do the survey. Let’s start.",
					value: "start",
					replyType: "survey",
					questionIndex: 0
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 5,
		text: "Thanks for your update.. 🙂",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		_end: true,
		text: "👋 See you next time, I'll take you back.",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
