import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import Styled from "styled-components"
import {connect} from "react-redux"

// actions
import {getCoaches, getCalendar} from "actions/coachActions"
// Utils
import {filterCoachesByCourseType} from "./utils"
// utils
import {sortCoaches} from "helpers/utils"
// Components
import CoachCard from "components/Shared/Coach/CoachCard"
import EmptyCoach from "components/Shared/Coach/EmptyCoach"
import Preloader from "components/Shared/Preloader/Preloader"

const CoachStyles = Styled.section`
`

const CoachList = ({className, userState, getCoaches, coaches, setIsOpen, getCalendar}) => {
	const [coachList, setCoachList] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		getCoaches()
		getCalendar()
	}, [])

	useEffect(() => {
		setLoading(true)
		renderCoaches(coaches.data)
	}, [coaches])

	const renderCoaches = data => {
		if (data) {
			const sorted = sortCoaches(coaches, coaches.sort)
			let filteredCoaches = filterCoachesByCourseType(sorted, userState?.courseType)

			filteredCoaches = filteredCoaches?.map((coach, index) => {
				let hasHours = false
				// check if hours are present
				if (!coach.hours) hasHours = false
				else
					Object.entries(coach.hours)?.find(hour => {
						if (hour?.[1]?.length) {
							hasHours = true
							return true
						}
					})

				if (hasHours) return <CoachCard key={index} className='m-b-10' coach={coach} pick={true} />
				else return null
			})

			setCoachList(filteredCoaches)
			setLoading(false)
		}
	}

	if (loading || coaches.loading)
		return <Preloader center={true} className={"w-100 h-100 d-flex align-items-center"} />
	else if (coachList.length) return <CoachStyles className={className}>{coachList}</CoachStyles>
	else return <EmptyCoach setIsOpen={setIsOpen} />
}

CoachList.propTypes = {
	className: PropTypes.string,
	userState: PropTypes.object.isRequired
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		coachEmail: state?.userState?.coach,
		coach: state?.coach,
		coaches: state?.coaches
	}
}

const mapDispatchToProps = dispatch => ({
	getCoaches: () => dispatch(getCoaches()),
	getCalendar: () => dispatch(getCalendar())
})

export default connect(mapStateToProps, mapDispatchToProps)(CoachList)
