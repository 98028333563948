export const coronaAnxietyOdds = [
	{
		_id: 0,
		text:
			"One of the ways to deal with stress about getting a disease like the coronavirus is to examine how health anxiety can make us overestimate the chance of getting it and underestimate our ability to cope if we did get it. ",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 1,
			_next: 2,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 2,
		text:
			"This exercise is easier if you’ve already watched the Mindset video that explains the Retraining Thinking skill",
		createdAt: new Date(),
		replyType: "dialogue",
		quickReplies: {
			_id: 3,
			_next: 6,
			optionType: "radio",
			values: [
				{
					label: "Show me the video that explains Retraining Thinking",
					videoURI:
						"https://player.vimeo.com/external/218729113.sd.mp4?s=8e3f73f332bca4871345a883706fcbccaf691327&profile_id=165",
					vimeoId: "218729113",
					value: "video",
					taskId: "video-retraining",
					replyType: "dialogue",
					quickReplies: {
						_id: 4,
						_next: 6,
						optionType: "radio",
						replyType: "dialogue",
						values: [
							{
								label: "I’m ok to keep going",
								value: "next"
							}
						]
					}
				},
				{
					label: "I’m ok to keep going",
					value: "next"
				}
			]
		},
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 6,
		text: "So let’s do an exercise with you to deal with this bias and hopefully feel less worried and stressed.",
		quickReplies: {
			_id: 7,
			_next: 8,
			optionType: "radio",
			surveyId: "survey-corona-anxiety-odds-v1",
			taskId: "exercise-corona-anxiety-odds",
			questions: [0],
			recurring: true,
			values: [
				{
					label: "Let’s do it",
					value: "start",
					questionIndex: 0,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 8,
		text: "Now let’s try to figure out the actual risk of getting coronavirus…",
		quickReplies: {
			_id: 9,
			_next: 10,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 10,
		text: "Can you do a google search for the number of confirmed cases in your country?",
		quickReplies: {
			_id: 11,
			_next: 12,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 12,
		text: "Now divide that number by the total population of your country",
		quickReplies: {
			_id: 13,
			_next: 14,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 14,
		text: "That’s your chance of getting coronavirus",
		quickReplies: {
			_id: 15,
			_next: 16,
			optionType: "radio",
			values: [
				{
					label: "Got it",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 16,
		text:
			"Now if you did get the virus then your change of having a mild case is 4 in 5 so you’d need to take your chance of getting sick and divide it by 5",
		quickReplies: {
			_id: 17,
			_next: 18,
			optionType: "radio",
			values: [
				{
					label: "Ok, chances are even lower",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 18,
		text:
			"If you did happen to get a very severe case then the chance of getting a case where you need to go to an intensive care unit is about 1 in 25 so you’d have to take your chance and divide by 25",
		quickReplies: {
			_id: 19,
			_next: 20,
			optionType: "radio",
			values: [
				{
					label: "All this maths is hurting but I get that it’s a lower chance",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 20,
		text: "Now let’s look at your chances of coping if you did happen to get the virus",
		quickReplies: {
			_id: 21,
			_next: 22,
			optionType: "radio",
			surveyId: "survey-corona-anxiety-odds-v1",
			taskId: "exercise-corona-anxiety-odds",
			questions: [1, 2, 3, 4, 5],
			recurring: true,
			values: [
				{
					label: "Ok",
					value: "okay",
					questionIndex: 1,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 22,
		text:
			"Thanks, if you answered yes to most these then the chances are better that you would recover from the virus",
		quickReplies: {
			_id: 23,
			_next: 24,
			optionType: "radio",
			values: [
				{
					label: "That’s good to know",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 24,
		text: "As a guide 99 in 100 people with no other health problems have recovered well from COVID-19",
		quickReplies: {
			_id: 25,
			_next: 26,
			optionType: "radio",
			values: [
				{
					label: "Ok, so most healthy people are fine",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 26,
		text: "And 499 out of 500 people under 50 recover well",
		quickReplies: {
			_id: 27,
			_next: 28,
			optionType: "radio",
			values: [
				{
					label: "Ok, almost all younger people recover",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 28,
		text: "249 out of 250 people under 60 recover",
		quickReplies: {
			_id: 29,
			_next: 30,
			optionType: "radio",
			surveyId: "survey-corona-anxiety-odds-v1",
			taskId: "exercise-corona-anxiety-odds",
			questions: [6],
			recurring: true,
			values: [
				{
					label: "Got it",
					value: "okay",
					questionIndex: 6,
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 30,
		text:
			"Thanks for doing the exercise with me. Hopefully this helped a little but it’s normal if you still feel anxious.",
		quickReplies: {
			_id: 31,
			_next: 32,
			optionType: "radio",
			values: [
				{
					label: "Ok",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 32,
		text:
			"I’d recommend that you keep doing the other retraining thinking exercises in this course as you’ll need to do a few of them before to start to see a difference.",
		quickReplies: {
			_id: 33,
			_next: 34,
			optionType: "radio",
			values: [
				{
					label: "Understood",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 34,
		text:
			"The goal was to help you to think as realistically as possible about the odds of getting sick and the chance that you’d cope well if you did fall ill. We hope it helped you to feel a little less worried about getting sick.",
		quickReplies: {
			_id: 35,
			_next: 36,
			optionType: "radio",
			values: [
				{
					label: "👍",
					value: "next",
					replyType: "dialogue"
				}
			]
		},
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	},
	{
		_id: 36,
		_end: true,
		text: "I've placed your notes in your notebook 👍",
		createdAt: new Date(),
		replyType: "dialogue",
		user: {
			_id: 2,
			name: "Uprise"
		}
	}
]
