const constraints = {
	email: {
		presence: {
			message: "^Please enter an email address"
		},
		email: {
			message: "^Please enter a valid email address",
			allowEmpty: false
		}
	},

	firstName: {
		presence: {
			message: "^Please enter first name",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^Your first name must be at least 1 characters"
		}
	},
	lastName: {
		presence: {
			message: "^Please enter last name",
			allowEmpty: false
		},
		length: {
			minimum: 2,
			message: "^Your last name must be at least 1 characters"
		}
	},
	password: {
		presence: {
			message: "^Please enter a password",
			allowEmpty: false
		},
		length: {
			minimum: 10,
			message: "^Your password must be at least 10 characters"
		},
		format: {
			pattern: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$`, //eslint-disable-line
			flags: "i",
			message: "^Password must contain lowercase, uppercase and 1 special character and 1 number"
		}
	},
	phone: {
		presence: {
			message: "^Please enter a phone number",
			allowEmpty: false
		},
		format: {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
			message: "Must be a valid phone number"
		},
		length: {
			minimum: 3,
			message: "Must be at least 3 digits in length"
		}
	}
}

export default constraints
