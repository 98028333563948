import {STORE_TOPICS, LOADING_TOPICS} from "../actions/types"

const INITIAL_STATE = {
	loading: false,
	topics: []
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case STORE_TOPICS:
			return {...state, ...{topics: action.payload}}
		case LOADING_TOPICS:
			return {...state, loading: action.payload}
		default:
			return state
	}
}
