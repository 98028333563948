import React, {useState} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
import {Alert} from "@uprise/alert"
import LargeSurvey from "components/Rtw/survey/large/LargeSurvey"
import {getScreeners, postProgramTask, post3MonthTask} from "./utils"
import {H5} from "@uprise/headings"

const PostProgramAssessmentWrapper = Styled.div`
	max-width: 900px;
	margin: 0 auto;
`

function PostProgramAssessment(props) {
	const [alertType, setAlertType] = useState("primary")
	const task = props.isThreeMonthSurvey ? post3MonthTask : postProgramTask
	const screeners = getScreeners(props.userState, {isThreeMonthSurvey: props.isThreeMonthSurvey})

	return (
		<PostProgramAssessmentWrapper className='onboarding-assesment rtw-onboarding-assesment'>
			<div className='row'>
				<div className='col-md-12'>
					{props.activeScreener === "CSQ8" ? (
						<Alert className='mb-5' textAlign='center' type={alertType}>
							Please help us improve our service by answering some questions about the help that you have
							received. We are interested in your honest opinions, whether they are positive or negative.
							Please answer all of the questions. We also welcome your comments and suggestions. Thank you
							very much. We appreciate your help.
						</Alert>
					) : (
						<Alert className='mb-5' textAlign='center' type={alertType}>
							All questions are mandatory
						</Alert>
					)}

					<H5 className='m-b-5'>Please answer the questions below to proceed</H5>

					<LargeSurvey
						module='onboarding'
						taskId={props.isThreeMonthSurvey ? "survey-postprogram-3-month-rtw" : "survey-postprogram-rtw"}
						surveyId={task.surveyId}
						task={task}
						surveyResults={props.userState.surveyResults}
						screener={props.activeScreener}
						screeners={screeners}
						done='booking'
						handleChange={props.handleChange}
						setAlertType={setAlertType}
					/>
				</div>
			</div>
		</PostProgramAssessmentWrapper>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState
	}
}

export default connect(mapStateToProps, null)(PostProgramAssessment)
