import {STORE_SUGGESTED} from "actions/types"

export default function(state = {}, action) {
	switch (action.type) {
		case STORE_SUGGESTED: {
			return {...state, ...action.suggested}
		}
		default:
			return {
				...state
			}
	}
}
