import React from "react"
import {func, object, bool, string} from "prop-types"
import moment from "moment-timezone"

const baseClass = "btn__booking-bar"

export const isCancellable = start => moment().isBefore(start.clone().subtract(24, "hours"), "minute")

const BookingSideBarButton = ({booking, taskId, disabled, skillName, onCreate, onCancel}) => {
	const canItBeCancelled = booking && isCancellable(booking.start)
	const action = booking === undefined ? () => onCreate(taskId) : canItBeCancelled ? () => onCancel(taskId) : () => {}
	const className =
		baseClass +
		(booking ? " has-booking" : "") +
		(canItBeCancelled ? " is-editable" : "") +
		(!canItBeCancelled && booking !== undefined ? " disabled" : "")

	const button =
		booking === undefined ? (
			<button
				type='button'
				className={` ${className} ${disabled ? "btn__booking-bar--trial" : ""}`}
				onClick={action}
				disabled={disabled}>
				<p className='list-group-item-heading'>{skillName}</p>
			</button>
		) : (
			<button type='button' className={className} onClick={action}>
				<span className='btn__booking-bar__text'>
					<span className='btn__booking-bar__skill'>
						<b>{skillName}</b>
					</span>
					<span className='btn__booking-bar__coach'>
						<b>with {booking.coachName}</b>
					</span>
				</span>
				<span className='btn__booking-bar__text btn__booking-bar__date'>
					{moment(booking.start).format("dddd, Do MMM")}
				</span>
				<span className='btn__booking-bar__text btn__booking-bar__time'>
					{moment(booking.start).format("h:mma")} - {moment(booking.end).format("h:mma")}
				</span>
			</button>
		)

	return button
}

BookingSideBarButton.propTypes = {
	booking: object,
	disabled: bool,
	taskId: string.isRequired,
	skillName: string.isRequired,
	onCancel: func.isRequired,
	onCreate: func.isRequired
}

export default BookingSideBarButton
