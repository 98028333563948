import React, {useState, Component, Fragment} from "react"
import {connect} from "react-redux"
import Styled from "styled-components"
// Components
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {H2, H3, H4, H5} from "@uprise/headings"
import {Medium} from "@uprise/text"
import {MoreInfoModal} from "components/Shared/CareNavigator/MoreInfoModal"
import {ErrorModal} from "components/Shared/CareNavigator/ErrorModal"
// Colors
import {extended, primary, backgrounds} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"

const UpComingSessionStyles = Styled.section`
`

const ContentWrap = Styled.section`
      display: flex;
      flex-direction: row;
`

const ImageWrap = Styled.section`
      min-width: 275px;
      height: 228px;
      display: flex;
      justify-content: center;
      align-items: center;
`
const ButtonWrap = Styled.section`
      display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-self: flex-end;
`

const ChatNowImage = Styled.img`
      height: 150px;
      width: 195px;
`

const ContentRight = Styled.div`
      padding: ${spacing.s7} ${spacing.s9} ${spacing.s7} 0;
	width: 100%;
	display: flex;
    	flex-direction: column;
    	justify-content: space-between;
`

const Top = Styled.div`
`
const Bottom = Styled.div`
`

const CareNavigator = ({className}) => {
	const [showMoreInfo, setShowMoreInfo] = useState(false)
	const [showError, setShowError] = useState(false)

	return (
		<UpComingSessionStyles className={className}>
			<H3 className='m-b-5'>Connect with your mental health specialist</H3>

			<Card shadow={true} backgroundColor={backgrounds.white}>
				<ContentWrap>
					<ImageWrap>
						<ChatNowImage src={require(`assets/images/art/care-nav/careNav@3x.png`)} />
					</ImageWrap>
					<ContentRight>
						<Top>
							<H5 color={primary.charcoal} className='m-b-2 m-t-0'>
								Get no-pressure support from an expert
							</H5>

							<Medium
								lineHeight={1.5}
								color={extended.charcoal.one}
								className='m-b-6 m-b-xs-3 line-clamp-xs-2'>
								Give them a quick intro about yourself, your goals and if you want a lot of contact or
								just an occasional check in. Anything you feel like writing is fine.
							</Medium>
						</Top>
						<Bottom>
							<ButtonWrap>
								<Button
									className='m-r-3'
									title='Find out more'
									variant='secondary'
									size='medium'
									fullWidth={false}
									width='160px'
									onClick={() => setShowMoreInfo(true)}
								/>

								<MoreInfoModal isOpen={showMoreInfo} handleClose={() => setShowMoreInfo(false)} />
								<ErrorModal isOpen={showError} handleClose={() => setShowError(false)} />

								<Button
									className='m-r-3'
									title='Chat now'
									variant='primary'
									size='medium'
									fullWidth={false}
									width='160px'
									onClick={() => {
										try {
											Intercom("show")
										} catch (e) {
											console.log(e)
											setShowError(true)
										}
									}}
								/>
							</ButtonWrap>
						</Bottom>
					</ContentRight>
				</ContentWrap>
			</Card>
		</UpComingSessionStyles>
	)
}

function mapStateToProps(state) {
	return {
		userState: state.userState,
		dispatch: state.dispatch
	}
}

export default connect(mapStateToProps)(CareNavigator)
