import React, {Fragment, useState, useEffect} from "react"
import {object, func} from "prop-types"
import Styled from "styled-components"

// Spacing
import {spacing} from "@uprise/spacing"
// Colors
import {extended, backgrounds} from "@uprise/colors"
// Typography
import {text} from "@uprise/typography"

import {ProgressBar} from "@uprise/charts/ProgressBar"

const ProgressStyles = Styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
	padding: 0 2.5rem 0 2.5rem;
	height: ${props => props.height}px;
	transition: ${props => (props.height > 50 ? "height 0.15s ease-out" : "height 0.35s ease-out")}; ;
	background-color: ${props => (props.height > 50 ? backgrounds.white : "transparent")};
	box-shadow: ${props => (props.height > 50 ? `inset 0 -1px 0 0  ${extended.purple.five}` : "0")};

	&:before {
		content: ' ';
		position: absolute;
		display: ${props => (props.height > 1 ? "block" : "none")};
		background-color: ${backgrounds.white};
		width: 10px;
		height: ${props => props.height - 1}px;
		margin-left: -50px;
		top: 0;
	}

	@media (max-width: 1024px) {
		width: 300px
	}
`

const CompletedStyles = Styled.div`
      margin-left: ${spacing.s5}
      font-size: ${text.t7}
`

export const SkillProgress = ({totalTasks, completedTasks, className}) => {
	const [overviewVideo, setOverviewVideo] = useState(null)
	const [progressHeight, setProgressHeight] = useState(100)
	const percentage = (completedTasks / totalTasks) * 100

	useEffect(() => {
		window.addEventListener("scroll", _handleScroll)
	}, [])

	const _handleScroll = () => {
		if (window.innerWidth > 1200 && document.documentElement.scrollTop <= 2) {
			setProgressHeight(100)
		} else if (window.innerWidth > 1200) {
			setProgressHeight(0)
		}
	}

	return (
		<ProgressStyles className={className} height={progressHeight}>
			{progressHeight > 50 && (
				<Fragment>
					<ProgressBar percentage={percentage} height='4px' radius={5} />
					<CompletedStyles data-testid={`completed-tasks`}>
						{completedTasks}/{totalTasks}
					</CompletedStyles>
				</Fragment>
			)}
		</ProgressStyles>
	)
}
